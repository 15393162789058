import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AiFillPlusCircle,
  AiFillCheckCircle,
  AiFillDelete,
} from 'react-icons/ai';

import api from '~/services/api';

import {
  Container,
  Welcome,
  AddFranchise,
  Franchise,
  Modal,
  FranchiseButton,
} from './styles';

import videoGallery from '~/assets/icons/video-gallery-icon.svg';

interface IFranchiseResponse {
  id: string;
  name: string;
  thumbnail: {
    thumbnail_url: string;
  };
}

interface IFranchise {
  id: string;
  name: string;
  thumbnail_url: string;
  selected?: boolean;
}

interface IParams {
  userID: string;
}

const Franchises: React.FC = () => {
  const params = useParams<IParams>();
  const [userId, setUserId] = useState('');
  const [franchises, setFranchises] = useState<IFranchise[]>([]);
  const [franchisesData, setFranchisesData] = useState<IFranchise[]>([]);
  const [franchisesSelected, setFranchisesSelected] = useState<string[]>([]);
  const [show, setShow] = useState(false);
  const [franchiseDeleteSelected, setFranchiseDeleteSelected] = useState<
    IFranchise
  >({} as IFranchise);
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    api
      .get(`users/admin/${params.userID}`)
      .then(async (responseUser) => {
        const response = await api.get<IFranchiseResponse[]>(
          `products/user/${responseUser.data.referral_code}`
        );
        const data: IFranchise[] = response.data.map(
          (franchise) => ({
            id: franchise.id,
            name: franchise.name,
            thumbnail_url: franchise.thumbnail
              ? franchise.thumbnail.thumbnail_url
              : '',
          }),
          []
        );
        if (responseUser.data) {
          setUserId(responseUser.data.id);
        }

        setFranchises(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params]);

  const handleShow = useCallback(async () => {
    const response = await api.get<IFranchiseResponse[]>(`products`);
    const dataBefore: IFranchise[] = response.data.map((franchise) => ({
      id: franchise.id,
      name: franchise.name,
      thumbnail_url: franchise.thumbnail
        ? franchise.thumbnail.thumbnail_url
        : '',
    }));

    const data: IFranchise[] = [];
    dataBefore.forEach((franchiseBefore) => {
      const franchiseFound = franchises.find(
        (franchise) => franchise.id === franchiseBefore.id
      );
      if (!franchiseFound) {
        data.push(franchiseBefore);
      }
    });

    setFranchisesData(data);
    setShow(true);
  }, [franchises]);

  const handleShowDelete = useCallback((franchise) => {
    setFranchiseDeleteSelected(franchise);
    setShowDelete(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
    setShowDelete(false);
  }, []);

  const handleSelect = useCallback(
    (franchise_id) => {
      const franchiseExistIndex = franchisesSelected.findIndex(
        (franchise) => franchise === franchise_id
      );
      const newFranchisesData = franchisesData.slice();

      const franchiseDataIndex = newFranchisesData.findIndex(
        (franchise) => franchise.id === franchise_id
      );

      let newFranchisesSelected = franchisesSelected.slice();
      if (franchiseExistIndex >= 0) {
        newFranchisesSelected = franchisesSelected.filter(
          (franchise) => franchise !== franchise_id
        );
        if (franchiseDataIndex >= 0) {
          newFranchisesData[franchiseDataIndex].selected = false;
        }
      } else {
        newFranchisesSelected.push(franchise_id);

        if (franchiseDataIndex >= 0) {
          newFranchisesData[franchiseDataIndex].selected = true;
        }
      }

      setFranchisesData(newFranchisesData);
      setFranchisesSelected(newFranchisesSelected);
    },
    [franchisesData, franchisesSelected]
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const save = new Promise<void>((resolve) => {
        if (franchisesSelected.length > 0) {
          const newFranchises = franchises.slice();
          franchisesSelected.forEach(async (franchise_id, index) => {
            const formData = {
              user_id: userId,
              product_id: franchise_id,
            };
            await api.post('affiliates-products', formData);
            const franchise = franchisesData.find(
              (franchiseData) => franchiseData.id === franchise_id
            );
            if (franchise) {
              newFranchises.push(franchise);
            }

            if (franchisesSelected.length === index + 1) {
              resolve();
            }
          });
          setFranchises(newFranchises);
        } else {
          resolve();
        }
      });

      await save;
      setShow(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [franchises, franchisesData, franchisesSelected, userId]);

  const handleSubmitDelete = useCallback(async () => {
    await api.delete(
      `affiliates-products/${userId}/${franchiseDeleteSelected.id}`
    );
    const newFranchises = franchises.filter(
      (franchise) => franchise.id !== franchiseDeleteSelected.id
    );
    setFranchises(newFranchises);
    handleClose();
  }, [franchiseDeleteSelected.id, franchises, handleClose, userId]);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <Welcome className="row mb-3">
          <div className="col-12">
            <h1 className="h2 font-weight-bold">Products</h1>
          </div>
        </Welcome>
        <div className="row bg-gray pt-5 px-5">
          <div className="col-lg-4 mb-5">
            <AddFranchise type="button" onClick={handleShow}>
              <img src={videoGallery} alt="Add franchise" />
              <p className="h5">Add product</p>
            </AddFranchise>
          </div>
          {franchises.map((franchise) => (
            <div key={franchise.id} className="col-lg-4 mb-5">
              <Franchise
                src={franchise.thumbnail_url}
                className="py-5 px-3"
                onClick={() => handleShowDelete(franchise)}
              >
                <h2 className="h5">{franchise.name}</h2>
                <div className="p-absolute delete-box">
                  <AiFillDelete />
                </div>
              </Franchise>
            </div>
          ))}
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="mx-auto mt-3">Select new product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row bg-gray pt-3 px-3">
            {franchisesData.length > 0 ? (
              <>
                {franchisesData.map((franchise) => (
                  <div key={franchise.id} className="col-lg-4 mb-3">
                    <FranchiseButton
                      src={franchise.thumbnail_url}
                      selected={franchise.selected}
                      className="py-4 px-3"
                      onClick={() => handleSelect(franchise.id)}
                    >
                      <h2 className="h6">{franchise.name}</h2>
                      {franchise.selected ? (
                        <AiFillCheckCircle />
                      ) : (
                        <AiFillPlusCircle />
                      )}
                    </FranchiseButton>
                  </div>
                ))}
              </>
            ) : (
              <div className="col-12">
                <h2 className="h3 text-center">This user have all products</h2>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn-save py-3"
            onClick={handleSubmit}
          >
            <span className="d-block font-weight-bold">Save</span>
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="mx-auto mt-3">
            Remove product "{franchiseDeleteSelected.name}" of user
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center mb-4">
            <button
              type="button"
              className="btn-save border-silver mx-3"
              onClick={handleClose}
            >
              <span className="d-block py-1">NO</span>
            </button>
            <button
              type="button"
              className="btn-save border-delete mx-3"
              onClick={handleSubmitDelete}
            >
              <span className="d-block py-1">YES</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default Franchises;
