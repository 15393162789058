import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import { HiOutlineTrash } from 'react-icons/hi';

import { Container, ThumbnailPreview, Resource, InputUrl } from './styles';

import imgThumb from '~/assets/icons/files-icon.svg';
import Input from '../Input';

interface fileProps {
  preview?: string;
}

export interface resourceTempProps {
  preview?: string;
  thumb?: File;
  resource?: string;
}

interface resourceProps {
  active: boolean;
  data?: fileProps[];
  onChange?(data: resourceTempProps[]): void;
}

const Resources: React.FC<resourceProps> = ({ active, data, onChange }) => {
  const [resources, setResources] = useState<resourceTempProps[]>([]);
  const [resourceTemp, setResourceTemp] = useState<resourceTempProps>(
    {} as resourceTempProps
  );
  const [thumbPreviewTemp, setThumbPreviewTemp] = useState('');
  const [resource, setResource] = useState('');

  useEffect(() => {
    if (data) {
      setResources(data);
    }
  }, [data]);

  useEffect(() => {
    if (resourceTemp.thumb && resourceTemp.resource) {
      setResources((state) => [...state, ...[resourceTemp]]);
      setThumbPreviewTemp('');
      setResourceTemp({} as resourceTempProps);
    }
  }, [resourceTemp]);

  useEffect(() => {
    if (onChange) {
      onChange(resources);
    }
  }, [resources, onChange]);

  const handleChangeThumb = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        const preview = URL.createObjectURL(file);
        setThumbPreviewTemp(preview);
        let tempData;
        if (resourceTemp.resource) {
          tempData = {
            preview,
            thumb: file,
            resource: resourceTemp.resource,
          };
          const element = document.getElementById(
            'resource'
          ) as HTMLInputElement;
          if (element) {
            element.value = '';
          }
          setResource('');
        } else {
          tempData = {
            preview,
            thumb: file,
          };
        }

        setResourceTemp(tempData);
      }
    },
    [resourceTemp.resource]
  );

  const handleChangeResource = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setResource(e.target.value);
    },
    []
  );

  const handleClickSaveUrl = useCallback(() => {
    if (resource) {
      let tempData;
      if (resourceTemp.thumb) {
        tempData = {
          preview: resourceTemp.preview,
          thumb: resourceTemp.thumb,
          resource,
        };
        const element = document.getElementById('resource') as HTMLInputElement;
        if (element) {
          element.value = '';
        }
        setResource('');
      } else {
        tempData = {
          resource,
        };
      }

      setResourceTemp(tempData);
    }
  }, [resourceTemp.preview, resourceTemp.thumb, resource]);

  const deleteFile = useCallback(
    (index) => {
      const newResources = resources.filter((_, idx) => index !== idx);
      setResources(newResources);
    },
    [resources]
  );

  return (
    <Container className={`${active ? 'd-block' : 'd-none'}`}>
      <h2 className="h5 mb-3">
        Here are you free bonus resources for this lesson!
      </h2>
      <div className="row">
        <div className="col-lg-4 my-2">
          <ThumbnailPreview
            src={thumbPreviewTemp}
            htmlFor="thumb"
            className="d-flex flex-column justify-content-center align-items-center card-resource btn py-4 px-0 w-100 text-center mb-2"
          >
            {!thumbPreviewTemp && (
              <>
                <img src={imgThumb} alt="plus" className="w-50 mb-3" />
                <p className="small file-upload mb-0">
                  Upload your file here or{' '}
                  <span className="d-block">Browse thumbnail</span>
                </p>
              </>
            )}
            <input
              id="thumb"
              type="file"
              className="d-none"
              onChange={handleChangeThumb}
            />
          </ThumbnailPreview>
          <InputUrl className="d-flex w-100 mt-3 ">
            <Input
              type="url"
              name="resource"
              id="resource"
              onChange={handleChangeResource}
              className="border-0 py-3"
              placeholder="Resource url"
            />
            <button
              type="button"
              className="btn btn-save"
              onClick={handleClickSaveUrl}
            >
              Save
            </button>
          </InputUrl>
        </div>
        {resources.map((file, index) => (
          <div key={index} className="col-lg-4 my-2">
            <Resource
              src={file.preview || imgThumb}
              className="w-100 d-flex justify-content-end align-items-end p-3"
            >
              <button type="button" onClick={() => deleteFile(index)}>
                <HiOutlineTrash color="#FF1A50" size={24} />
              </button>
            </Resource>
            <p className="fileName">{file.resource}</p>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Resources;
