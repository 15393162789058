import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { format, parseISO } from 'date-fns';
import api from '~/services/api';

import { Container, Welcome, Modal } from './styles';
import Table from '~/components/Table';

import { formatPrice } from '~/utils/format';

interface IOrderResponse {
  id: string;
  amount_paid: string;
  status: string;
  installments: number;
  updated_at: string;
  product: {
    name: string;
  };
  user: {
    name: string;
  };
}

interface IOrder {
  id: string;
  sales_date: string;
  amount_paid: string;
  installments: string;
  status: string;
  product: string;
  user: string;
}

const Orders: React.FC = () => {
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [orderSelected, setOrderSelected] = useState({} as IOrder);
  const [show, setShow] = useState(false);

  const handleLoadOrders = useCallback(async () => {
    const response = await api.get<IOrderResponse[]>('orders/admin', {
      params: {
        search: filter,
        onlyFirst: true,
      },
    });
    const data = response.data.map<IOrder>((order) => ({
      id: order.id,
      user: order.user?.name || '-',
      product: order.product?.name || '-',
      amount_paid: formatPrice(parseFloat(order.amount_paid)),
      installments: order.installments.toString().padStart(2, '0'),
      sales_date: format(parseISO(order.updated_at), 'yyyy-MM-dd'),
      status: order.status,
    }));
    setOrders(data);
    setLoading(false);
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    handleLoadOrders();
  }, [filter, handleLoadOrders]);

  const handleSearch = useCallback((value) => {
    setFilter(value);
  }, []);

  const handleClickRefund = useCallback((order) => {
    setOrderSelected(order);
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
    setOrderSelected({} as IOrder);
  }, []);

  const handleConfirmRefund = useCallback(async () => {
    await api.post(`payments/refund/${orderSelected.id}`, {});
    handleLoadOrders();
    handleClose();
  }, [handleClose, handleLoadOrders, orderSelected.id]);

  const columns = useMemo(
    () => [
      {
        name: 'User',
        selector: 'user',
        sortable: true,
      },
      {
        name: 'Product',
        selector: 'product',
        sortable: true,
      },
      {
        name: 'Amount paid',
        selector: 'amount_paid',
        sortable: true,
        cell: (row: IOrder) => (
          <p data-tag="allowRowEvents" className="mb-0">
            {row.amount_paid} x {row.installments}
          </p>
        ),
      },
      {
        name: 'Sales date',
        selector: 'sales_date',
        sortable: true,
      },
      {
        name: 'Status',
        selector: 'status',
        sortable: true,
      },
      {
        name: '',
        selector: 'id',
        sortable: true,
        cell: (row: IOrder) => (
          <>
            {(row.status === 'succeeded' || row.status === 'active') && (
              <button
                type="button"
                onClick={() => handleClickRefund(row)}
                className="btn-dark rounded-pill w-100 font-weight-light py-1 table-btn"
              >
                <span className="d-block py-1 px-2">Refund this order</span>
              </button>
            )}
          </>
        ),
      },
    ],
    [handleClickRefund]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h4 h2-lg mb-4">Commissions</h1>
                  </div>
                </div>
              </div>
            </Welcome>
            <Table
              data={orders}
              columns={columns}
              pagination
              date
              searchable
              onSearch={handleSearch}
              exportable
            />
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="ml-auto">Refund order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0 text-center">
            Are you sure you want to refund the order
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-around">
          <button
            type="button"
            onClick={handleClose}
            className="w-25 btn-grey px-3 py-2"
          >
            NO
          </button>
          <button
            type="button"
            onClick={handleConfirmRefund}
            className="w-25 btn-grey px-3 py-2"
          >
            YES
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Orders;
