import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  AiFillPlusCircle,
  AiFillCheckCircle,
  AiFillDelete,
} from 'react-icons/ai';

import api from '~/services/api';

import {
  Container,
  Welcome,
  AddCourse,
  Course,
  Modal,
  CourseButton,
} from './styles';

import videoGallery from '~/assets/icons/video-gallery-icon.svg';

interface ICourseResponse {
  id: string;
  title: string;
  thumbnail: {
    thumbnail_url: string;
  };
}

interface ICourse {
  id: string;
  title: string;
  thumbnail_url: string;
  selected?: boolean;
}

interface IParams {
  userID: string;
}

const Courses: React.FC = () => {
  const params = useParams<IParams>();
  const [userId, setUserId] = useState('');
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [coursesData, setCoursesData] = useState<ICourse[]>([]);
  const [coursesSelected, setCoursesSelected] = useState<string[]>([]);
  const [show, setShow] = useState(false);
  const [courseDeleteSelected, setCourseDeleteSelected] = useState<ICourse>(
    {} as ICourse
  );
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    api
      .get(`users/admin/${params.userID}`)
      .then(async (responseUser) => {
        const response = await api.get<ICourseResponse[]>(
          `courses/user/${responseUser.data.referral_code}`
        );
        const data: ICourse[] = response.data.map(
          (course) => ({
            id: course.id,
            title: course.title,
            thumbnail_url: course.thumbnail
              ? course.thumbnail.thumbnail_url
              : '',
          }),
          []
        );

        if (responseUser.data) {
          setUserId(responseUser.data.id);
        }

        setCourses(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params]);

  const handleShow = useCallback(async () => {
    const response = await api.get<ICourseResponse[]>(`courses`);
    const dataBefore: ICourse[] = response.data.map((course) => ({
      id: course.id,
      title: course.title,
      thumbnail_url: course.thumbnail ? course.thumbnail.thumbnail_url : '',
    }));

    const data: ICourse[] = [];
    dataBefore.forEach((courseBefore) => {
      const courseFound = courses.find(
        (course) => course.id === courseBefore.id
      );
      if (!courseFound) {
        data.push(courseBefore);
      }
    });

    setCoursesData(data);
    setShow(true);
  }, [courses]);

  const handleShowDelete = useCallback((course) => {
    setCourseDeleteSelected(course);
    setShowDelete(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
    setShowDelete(false);
  }, []);

  const handleSelect = useCallback(
    (course_id) => {
      const courseExistIndex = coursesSelected.findIndex(
        (course) => course === course_id
      );
      const newCoursesData = coursesData.slice();

      const courseDataIndex = newCoursesData.findIndex(
        (course) => course.id === course_id
      );

      let newCoursesSelected = coursesSelected.slice();
      if (courseExistIndex >= 0) {
        newCoursesSelected = coursesSelected.filter(
          (course) => course !== course_id
        );
        if (courseDataIndex >= 0) {
          newCoursesData[courseDataIndex].selected = false;
        }
      } else {
        newCoursesSelected.push(course_id);

        if (courseDataIndex >= 0) {
          newCoursesData[courseDataIndex].selected = true;
        }
      }

      setCoursesData(newCoursesData);
      setCoursesSelected(newCoursesSelected);
    },
    [coursesData, coursesSelected]
  );

  const handleSubmit = useCallback(async () => {
    setLoading(true);
    try {
      const save = new Promise<void>((resolve) => {
        if (coursesSelected.length > 0) {
          const newCourses = courses.slice();
          coursesSelected.forEach(async (course_id, index) => {
            const formData = {
              course_id,
              user_id: userId,
            };
            await api.post('courses-users', formData);
            const course = coursesData.find(
              (courseData) => courseData.id === course_id
            );
            if (course) {
              newCourses.push(course);
            }

            if (coursesSelected.length === index + 1) {
              resolve();
            }
          });
          setCourses(newCourses);
        } else {
          resolve();
        }
      });

      await save;
      setShow(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [courses, coursesData, coursesSelected, userId]);

  const handleSubmitDelete = useCallback(async () => {
    await api.delete(`courses-users/${courseDeleteSelected.id}/${userId}`);
    const newCourses = courses.filter(
      (course) => course.id !== courseDeleteSelected.id
    );
    setCourses(newCourses);
    handleClose();
  }, [courseDeleteSelected.id, courses, handleClose, userId]);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <Welcome className="row mb-3">
          <div className="col-12">
            <h1 className="h2 font-weight-bold">Courses</h1>
          </div>
        </Welcome>
        <div className="row bg-gray pt-5 px-5">
          <div className="col-lg-4 mb-5">
            <AddCourse type="button" onClick={handleShow}>
              <img src={videoGallery} alt="Add course" />
              <p className="h5">Add course</p>
            </AddCourse>
          </div>
          {courses.map((course) => (
            <div key={course.id} className="col-lg-4 mb-5">
              <Course
                src={course.thumbnail_url}
                className="py-5 px-3"
                onClick={() => handleShowDelete(course)}
              >
                <h2 className="h5">{course.title}</h2>
                <div className="p-absolute delete-box">
                  <AiFillDelete />
                </div>
              </Course>
            </div>
          ))}
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="mx-auto mt-3">Select new course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row bg-gray pt-3 px-3">
            {coursesData.length > 0 ? (
              <>
                {coursesData.map((course) => (
                  <div key={course.id} className="col-lg-4 mb-3">
                    <CourseButton
                      src={course.thumbnail_url}
                      selected={course.selected}
                      className="py-4 px-3"
                      onClick={() => handleSelect(course.id)}
                    >
                      <h2 className="h6">{course.title}</h2>
                      {course.selected ? (
                        <AiFillCheckCircle />
                      ) : (
                        <AiFillPlusCircle />
                      )}
                    </CourseButton>
                  </div>
                ))}
              </>
            ) : (
              <div className="col-12">
                <h2 className="h3 text-center">
                  This user have all courses
                </h2>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn-save py-3"
            onClick={handleSubmit}
          >
            <span className="d-block font-weight-bold">Save</span>
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title className="mx-auto mt-3">
            Remove course "{courseDeleteSelected.title}" of user
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center mb-4">
            <button
              type="button"
              className="btn-save border-silver mx-3"
              onClick={handleClose}
            >
              <span className="d-block py-1">NO</span>
            </button>
            <button
              type="button"
              className="btn-save border-delete mx-3"
              onClick={handleSubmitDelete}
            >
              <span className="d-block py-1">YES</span>
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default Courses;
