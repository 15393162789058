import styled from 'styled-components';
import { lighten } from 'polished';

interface IStatus {
  overdue: boolean;
}

export const Container = styled.div`
  h2,
  h3 {
    font-size: 18px;
    color: #cbccce;
    font-weight: 400;
  }

  .bg-gray {
    background: #1f1f1f;
    border-radius: 20px;
  }

  .bd-gray {
    border: solid 1px #333;
    border-radius: 20px;
  }

  @media screen and (max-width: 575px) {
    h2 span {
      font-size: 18px !important;
      font-weight: 500;
    }
  }

  @media screen and (min-width: 1600px) {
    .text-1600-right {
      text-align: right !important;
    }
  }
`;

export const Box = styled.div`
  p {
    color: #e4e4e4;
    font-size: 26px;
    font-weight: 500;
  }
  h2 {
    color: #8c8c8c;
    font-weight: 400;
    font-size: 18px;
  }

  h3 {
    color: #e4e4e4;
    font-weight: 600;
    font-size: 36px;
  }

  h4 {
    color: #e4e4e4;
    font-weight: 600;
    font-size: 20px;
  }

  .bd-radius-20 {
    background-color: transparent !important;

    div.tableData div {
      background-color: transparent;
    }
  }

  .buy-button {
    background-color: rgba(110, 110, 110, 1);
    border: none;
    border-radius: 18px;

    :hover {
      background-color: ${lighten(0.1, '#6e6e6e')};
      transition: 0.5s;
    }

    span {
      padding: 12px 0;
      font-weight: 600;
    }
  }

  .search {
    border: 1px solid rgba(51, 51, 51, 1);
    border-radius: 20px;

    input {
      border: none;
      background-color: transparent;
      line-height: 33px;
    }
    button {
      right: 10px;
      top: 5px;
      background: transparent;
      border: none;
    }

    svg {
      color: #cbccce;
    }
  }

  span svg {
    color: #8c8c8c;
    font-weight: 400;
    font-size: 12px;
  }

  [aria-label] {
    position: relative;
  }

  [aria-label]::after {
    content: attr(aria-label);
    display: none;
    position: absolute;
    top: -103px;
    left: -100px;
    z-index: 5000;
    pointer-events: none;
    padding: 41px 22px;
    text-decoration: none;
    font-size: 10px;
    width: 218px;
    color: #8c8c8c;
    background-color: #242526;
    border-radius: 15px;
    text-align: start;
    filter: drop-shadow(10px 5px 12px rgba(0, 0, 0, 0.3));
  }

  [aria-label]:hover::after {
    display: block;
  }

  .circle {
    color: #f5f5f5;
    font-size: 36px;
    font-weight: 500;
    background-color: #606060;
    border-radius: 50%;
    height: 78px;
    width: 78px;
  }

  @media screen and (min-width: 992px) {
    .h-lg-64 {
      height: 64%;
    }

    .mg-box {
      margin-bottom: 2rem !important;
    }
  }

  @media screen and (min-width: 1400px) {
    .h-xxl-30 {
      height: 30%;
    }
  }

  @media screen and (min-width: 1600px) {
    .pr-1600-3 {
      padding-right: 1rem !important;
    }

    .pl-1600-3 {
      padding-left: 1rem !important;
    }
  }

  @media screen and (max-width: 1600px) {
    .px-1600-0 {
      padding: 0 0;
    }

    [aria-label]::after {
      left: -110px;
    }
  }

  @media screen and (max-width: 1399px) {
    .circle {
      height: 64px;
      width: 64px;
      font-size: 30px;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    p {
      font-size: 22px;
    }

    .circle {
      font-size: 25px;
      height: 50px;
      width: 50px;
    }
  }

  @media screen and (max-width: 575px) {
    p {
      font-size: 20px;
    }
    h3 {
      font-size: 30px;
      font-weight: 400;
    }
    .buy-button {
      border-radius: 15px;

      span {
        padding: 1px 0;
      }
    }
    .circle {
      font-size: 20px;
      height: 40px;
      width: 40px;
    }
  }

  @media screen and (max-width: 400px) {
    [aria-label]::after {
      left: -150px;
    }

    .bd-radius-20 {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
`;

export const Status = styled.span<IStatus>`
  color: ${(props) => (props.overdue ? '#ff1a50' : '#37c694')};
  font-weight: 600;

  @media screen and (min-width: 1600px) {
    .d-1600-none {
      display: none !important;
    }
  }
`;
