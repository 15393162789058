import styled from 'styled-components';

export const Container = styled.div`
  .bg-gray {
    background-color: rgba(32, 32, 32, 1);
    border-radius: 20px;
  }

  .border-gray {
    border-color: #8c8c8c;
  }

  .btn-light-grey {
    background-color: rgba(203, 204, 206, 1);
    border: none;
    color: rgba(110, 110, 110, 1);
    border-radius: 15px;
  }

  .step-module {
    span {
      color: #8c8c8c;

      :hover {
        color: rgba(32, 32, 32, 1) !important;
        text-decoration: none;
      }
    }
    text-decoration: none;
  }

  .min-width {
    min-width: 600px;
  }
`;

export const Modules = styled.div`
  background: #202020;
  border-radius: 20px;
  padding: 20px;

  a {
    span {
      color: #606060;
    }

    :hover {
      text-decoration: none;
    }
  }

  .min-height {
    min-height: 202px;
  }

  .bg-gray {
    background: #242526;
    border-radius: 20px;
    overflow: hidden;

    p {
      color: #565656;
    }
  }

  .module-link {
    height: 100%;

    img {
      border-radius: 20px;
    }

    p {
      color: #fff;
      background: #000000;
      border-radius: 10px;
    }
  }

  .btn-edit {
    background: #242526;
    border: 1px solid #4a4b4b;
    color: #8c8c8c;
    bottom: 5px;
    right: 5px;

    :hover {
      text-decoration: none;
    }
  }
`;
