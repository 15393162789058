/* eslint-disable global-require */
import { AxiosResponse } from 'axios';
import api from '~/services/api';

interface IUploadVideo {
  course_title: string;
  file: File;
  duration: string;
  setPercent: (value: React.SetStateAction<number>) => void;
  setStatus: (value: React.SetStateAction<string>) => void;
}

export default async function uploadVideo({
  course_title,
  file,
  duration,
  setPercent,
  setStatus,
}: IUploadVideo): Promise<AxiosResponse> {
  const title = `${new Date().getTime()}-${file.name}`
    .replace(/\s/g, '-')
    .toLowerCase();

  // const [titleData] = title.split('.');

  // const videoFileName = file.name;
  // const hlsFileName = `${titleData}.m3u8`;

  // const ffmpegWorker = new Worker(
  //   require('../services/workers/convertToHsl.worker')
  // );

  // ffmpegWorker.postMessage({
  //   videoFileName,
  //   hlsFileName,
  //   file,
  // });

  // ffmpegWorker.addEventListener('message', (event) => {
  //   const { data } = event;
  //   console.log('Arquivo HLS gerado:', data);
  // });

  let index = 0;
  let chunkSize = 0;
  let response: AxiosResponse = [] as any;

  const consumeFile = new WritableStream({
    write: async (chunk: Uint8Array) => {
      const blob = new Blob([chunk], { type: 'application/octet-stream' });
      const formData = new FormData();
      formData.append('title', title);
      formData.append('content', blob);
      formData.append('index', `${index + 1}`);

      await api.post('videos/chunks', formData);

      chunkSize += chunk.byteLength;
      const percent = Math.floor((chunkSize * 100) / file.size);

      index += 1;
      setPercent(percent);
    },
    close: async () => {
      setPercent(0);
      setStatus('Processing...');
      response = await api.post(
        'videos',
        {
          course_title,
          title,
          duration,
          totalParts: index,
        },
        {
          responseType: 'stream',
          onDownloadProgress: (progressEvent) => {
            const responseData = JSON.parse(
              `[${progressEvent.currentTarget.response.slice(0, -1)}]`
            );
            const data = responseData[responseData.length - 1];
            if (data.percent) {
              setPercent(data.percent);
            } else {
              setPercent(100);
            }
          },
        }
      );
      index = 0;
      chunkSize = 0;
    },
  });

  await file.stream().pipeTo(consumeFile);

  const responseData = JSON.parse(`[${response.data.slice(0, -1)}]`);

  return {
    data: responseData[responseData.length - 1],
  } as AxiosResponse;
}
