import React, { useState, useEffect, useCallback } from 'react';
// import { Link } from 'react-router-dom';
import { Form } from '@unform/web';

import {
  Container,
  Welcome,
  Options,
  MyProfile,
  SubscriptionsCards,
  BankWire,
  OtherPaymentOptions,
} from './styles';
import Input from '~/components/Input';

import profilePhoto from '~/assets/defaults/photo-profile.png';
import graph from '~/assets/icons/graph-icon.png';
import visa from '~/assets/icons/cc-visa.svg';
import mastercard from '~/assets/icons/cc-mastercard.svg';
import discover from '~/assets/icons/cc-discover.svg';
import amex from '~/assets/icons/cc-amex.svg';
import ddp from '~/assets/icons/ddp-icon.png';
import exclamation from '~/assets/icons/exclamation-icon.svg';
import qrCode from '~/assets/icons/qr-code-icon.png';
import starFlag from '~/assets/icons/star-flag-icon.png';

const Profile: React.FC = () => {
  const [avatar, setAvatar] = useState(profilePhoto);
  const [optionSelected, setOptionSelected] = useState('my-profile');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = useCallback((e) => {
    const filePreview = e.target.files[0];
    setAvatar(URL.createObjectURL(filePreview));
  }, []);

  return (
    <Container>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h2 font-weight-bold">Account Settings.</h1>
                  </div>
                </div>
              </div>
            </Welcome>
            <Options>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-11 mx-3 py-3 mb-4 border-bottom">
                    <div className="d-flex">
                      <div className="pr-4">
                        <button
                          type="button"
                          className={
                            optionSelected === 'my-profile' ? 'active' : ''
                          }
                          onClick={() => setOptionSelected('my-profile')}
                        >
                          My Profile
                        </button>
                      </div>
                      <div className="pr-4">
                        <button
                          type="button"
                          className={
                            optionSelected === 'subscriptions-cards'
                              ? 'active'
                              : ''
                          }
                          onClick={() =>
                            setOptionSelected('subscriptions-cards')
                          }
                        >
                          Subscriptions & Cards
                        </button>
                      </div>
                      <div className="pr-4">
                        <button
                          type="button"
                          className={
                            optionSelected === 'bank-wire' ? 'active' : ''
                          }
                          onClick={() => setOptionSelected('bank-wire')}
                        >
                          Bank Wire
                        </button>
                      </div>
                      <div className="pr-4">
                        <button
                          type="button"
                          className={
                            optionSelected === 'other-payment-options'
                              ? 'active'
                              : ''
                          }
                          onClick={() =>
                            setOptionSelected('other-payment-options')
                          }
                        >
                          Other Payment Options
                        </button>
                      </div>
                      <div className="pr-4">
                        <button type="button">Receipts</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Options>
            <MyProfile
              className={optionSelected === 'my-profile' ? 'd-block' : 'd-none'}
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="bg-gray">
                      <Form
                        onSubmit={() => {}}
                        className="text-center pb-4 mb-4 border-bottom"
                      >
                        <img src={avatar} alt="ProfilePhoto" />
                        <h2 className="h4 my-2">David Motta</h2>
                        <Input
                          type="file"
                          id="avatar"
                          name="avatar"
                          className="d-none"
                          onChange={handleChange}
                        />
                        <label htmlFor="avatar" className="button w-100">
                          <span>Update Photo</span>
                        </label>
                      </Form>
                      <h3 className="h5">Edit Account</h3>
                      <Form onSubmit={() => {}}>
                        <label htmlFor="username" className="mb-1">
                          User Name
                        </label>
                        <Input
                          id="username"
                          name="username"
                          value="David Motta"
                          className="mb-3"
                        />
                        <label htmlFor="phone" className="mb-1">
                          Phone Number
                        </label>
                        <Input
                          id="phone"
                          name="phone"
                          value="555-445-5445"
                          className="mb-3"
                        />
                        <label htmlFor="referral_code" className="mb-1">
                          Referral Code
                        </label>
                        <Input
                          id="referral_code"
                          name="referral_code"
                          value="Jerry123"
                          className="mb-3"
                        />
                        <button type="submit" className="w-100">
                          <span>Update Account</span>
                        </button>
                      </Form>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="bg-gray">
                      <Form className="row" onSubmit={() => {}}>
                        <div className="col-12">
                          <h3 className="h5">Personal Information</h3>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="name" className="mb-1">
                            Full Name
                          </label>
                          <Input
                            id="name"
                            name="name"
                            value="David Motta"
                            className="mb-3"
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="email" className="mb-1">
                            Email Address
                          </label>
                          <Input
                            type="email"
                            id="email"
                            name="email"
                            value="jerrys8tge@gmail.com"
                            className="mb-3"
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="phone" className="mb-1">
                            Phone Number
                          </label>
                          <Input
                            id="phone"
                            name="phone"
                            value="+1 48 6957 585 69"
                            className="mb-3"
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="country" className="mb-1">
                            Country
                          </label>
                          <Input
                            id="country"
                            name="country"
                            value="Australia"
                            className="mb-3"
                          />
                        </div>
                        <div className="col-12">
                          <label htmlFor="address" className="mb-1">
                            Address
                          </label>
                          <Input id="address" name="address" className="mb-3" />
                        </div>
                        <div className="col-12">
                          <h3 className="h5">Change Password</h3>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="old-password" className="mb-1">
                            Old Password
                          </label>
                          <Input
                            type="password"
                            id="old-password"
                            name="old_password"
                            value="Jerry Funkpower"
                            className="mb-3"
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="password" className="mb-1">
                            New Password
                          </label>
                          <Input
                            type="password"
                            id="password"
                            name="password"
                            value="Jerry Funkpower"
                            className="mb-3"
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="confirm-password" className="mb-1">
                            Confirm Password
                          </label>
                          <Input
                            type="password"
                            id="confirm-password"
                            name="confirm_password"
                            value="Jerry Funkpower"
                            className="mb-3"
                          />
                        </div>
                        <div className="col-12">
                          <h3 className="h5">Notifications</h3>
                        </div>
                        <div className="col-lg-9">
                          <div className="d-flex">
                            <div className="d-flex align-items-center w-100">
                              <Input
                                type="checkbox"
                                id="email-notifications"
                                name="email_notifications"
                                className="bg-transparent border-0 w-25"
                                checked
                              />
                              <label
                                htmlFor="email-notifications"
                                className="mb-0"
                              >
                                Email notifications
                              </label>
                            </div>
                            <div className="d-flex align-items-center w-100">
                              <Input
                                type="checkbox"
                                id="sms-notifications"
                                name="sms_notifications"
                                className="bg-transparent border-0 w-25"
                              />
                              <label
                                htmlFor="sms-notifications"
                                className="mb-0"
                              >
                                SMS notifications
                              </label>
                            </div>
                          </div>
                          <button type="submit" className="w-50 mt-3">
                            <span>Update Account</span>
                          </button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </MyProfile>
            <SubscriptionsCards
              className={
                optionSelected === 'subscriptions-cards' ? 'd-block' : 'd-none'
              }
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 py-3">
                    <h2 className="h4 mb-3">Subscriptions</h2>
                    <div className="d-flex align-items-center bg-gray p-2">
                      <img src={graph} alt="Graph" className="mx-2 graph" />
                      <div className="d-flex justify-content-around w-100">
                        <div className="mx-2">
                          <small className="font-weight-bold">Product</small>
                          <p>Digital Franchise VIP Member</p>
                        </div>
                        <div className="mx-2">
                          <small className="font-weight-bold">
                            Next Billing Date
                          </small>
                          <p>2020-Mar-01</p>
                        </div>
                        <div className="mx-2">
                          <small className="font-weight-bold">
                            Next Payment
                          </small>
                          <p>$67.00</p>
                        </div>
                        <div className="mx-2">
                          <small className="font-weight-bold">Expires</small>
                          <p className="mb-0">2021-Jan-01</p>
                          <small>Started January 1, 2019</small>
                        </div>
                        <div className="mx-2">
                          <p className="mb-0">$97/mo</p>
                          <button type="button">
                            <span>Cancel</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 py-3">
                    <div className="d-flex align-items-center mb-3">
                      <h2 className="h4 mb-0">Payment Cards</h2>
                      <div className="d-flex mx-2">
                        <img src={visa} alt="Visa" className="mx-1" />
                        <img
                          src={mastercard}
                          alt="Mastercard"
                          className="mx-1"
                        />
                        <img src={discover} alt="Discover" className="mx-1" />
                        <img src={amex} alt="Amex" className="mx-1" />
                      </div>
                    </div>
                    <div className="table-responsive bg-gray p-2">
                      <table>
                        <tr className="border-bottom">
                          <th className="cardId pb-2">#</th>
                          <th className="cardNumber pb-2">Card</th>
                          <th className="cardExpirationDate pb-2">
                            Expiration Date
                          </th>
                          <th className="cardName pb-2">Name on Card</th>
                          <th className="cardBillingAddress pb-2">
                            Billing Address
                          </th>
                          <th className="cardPrimary pb-2">Primary Card</th>
                          <th className="cardAction pb-2">Action</th>
                        </tr>
                        <tr>
                          <td className="pt-2">1</td>
                          <td className="pt-2">Visa: xxxx-2020</td>
                          <td className="pt-2">05/2023</td>
                          <td className="pt-2">David Motta</td>
                          <td className="pt-2">
                            34 Ave of the Arts Costa Mesa, CA 92666 United
                            States
                          </td>
                          <td className="pt-2 text-center">
                            <input type="checkbox" checked />
                          </td>
                          <td className="pt-2 text-center">
                            Edit
                            <button type="button" className="mt-1">
                              <span>Delete</span>
                            </button>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </SubscriptionsCards>
            <BankWire
              className={optionSelected === 'bank-wire' ? 'd-block' : 'd-none'}
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 py-3">
                    <h2 className="h4 mb-3">Wire | Direct Deposit Payments</h2>
                    <div className="d-flex align-items-center bg-gray p-2">
                      <img src={ddp} alt="DDP" className="mx-2 ddp" />
                      <div className="d-flex justify-content-around w-100">
                        <div className="mx-2">
                          <small className="font-weight-bold">
                            Company Name
                          </small>
                          <p>Wiser</p>
                        </div>
                        <div className="mx-2">
                          <small className="font-weight-bold">
                            Routing | ABA
                          </small>
                          <p>12340567</p>
                        </div>
                        <div className="mx-2">
                          <small className="font-weight-bold">Account #</small>
                          <p>66998989</p>
                        </div>
                        <div className="mx-2">
                          <small className="font-weight-bold">Swift Code</small>
                          <p className="mb-0">CHASU</p>
                          <small>International Wires</small>
                        </div>
                        <div className="mx-2">
                          <small className="font-weight-bold">Memo:</small>
                          <p className="mb-0">Product Name</p>
                          <small>Your Name & User Name</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 py-3">
                    <h2 className="h4 mb-3">Certified Checks | Money Orders</h2>
                    <div className="bg-gray p-2">
                      <div className="d-flex align-items-center">
                        <img src={graph} alt="Graph" className="mx-2 graph" />
                        <div className="d-flex justify-content-around w-100">
                          <div className="mx-2">
                            <small className="font-weight-bold">
                              Company Name
                            </small>
                            <p>Wiser</p>
                          </div>
                          <div className="mx-2">
                            <small className="font-weight-bold">Address</small>
                            <p className="mb-0">1234 Roader Lane</p>
                            <small>Costa Mesa, CA 92626 USA</small>
                          </div>
                          <div className="mx-2">
                            <small className="font-weight-bold">Memo:</small>
                            <p className="mb-0">Product Name</p>
                            <small>Your Name & User Name</small>
                          </div>
                        </div>
                      </div>
                      <div className="alert-text">
                        <img src={exclamation} alt="Exclamation" />
                        <small className="ml-1">
                          <b>
                            To mail a certified check | cashiers check (must be
                            a bank check - no personal
                            checks&nbsp;are&nbsp;accepted):
                          </b>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </BankWire>
            <OtherPaymentOptions
              className={
                optionSelected === 'other-payment-options'
                  ? 'd-block'
                  : 'd-none'
              }
            >
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 py-3">
                    <h2 className="h4 mb-3">Mobie Pay</h2>
                    <div className="d-flex align-items-center bg-gray p-2">
                      <img
                        src={qrCode}
                        alt="QR Code"
                        className="mx-2 qr-code"
                      />
                      <div className="d-flex w-100 infos">
                        <div className="mx-2 empty" />
                        <div className="mx-2">
                          <small className="font-weight-bold">
                            Routing | ABA
                          </small>
                          <p>12340567</p>
                        </div>
                        <div className="mx-2">
                          <small className="font-weight-bold">Account #</small>
                          <p>66998989</p>
                        </div>
                        <div className="mx-2">
                          <small className="font-weight-bold">Swift Code</small>
                          <p className="mb-0">CHASU</p>
                          <small>International Wires</small>
                        </div>
                        <div className="mx-2">
                          <small className="font-weight-bold">Memo:</small>
                          <p className="mb-0">Product Name</p>
                          <small>Your Name & User Name</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 py-3">
                    <h2 className="h4 mb-3">Bitcoin</h2>
                    <div className="bg-gray d-flex align-items-center p-2">
                      <img
                        src={qrCode}
                        alt="QR Code"
                        className="mx-2 qr-code"
                      />
                      <div className="d-flex w-100 infos">
                        <div className="mx-2 empty" />
                        <div className="mx-2">
                          <small className="font-weight-bold">Address</small>
                          <p className="mb-0">1234 Roader Lane</p>
                          <small>Costa Mesa, CA 92626 USA</small>
                        </div>
                        <div className="mx-2">
                          <small className="font-weight-bold">Memo:</small>
                          <p className="mb-0">Product Name</p>
                          <small>Your Name & User Name</small>
                        </div>
                        <div className="mx-2 empty" />
                        <div className="mx-2 empty" />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 py-3">
                    <h2 className="h4 mb-3">Payoneer</h2>
                    <div className="d-flex align-items-center bg-gray p-2">
                      <img
                        src={starFlag}
                        alt="Star Flag"
                        className="mx-2 star-flag"
                      />
                      <div className="d-flex w-100 infos">
                        <div className="mx-2">
                          <small className="font-weight-bold">
                            Company Name
                          </small>
                          <p>Wiser</p>
                        </div>
                        <div className="mx-2">
                          <small className="font-weight-bold">Address</small>
                          <p className="mb-0">1234 Roader Lane</p>
                          <small>Costa Mesa, CA 92626 USA</small>
                        </div>
                        <div className="mx-2">
                          <small className="font-weight-bold">Memo:</small>
                          <p className="mb-0">Product Name</p>
                          <small>Your Name & User Name</small>
                        </div>
                        <div className="mx-2 empty" />
                        <div className="mx-2 empty" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </OtherPaymentOptions>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Profile;
