import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Link, useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import { BsArrowLeft } from 'react-icons/bs';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import { Container, Welcome, InputsGroup, Thumbnail } from './styles';
import Input from '~/components/Input';
import InputTags from '~/components/InputTags';
import Textarea from '~/components/Textarea';

import imgThumb from '~/assets/icons/img_thumb.svg';

interface resourceFormData {
  name: string;
  link?: string;
}

const AffiliateOffersRegister: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailSelected, setThumbnailSelected] = useState<File | null>(null);
  const [fileSelected] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSelectThumbnail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        if (file) {
          setThumbnail(URL.createObjectURL(file));
          setThumbnailSelected(file);
        } else {
          setThumbnail('');
          setThumbnailSelected(null);
        }
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (data: resourceFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Name is a required'),
          link: Yup.string().when('$filledFile', {
            is: (filledFile: boolean) => filledFile,
            then: Yup.string(),
            otherwise: Yup.string().required(
              'Link is required if you do not select a file'
            ),
          }),
          file: Yup.string().when('$filledLink', {
            is: (filledLink: boolean) => filledLink,
            then: Yup.string(),
            otherwise: Yup.string().required(
              'The file is required if you do not specify a link'
            ),
          }),
          thumbnail: Yup.string().required('Thumbnail is a required'),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: { filledFile: !!fileSelected, filledLink: !!data.link },
        });

        const thumbnailData = new FormData();
        thumbnailData.append('thumbnail', thumbnailSelected as File);
        const responseThumbnail = await api.post('thumbnails', thumbnailData);

        if (responseThumbnail.data) {
          const { name, link } = data;

          const slug = name
            .replace(/[àáâãäå]/g, 'a')
            .replace(/æ/g, 'ae')
            .replace(/ç/g, 'c')
            .replace(/[èéêë]/g, 'e')
            .replace(/[ìíîï]/g, 'i')
            .replace(/ñ/g, 'n')
            .replace(/[òóôõö]/g, 'o')
            .replace(/œ/g, 'oe')
            .replace(/[ùúûü]/g, 'u')
            .replace(/[ýÿ]/g, 'y')
            .replace(/[^a-zA-Z0-9 -]/g, '')
            .replace(/ /g, '-')
            .toLowerCase();

          const formData = new FormData();
          formData.append('thumbnail_id', responseThumbnail.data.id);
          formData.append('name', name);
          if (link) {
            formData.append('link', link);
          }
          if (fileSelected) {
            formData.append('resource', fileSelected);
          }
          formData.append('slug', slug);

          const response = await api.post('resources', formData);

          if (response.data) {
            Swal.fire(
              'Good job!',
              'Resource successfully registered.',
              'success'
            ).then(() => {
              setLoading(false);
              history.push(`${process.env.PUBLIC_URL}/resources`);
            });
          }
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          ).then(() => setLoading(false));
        }
      }
    },
    [fileSelected, history, thumbnailSelected]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl px-0">
        <div className="row justify-content-center">
          <div className="col-12 p-0 mb-5">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 d-flex align-items-center">
                    <Link to={`${process.env.PUBLIC_URL}/affiliate-offers`}>
                      <BsArrowLeft size={38} color="#8C8C8C" />
                    </Link>
                    <h1 className="h2 mb-0 ml-4">New Affiliate Offer</h1>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          <div className="col-12">
            <Form ref={formRef} onSubmit={handleSubmit} className="row">
              <div className="col-lg-6 col-xl-5 order-1 order-lg-0">
                <InputsGroup className="p-4 h-100">
                  <h2 className="h5">Offer Information</h2>
                  <div className="py-3">
                    <label htmlFor="name" className="font-weight-light">
                      Name
                    </label>
                    <Input name="name" id="name" className="py-2" />
                  </div>
                  <div className="py-3">
                    <label
                      htmlFor="short-description"
                      className="font-weight-light"
                    >
                      Short Description
                    </label>
                    <Input
                      name="short_description"
                      id="short-description"
                      className="py-2"
                    />
                  </div>
                  <div className="py-3">
                    <label htmlFor="description" className="font-weight-light">
                      Long Description
                    </label>
                    <Textarea
                      name="description"
                      id="description"
                      className="py-2"
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-4">
                      <label htmlFor="commission" className="font-weight-light">
                        Commission
                      </label>
                      <div className="p-relative input-percent">
                        <Input
                          type="number"
                          name="commission"
                          id="commission"
                          min={0}
                          max={100}
                        />
                        <span className="p-absolute percentage">%</span>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <label htmlFor="tags" className="font-weight-light">
                        Tags
                      </label>
                      <InputTags name="tags" id="tags" className="py-2" />
                    </div>
                  </div>
                  <div className="py-3">
                    <label htmlFor="link" className="font-weight-light">
                      Affiliate Link
                    </label>
                    <Input type="url" name="link" id="link" className="py-2" />
                  </div>
                  <div className="d-flex py-3">
                    <button type="submit" className="btn-grey py-2 w-100">
                      <span className="d-block py-1">Save</span>
                    </button>
                  </div>
                </InputsGroup>
              </div>
              <div className="col-lg-6 col-xl-7 order-0 order-lg-1 mb-5 mb-lg-0">
                <div className="w-100 h-100 mb-2">
                  <Thumbnail
                    src={thumbnail}
                    htmlFor="thumbnail"
                    className="btn bg-gray text-center w-100 h-100 p-0 p-relative"
                  >
                    <div className="p-absolute p-4 w-100 h-100 text-left">
                      <p className="h5 text-white">Thumbnail</p>
                    </div>
                    {!thumbnail && (
                      <div className="h-100 py-2 d-flex flex-column justify-content-center align-items-center">
                        <img
                          src={imgThumb}
                          alt="AddThumbnail"
                          className="w-25 mb-2"
                        />
                        <p className="mb-0">Upload your file here or</p>
                        <p className="underline">Browse file</p>
                      </div>
                    )}
                  </Thumbnail>
                  <Input
                    type="file"
                    id="thumbnail"
                    name="thumbnail"
                    className="d-none"
                    onChange={handleSelectThumbnail}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default AffiliateOffersRegister;
