import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { format, parseISO, subDays } from 'date-fns';
import api from '~/services/api';

import { Container, Welcome, NameUserTable } from './styles';
import Table from '~/components/Table';

import { formatPrice } from '~/utils/format';

interface IWalletHistory {
  id: string;
  amount: string;
  status: string;
}

interface ICommission {
  id: string;
  earning: string;
  created_at: string;
}

interface IWalletResponse {
  id: string;
  affiliate: {
    type: string;
    affiliate_commission: string;
    user: {
      name: string;
      avatar: {
        avatar_url: string;
      };
    };
    commissions: ICommission[];
  };
  withdrawal_requested: boolean;
  amount: string;
  updated_at: string;
  walletHistory: IWalletHistory[];
  status: string;
}

interface IWallet {
  id: string;
  request_date: string;
  avatar: string;
  member: string;
  total_available: string;
  total_pending: string;
  total_crawl_back: string;
  net_payout: string;
  commission_rate: string;
  type: string;
  status: string;
}

const PayoutManagement: React.FC = () => {
  const [wallets, setWallets] = useState<IWallet[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');

  const handleLoadCommissions = useCallback(async () => {
    const response = await api.get<IWalletResponse[]>('wallets', {
      params: {
        search: filter,
      },
    });

    let data = response.data.map((wallet) => {
      const total_available = wallet.affiliate.commissions.reduce(
        (acumulador: number, currentValue) => {
          const value =
            parseISO(currentValue.created_at) < subDays(new Date(), 44)
              ? parseFloat(currentValue.earning)
              : 0;
          return acumulador + value;
        },
        0
      );
      const total_pending = wallet.affiliate.commissions.reduce(
        (acumulador: number, currentValue) => {
          const value =
            parseISO(currentValue.created_at) > subDays(new Date(), 44)
              ? parseFloat(currentValue.earning)
              : 0;
          return acumulador + value;
        },
        0
      );
      const total_crawl_back = wallet.walletHistory.reduce(
        (acumulador: number, currentValue) => {
          const value =
            currentValue.status === 'Refund'
              ? parseFloat(currentValue.amount)
              : 0;
          return acumulador + value;
        },
        0
      );

      const net_payout = total_available - total_available * 0.03;

      return {
        id: wallet.id,
        request_date: format(parseISO(wallet.updated_at), 'yyyy-MM-dd'),
        avatar: wallet.affiliate?.user?.avatar?.avatar_url || '-',
        member: wallet.affiliate?.user?.name || '-',
        total_available: formatPrice(total_available),
        total_pending: formatPrice(total_pending),
        total_crawl_back: formatPrice(total_crawl_back),
        net_payout: formatPrice(net_payout),
        commission_rate: wallet.affiliate?.affiliate_commission || '-',
        type: wallet.affiliate?.type || '-',
        status: wallet.status,
      };
    });

    data = data.filter((wallet) => wallet.total_available !== '$0.00');

    setWallets(data);
    setLoading(false);
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    handleLoadCommissions();
  }, [filter, handleLoadCommissions]);

  const handleSearch = useCallback((value) => {
    setFilter(value);
  }, []);

  const columns = useMemo(
    () => [
      {
        name: 'Member',
        selector: 'member',
        sortable: true,
        cell: (row: IWallet) => (
          <NameUserTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
          >
            <img src={row.avatar} alt={row.member} className="mr-2" />
            <div>
              <p className="mb-0">{row.member}</p>
            </div>
          </NameUserTable>
        ),
      },
      {
        name: 'Total Available',
        selector: 'total_available',
        sortable: true,
      },
      {
        name: 'Total Peding',
        selector: 'total_pending',
        sortable: true,
      },
      {
        name: 'Total Crawl Back',
        selector: 'total_crawl_back',
        sortable: true,
        cell: (row: IWallet) => (
          <div className="negative">-{row.total_crawl_back}</div>
        ),
      },
      {
        name: 'Net Payout',
        selector: 'net_payout',
        sortable: true,
      },
      {
        name: 'Commission Rate',
        selector: 'commission_rate',
        sortable: true,
      },
      {
        name: 'Member Type',
        selector: 'type',
        sortable: true,
      },
      {
        name: 'Status',
        selector: 'status',
        sortable: true,
      },
    ],
    []
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h4 h2-lg mb-4">Payout Management</h1>
                  </div>
                </div>
              </div>
            </Welcome>
            <Table
              data={wallets}
              columns={columns}
              pagination
              date
              searchable
              onSearch={handleSearch}
              exportable
            />
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default PayoutManagement;
