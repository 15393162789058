import React, { useState, useCallback, ChangeEvent, useEffect } from 'react';
import { HiOutlineTrash } from 'react-icons/hi';

import { Container, ThumbnailPreview, Resource } from './styles';

import imgThumb from '~/assets/icons/files-icon.svg';

interface fileProps {
  preview?: string;
}

export interface downloadTempProps {
  preview?: string;
  thumb?: File;
  download?: File;
}

interface downloadProps {
  active: boolean;
  data?: fileProps[];
  onChange?(data: downloadTempProps[]): void;
}

const Downloads: React.FC<downloadProps> = ({ active, data, onChange }) => {
  const [downloads, setDownloads] = useState<downloadTempProps[]>([]);
  const [downloadTemp, setDownloadTemp] = useState<downloadTempProps>(
    {} as downloadTempProps
  );
  const [thumbPreviewTemp, setThumbPreviewTemp] = useState('');

  useEffect(() => {
    if (data) {
      setDownloads(data);
    }
  }, [data]);

  useEffect(() => {
    if (downloadTemp.thumb && downloadTemp.download) {
      console.log(downloadTemp);
      setDownloads((state) => [...state, ...[downloadTemp]]);
      setThumbPreviewTemp('');
      setDownloadTemp({} as downloadTempProps);
    }
  }, [downloadTemp]);

  useEffect(() => {
    if (onChange) {
      onChange(downloads);
    }
  }, [downloads, onChange]);

  const handleChangeThumb = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        const preview = URL.createObjectURL(file);
        setThumbPreviewTemp(preview);
        let tempData;
        if (downloadTemp.download) {
          tempData = {
            preview,
            thumb: file,
            download: downloadTemp.download,
          };
        } else {
          tempData = {
            preview,
            thumb: file,
          };
        }

        setDownloadTemp(tempData);
      }
    },
    [downloadTemp.download]
  );

  const handleChangeDownload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        let tempData;
        if (downloadTemp.thumb) {
          tempData = {
            preview: downloadTemp.preview,
            thumb: downloadTemp.thumb,
            download: file,
          };
        } else {
          tempData = {
            download: file,
          };
        }

        setDownloadTemp(tempData);
      }
    },
    [downloadTemp.preview, downloadTemp.thumb]
  );

  const deleteFile = useCallback(
    (index) => {
      const newDownloads = downloads.filter((_, idx) => index !== idx);
      setDownloads(newDownloads);
    },
    [downloads]
  );

  return (
    <Container className={`${active ? 'd-block' : 'd-none'}`}>
      <h2 className="h5 mb-3">
        Here are you free bonus resources for this lesson!
      </h2>
      <div className="row">
        <div className="col-lg-4 my-2">
          <ThumbnailPreview
            src={thumbPreviewTemp}
            htmlFor="thumb"
            className="d-flex flex-column justify-content-center align-items-center card-resource btn py-4 px-0 w-100 text-center mb-2"
          >
            {!thumbPreviewTemp && (
              <>
                <img src={imgThumb} alt="plus" className="w-50 mb-3" />
                <p className="small file-upload mb-0">
                  Upload your file here or{' '}
                  <span className="d-block">Browse thumbnail</span>
                </p>
              </>
            )}
            <input
              id="thumb"
              type="file"
              className="d-none"
              onChange={handleChangeThumb}
            />
          </ThumbnailPreview>
          <label
            htmlFor="download"
            className="w-100 btn-file text-white text-center py-2 mt-3"
          >
            <span className="d-block py-1 font-weight-bold">Upload File</span>
            <input
              id="download"
              type="file"
              className="d-none"
              onChange={handleChangeDownload}
            />
          </label>
        </div>
        {downloads.map((file, index) => (
          <div key={index} className="col-lg-4 my-2">
            <Resource
              src={file.preview || imgThumb}
              className="w-100 d-flex justify-content-end align-items-end p-3"
            >
              <button type="button" onClick={() => deleteFile(index)}>
                <HiOutlineTrash color="#FF1A50" size={24} />
              </button>
            </Resource>
            <p className="fileName">{file.download?.name}</p>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Downloads;
