import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div``;

export const Welcome = styled.div`
  p {
    color: #969696;
  }

  .btn-new-course {
    color: #dadada;
    border: solid 1px rgba(96, 96, 96, 1);
    background-color: rgba(32, 32, 32, 1);
    padding: 0.7rem 0;

    :hover {
      color: ${lighten(0.1, '#dadada')};
      border: solid 1px ${lighten(0.1, 'rgba(96, 96, 96, 1)')};
      text-decoration: none;
      img {
        filter: brightness(2.5);
      }
    }
  }
`;

export const Options = styled.div`
  a,
  button {
    color: #dadada;
    border: solid 1px rgba(96, 96, 96, 1);
    background-color: rgba(32, 32, 32, 1);
    border-radius: 50px;

    :hover {
      text-decoration: none;
      background-color: #606060;
    }
  }

  a.active,
  button.active {
    background-color: #606060;
    border: solid 1px transparent !important;
  }

  .btn-new-course {
    color: #dadada;
    padding: 1px;

    :hover {
      color: #dadada;
      text-decoration: none;
    }
  }
`;

export const Search = styled.div`
  padding: 1px;
  border-radius: 100px;
  border: 1px solid #242526;
  background-color: transparent;

  input {
    margin: 5px 0;
    padding: 0 10px;
    border: none;
    border-radius: 100px;
    background-color: transparent;
    color: #cbccce !important;

    ::placeholder {
      color: #cbccce !important;
    }
  }
`;
