import React, { useState, useCallback, ChangeEvent, useRef } from 'react';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Swal from 'sweetalert2';
import api from '~/services/api';

import { Container, VideoInfo, CourseInfo, Img, Modal } from './styles';
import Input from '~/components/Input';

import imgThumb from '~/assets/icons/img_thumb.svg';
import getValidationErros from '~/utils/getValidationsErrors';
import Textarea from '~/components/Textarea';

interface courseFormData {
  thumbnail_title: string;
  title: string;
  subtitle: string;
  presented_by: string;
  description: string;
  slug: string;
  page_title: string;
  meta_description: string;
  price: string;
  show_promotion_price: string;
  promotion_price?: string;
}

const ArticlesUpdate: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailSelected, setThumbnailSelected] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [show, setShow] = useState(false);
  const [body, setBody] = useState('');
  const [url, setUrl] = useState('');
  const [highlightArticle, setHighlightArticle] = useState('no');
  const [published, setPublished] = useState('no');

  const handleSelectThumbnail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        if (file) {
          setThumbnail(URL.createObjectURL(file));
          setThumbnailSelected(file);
        } else {
          setThumbnail('');
          setThumbnailSelected(null);
        }
      }
    },
    []
  );

  const handleChangeTitle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUrl(
      e.target.value
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y')
        .replace(/[^a-zA-Z0-9 -]/g, '')
        .replace(/ /g, '-')
        .toLowerCase()
    );
  }, []);

  const handleChangeSlug = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUrl(
      e.target.value
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y')
        .replace(/[^a-zA-Z0-9 -]/g, '')
        .replace(/ /g, '-')
        .toLowerCase()
    );
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleDelete = useCallback(async () => {
    setLoading(true);
    try {
      await api.delete(`courses/`);
      Swal.fire('Good job!', 'Module deleted successfully.', 'success').then(
        () => {
          handleClose();
          setLoading(false);
          history.push(`${process.env.PUBLIC_URL}/courses`);
        }
      );
    } catch (error) {
      Swal.fire(
        'Opss...',
        'An error has occurred, please try again.',
        'error'
      ).then(() => setLoading(false));
    }
  }, [handleClose, history]);

  const handleChangeBody = useCallback((_, editor) => {
    setBody(editor.getData());
  }, []);

  const changeHighlightArticle = useCallback((e) => {
    setHighlightArticle(e.target.value);
  }, []);

  const changePublishedArticle = useCallback((e) => {
    setPublished(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    async (data: courseFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('Title is a required'),
          description: Yup.string().required('Description is a required'),
          slug: Yup.string().required('URL is a required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setStatus('Updating article...');
        const { title, description, slug } = data;

        const formData = {
          title,
          meta: description,
          body,
          highlight: highlightArticle === 'yes',
          published: published === 'yes',
          slug,
        };

        const response = await api.post(`articles`, formData);

        if (thumbnailSelected) {
          setStatus('Uploading thumbnail...');
          const thumbnailData = new FormData();
          thumbnailData.append('article_id', response.data.id);
          thumbnailData.append('upload', thumbnailSelected as File);
          await api.post(`articles-files`, thumbnailData);
        }

        if (response.data) {
          Swal.fire(
            'Good job!',
            'Article created successfully.',
            'success'
          ).then(() => {
            setLoading(false);
            history.push(
              `${process.env.PUBLIC_URL}/promotional-material/blog-articles`
            );
          });
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          ).then(() => setLoading(false));
        }
      }
    },
    [body, highlightArticle, history, published, thumbnailSelected]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <Form ref={formRef} onSubmit={handleSubmit} className="row">
          <div className="col-12 pt-3 text-center mt-3">
            <h1>Create Blog Article</h1>
          </div>
          <div className="col-xl-4 col-xxl-3 px-0 px-lg-3 pr-xl-0">
            <VideoInfo
              id="videoInfo"
              className="d-flex flex-wrap flex-xl-column justify-content-between"
            >
              <div className="col-md-6 col-lg-4 col-xl-12 pr-md-4 pl-lg-0 px-xl-0 bd-right">
                <h3 className="h5">Thumbnail</h3>
                <label
                  htmlFor="thumbnail"
                  className="btn bg-gray text-center w-100 p-0"
                >
                  {thumbnail ? (
                    <Img src={thumbnail} className="w-100" />
                  ) : (
                    <div className="d-flex justify-content-center align-items-center py-5">
                      <img
                        src={imgThumb}
                        alt="AddThumbnail"
                        className="w-25 mb-2"
                      />
                    </div>
                  )}
                </label>
                <Input
                  type="file"
                  id="thumbnail"
                  name="thumbnail"
                  className="d-none"
                  onChange={handleSelectThumbnail}
                />
              </div>
            </VideoInfo>
            <CourseInfo>
              <h3 className="h5">SEO</h3>
              <div className="row">
                <div className="col-12">
                  <div className="mt-3">
                    <label htmlFor="slug" className="small">
                      URL
                    </label>
                    <div className="d-flex align-items-center link-preview p-relative">
                      <span className="mb-0 before-slug py-3">/blog/</span>
                      <Input
                        id="slug"
                        name="slug"
                        value={url}
                        onChange={handleChangeSlug}
                        className="py-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </CourseInfo>
            <VideoInfo
              id="videoInfo"
              className="d-flex flex-wrap flex-xl-column justify-content-between"
            >
              <div className="mt-3">
                <h3 className="h5 mb-3">Highlight article?</h3>
                <div className="d-flex text-center">
                  <label
                    className={`btn w-100 mx-1 btn-radio ${
                      highlightArticle === 'yes' ? 'btn-grey' : 'btn-dark'
                    }`}
                    htmlFor="highlight_article_yes"
                  >
                    <span className="d-block py-2">YES</span>
                    <Input
                      type="radio"
                      onChange={changeHighlightArticle}
                      name="watch_full"
                      id="highlight_article_yes"
                      className="d-none"
                      value="yes"
                      checked={highlightArticle === 'yes'}
                    />
                  </label>
                  <label
                    className={`btn w-100 mx-1 btn-radio ${
                      highlightArticle === 'no' ? 'btn-grey' : 'btn-dark'
                    }`}
                    htmlFor="highlight_article_no"
                  >
                    <span className="d-block py-2">NO</span>
                    <Input
                      type="radio"
                      onChange={changeHighlightArticle}
                      name="watch_full"
                      id="highlight_article_no"
                      className="d-none"
                      value="no"
                      checked={highlightArticle === 'no'}
                    />
                  </label>
                </div>
              </div>
              <div className="mt-3">
                <h3 className="h5 mb-3">Published article?</h3>
                <div className="d-flex text-center">
                  <label
                    className={`btn w-100 mx-1 btn-radio ${
                      published === 'yes' ? 'btn-grey' : 'btn-dark'
                    }`}
                    htmlFor="published_article_yes"
                  >
                    <span className="d-block py-2">YES</span>
                    <Input
                      type="radio"
                      onChange={changePublishedArticle}
                      name="watch_full"
                      id="published_article_yes"
                      className="d-none"
                      value="yes"
                      checked={published === 'yes'}
                    />
                  </label>
                  <label
                    className={`btn w-100 mx-1 btn-radio ${
                      published === 'no' ? 'btn-grey' : 'btn-dark'
                    }`}
                    htmlFor="published_article_no"
                  >
                    <span className="d-block py-2">NO</span>
                    <Input
                      type="radio"
                      onChange={changePublishedArticle}
                      name="watch_full"
                      id="published_article_no"
                      className="d-none"
                      value="no"
                      checked={published === 'no'}
                    />
                  </label>
                </div>
              </div>
            </VideoInfo>
          </div>
          <div className="col-xl-8 col-xxl-9">
            <CourseInfo>
              <div className="row">
                <div className="col-sm-6">
                  <div className="mt-3">
                    <label htmlFor="title" className="small">
                      Title
                    </label>
                    <Input
                      id="title"
                      name="title"
                      className="py-3"
                      onChange={handleChangeTitle}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="mt-3">
                    <label htmlFor="presented_by" className="small">
                      Presented by
                    </label>
                    <Input
                      id="presented_by"
                      className="py-3"
                      name="presented_by"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mt-3">
                    <label htmlFor="description" className="small">
                      Description
                    </label>
                    <Textarea
                      id="description"
                      className="py-3"
                      name="description"
                    />
                  </div>
                </div>
              </div>
            </CourseInfo>
            <CourseInfo className="mt-3">
              <div className="row">
                <div className="col-12">
                  <h3 className="h5 mb-3">Content</h3>
                  <CKEditor
                    editor={ClassicEditor}
                    config={{
                      ckfinder: {
                        uploadUrl: `${process.env.REACT_APP_API_URL}/articles-files`,
                      },
                    }}
                    data={body}
                    onChange={handleChangeBody}
                  />
                </div>
              </div>
              <div className="row mt-5 align-items-end justify-content-end">
                <div className="col-lg-4">
                  <button type="submit" className="btn-grey py-3 w-100">
                    <span className="d-block font-weight-bold">Save</span>
                  </button>
                </div>
              </div>
            </CourseInfo>
          </div>
        </Form>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <p className="font-weight-bold text-white mt-3">{status}</p>
        </div>
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="ml-auto">Delete course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0 text-center">
            Are you sure you want to delete the course
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-around">
          <button
            type="button"
            onClick={handleClose}
            className="w-25 btn-grey px-3 py-2"
          >
            NO
          </button>
          <button
            type="button"
            onClick={handleDelete}
            className="w-25 btn-grey px-3 py-2"
          >
            YES
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ArticlesUpdate;
