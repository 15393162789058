import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { BiLeftArrowAlt } from 'react-icons/bi';
import { useAuth } from '~/hooks/Auth';

import { Container, Menu } from './styles';

import logo from '~/assets/logotipos/logo.svg';

const Header: React.FC = () => {
  const location = useLocation();
  const { user, signOut } = useAuth();
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    setActive(false);
  }, [location]);

  return (
    <Container className="d-flex flex-lg-column justify-content-between justify-content-lg-around align-items-center text-center menu-header">
      <div className="d-flex flex-column py-lg-3">
        <Link to="/">
          <img src={logo} alt="logo" className="pl-2 pl-lg-0 w-75" />
        </Link>
      </div>
      <Menu active={active} className="w-auto w-lg-100">
        <button
          type="button"
          className="bg-transparent d-lg-none border-0 mx-3"
          onClick={() => setActive(!active)}
        >
          <span />
          <span />
          <span />
        </button>
        {/* below menu desk */}
        <div className="d-lg-flex overflow flex-column p-absolute p-lg-relative menu-group active">
          <div className="d-flex flex-column pl-4 px-lg-0 py-5 py-lg-1 py-xxl-3 h-lg-100 menu">
            {user.type === 'master' && (
              <>
                {selected === '' ? (
                  <Link
                    to={`${process.env.PUBLIC_URL}/dashboard`}
                    className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                      location.pathname.match(/\/dashboard.*/) ? 'active' : ''
                    }`}
                  >
                    <div className="dashboard d-none d-lg-block" />
                    <small>Dashboard</small>
                  </Link>
                ) : (
                  <button
                    onClick={() => setSelected('')}
                    type="button"
                    className="btn-back border-0 menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto flex-lg-column justify-content-lg-center align-items-center"
                  >
                    <BiLeftArrowAlt size={25} />
                  </button>
                )}
                <button
                  onClick={() => setSelected('users')}
                  type="button"
                  className={`${
                    selected !== '' ? 'd-none' : 'd-flex'
                  } border-0 menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto flex-lg-column justify-content-lg-center align-items-center`}
                >
                  <div className="users d-none d-lg-block" />
                  <small>Users</small>
                </button>
                {selected === 'users' && (
                  <>
                    <Link
                      to={`${process.env.PUBLIC_URL}/users`}
                      className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                        location.pathname.match(/\/users.*/) &&
                        !location.pathname.match(/\/users-placement.*/)
                          ? 'active'
                          : ''
                      }`}
                    >
                      <div className="users d-none d-lg-block" />
                      <small>Users</small>
                    </Link>

                    <Link
                      to={`${process.env.PUBLIC_URL}/users-placement`}
                      className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                        location.pathname.match(/\/users-placement.*/)
                          ? 'active'
                          : ''
                      }`}
                    >
                      <div className="placement-user d-none d-lg-block" />
                      <small>Users placement</small>
                    </Link>
                    <Link
                      to={`${process.env.PUBLIC_URL}/waitlist`}
                      className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                        location.pathname.match(/\/waitlist.*/) ? 'active' : ''
                      }`}
                    >
                      <div className="waitlist d-none d-lg-block" />
                      <small>Waitlist</small>
                    </Link>
                    {false && (
                      <Link
                        to={`${process.env.PUBLIC_URL}/coaches`}
                        className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                          location.pathname.match(/\/coaches.*/) ? 'active' : ''
                        }`}
                      >
                        <div className="coaches d-none d-lg-block" />
                        <small>Coaches</small>
                      </Link>
                    )}
                  </>
                )}
              </>
            )}
            <button
              onClick={() => setSelected('courses')}
              type="button"
              className={`${
                selected !== '' ? 'd-none' : 'd-flex'
              } border-0 menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto flex-lg-column justify-content-lg-center align-items-center`}
            >
              <div className="courses d-none d-lg-block" />
              <small>Courses</small>
            </button>
            {selected === 'courses' && (
              <>
                <Link
                  to={`${process.env.PUBLIC_URL}/courses`}
                  className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/courses.*/) ? 'active' : ''
                  }`}
                >
                  <div className="courses d-none d-lg-block" />
                  <small>Courses</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/trainings`}
                  className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/trainings.*/) ? 'active' : ''
                  }`}
                >
                  <div className="courses d-none d-lg-block" />
                  <small>Trainings</small>
                </Link>
                {false && (
                  <>
                    <Link
                      to={`${process.env.PUBLIC_URL}/resources/downloads`}
                      className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                        location.pathname.match(/\/resources.*/) ? 'active' : ''
                      }`}
                    >
                      <div className="resources d-none d-lg-block" />
                      <small>Resources</small>
                    </Link>
                    <Link
                      to={`${process.env.PUBLIC_URL}/products`}
                      className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                        location.pathname.match(/\/products.*/) ? 'active' : ''
                      }`}
                    >
                      <div className="product d-none d-lg-block" />
                      <small>Franchises</small>
                    </Link>
                    <Link
                      to={`${process.env.PUBLIC_URL}/categories/courses`}
                      className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                        location.pathname.match(/\/categories.*/)
                          ? 'active'
                          : ''
                      }`}
                    >
                      <div className="categories d-none d-lg-block" />
                      <small>Categories</small>
                    </Link>
                    <Link
                      to={`${process.env.PUBLIC_URL}/order-review`}
                      className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                        location.pathname.match(/\/order.*/) ? 'active' : ''
                      }`}
                    >
                      <div className="order d-none d-lg-block" />
                      <small>Order review</small>
                    </Link>
                    <Link
                      to={`${process.env.PUBLIC_URL}/scheduled-withdrawals`}
                      className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                        location.pathname.match(/\/scheduled-withdrawals.*/)
                          ? 'active'
                          : ''
                      }`}
                    >
                      <div className="scheduled-withdrawals d-none d-lg-block" />
                      <small>Scheduled Withdrawals</small>
                    </Link>
                  </>
                )}

                <Link
                  to={`${process.env.PUBLIC_URL}/promotional-material/sales-funnels`}
                  className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/promotional-material.*/)
                      ? 'active'
                      : ''
                  }`}
                >
                  <div className="promotional-material d-none d-lg-block" />
                  <small>Promotional material</small>
                </Link>
              </>
            )}
            <button
              onClick={() => setSelected('orders')}
              type="button"
              className={`${
                selected !== '' ? 'd-none' : 'd-flex'
              } border-0 menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto flex-lg-column justify-content-lg-center align-items-center`}
            >
              <div className="orders d-none d-lg-block" />
              <small>Orders</small>
            </button>
            {selected === 'orders' && (
              <>
                <Link
                  to={`${process.env.PUBLIC_URL}/orders`}
                  className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/orders.*/) ? 'active' : ''
                  }`}
                >
                  <div className="orders d-none d-lg-block" />
                  <small>Orders</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/commissions`}
                  className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/commissions.*/) ? 'active' : ''
                  }`}
                >
                  <div className="commissions d-none d-lg-block" />
                  <small>Commissions</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/sales-management`}
                  className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/sales-management.*/)
                      ? 'active'
                      : ''
                  }`}
                >
                  <div className="sales-management d-none d-lg-block" />
                  <small>sales Management</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/payout-management`}
                  className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/payout-management.*/)
                      ? 'active'
                      : ''
                  }`}
                >
                  <div className="payout-management d-none d-lg-block" />
                  <small>Payout Management</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/payout-request`}
                  className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/payout-request.*/)
                      ? 'active'
                      : ''
                  }`}
                >
                  <div className="payout-request d-none d-lg-block" />
                  <small>Payout Request</small>
                </Link>
              </>
            )}
            <button
              onClick={() => setSelected('chats')}
              type="button"
              className={`${
                selected !== '' ? 'd-none' : 'd-flex'
              } border-0 menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto flex-lg-column justify-content-lg-center align-items-center`}
            >
              <div className="chats d-none d-lg-block" />
              <small>Chats</small>
            </button>
            {selected === 'chats' && (
              <>
                <Link
                  to={`${process.env.PUBLIC_URL}/chats`}
                  className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/chats.*/) ? 'active' : ''
                  }`}
                >
                  <div className="chats d-none d-lg-block" />
                  <small>Chats</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/sms`}
                  className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/sms.*/) ? 'active' : ''
                  }`}
                >
                  <div className="sms d-none d-lg-block" />
                  <small>SMS</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/faq`}
                  className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/faq.*/) ? 'active' : ''
                  }`}
                >
                  <div className="faq d-none d-lg-block" />
                  <small>FAQ</small>
                </Link>
              </>
            )}
            <button
              onClick={() => setSelected('leads')}
              type="button"
              className={`${
                selected !== '' ? 'd-none' : 'd-flex'
              } border-0 menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto flex-lg-column justify-content-lg-center align-items-center`}
            >
              <div className="leads d-none d-lg-block" />
              <small>Leads</small>
            </button>
            {selected === 'leads' && (
              <>
                <Link
                  to={`${process.env.PUBLIC_URL}/founders-club-leads`}
                  className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/founders-club-leads.*/)
                      ? 'active'
                      : ''
                  }`}
                >
                  <div className="founders-club-leads d-none d-lg-block" />
                  <small>Founders Club Leads</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/founders-club-members`}
                  className={`menu-item px-2 px-lg-0 py-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/founders-club-members.*/)
                      ? 'active'
                      : ''
                  }`}
                >
                  <div className="founders-club-members d-none d-lg-block" />
                  <small>Founders Club Members</small>
                </Link>
              </>
            )}
          </div>
          <div className="d-flex flex-column mb-5 mb-lg-0 pl-4 px-lg-0 py-3 py-lg-2 py-xxl-3 logoff">
            <button
              type="button"
              onClick={() => signOut()}
              className="border-0 menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center"
            >
              <div className="logout ml-0 mx-lg-auto" />
              <small>Logout</small>
            </button>
          </div>
        </div>
        {/* below menu mobile */}
        <div className="d-lg-none overflow d-flex flex-column p-absolute p-lg-relative menu-group active">
          <div className="d-flex flex-column justify-content-between pl-4 px-lg-0 py-5 py-lg-1 py-xxl-3 h-lg-100 menu">
            {user.type === 'master' && (
              <>
                <Link
                  to={`${process.env.PUBLIC_URL}/dashboard`}
                  className={`menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/dashboard.*/) ? 'active' : ''
                  }`}
                >
                  <div className="dashboard d-none d-lg-block" />
                  <small>Dashboard</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/users`}
                  className={`menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/users.*/) &&
                    !location.pathname.match(/\/users-placement.*/)
                      ? 'active'
                      : ''
                  }`}
                >
                  <div className="users d-none d-lg-block" />
                  <small>Users</small>
                </Link>

                <Link
                  to={`${process.env.PUBLIC_URL}/users-placement`}
                  className={`menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/users-placement.*/)
                      ? 'active'
                      : ''
                  }`}
                >
                  <div className="placement-user d-none d-lg-block" />
                  <small>Users placement</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/waitlist`}
                  className={`menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/waitlist.*/) ? 'active' : ''
                  }`}
                >
                  <div className="waitlist d-none d-lg-block" />
                  <small>Waitlist</small>
                </Link>
                {false && (
                  <Link
                    to={`${process.env.PUBLIC_URL}/coaches`}
                    className={`menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                      location.pathname.match(/\/coaches.*/) ? 'active' : ''
                    }`}
                  >
                    <div className="coaches d-none d-lg-block" />
                    <small>Coaches</small>
                  </Link>
                )}
              </>
            )}
            <Link
              to={`${process.env.PUBLIC_URL}/courses`}
              className={`menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                location.pathname.match(/\/courses.*/) ? 'active' : ''
              }`}
            >
              <div className="courses d-none d-lg-block" />
              <small>Courses</small>
            </Link>
            <Link
              to={`${process.env.PUBLIC_URL}/trainings`}
              className={`menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                location.pathname.match(/\/trainings.*/) ? 'active' : ''
              }`}
            >
              <div className="courses d-none d-lg-block" />
              <small>Trainings</small>
            </Link>
            {false && (
              <>
                <Link
                  to={`${process.env.PUBLIC_URL}/resources/downloads`}
                  className={`menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/resources.*/) ? 'active' : ''
                  }`}
                >
                  <div className="resources d-none d-lg-block" />
                  <small>Resources</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/products`}
                  className={`menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/products.*/) ? 'active' : ''
                  }`}
                >
                  <div className="product d-none d-lg-block" />
                  <small>Franchises</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/categories/courses`}
                  className={`menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/categories.*/) ? 'active' : ''
                  }`}
                >
                  <div className="categories d-none d-lg-block" />
                  <small>Categories</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/order-review`}
                  className={`menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/order.*/) ? 'active' : ''
                  }`}
                >
                  <div className="order d-none d-lg-block" />
                  <small>Order review</small>
                </Link>
                <Link
                  to={`${process.env.PUBLIC_URL}/scheduled-withdrawals`}
                  className={`menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                    location.pathname.match(/\/scheduled-withdrawals.*/)
                      ? 'active'
                      : ''
                  }`}
                >
                  <div className="scheduled-withdrawals d-none d-lg-block" />
                  <small>Scheduled Withdrawals</small>
                </Link>
              </>
            )}

            <Link
              to={`${process.env.PUBLIC_URL}/promotional-material/sales-funnels`}
              className={`menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                location.pathname.match(/\/promotional-material.*/)
                  ? 'active'
                  : ''
              }`}
            >
              <div className="promotional-material d-none d-lg-block" />
              <small>Promotional material</small>
            </Link>
            <Link
              to={`${process.env.PUBLIC_URL}/orders`}
              className={`menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                location.pathname.match(/\/orders.*/) ? 'active' : ''
              }`}
            >
              <div className="orders d-none d-lg-block" />
              <small>Orders</small>
            </Link>
            <Link
              to={`${process.env.PUBLIC_URL}/commissions`}
              className={`menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                location.pathname.match(/\/commissions.*/) ? 'active' : ''
              }`}
            >
              <div className="commissions d-none d-lg-block" />
              <small>Commissions</small>
            </Link>
            <Link
              to={`${process.env.PUBLIC_URL}/chats`}
              className={`menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                location.pathname.match(/\/chats.*/) ? 'active' : ''
              }`}
            >
              <div className="chats d-none d-lg-block" />
              <small>Chats</small>
            </Link>
            <Link
              to={`${process.env.PUBLIC_URL}/sms`}
              className={`menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                location.pathname.match(/\/sms.*/) ? 'active' : ''
              }`}
            >
              <div className="sms d-none d-lg-block" />
              <small>SMS</small>
            </Link>
            <Link
              to={`${process.env.PUBLIC_URL}/faq`}
              className={`menu-item px-2 px-lg-0 py-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center ${
                location.pathname.match(/\/faq.*/) ? 'active' : ''
              }`}
            >
              <div className="faq d-none d-lg-block" />
              <small>FAQ</small>
            </Link>
          </div>
          <div className="d-flex flex-column mb-5 mb-lg-0 pl-4 px-lg-0 py-3 py-lg-2 py-xxl-3 logoff">
            <button
              type="button"
              onClick={() => signOut()}
              className="border-0 menu-item px-2 px-lg-0 py-2 py-lg-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center"
            >
              <div className="logout ml-0 mx-lg-auto" />
              <small>Logout</small>
            </button>
          </div>
        </div>
      </Menu>
    </Container>
  );
};

export default Header;
