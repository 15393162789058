import React, { useCallback, useEffect, useState } from 'react';
import plus from '~/assets/icons/plus-admin-icon2.svg';
import search from '~/assets/icons/search-icon.svg';
import QuestionBox from '~/components/QuestionBox';

import api from '~/services/api';
import { Container, Search, Welcome } from './styles';

interface IFaqResponse {
  id: string;
  question: string;
  answer: string;
}

interface IFaq {
  id?: string;
  question: string;
  answer: string;
  edited: boolean;
  status: string;
}

const FaqList: React.FC = () => {
  const [questions, setQuestions] = useState<IFaq[]>([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClickQuestion = useCallback((e) => {
    const questionBox = e.target.closest('.question-box');
    if (questionBox) {
      questionBox.classList.toggle('active');
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .get<IFaqResponse[]>(`faq`, {
        params: {
          question: filter,
        },
      })
      .then((response) => {
        const data: IFaq[] = response.data.map((item) => ({
          id: item.id,
          question: item.question,
          answer: item.answer,
          edited: false,
          status: 'old',
        }));
        setQuestions(data.reverse());
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filter, handleClickQuestion]);

  const handleAddQuestion = useCallback(() => {
    const newQuestions = questions.slice();
    const newQuestion: IFaq = {
      question: '',
      answer: '',
      edited: true,
      status: 'new',
    };
    newQuestions.unshift(newQuestion);
    console.log(newQuestions);
    setQuestions(newQuestions);
  }, [questions]);

  const handleChange = useCallback((e) => {
    setFilter(e.target.value);
  }, []);

  const handleChangeQuestion = useCallback(
    (data, index) => {
      const newQuestions = questions.slice();
      newQuestions[index] = data;

      setQuestions(newQuestions);
    },
    [questions]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row align-items-center pb-5 mb-lg-5">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row align-items-center justify-content-center">
                  <div className="col-lg-8 px-md-0 px-lg-3">
                    <div className="d-sm-flex align-items-center">
                      <h1 className="h2 mb-0">Help & FAQ</h1>
                      <button
                        type="button"
                        onClick={handleAddQuestion}
                        className="btn-new-ask align-items-center mx-auto m-sm-0 px-5 px-lg-4 ml-sm-5 mt-5 rounded-pill text-center d-flex justify-content-center"
                      >
                        <img src={plus} alt="plus" className="mr-2" />
                        Add New Ask
                      </button>
                    </div>
                  </div>
                  <div className="col-11 col-sm-9 col-lg-4 mt-5 mt-lg-0">
                    <Search className="d-flex">
                      <input
                        className="w-100"
                        placeholder="Search"
                        onChange={handleChange}
                      />
                      <img src={search} alt="Search" className="mx-2" />
                    </Search>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
        </div>
        <div className="row">
          {questions.length > 0 ? (
            <>
              {questions.map((question, index) => (
                <QuestionBox
                  key={question.id}
                  onLoading={setLoading}
                  onClickQuestion={handleClickQuestion}
                  questionData={question}
                  indexQuestion={index}
                  onChangeQuestion={handleChangeQuestion}
                  edit={question.edited}
                />
              ))}
            </>
          ) : (
            <div className="col-12">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <p className="h5 text-center">
                    No questions found for this filter.
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default FaqList;
