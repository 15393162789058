import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface Img {
  src: string;
}

interface IPercent {
  percent: number;
}

export const Container = styled.div`
  .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_nw,
  .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_sw {
    border-radius: 20px 20px 0 0;
  }
  .ck.ck-button,
  a.ck.ck-button {
    :hover {
      color: #414141 !important;
      background-color: #cbccca !important;
    }
  }

  .ck-on {
    color: #414141 !important;
  }

  .ck.ck-list {
    background-color: #414141;
  }

  .ck.ck-button .ck-tooltip,
  a.ck.ck-button .ck-tooltip {
    display: none !important;
  }

  .ck-content {
    min-height: 65vh !important;
  }

  .ck-toolbar {
    background: rgba(65, 65, 65, 1) !important;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-color: rgba(51, 51, 51, 1) !important;
    padding: 1rem !important;
  }

  .ck-content {
    background: #202020 !important;
    border: 1px solid rgba(51, 51, 51, 1) !important;
    box-shadow: none !important;
    min-height: 100vh;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }

  .ck-focused {
    border: 1px solid #fff !important;
  }

  .min-width {
    min-width: 600px;
  }
  .border-gray {
    border-color: #8c8c8c;
  }

  .hr-gray {
    border-color: #242526;
  }

  .step-lesson {
    span {
      color: #8c8c8c;

      :hover {
        color: rgba(32, 32, 32, 1) !important;
        text-decoration: none;
      }
    }
    text-decoration: none;
  }
  form {
    background-color: rgba(32, 32, 32, 1);
    border-radius: 20px;
  }
  .delete {
    background-color: transparent;
    border: none;
  }
  .btn-light-grey {
    background-color: rgba(203, 204, 206, 1);
    border: none;
    color: rgba(110, 110, 110, 1);
    border-radius: 15px;
  }
`;

export const VideoInfo = styled.div`
  background: #202020;
  border-radius: 20px;
  padding: 20px;

  .bg-gray {
    background: #242526;
    border-radius: 20px;
    overflow: hidden;

    p {
      color: #565656;
    }
  }

  .remove-video {
    top: 0;
    right: 5px;
    z-index: 999;
    background-color: transparent;
    border: none;
  }

  .short-tilte {
    border: none;
    border-bottom: 1px solid rgba(51, 51, 51, 1);
    border-radius: 0;
  }

  .border-gray {
    border-color: #8c8c8c;
  }

  label {
    color: #828282;
  }

  .btn-radio {
    color: #cbccce;

    :hover {
      color: #cbccce;
    }
  }

  @media (min-width: 769px) and (max-width: 1199px) {
    .bd-right {
      border-right: solid 1px #333;
    }
  }
`;

export const CourseInfo = styled.div`
  background: #202020;
  border-radius: 20px;
  padding: 20px;

  .bg-gray {
    background: #242526;
    border-radius: 20px;
    overflow: hidden;

    p {
      color: #565656;
    }
  }

  label {
    color: #828282;
  }

  .bio-photo {
    width: 100%;
    border-radius: 20px;
  }

  .link-preview {
    span {
      background: #242526;
      border-radius: 10px;
      border: 1.5px solid #6e6e6e;
      padding: 5px 20px;
      color: #cbccce;
      margin-right: -15px;
      z-index: 1;
    }

    input {
      padding-left: 15px;
    }
  }
`;

export const Img = styled.div<Img>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  height: 184px;
`;

export const VideoPreview = styled.div`
  border-radius: 20px;
  overflow: hidden;
`;

export const Modal = styled(ModalComponent)``;

export const Percent = styled.div<IPercent>`
  width: 200px;
  height: 15px;
  position: relative;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid #fff;

  div {
    width: ${(props) => props.percent}%;
    height: 100%;
    position: absolute;
    border-radius: 5px;
    background-color: #fff;
    transition-duration: 0.3s;
  }
`;
