import styled from 'styled-components';

export const Container = styled.div`
  h2 {
    color: #e4e4e4;
    font-size: 18px;
  }

  p {
    font-size: 14px;
    font-weight: 300;
  }

  span {
    font-weight: 500;
    font-size: 48px;
  }

  .color-green {
    color: #37c694;
  }
  .bg {
    background: #202020;
    border-radius: 20px;
  }
  .h-box {
    height: 272px;
  }

  .h-box-flags {
    ::-webkit-scrollbar {
      background-color: transparent;
      width: 7px;
      height: 5px;
    }
  }
`;
