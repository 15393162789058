import styled from 'styled-components';

export const Container = styled.div``;

export const Welcome = styled.div`
  h1 {
    color: #e4e4e4;
  }
`;

export const Options = styled.div`
  a,
  button {
    border: none;
    background: none;
    color: #cbccce;
    -webkit-text-stroke: 0.5px #00000000;

    :hover {
      color: #e4e4e4;
      -webkit-text-stroke: 0.5px #e4e4e4;
      text-decoration: none;
    }
  }

  a.active,
  button.active {
    color: #ffffff;
    -webkit-text-stroke: 0.5px #fff;
  }
`;
