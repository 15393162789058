import styled from 'styled-components';

export const Container = styled.div`
  width: 270px;
  height: 200px;
  position: relative;

  .pointer-events-none {
    pointer-events: none;
  }

  .background {
    position: absolute;
    width: 270px;
    height: 170px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .circle-around {
      position: absolute;
      width: 140px;
      height: 140px;
      background: radial-gradient(
        79.65% 79.65% at 17.75% 20.35%,
        #353535 0%,
        #222221 100%
      );
      box-shadow: 1px 3px 6px rgba(29, 29, 29, 0.9);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: -1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -40%);

      .circle-inside {
        width: 105px;
        height: 105px;
        background: radial-gradient(
          79.65% 79.65% at 17.75% 20.35%,
          #2c2c2c 0%,
          #2b2b2b 12.18%,
          #232323 100%
        );
        border-radius: 235px;
        transform: rotate(180deg);
      }
    }
    p {
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      color: #e4e4e4;
      margin-bottom: 0;
    }
    span {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #8c8c8c;
      margin-bottom: -30px;
    }
  }

  @media screen and (max-width: 350px) {
    width: 250px;
    .background {
      width: 250px;

      top: 45%;
    }
  }
`;
