import styled from 'styled-components';

interface IContainer {
  items: number;
}

interface IItem {
  src: string;
}

export const Container = styled.div<IContainer>`
  background: #242526;
  border: 1.5px solid #2c2c2c;
  border-radius: 15px;
  min-height: 645px;
  height: ${(props) => props.items * 150 + 30}px;

  .dropzone {
    width: 100%;
    min-height: 645px;
    height: ${(props) => props.items * 150}px;
  }

  .grab-item {
    opacity: 1 !important;
    cursor: grab;

    :active {
      cursor: grabbing;
    }
  }
`;

export const Item = styled.div<IItem>`
  > div {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url('${(props) => props.src}');
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    width: 100%;
    height: 140px;

    p {
      color: #e4e4e4;
      font-weight: 600;
    }
  }
`;
