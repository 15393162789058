import React, { useCallback, useEffect, useState, Fragment } from 'react';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';

import { Container, Question, Modal, InputRadio } from './styles';
import Input from '~/components/Input';

import quiz from '~/assets/icons/quiz-icon.svg';
import quizModalIcon from '~/assets/icons/quiz-modal-icon.svg';

interface IOption {
  id?: string;
  answer: string;
  correct_answer: boolean;
  status?: 'NEW' | 'UPDATED' | 'DELETED';
}

export interface IQuestion {
  id?: string;
  question: string;
  options: IOption[];
  status?: 'NEW' | 'UPDATED' | 'DELETED';
}

interface QuizzesProps {
  onChange?(questions: IQuestion[]): void;
  hasValidateError?: boolean;
  required?: boolean;
  dataQuestions?: IQuestion[];
  setHasAnswers?(data: boolean): void;
}

const Quizzes: React.FC<QuizzesProps> = ({
  onChange,
  hasValidateError,
  required,
  dataQuestions,
  setHasAnswers,
}) => {
  const [show, setShow] = useState(false);
  const [questions, setQuestions] = useState<IQuestion[]>([
    {
      question: '',
      options: [
        {
          answer: '',
          correct_answer: true,
          status: 'NEW',
        },
      ],
      status: 'NEW',
    },
  ]);
  const [error, setError] = useState('');
  const [answersHasBeenAdd, setAnswersHasBeenAdd] = useState(false);

  useEffect(() => {
    if (dataQuestions && dataQuestions.length > 0) {
      setQuestions(dataQuestions);
      const checkQuestion = dataQuestions.find(
        (dataQuestion) =>
          dataQuestion.status !== 'DELETED' && dataQuestion.question.length > 0
      );
      if (checkQuestion) {
        setAnswersHasBeenAdd(true);
      }
    }
  }, [dataQuestions]);

  useEffect(() => {
    if (onChange) {
      onChange(questions);
    }
  }, [questions, onChange]);

  useEffect(() => {
    if (setHasAnswers) {
      setHasAnswers(answersHasBeenAdd);
    }
  }, [answersHasBeenAdd, setHasAnswers]);

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(() => setShow(true), []);

  const handleAddMoreQuestions = useCallback(() => {
    setQuestions((state) => [
      ...state,
      {
        question: '',
        options: [
          {
            answer: '',
            correct_answer: true,
            status: 'NEW',
          },
        ],
        status: 'NEW',
      },
    ]);
  }, []);

  const handleAddMoreAnswers = useCallback(
    (question) => {
      const questionIndex = questions.findIndex(
        (questionData) => questionData === question
      );
      if (questionIndex >= 0) {
        const newQuestions = questions.slice();
        newQuestions[questionIndex].options.push({
          answer: '',
          correct_answer: false,
          status: 'NEW',
        });
        if (
          newQuestions[questionIndex].status &&
          newQuestions[questionIndex].status !== 'NEW'
        ) {
          newQuestions[questionIndex].status = 'UPDATED';
        }
        setQuestions(newQuestions);
      }
    },
    [questions]
  );

  const handleRemoveQuestions = useCallback(
    (question) => {
      const questionIndex = questions.findIndex(
        (questionData) => questionData === question
      );
      if (questionIndex >= 0) {
        const newQuestions = questions.slice();
        newQuestions[questionIndex].status = 'DELETED';
        const checkQuestions = newQuestions.find(
          (questionData) => questionData.status !== 'DELETED'
        );
        if (!checkQuestions) {
          const newQuestion: IQuestion = {
            question: '',
            options: [
              {
                answer: '',
                correct_answer: true,
                status: 'NEW',
              },
            ],
            status: 'NEW',
          };
          newQuestions.push(newQuestion);
          setAnswersHasBeenAdd(false);
        }
        setQuestions(newQuestions);
      }
    },
    [questions]
  );

  const handleRemoveAnswers = useCallback(
    (question, option) => {
      const questionIndex = questions.findIndex(
        (questionData) => questionData === question
      );
      if (questionIndex >= 0) {
        if (questions[questionIndex].options.length > 1) {
          const optionIndex = questions[questionIndex].options.findIndex(
            (optionData) => optionData === option
          );
          if (optionIndex >= 0) {
            const newQuestions = questions.slice();
            newQuestions[questionIndex].status = 'UPDATED';
            newQuestions[questionIndex].options[optionIndex].status = 'DELETED';

            const checkOptions = newQuestions[questionIndex].options.find(
              (optionData) => optionData.status !== 'DELETED'
            );

            if (!checkOptions) {
              const newOption: IOption = {
                answer: '',
                correct_answer: true,
                status: 'NEW',
              };

              newQuestions[questionIndex].options.push(newOption);
            }

            setQuestions(newQuestions);
          }
        }
      }
    },
    [questions]
  );

  const handleChangeQuestion = useCallback(
    (e, question) => {
      const data = e.target.value;

      const questionIndex = questions.findIndex(
        (questionData) => questionData === question
      );
      if (questionIndex >= 0) {
        const newQuestions = questions.slice();
        newQuestions[questionIndex].question = data;
        if (newQuestions[questionIndex].status !== 'NEW') {
          newQuestions[questionIndex].status = 'UPDATED';
        }
        setQuestions(newQuestions);
      }
    },
    [questions]
  );

  const handleChangeOption = useCallback(
    (e, question, option) => {
      const data = e.target.value;

      const questionIndex = questions.findIndex(
        (questionData) => questionData === question
      );
      if (questionIndex >= 0) {
        const optionIndex = questions[questionIndex].options.findIndex(
          (optionData) => optionData === option
        );
        if (optionIndex >= 0) {
          const newQuestions = questions.slice();
          newQuestions[questionIndex].options[optionIndex].answer = data;
          if (
            newQuestions[questionIndex].options[optionIndex].status !== 'NEW'
          ) {
            newQuestions[questionIndex].options[optionIndex].status = 'UPDATED';
          }
          if (newQuestions[questionIndex].status !== 'NEW') {
            newQuestions[questionIndex].status = 'UPDATED';
          }
          setQuestions(newQuestions);
        }
      }
    },
    [questions]
  );

  const handleChangeAnswersCorrect = useCallback(
    (question, option) => {
      const questionIndex = questions.findIndex(
        (questionData) => questionData === question
      );
      if (questionIndex >= 0) {
        const optionIndex = questions[questionIndex].options.findIndex(
          (optionData) => optionData === option
        );
        if (optionIndex >= 0) {
          const newQuestions = questions.slice();
          newQuestions[questionIndex].options.forEach(
            (otherOption: IOption) => {
              if (otherOption.correct_answer) {
                // eslint-disable-next-line no-param-reassign
                otherOption.status = 'UPDATED';
              }

              // eslint-disable-next-line no-param-reassign
              otherOption.correct_answer = false;
            }
          );
          newQuestions[questionIndex].options[
            optionIndex
          ].correct_answer = true;
          if (
            newQuestions[questionIndex].options[optionIndex].status !== 'NEW'
          ) {
            newQuestions[questionIndex].options[optionIndex].status = 'UPDATED';
          }
          if (newQuestions[questionIndex].status !== 'NEW') {
            newQuestions[questionIndex].status = 'UPDATED';
          }
          setQuestions(newQuestions);
        }
      }
    },
    [questions]
  );

  const handleDone = useCallback(() => {
    setError('');

    let hasError = false;
    let hasErrorAux = false;

    // eslint-disable-next-line no-restricted-syntax
    for (const question of questions) {
      if (!question.question) {
        setError('Fill in all the fields or remove the blanks');
        hasError = true;
        hasErrorAux = true;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const option of question.options) {
        if (!option.answer) {
          setError('Fill in all the fields or remove the blanks');
          hasError = true;
          hasErrorAux = true;
        }
      }
    }

    if (!required) {
      setError('');
      hasError = false;
    }

    if (!hasError) {
      if (!hasErrorAux) {
        setAnswersHasBeenAdd(true);
      }
      handleClose();
    } else {
      setAnswersHasBeenAdd(false);
    }
  }, [handleClose, questions, required]);

  return (
    <Container
      hasValidateError={hasValidateError}
      className="order-2 order-lg-1 col-12"
    >
      <div className="mt-3">
        <label htmlFor="title" className="small">
          QUIZ
        </label>
        <button
          type="button"
          className="d-flex align-items-center w-100 py-3 button-quiz"
          onClick={handleShow}
        >
          <img src={quiz} alt="Quiz Button" className="mr-2" />{' '}
          {answersHasBeenAdd ? 'Quiz filled' : '-'}
        </button>
      </div>
      <Input
        name="quizzes"
        value={answersHasBeenAdd ? 'answersHasBeenAdd' : ''}
        className="d-none order-2 order-lg-1"
      />
      <Modal
        className="modal-quizzes"
        size="lg"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="ml-auto">
            <img src={quizModalIcon} alt="Add questions" className="mr-3" />
            Add questions
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {questions.map((question, indexQuestion) => (
            <Fragment key={indexQuestion}>
              {question.status !== 'DELETED' && (
                <Question className="questions">
                  <div className="mb-3">
                    <div className="d-flex justify-content-between">
                      <label
                        htmlFor={`question_${indexQuestion}`}
                        className="small"
                      >
                        Question
                      </label>
                      <button
                        type="button"
                        onClick={() => handleRemoveQuestions(question)}
                      >
                        <AiOutlineClose size={20} color="#CBCCCE" />
                      </button>
                    </div>
                    <Input
                      id={`question_${indexQuestion}`}
                      name={`question_${indexQuestion}`}
                      onChange={(e) => handleChangeQuestion(e, question)}
                      value={question.question}
                    />
                  </div>
                  <div className="mb-3">
                    {question.options.map((option, indexOption) => (
                      <Fragment key={indexOption}>
                        {option.status !== 'DELETED' && (
                          <div className="row align-items-center answer">
                            <div className="col-lg-9 d-flex align-items-center">
                              <InputRadio
                                htmlFor={`answers_correct_${indexQuestion}_${indexOption}`}
                                checked={option.correct_answer}
                                className="mb-0 mr-2"
                              >
                                <Input
                                  type="radio"
                                  id={`answers_correct_${indexQuestion}_${indexOption}`}
                                  name={`answers_correct_${indexQuestion}`}
                                  className="checkbox mr-1"
                                  onChange={() =>
                                    handleChangeAnswersCorrect(question, option)
                                  }
                                  checked={option.correct_answer}
                                />
                              </InputRadio>
                              <Input
                                id={`answers_${indexQuestion}_${indexOption}`}
                                placeholder="Answers"
                                name={`answers_${indexQuestion}_${indexOption}`}
                                onChange={(e) =>
                                  handleChangeOption(e, question, option)
                                }
                                className="input-answers"
                                value={option.answer}
                              />
                            </div>
                            <div className="col-lg-3 pl-0 d-flex align-items-center">
                              <button
                                type="button"
                                onClick={() =>
                                  handleRemoveAnswers(question, option)
                                }
                              >
                                <AiOutlineClose size={20} color="#CBCCCE" />
                              </button>
                              {option.correct_answer && (
                                <label
                                  htmlFor={`answers_correct_${indexQuestion}_${indexOption}`}
                                  className="small mb-0 ml-2 correct"
                                >
                                  Correct Answer
                                </label>
                              )}
                            </div>
                          </div>
                        )}
                      </Fragment>
                    ))}
                    <button
                      type="button"
                      onClick={() => handleAddMoreAnswers(question)}
                      className="mt-1 border-0 bg-transparent"
                    >
                      <AiOutlinePlus
                        size={18}
                        color="#FFFFFF"
                        className="mr-1"
                      />
                      <span className="px-2 py-1 small">Add more Answers</span>
                    </button>
                  </div>
                </Question>
              )}
            </Fragment>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <div
            className={`row w-100 ${
              error ? 'justify-content-between' : 'justify-content-end'
            }`}
          >
            {error && (
              <div className="alert-danger d-flex align-items-center px-2 py-1 rounded">
                <span className="mb-0">{error}</span>
              </div>
            )}
            <div className="col-lg-6">
              <div className="d-flex h-100 align-items-center">
                <button
                  type="button"
                  onClick={() => handleAddMoreQuestions()}
                  className="mt-2 transparent-button"
                >
                  <AiOutlinePlus size={18} color="#FFFFFF" className="mr-1" />
                  <span className="px-2 py-1 small">Add more questions</span>
                </button>
              </div>
            </div>
            <div className="col-lg-6 text-right">
              <button
                type="button"
                onClick={handleDone}
                className="mt-2 ml-auto btn-grey px-4 py-3"
              >
                <span className="py-1 px-2">Done</span>
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Quizzes;
