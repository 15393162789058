import React, { useState, useEffect, useCallback } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import 'react-day-picker/lib/style.css';

import { Container, Welcome, Options, Search } from './styles';
import AllCourses from './AllCourses';
import Categories from './Categories';
import Drafts from './Drafts';

import plus from '~/assets/icons/plus-admin-icon2.svg';
import search from '~/assets/icons/search-icon.svg';

const CoursesList: React.FC = () => {
  const location = useLocation();
  const [optionSelected, setOptionSelected] = useState('categories');
  const [courses, setCourses] = useState(0);
  const [drafts, setDrafts] = useState(0);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    setOptionSelected(location.pathname.slice(9) || 'all-courses');
    setFilter('');
  }, [location.pathname]);

  const handleChange = useCallback((e) => {
    setFilter(e.target.value);
  }, []);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="d-sm-flex text-center">
                      <h1 className="h2 text-left">Courses</h1>
                      <Link
                        to={`${process.env.PUBLIC_URL}/courses/register`}
                        className="btn-new-course align-items-center px-5 ml-lg-5 ml-auto mt-5 mt-sm-0 rounded-pill text-center d-inline-flex justify-content-center"
                      >
                        <img src={plus} alt="plus" className="mr-2" />
                        New&nbsp;Course
                      </Link>
                    </div>
                  </div>
                  <div className="col-11 col-sm-9 col-lg-4 mt-5 mt-lg-0">
                    <Search className="d-flex">
                      <input
                        className="w-100"
                        placeholder="Search"
                        onChange={handleChange}
                        value={filter}
                      />
                      <img src={search} alt="Search" className="mx-2" />
                    </Search>
                  </div>
                </div>
              </div>
            </Welcome>
            <Options>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xxl-10 order-2 order-xxl-1 col-12 ">
                    <div className="row mx-1 pt-5 pb-4 mb-4">
                      <div className="col-5 col-sm-3 px-0">
                        <NavLink
                          to={`${process.env.PUBLIC_URL}/courses`}
                          activeClassName="active border-bottom pb-1"
                          exact
                        >
                          All Courses
                        </NavLink>
                      </div>
                      <div className="col-4 col-sm-3 px-0">
                        <NavLink
                          to={`${process.env.PUBLIC_URL}/courses/categories`}
                          activeClassName="active border-bottom pb-1"
                        >
                          Categories
                        </NavLink>
                      </div>
                      <div className="col-3 px-3 px-sm-0">
                        <NavLink
                          to={`${process.env.PUBLIC_URL}/courses/drafts`}
                          activeClassName="active border-bottom pb-1"
                        >
                          Drafts
                        </NavLink>
                      </div>
                      <hr className="w-100 w-sm-75 mt-4" />
                    </div>
                    <AllCourses
                      active={optionSelected === 'all-courses'}
                      totalCourses={setCourses}
                      filter={filter}
                    />
                    <Categories
                      active={!!optionSelected.match('categories')}
                      filter={filter}
                    />
                    <Drafts
                      active={optionSelected === 'drafts'}
                      totalCourses={setDrafts}
                      filter={filter}
                    />
                  </div>
                  <div className="col-xxl-2 col-12 order-1 order-xxl-2 py-3 pt-5 overview">
                    <h2 className="h3 mb-5">Overview</h2>
                    <div className="row">
                      <div className="col">
                        <div className="over-card">
                          <div className="px-3 py-2">
                            <small className="title-card d-block h6 text-gray py-4">
                              Active Courses
                            </small>
                            <span className="number-courses d-block h2 mb-0 text-white pb-3">
                              {courses}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 d-none d-xxl-block">
                        <hr />
                      </div>
                      <div className="col">
                        <div className="over-card">
                          <div className="px-3 py-2">
                            <small className="title-card d-block h6 text-gray py-4">
                              Drafts Courses
                            </small>
                            <span className="number-courses d-block h2 mb-0 text-white pb-3">
                              {drafts}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Options>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CoursesList;
