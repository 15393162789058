/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react';
import { FiCopy } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Swal from 'sweetalert2';

import api from '~/services/api';
import { Container, AddButton, Card, LinkCopy } from './styles';

import imgThumb from '~/assets/icons/img_thumb.svg';
import plus from '~/assets/icons/plus-admin-icon2.svg';

interface IResponseBanner {
  id: string;
  title: string;
  link: string;
  thumbnail: {
    id: string;
    thumbnail_url: string;
  };
}

interface IBanner {
  id?: string;
  title: string;
  image: string;
  thumbnail?: File;
  thumbnail_id?: string;
  link: string;
  edited: boolean;
}

const Banners: React.FC = () => {
  const [links, setLinks] = useState<IBanner[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const elements = document.querySelectorAll('textarea');
    elements.forEach((element) => {
      element.style.height = '1px';
      element.style.height = `${element.scrollHeight}px`;
    });

    api.get(`banners`).then((response) => {
      const data = response.data.map((banner: IResponseBanner) => ({
        id: banner.id,
        title: banner.title,
        image: banner.thumbnail.thumbnail_url,
        thumbnail_id: banner.thumbnail.id,
        link: banner.link,
        edited: false,
      }));

      setLinks(data);
    });
  }, []);

  const handleAddLink = useCallback(() => {
    setLinks((state) => [
      ...state,
      {
        title: '',
        image: '',
        link: 'https://',
        edited: false,
      },
    ]);
  }, []);

  const handleChangeTitle = useCallback(
    (e, index) => {
      const newLinks = links.slice();
      newLinks[index].title = e.target.value;
      newLinks[index].edited = true;
      setLinks(newLinks);
    },
    [links]
  );

  const handleSelectFile = useCallback(
    (e, index) => {
      const newLinks = links.slice();
      if (e.target.files) {
        const file = e.target.files[0];
        if (file) {
          newLinks[index].image = URL.createObjectURL(file);
          newLinks[index].thumbnail = file;
        } else {
          newLinks[index].image = '';
          newLinks[index].thumbnail = undefined;
        }
      }
      newLinks[index].edited = true;
      setLinks(newLinks);
    },
    [links]
  );

  const handleChangeLink = useCallback(
    (value, index) => {
      const newLinks = links.slice();
      newLinks[index].link = value;
      newLinks[index].edited = true;
      setLinks(newLinks);
    },
    [links]
  );

  const handleClickDelete = useCallback(
    (item) => {
      const link = links.find((_, index) => index === item);
      if (link) {
        api.delete(`banners/${link.id}`);
      }

      const newLinks = links.filter((_, index) => index !== item);
      setLinks(newLinks);
    },
    [links]
  );

  const handleClickSave = useCallback(
    async (index) => {
      try {
        const newLinks = links.slice();
        let responseThumbnail;
        if (newLinks[index].thumbnail) {
          const thumbnailData = new FormData();
          thumbnailData.append('thumbnail', newLinks[index].thumbnail as File);
          responseThumbnail = await api.post('thumbnails', thumbnailData);
        } else {
          responseThumbnail = {
            data: {
              id: newLinks[index].thumbnail_id,
            },
          };
        }

        if (responseThumbnail.data) {
          let response;
          if (newLinks[index].id) {
            response = await api.put(`banners/${newLinks[index].id}`, {
              thumbnail_id: responseThumbnail.data.id,
              title: newLinks[index].title,
              link: newLinks[index].link,
            });
          } else {
            response = await api.post('banners', {
              thumbnail_id: responseThumbnail.data.id,
              title: newLinks[index].title,
              link: newLinks[index].link,
            });
          }

          if (response) {
            newLinks[index].edited = false;
            setLinks(newLinks);
          }
        }
      } catch {
        Swal.fire(
          'Opss...',
          'An error has occurred, please try again.',
          'error'
        );
      }
    },
    [links]
  );
  return (
    <Container>
      <div className="col-lg-12 my-3">
        <div className="d-flex w-100 my-5 my-md-4 my-lg-0 justify-content-center justify-content-sm-start justify-content-lg-end">
          <AddButton
            type="button"
            className="h6 mb-0 py-2 px-4 small normal-sm"
            onClick={handleAddLink}
          >
            <img src={plus} alt="plus" className="my-1 mr-3" />
            Add New Banner
          </AddButton>
        </div>
      </div>
      {links.map((link, index) => (
        <div key={index} className="col-md-6 col-xl-4 px-0 px-sm-3 my-3">
          <Card className="p-3 h-100">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div />
              {link.edited ? (
                <button
                  type="button"
                  className="rounded-pill btn-top py-2"
                  onClick={() => handleClickSave(index)}
                >
                  <span className="mb-0 small m-1">Save</span>
                </button>
              ) : (
                <button
                  type="button"
                  className="d-flex align-items-center justify-content-center rounded-pill btn-top py-2 text-danger"
                  onClick={() => handleClickDelete(index)}
                >
                  <RiDeleteBin6Line size={15} />
                  <span className="mb-0 small ml-2">Delete</span>
                </button>
              )}
            </div>
            <div>
              <input
                className="h5 mb-0 form-control"
                value={link.title}
                onChange={(e) => handleChangeTitle(e, index)}
                placeholder="Title here..."
              />
            </div>
            <label
              htmlFor={`image-${index}`}
              className="d-flex flex-column justify-content-center align-items-center label-image"
            >
              {link.image ? (
                <img
                  src={link.image}
                  alt="Banner"
                  className="w-100 mh-100 d-flex mx-auto justify-content-center align-items-center image-banner my-auto"
                />
              ) : (
                <>
                  <img src={imgThumb} alt="plus" className="w-25 mb-2" />
                  <p className="h4 mt-2 tinytext">New Image</p>
                </>
              )}
            </label>
            <input
              type="file"
              id={`image-${index}`}
              className="d-none"
              onChange={(e) => handleSelectFile(e, index)}
            />
            <LinkCopy className="rounded-pill d-flex justify-content-between align-items-center py-1 px-3 mt-4 mt-xl-3 mt-xxl-5">
              <input
                className="small py-2 w-100 bg-transparent border-0"
                value={link.link}
                onChange={(e) => handleChangeLink(e.target.value, index)}
              />
              <button
                type="button"
                className="border-0 btn-copy bg-transparent"
              >
                <FiCopy size={24} color="#CBCCCE" />
              </button>
            </LinkCopy>
          </Card>
        </div>
      ))}
    </Container>
  );
};

export default Banners;
