import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { parseISO, format } from 'date-fns';

import api from '~/services/api';

import { Container, Welcome, NameUserTable } from './styles';
import Table from '~/components/Table';

interface ITableData {
  position: string;
  dataJoined: string;
  userID: string;
  avatar: string;
  name: string;
  waitlist: string;
  referred: string;
  created_at: string;
}

interface IReferrer {
  id: string;
}

interface IAffiliateResponse {
  id: string;
  referrers: IReferrer[];
  user: {
    id: string;
    name: string;
    created_at: string;
    avatar: {
      avatar_url: string;
    };
    waitlist: {
      position: number;
    };
  };
}

const TopAffiliates: React.FC = () => {
  const [affiliates, setAffiliates] = useState<ITableData[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);

    api
      .get<IAffiliateResponse[]>('users/affiliates/top-affiliates', {
        params: {
          filter: filter || sessionStorage.getItem('@AutoAffiliate:searchUser'),
          isAdmin: true,
        },
      })
      .then((response) => {
        const data = response.data.map((affiliate, index) => ({
          position: `${(index + 1).toString().padStart(3, '0')}º`,
          dataJoined: format(
            parseISO(affiliate.user.created_at),
            'yyyy-MMM-dd'
          ),
          userID: affiliate.user.id,
          avatar: affiliate.user.avatar ? affiliate.user.avatar.avatar_url : '',
          name: affiliate.user.name,
          waitlist:
            !affiliate.user.waitlist || affiliate.user.waitlist.position < 0
              ? '00'
              : affiliate.user.waitlist.position.toString().padStart(2, '0'),
          referred: affiliate.referrers
            ? affiliate.referrers.length.toString().padStart(2, '0')
            : '00',
          created_at: affiliate.user.created_at,
        }));
        setAffiliates(data);
        setLoading(false);
      });
  }, [filter]);

  const handleSearch = useCallback((value) => {
    sessionStorage.setItem('@AutoAffiliate:searchUser', value);
    setFilter(value);
  }, []);

  const columns = useMemo(
    () => [
      {
        name: 'Position',
        selector: 'position',
        sortable: true,
      },
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        cell: (row: ITableData) => (
          <NameUserTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
          >
            <img src={row.avatar} alt={row.name} className="mr-2" />
            <p className="mb-0">{row.name}</p>
          </NameUserTable>
        ),
      },
      {
        name: 'Waitlist Position',
        selector: 'waitlist',
        sortable: true,
      },
      {
        name: 'Referred Members',
        selector: 'referred',
        sortable: true,
      },
      {
        name: 'Date Joined',
        selector: 'created_at',
        sortable: true,
        cell: (row: ITableData) => row.dataJoined,
      },
    ],
    []
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h4 h2-lg mb-4">Top Affiliates</h1>
                  </div>
                </div>
              </div>
            </Welcome>
            <Table
              data={affiliates}
              columns={columns}
              pagination
              date
              searchable
              onSearch={handleSearch}
              exportable
              searchValue={
                sessionStorage.getItem('@AutoAffiliate:searchUser') || undefined
              }
            />
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default TopAffiliates;
