import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface IAvatarProps {
  src: string;
}

export const Container = styled.div``;

export const Welcome = styled.div`
  p {
    color: #969696;
  }
`;

export const ManageCoaches = styled.div`
  background: #202020;
  border-radius: 20px;

  .add-width,
  .card-width {
    width: 341px;
  }

  .scrollbar {
    ::-webkit-scrollbar {
      background-color: transparent !important;
      height: 10px !important;
    }

    ::-webkit-scrollbar-thumb {
      background: #606060;
    }
  }

  @media screen and (min-width: 576px) {
    .overflow-sm-auto {
      overflow: auto;
    }
  }

  @media screen and (max-width: 575px) {
    .add-width {
      width: 100%;
    }
    .card-width {
      width: 288px;
    }
    .overflow-xs-auto {
      overflow: auto;
    }
  }
`;

export const AddCoach = styled(Link)`
  background: #242526;
  border-radius: 20px;

  .color-add p {
    color: #606060;
  }

  .icon-add svg {
    height: 60px;
    width: 60px;
  }

  .color-add :nth-child(2) {
    color: #8c8c8c;
  }

  :hover {
    text-decoration: none;
  }

  @media screen and (max-width: 575px) {
    .icon-add svg {
      height: 40px;
      width: 40px;
    }
  }
`;

export const Coach = styled.div`
  .bg-gray {
    background: #242526;
    border-radius: 20px;
    color: #8c8c8c;

    div {
      h3 {
        color: #ffffff;
      }

      .box-title {
        color: #8c8c8c;
      }

      .box-number {
        color: #e4e4e4;
        font-size: 24px;
      }

      .border-gray {
        border: solid 2px #2f2f2f;
        border-radius: 20px;
      }
    }

    .btn-edit {
      right: 10px;
      top: 10px;
      color: #828282;
      border-color: #4a4b4b !important;

      :hover {
        text-decoration: none;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .bg-gray {
      div .border-gray {
        background: #202020;
        border: 2px solid #242526;
      }
    }
  }
`;

export const Avatar = styled.div<IAvatarProps>`
  margin: 0 auto;
  width: 71px;
  height: 71px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 3px rgba(228, 228, 228, 0.5);
  box-sizing: border-box;
`;

export const CoachDetails = styled.div`
  background: #202020;
  border-radius: 20px;

  .rdt_TableHeader {
    margin-top: -10px;

    > :last-child {
      display: none;
    }
  }

  .btn-message {
    right: 10px;
    top: 10px;
    color: #fff;
    border-color: #2f2f2f !important;
    background-color: #2f2f2f;

    :hover {
      text-decoration: none;
    }
  }

  .rdt_Table {
    margin: 3rem 0;
    min-width: 1200px;
  }

  div.tableData .rdt_TableRow {
    padding: 15px 0;
  }
`;

export const MiniAvatar = styled.div<IAvatarProps>`
  margin: 0 auto;
  width: 40px;
  height: 40px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 2px rgba(228, 228, 228, 0.5);
  box-sizing: border-box;
`;
