import styled from 'styled-components';

export const Container = styled.div`
  h2.text-gray {
    color: #ababab;
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #e4e4e4;
  }
  p {
    color: #858585;
  }

  .btn-funnels-articles {
    color: #e4e4e4;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    border-radius: 33px;
    border: 1px solid #606060;
    background-color: #202020;
    padding-right: 15px;
    display: flex;
    align-items: center;
    height: 51px;
    transition-duration: 0.3s;
    text-decoration: none;

    :hover {
      opacity: 0.8;
    }

    span {
      width: 106.5px;
      display: block;
    }

    img {
      padding: 9px 15px;
      border-radius: 27px;
      background: #606060;
      margin: 4px;
      margin-right: 10px;
      width: 54px;
      height: 41px;
    }
  }

  @media screen and (max-width: 1399px) {
    p {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 991px) {
    p {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 400px) {
    h1 {
      font-size: 24px !important;
    }
  }
`;
