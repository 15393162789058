import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div``;

export const Welcome = styled.div`
  p {
    color: #969696;
  }
  h1 {
    color: #e4e4e4;
  }
`;

export const Card = styled.div`
  background-color: #202020;
  border-radius: 20px;
  overflow: hidden;

  img {
    width: 100%;
    border-radius: 20px;
  }

  a {
    color: #e4e4e4;
    padding: 10px 0px;

    :hover {
      color: ${lighten(0.1, '#e4e4e4')};
      background-color: ${lighten(0.1, '#606060')};
      text-decoration: none;
      transition-duration: 0.5s;
    }
  }

  .text-gray {
    color: #7e7e7e;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    display: flex;
    img {
      width: 35%;
      border-radius: 20px;
    }
  }

  @media screen and (min-width: 992px) {
    a {
      padding: 14px 0px;
    }
    h3 {
      height: 76px;
    }
    img {
      border-radius: 0px;
    }
    .text-gray {
      height: 126px;
    }
  }
`;
