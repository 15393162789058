import React, {
  FC,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface IOption {
  id: string;
  value: string;
  selected: boolean;
}

interface SelectProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: IOption[];
  readOnly?: boolean;
  hasError?(hasError: boolean): void;
}

const Input: FC<SelectProps> = ({
  name,
  hasError,
  className,
  options,
  onFocus,
  onBlur,
  readOnly,
  onChange,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [values, setValues] = useState<IOption[]>(options);
  const [optionSelected, setOptionSelected] = useState('');
  const [valueSelected, setValueSelected] = useState('');
  const [isFocuses, setIsFocuses] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  useEffect(() => {
    if (hasError) {
      hasError(!!error);
    }

    setValues(options);
    const selectedOption = options.find((option) => option.selected);
    if (selectedOption) {
      setOptionSelected(selectedOption.id);
      setValueSelected(selectedOption.value);
    }
  }, [error, hasError, options]);

  const handleInputFocus = useCallback(
    (e) => {
      if (onFocus) {
        onFocus(e);
      }
      setIsFocuses(true && !readOnly);
    },
    [onFocus, readOnly]
  );

  const handleInputBlur = useCallback(
    (e) => {
      if (onBlur) {
        onBlur(e);
      }
      setTimeout(() => {
        setIsFocuses(false);
      }, 300);
      setIsFilled(!!inputRef.current?.value);
    },
    [onBlur]
  );

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;
      const search = new RegExp(`${value.toUpperCase()}`);
      const newValuesList = options.filter((option) =>
        option.value?.toUpperCase().match(search)
      );
      setValueSelected(value);
      setValues(newValuesList);
    },
    [options]
  );

  const handleClick = useCallback(
    (option: IOption) => {
      setOptionSelected(option.id);
      setValueSelected(option.value);
      if (onChange) {
        onChange(option as any);
      }
    },
    [onChange]
  );

  return (
    <>
      <Container
        className={`${className} p-relative`}
        isErrored={!!error}
        isFilled={isFilled}
        isFocuses={isFocuses}
      >
        <div>
          <input
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={handleChange}
            autoComplete="off"
            value={valueSelected}
            readOnly={readOnly}
            {...rest}
          />
          <input
            type="hidden"
            name={name}
            defaultValue={defaultValue}
            ref={inputRef}
            value={optionSelected}
          />
        </div>
        {isFocuses && (
          <div className="p-absolute options">
            {values.map((option) => (
              <button
                key={option.id}
                type="button"
                onClick={() => handleClick(option)}
                className="w-100"
              >
                {option.value}
              </button>
            ))}
          </div>
        )}
      </Container>
      {error && <span className="small text-danger error">{error}</span>}
    </>
  );
};

export default Input;
