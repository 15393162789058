import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IFranchise {
  src: string;
  selected?: boolean;
}

export const Container = styled.div`
  .bg-gray {
    background-color: #1f1f1f;
    border-radius: 20px;
  }
`;

export const Welcome = styled.div`
  p {
    color: #969696;
  }
`;

export const AddFranchise = styled.button`
  width: 100%;
  height: 250px;
  background-color: #242526;
  border-radius: 20px;
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-top: 5px;
    color: #606060;
  }

  @media screen and (min-width: 1600px) {
    height: 298px;
  }
`;

export const Franchise = styled.button<IFranchise>`
  width: 100%;
  height: 250px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  border: none;
  text-align: left;
  align-items: start;
  justify-content: start;
  display: flex;

  h2 {
    color: #ffffff;
    position: relative;
    z-index: 1;
  }

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.4;
  }

  .delete-box {
    transition-duration: 0.3s;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    svg {
      color: #ff1a50;
      width: 50px;
      height: 50px;
    }
  }

  :hover {
    .delete-box {
      opacity: 1;
    }
  }

  @media screen and (min-width: 1600px) {
    height: 298px;
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border-radius: 20px;
  }
  .btn-save {
    width: 100px;
    background-color: #4a4b4b;
    border: none;
    border-radius: 20px;
    color: #e4e4e4 !important;
    text-align: center;
    :hover {
      filter: brightness(1.3);
    }
  }
`;

export const FranchiseButton = styled.button<IFranchise>`
  width: 100%;
  height: 150px;
  border: ${(props) => (props.selected ? '#fff 1px solid' : 'none')};
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h2 {
    color: #ffffff;
    position: relative;
    z-index: 1;
  }

  svg {
    width: 30px;
    height: 30px;
    color: #ffffff;
    position: relative;
    z-index: 1;
  }

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    opacity: 0.4;
  }

  @media screen and (min-width: 1600px) {
    height: 200px;
  }
`;
