import styled from 'styled-components';
import { lighten } from 'polished';

interface ICard {
  src: string;
  bannerCard?: boolean;
}

export const Container = styled.div`
  .categories-list {
    overflow: hidden auto;
    height: 450px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    .categories-list {
      height: auto;
    }
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #e4e4e4;
  }
  p {
    color: #969696;
  }

  .search {
    padding: 1px;
    border-radius: 100px;
    border: 1px solid #4a4b4b;
    background-color: #202020;

    input {
      margin: 5px 0;
      padding: 0 10px;
      border: none;
      border-radius: 100px;
      background-color: #202020;
    }
  }
  .add-training {
    border: 1px solid #4a4b4b;
    background-color: #202020;
    color: #e4e4e4;
    text-decoration: none !important;
    font-size: 12px;
    font-weight: 600;
    :hover {
      border-color: ${lighten(0.1, '#4a4b4b')};
      color: ${lighten(0.1, '#e4e4e4')};
      transition-duration: 0.5s;
      img {
        filter: brightness(1.3);
      }
    }
  }
`;

export const Search = styled.div`
  padding: 1px;
  border-radius: 100px;
  border: 1px solid #4a4b4b;
  background-color: #202020;

  input {
    margin: 5px 0;
    padding: 0 10px;
    border: none;
    border-radius: 100px;
    background-color: #202020;
  }
`;

export const Card = styled.div<ICard>`
  border-radius: 20px;
  overflow: hidden;
  border-radius: 20px;

  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  height: ${(props) => (props.bannerCard ? '450px' : '250px')};

  > div {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    .edit {
      background-color: transparent;
      border: none;
      color: rgba(203, 204, 206, 1);
      text-decoration: none;
      img {
        border-radius: 0px;
      }
      :hover {
        color: ${lighten(0.1, 'rgba(203, 204, 206, 1)')};
        transition-duration: 0.5s;
        img {
          filter: brightness(1.3);
        }
      }
    }

    a.btn-more {
      width: 40%;
      background: rgba(111, 110, 110, 1);
      border: none;
      color: rgba(203, 204, 206, 1);
      text-align: center;

      :hover {
        text-decoration: none;
        color: ${lighten(0.1, 'rgba(203, 204, 206, 1)')};
        background-color: ${lighten(0.1, 'rgba(111, 110, 110, 1)')};
        transition-duration: 0.5s;
      }
    }
  }

  @media screen and (max-width: 575px) {
    height: 250px;

    .btn-more {
      padding: 10px 40px;
    }
  }
`;
