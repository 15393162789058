import React, { useEffect, useState } from 'react';
import GraphSalesFunnel from '~/components/GraphSalesFunnel';
import { Container } from './styles';

import adspend from '~/assets/icons/adspend-icon.svg';
import brazil from '~/assets/icons/flag-brazil.svg';
import argentina from '~/assets/icons/flag-argentina.svg';
import usa from '~/assets/icons/flag-usa.svg';
import colombia from '~/assets/icons/flag-colombia.svg';
import generic from '~/assets/icons/generic-flag.svg';
import api from '~/services/api';

interface IFunnelTrackAllData {
  quantity: number;
  country: string;
}

interface IFunnelTrack {
  page: string;
  quantity: string;
  origin: string;
  action: string;
}

interface IFunnel {
  labels: string[];
  values: number[][];
}

interface ICountry {
  name: string;
  flag: string;
  quantity: number;
}

const Book: React.FC = () => {
  const [funnel, setFunnel] = useState<IFunnel>({
    labels: ['First Order', 'Second Order', 'Third Order', 'Congratulations'],
    values: [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
    ],
  });
  const [conversions, setConversions] = useState(0);
  const [countries, setCountries] = useState<ICountry[]>([]);

  useEffect(() => {
    api
      .get<IFunnelTrack[]>('funnels-tracks', {
        params: {
          page: 'book',
        },
      })
      .then(async (response) => {
        const funnelData: IFunnel = {
          labels: ['Book', 'Sign up', 'Create Account', 'Digital Franchise'],
          values: [
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0],
          ],
        };

        const responseSignUpFunnel = await api.get<IFunnelTrack[]>(
          'funnels-tracks',
          {
            params: {
              page: 'sign-up',
            },
          }
        );

        const responseDigitalFranchiseFunnel = await api.get<IFunnelTrack[]>(
          'funnels-tracks',
          {
            params: {
              page: 'sales-funnel',
              action: 'first-contact',
            },
          }
        );

        response.data.forEach((funnelTrack) => {
          if (funnelTrack.action === 'first-contact') {
            if (funnelTrack.origin === 'direct') {
              funnelData.values[0][0] = parseInt(funnelTrack.quantity, 10);
            } else if (funnelTrack.origin === 'social media') {
              funnelData.values[0][1] = parseInt(funnelTrack.quantity, 10);
            } else if (funnelTrack.origin === 'ads') {
              funnelData.values[0][2] = parseInt(funnelTrack.quantity, 10);
            }
          }
        });

        responseSignUpFunnel.data.forEach((funnelTrack) => {
          if (funnelTrack.action === 'first-contact') {
            if (funnelTrack.origin === 'direct') {
              funnelData.values[1][0] = parseInt(funnelTrack.quantity, 10);
            } else if (funnelTrack.origin === 'social media') {
              funnelData.values[1][1] = parseInt(funnelTrack.quantity, 10);
            } else if (funnelTrack.origin === 'ads') {
              funnelData.values[1][2] = parseInt(funnelTrack.quantity, 10);
            }
          } else if (funnelTrack.action === 'create-account') {
            if (funnelTrack.origin === 'direct') {
              funnelData.values[2][0] = parseInt(funnelTrack.quantity, 10);
            } else if (funnelTrack.origin === 'social media') {
              funnelData.values[2][1] = parseInt(funnelTrack.quantity, 10);
            } else if (funnelTrack.origin === 'ads') {
              funnelData.values[2][2] = parseInt(funnelTrack.quantity, 10);
            }
          }
        });

        responseDigitalFranchiseFunnel.data.forEach((funnelTrack) => {
          if (funnelTrack.origin === 'direct') {
            funnelData.values[3][0] = parseInt(funnelTrack.quantity, 10);
          } else if (funnelTrack.origin === 'social media') {
            funnelData.values[3][1] = parseInt(funnelTrack.quantity, 10);
          } else if (funnelTrack.origin === 'ads') {
            funnelData.values[3][2] = parseInt(funnelTrack.quantity, 10);
          }
        });

        const conversionsData = funnelData.values[2].reduce(
          (previous, current) => previous + current,
          0
        );

        const responseFunnel = await api.get<IFunnelTrackAllData[]>(
          'funnels-tracks',
          {
            params: {
              page: 'sign-up',
              action: 'create-account',
              allData: true,
            },
          }
        );

        const orderedFunnelData = responseFunnel.data.sort(
          (a, b) => b.quantity - a.quantity
        );

        const countriesData = orderedFunnelData.map((funnelOrderedData) => {
          let countryData: ICountry;
          switch (funnelOrderedData.country) {
            case 'Br':
              countryData = {
                flag: brazil,
                name: 'Brazil',
                quantity: funnelOrderedData.quantity,
              };
              break;
            case 'Ar':
              countryData = {
                flag: argentina,
                name: 'Argentina',
                quantity: funnelOrderedData.quantity,
              };
              break;
            case 'Us':
              countryData = {
                flag: usa,
                name: 'USA',
                quantity: funnelOrderedData.quantity,
              };
              break;
            case 'Co':
              countryData = {
                flag: colombia,
                name: 'Colombia',
                quantity: funnelOrderedData.quantity,
              };
              break;

            default:
              countryData = {
                flag: generic,
                name: 'Outros',
                quantity: funnelOrderedData.quantity,
              };
              break;
          }

          return countryData;
        });

        const othersCountriesData = countriesData.filter(
          (countryData) => countryData.name === 'Outros'
        );
        const newCountriesData = countriesData.filter(
          (countryData) => countryData.name !== 'Outros'
        );
        const otherCountriesQuantity = othersCountriesData.reduce(
          (previous, current) => previous + current.quantity,
          0
        );
        newCountriesData.push({
          name: 'Outros',
          flag: generic,
          quantity: otherCountriesQuantity,
        });

        setCountries(newCountriesData);
        setConversions(conversionsData);
        setFunnel(funnelData);
      });
  }, []);

  return (
    <Container>
      <GraphSalesFunnel data={funnel} />
      <div className="container px-0">
        <div className="row mt-5">
          <div className="col-lg-6">
            <div className="bg d-flex flex-column p-4 h-box">
              <h2 className="font-weight-600 mb-auto">Conversion</h2>
              {/* <p className="color-green mb-1">+1.4%</p> */}
              <div className="d-flex justify-content-between align-items-center">
                <span>{conversions.toString().padStart(3, '0')}</span>
                <img src={adspend} alt="icon ads" />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="bg d-flex flex-column p-4 h-box">
              <h2 className="font-weight-600 mb-5">Visits by country</h2>
              <div className="h-box-flags overflow-auto">
                {countries.map((country) => (
                  <div
                    key={country.name}
                    className="d-flex justify-content-between mb-4"
                  >
                    <div className="d-flex">
                      <img
                        src={country.flag}
                        alt="Colombia Flag"
                        className="mr-4"
                      />
                      {country.name}
                    </div>
                    <p className="h5 mb-0 font-weight-normal">
                      {country.quantity.toString().padStart(2, '0')}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Book;
