import styled from 'styled-components';

interface IBgImageProps {
  src: string;
}

export const Container = styled.div``;

export const Card = styled.div<IBgImageProps>`
  border-radius: 20px;
  text-decoration: none;
  height: 14.5vw;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .see-more-link {
    a {
      color: rgba(203, 204, 206, 1);
      text-decoration: none;
      border-radius: 20px;
    }
  }
  .short-title {
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px 20px 20px 20px;
    height: 100%;

    p {
      color: #e4e4e4 !important;
    }
  }

  @media screen and (min-width: 1800px) {
    height: 238px;

    .align-center-1800 {
      align-items: center;
    }

    .h4-1800 {
      font-size: 1.5rem !important;
    }
  }

  @media screen and (min-width: 2200px) {
    height: 300px;
  }

  @media screen and (max-width: 991px) {
    height: 21vw;
  }

  @media screen and (max-width: 768px) {
    height: 25vw;
  }

  @media screen and (max-width: 575px) {
    height: 40vw;
  }
`;
