import styled, { css } from 'styled-components';

interface IContainerProps {
  noBorderRadiusBottom: boolean;
  isErrored?: boolean;
}

export const Container = styled.div<IContainerProps>`
  border: 1.5px solid #3e3e3e;
  border-radius: 10px;
  padding: 10px;

  ${(props) =>
    props.noBorderRadiusBottom &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}

  .language {
    background: rgba(41, 41, 41, 1);
    color: #cbccce;

    svg {
      color: #cbccce;
    }
  }

  .min-width-10px {
    min-width: 20px;
  }

  .error {
    position: absolute;
    left: 0;
    bottom: -22px;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}
`;

export const SelectTag = styled.div`
  background-color: #242526;
  border: 1.5px solid #6e6e6e;
  border-top: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-height: 200px;
  overflow: auto;
  z-index: 500;

  button {
    color: #cbccce;
    transition-duration: 0.3s;
    background-color: transparent;

    + button {
      border-top: 1.5px solid #6e6e6e !important;
    }

    :hover {
      color: #cbccce;
      background-color: #202020;
    }
  }
`;
