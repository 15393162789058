import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  .flex-wrap-row-reverse {
    flex-wrap: wrap-reverse;
  }
`;

export const Welcome = styled.div`
  p {
    color: #969696;
  }

  .border-golden {
    :hover {
      color: #e4e4e4;
      text-decoration: none;
    }
  }
  .btn-new-ask {
    color: #dadada !important;
    border: solid 1px rgba(96, 96, 96, 1);
    background-color: rgba(32, 32, 32, 1);
    padding: 0.8rem 0;
    :hover {
      color: ${lighten(0.2, '#dadada')}!important;
      border: solid 1px ${lighten(0.1, 'rgba(96, 96, 96, 1)')};
      img {
        filter: brightness(2.5);
      }
      text-decoration: none;
    }
  }
`;

export const Search = styled.div`
  padding: 1px;
  border-radius: 100px;
  border: 1px solid #242526;
  background-color: transparent;

  input {
    margin: 5px 0;
    padding: 0 10px;
    border: none;
    border-radius: 100px;
    background-color: transparent;
    color: #cbccce !important;

    ::placeholder {
      color: #cbccce !important;
    }
  }
`;
