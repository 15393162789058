import styled from 'styled-components';

interface IThumbnailPreview {
  src: string;
}

interface IResource {
  src: string;
}

export const Container = styled.div`
  .card-resource {
    .thumb-preview {
      border-radius: 20px;
    }

    .separator {
      width: 100%;
      height: 5px;
      border-radius: 3px;
      background: rgba(228, 228, 228, 0.49);

      div {
        width: 50%;
        height: 5px;
        border-radius: 3px;
        background: #f7f7f7;
      }
    }

    .file-upload {
      color: #606060;
      font-weight: 500;

      span {
        font-weight: 700;
        text-decoration: underline;
        color: #cbccce;
      }
    }

    .shadow-text {
      background: -webkit-linear-gradient(
        -90deg,
        rgba(247, 247, 247, 0.6) -4.56%,
        rgba(247, 247, 247, 0) 86.6%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    @media screen and (max-width: 1400px) {
      img {
        width: 30px;
      }
    }
  }

  .btn-file {
    background: #606060;
    border-radius: 15px;
    color: #e4e4e4;

    :hover {
      color: #e4e4e4;
    }
  }

  .fileName {
    color: #cbcbcb;
    font-weight: 600;
    font-size: 14px;
  }
`;

export const ThumbnailPreview = styled.label<IThumbnailPreview>`
  background-color: #242526;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  min-height: 167px;
`;

export const Resource = styled.label<IResource>`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(${(props) => props.src}), #242526;
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  min-height: 167px;

  button {
    background: rgba(28, 27, 27, 0.68);
    border-radius: 50%;
    border: none;
    padding: 10px;
  }
`;
