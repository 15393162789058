import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { format, parseISO } from 'date-fns';
import api from '~/services/api';

import { Container, Welcome, NameUserTable } from './styles';
import Table from '~/components/Table';

interface IOrderResponse {
  id: string;
  updated_at: string;
  affiliate: {
    type: string;
    affiliate_commission: string;
    user: {
      name: string;
      avatar: {
        avatar_url: string;
      };
      termUser?: {
        id: string;
      };
    };
  };
  product: {
    name: string;
  };
  amount_paid: string;
  commission?: {
    earning: string;
  };
  status: string;
  installments: number;
}

interface IOrder {
  id: string;
  sales_date: string;
  avatar: string;
  member: string;
  product: string;
  amount_paid: string;
  installments: string;
  earning: string;
  affiliate_commission: string;
  type: string;
  status: string;
}

const SalesManagement: React.FC = () => {
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');

  const handleLoadCommissions = useCallback(async () => {
    const response = await api.get<IOrderResponse[]>('orders/admin', {
      params: {
        search: filter,
      },
    });
    const data = response.data.map<IOrder>((order) => ({
      id: order.id,
      sales_date: format(parseISO(order.updated_at), 'yyyy-MM-dd'),
      avatar: order.affiliate?.user.avatar.avatar_url || '-',
      member: order.affiliate?.user.name || '-',
      agreement_signed: order.affiliate?.user.termUser ? 'Yes' : 'No',
      product: order.product?.name || '-',
      amount_paid: order.amount_paid,
      installments: order.installments.toString(),
      earning: order.commission?.earning || '-',
      affiliate_commission: order.affiliate?.affiliate_commission || '-',
      type: order.affiliate?.type || '-',
      status: order.status,
    }));
    setOrders(data);
    setLoading(false);
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    handleLoadCommissions();
  }, [filter, handleLoadCommissions]);

  const handleSearch = useCallback((value) => {
    setFilter(value);
  }, []);

  const columns = useMemo(
    () => [
      {
        name: 'Date of Sale',
        selector: 'sales_date',
        sortable: true,
      },
      {
        name: 'Member',
        selector: 'member',
        sortable: true,
        cell: (row: IOrder) => (
          <NameUserTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
          >
            <img src={row.avatar} alt={row.member} className="mr-2" />
            <div>
              <p className="mb-1">{row.member}</p>
            </div>
          </NameUserTable>
        ),
      },
      {
        name: 'Agreement Signed',
        selector: 'agreement_signed',
        sortable: true,
      },
      {
        name: 'Product Sold',
        selector: 'product',
        sortable: true,
      },
      {
        name: 'Product Value',
        selector: 'amount_paid',
        sortable: true,
      },
      {
        name: 'Commission Amound',
        selector: 'earning',
        sortable: true,
      },
      {
        name: 'Commission Rate',
        selector: 'affiliate_commission',
        sortable: true,
      },
      {
        name: 'Member type',
        selector: 'type',
        sortable: true,
      },
      {
        name: 'Status',
        selector: 'status',
        sortable: true,
      },
    ],
    []
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h4 h2-lg mb-4">Sales Management</h1>
                  </div>
                </div>
              </div>
            </Welcome>
            <Table
              data={orders}
              columns={columns}
              pagination
              date
              searchable
              onSearch={handleSearch}
              exportable
            />
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default SalesManagement;
