import { lighten } from 'polished';
import styled from 'styled-components';

interface IThumbnailProps {
  src: string;
  alt: string;
}

export const Container = styled.div`
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: transparent;
  }
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    height: 5px;
  }
  button {
    color: #fff;
    border: 1px solid #606060;
    background: transparent;
    font-size: 16px;
    width: 144px;
    border-radius: 14px;
    padding: 7px;
    transition: all 0.3s ease-in-out;
    margin: 0 10px;

    :hover {
      color: #fff;
      background: #606060;
    }
  }

  .active {
    color: #fff;
    background: #606060;
  }

  .min-width {
    min-width: 1150px;
  }
`;

export const Card = styled.div`
  border-radius: 20px;
  overflow: hidden;
  background-color: #202020;

  //min-height: 526px;

  h3 {
    color: #e4e4e4;

    font-size: 16px;

    font-weight: 600;
    line-height: normal;
  }

  .label-image {
    height: 25vw;
    max-height: 376px;
    background-color: #18191a;
    border-radius: 20px;
  }

  .subject {
    background: transparent;
  }

  .body {
    background: transparent;
  }

  .subject textarea {
    height: 85px;
  }

  .body textarea {
    height: 510px;
  }

  textarea {
    color: #515151 !important;

    font-family: 'Inter';
    font-size: 16px;
    font-weight: 300;
    line-height: 140%;
  }

  .input-tag {
    font-size: 14px;
    color: #515151;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #f2f2f2;
    height: 50px;
    text-align: center;
  }

  .radius {
    border-radius: 20px !important;
  }

  .height-default {
    height: 310px;
  }

  .flex-1 {
    flex: 1;
  }

  .h3-social {
    font-size: 16px;
    font-weight: 600;
    color: #e4e4e4 !important;
    line-height: 23px;
  }

  .btn-share {
    background-color: rgb(44, 44, 44) !important;
    border: none;
    cursor: unset;
  }

  .body-social textarea {
    font-size: 16px !important;
    max-height: 350px;
  }

  .image-banner {
    max-width: fit-content;
    border-radius: 15px;
    border: 2px solid #116bff;
  }

  .article-title {
    color: #242526;
    font-size: 18px;
    font-weight: 600;
    min-height: 44px;
  }

  .description-h {
    height: 95px;
    overflow: auto;
  }

  .img-profile {
    width: 43px;
    height: 43px;
    border-radius: 50%;
    box-shadow: inset 0px 0px 0px 2px rgb(228 228 228 / 50%);
    box-sizing: border-box;
    //filter: drop-shadow(0px 0px 7px rgba(0, 0, 0, 0.24));
    background-color: #303030;
  }

  textarea {
    //height: 29px;
    //max-height: 180px;
    resize: none;
    //overflow: hidden;

    background-color: transparent;
    border-radius: 5px;

    :focus {
      outline: none;
      cursor: unset;
      background-color: #18191a;
      box-shadow: none;
    }

    ::placeholder {
      color: #828282;
    }
  }

  .username {
    color: #242526;
  }

  .tinytext {
    color: #8c8c8c;
    font-weight: 300;
  }

  small {
    color: #8c8c8c;
  }

  button.btn-top {
    padding: 1px;
    width: 40%;
    text-align: center;

    :hover {
      text-decoration: none;
    }
  }

  button {
    transition-duration: 0.2s;
  }

  button:hover {
    opacity: 0.8;
  }

  button.border-delete {
    color: #ff4d77;
  }

  img {
    border-radius: 20px;
  }

  .border-golden {
    padding: 1px !important;
  }

  button.btn-top {
    padding: 1px;
    width: 40%;
    text-align: center;
    background-color: rgb(44, 44, 44) !important;
    border: none;

    :hover {
      text-decoration: none;
    }
  }

  button.border-delete {
    color: #ff4d77;
  }

  @media screen and (max-width: 767px) {
    .description-h {
      height: auto;
    }

    .body textarea {
      height: 500px;
    }

    .label-image {
      height: 41vw;
    }
  }
`;

export const AddButton = styled.button`
  border-radius: 50px;
  background-color: #202020;
  border: solid 1px #606060;
  color: #e4e4e4 !important;

  :hover {
    color: ${lighten(0.1, '#e4e4e4')}!important;
    border: solid 1px ${lighten(0.1, '#606060')};

    img {
      filter: brightness(1.5);
    }
  }
`;

export const Thumbnail = styled.div<IThumbnailProps>`
  background-color: #18191a;
  border-radius: 10px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 767px) {
    height: 200px;
  }

  @media screen and (max-width: 575px) {
    height: 160px;
  }
`;
