import React from 'react';

import { Container, Avatar } from './styles';

interface IAffiliate {
  id: string;
  user_id: string;
  name: string;
  avatar: string;
  referred_members: number;
  position: number;
}

interface ITopAffiliate {
  affiliate: IAffiliate;
}

const TopAffiliate: React.FC<ITopAffiliate> = ({ affiliate }) => {
  return (
    <Container className="container-fluid mb-3 mb-xxl-0 px-4 py-3">
      <div className="row align-items-center">
        <div className="col-3 col-xxl-12 mt-3 mb-2 text-center">
          <Avatar src={affiliate.avatar} className="mb-2 mx-auto" />
          <h3 className="h6 h5-xl font-weight-bold mb-0 mb-xxl-5 mt-xxl-4">
            {affiliate.name}
          </h3>
        </div>
        <div className="col col-xxl-6 px-2 my-2">
          <div className="border-gray">
            <div className="py-2 px-3 align-items-center">
              <p className="small mb-3 box-title">
                Waitlist
                <br />
                Position
              </p>
              <p className="h3 font-weight-bold mb-0 box-number">
                {affiliate.position > 0
                  ? affiliate.position.toString().padStart(2, '0')
                  : '00'}
              </p>
            </div>
          </div>
        </div>

        <div className="col col-xxl-6 px-2 my-2">
          <div className="border-gray">
            <div className="py-2 px-3 align-items-center">
              <p className="small mb-3 box-title">
                Referred
                <br />
                Members
              </p>
              <p className="h3 font-weight-bold mb-0 box-number">
                {affiliate.referred_members.toString().padStart(2, '0')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TopAffiliate;
