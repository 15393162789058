import styled from 'styled-components';
import { lighten } from 'polished';
import { Link } from 'react-router-dom';
import { Modal as ModalComponent } from 'react-bootstrap';
import { Fragment } from 'react';

interface IImg {
  src: string;
}

export const Container = styled(Fragment)``;

export const AddButton = styled.button`
  border-radius: 50px;
  background-color: #202020;
  border: solid 1px #606060;
  color: #e4e4e4 !important;

  img {
    width: 20px;
    height: 20px;
  }

  :hover {
    color: ${lighten(0.1, '#e4e4e4')}!important;
    border: solid 1px ${lighten(0.1, '#606060')};

    img {
      filter: brightness(1.5);
    }
  }
`;

export const ArticleSkeleton = styled.button`
  background-color: #343434 !important;
  overflow: hidden;
  position: relative;
  border-radius: 20px;
  border: none !important;
  color: transparent !important;
  pointer-events: none;
  box-shadow: none;
  height: 14.5vw;

  p {
    position: relative;
    z-index: 2;
    color: #fff;
    font-weight: 600;
    font-size: 1.5rem;
    margin: 0;
  }

  :after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    transform: translateX(-100%);
    background: linear-gradient(
      90deg,
      #343434 0%,
      rgba(24, 26, 26, 0) 0%,
      rgba(24, 26, 26, 0) 10%,
      rgba(24, 26, 26, 0.2) 20%,
      rgba(24, 26, 26, 0.5) 40%,
      rgba(24, 26, 26, 0.8) 50%,
      rgba(24, 26, 26, 0.2) 80%,
      rgba(24, 26, 26, 0) 90%,
      rgba(24, 26, 26, 0) 100%
    ) !important;
    animation: glow 1.3s linear infinite;
  }

  @media screen and (min-width: 1800px) {
    height: 238px;
  }

  @media screen and (min-width: 2200px) {
    height: 300px;
  }

  @media screen and (max-width: 991px) {
    height: 27vw;
  }

  @media screen and (max-width: 768px) {
    height: 40vw;
  }

  @media screen and (max-width: 575px) {
    height: 50vw;
  }

  @keyframes glow {
    100% {
      transform: translateX(100%);
    }
  }
`;

export const Article = styled(Link)<IImg>`
  border-radius: 20px;
  text-decoration: none;
  height: 14.5vw;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .short-title {
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px 20px 20px 20px;
    height: 100%;

    p {
      color: #e4e4e4 !important;
    }

    .see-more-link {
      p {
        color: #828282 !important;
        text-decoration: none;
        border-radius: 20px;
        transition-duration: 0.3s;
        filter: brightness(2.5);

        img {
          transition-duration: 0.3s;
        }
      }
    }
  }

  :hover {
    .short-title {
      .see-more-link {
        p {
          filter: brightness(2.5);

          img {
            filter: brightness(2.5);
          }
        }
      }
    }
  }

  @media screen and (min-width: 1800px) {
    height: 238px;

    .align-center-1800 {
      align-items: center;
    }

    .h4-1800 {
      font-size: 1.5rem !important;
    }
  }

  @media screen and (min-width: 2200px) {
    height: 300px;
  }

  @media screen and (max-width: 991px) {
    height: 27vw;
  }

  @media screen and (max-width: 768px) {
    height: 40vw;
  }

  @media screen and (max-width: 575px) {
    height: 50vw;
  }
`;

export const Modal = styled(ModalComponent)``;
