import React from 'react';
import Player from '~/components/Player';

// import { Container } from './styles';

const Video: React.FC = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <Player src="http://localhost:3333/files/intro.mp4" />
          </div>
          <div className="col-lg-6">
            <Player src="http://localhost:3333/files/intro.mp4" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
