import React, { useCallback, useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

import { Container, SelectTag } from './styles';
import Textarea from '~/components/Textarea';

export interface IValue {
  id?: string;
  value: string;
}
interface IInputTagsProps {
  name: string;
  data?: IValue[];
  onChange?(valuesData: IValue[]): void;
  className?: string;
  id?: string;
  onSearch?(valuesData: IValue): void;
  foundData?: IValue[];
  onSelect?(data: IValue): void;
  onRemove?(data: IValue): void;
  limit?: number;
}

const InputTags: React.FC<IInputTagsProps> = ({
  name,
  data,
  onChange,
  className,
  id,
  onSearch,
  foundData,
  onSelect,
  onRemove,
  limit,
}) => {
  const [values, setValues] = useState<IValue[]>([]);
  const [tag, setTag] = useState<IValue>({} as IValue);
  const [isFocused, setIsFocused] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [filterFoundData, setFilterFoundData] = useState(foundData);

  useEffect(() => {
    if (data) {
      setValues(data);
    }
  }, [data]);

  const handleKeyPress = useCallback(
    (e) => {
      const keyPressed = e.keyCode || e.which || e.charCode;
      if (keyPressed === 32) {
        e.preventDefault();
        setValues((state) => [...state, tag]);
        setTag({} as IValue);
      } else if (keyPressed === 8) {
        if (e.target.value.length === 0) {
          e.preventDefault();
          const newValuesList = values.slice();
          setTag(newValuesList[newValuesList.length - 1]);
          newValuesList.splice(-1, 1);
          setValues(newValuesList);
        }
      }
    },
    [tag, values]
  );

  const handleRemoveValue = useCallback(
    (indexSelected, value) => {
      const newValuesList = values.filter(
        (_, index) => index !== indexSelected
      );
      if (onRemove) {
        onRemove(value);
      }
      if (newValuesList.length === 0 && onChange) {
        onChange([]);
      }
      setValues(newValuesList);
    },
    [onChange, onRemove, values]
  );

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setIsFocused(false);
    }, 200);
  }, []);

  useEffect(() => {
    if (onChange && values.length > 0) {
      onChange(values);
    }
  }, [onChange, values]);

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;
      setTag({ value });
      if (foundData) {
        const newFilterFoundData = foundData.filter((found) =>
          found.id?.toLowerCase().match(value.toLowerCase())
        );
        setFilterFoundData(newFilterFoundData);
      }
      if (onSearch) {
        onSearch({ value });
      }
    },
    [foundData, onSearch]
  );

  const handleSelect = useCallback(
    (dataSelected) => {
      setValues((state) => [...state, dataSelected]);
      setTag({} as IValue);
      if (foundData) {
        setFilterFoundData(foundData);
      }
      if (onSearch) {
        onSearch({} as IValue);
      }
      if (onSelect) {
        onSelect(dataSelected);
      }
    },
    [foundData, onSearch, onSelect]
  );

  return (
    <div className="p-relative w-100">
      <Container
        className={`p-relative d-flex align-items-start flex-wrap ${
          className || ''
        }`}
        noBorderRadiusBottom={
          !!(foundData && foundData.length > 0 && isFocused)
        }
        isErrored={hasError}
      >
        {values.map((value, index) => (
          <div
            key={index}
            className="ml-2 my-1 px-3 py-2 language rounded-pill d-flex align-items-center"
          >
            <span>{value.value}</span>
            <button
              type="button"
              className="border-0 bg-transparent ml-1"
              onClick={() => handleRemoveValue(index, value)}
            >
              <FaTimes />
            </button>
          </div>
        ))}
        <Textarea
          id={id}
          name={name}
          className="border-0 col px-1 min-width-10px"
          onKeyDown={handleKeyPress}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={tag && tag.value ? tag.value : ''}
          autoComplete="off"
          hasError={setHasError}
          disabled={!!(limit && values.length >= limit)}
        />
      </Container>
      {filterFoundData && filterFoundData.length > 0 && isFocused && (
        <SelectTag className="w-100 p-absolute">
          {filterFoundData.map((found: IValue) => (
            <button
              key={found.id}
              type="button"
              onClick={() => handleSelect(found)}
              className="w-100 py-2 border-0"
            >
              {found.value}
            </button>
          ))}
        </SelectTag>
      )}
    </div>
  );
};

export default InputTags;
