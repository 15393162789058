import { lighten } from 'polished';
import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IOptions {
  show: boolean;
}

export const Container = styled.div`
  .rdt_TableHeadRow,
  .rdt_TableRow {
    > div:nth-child(2) {
      max-width: 500px;
    }

    .input {
      border: none;

      input {
        color: #8c8c8c !important;
        font-size: 13px;
      }
    }

    .crawl-back {
      input {
        color: #e40041 !important;
      }
    }
  }

  .users-radius {
    border-radius: 20px;
  }

  @media screen and (max-width: 991px) {
    .h-date input {
      height: 41px;
    }
  }

  @media screen and (max-width: 767px) {
  }

  @media screen and (max-width: 575px) {
    .size-users {
      font-size: 12px;
    }
    .users-size {
      display: block;

      margin: auto;
    }
  }

  @media (min-width: 992px) and (max-width: 1250px) {
    .vw80 {
      width: 80vw !important;
    }
  }
`;

export const Welcome = styled.div`
  p {
    color: #969696;
  }

  .btn-pay {
    border-radius: 50px;
    background-color: #202020;
    border: solid 1px #606060;
    color: #e4e4e4 !important;

    :hover {
      color: ${lighten(0.1, '#e4e4e4')}!important;
      border: solid 1px ${lighten(0.1, '#606060')};
    }
  }
`;

export const NameUserTable = styled.div`
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;

export const OptionUser = styled.div`
  cursor: pointer;
  position: relative;
`;

export const Options = styled.div<IOptions>`
  position: absolute;
  width: 200px;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition-duration: 0.3s;
  display: flex;
  flex-direction: column;
  background-color: #202020 !important;
  border: 1px solid #606060;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 16px;
  padding: 10px;
  z-index: 1;

  button {
    padding: 10px 0;
  }

  hr {
    margin: 0;
    border: none;
    opacity: 1;
    background: #606060;
    height: 1px;
  }

  :after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    border-top: 1px solid #606060;
    border-right: 1px solid #606060;
    background-color: #202020;
    right: -8px;
    top: 50%;
    transform: translateY(-50%) rotateZ(45deg);
  }
`;

export const Modal = styled(ModalComponent)``;
