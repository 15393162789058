import styled from 'styled-components';

interface IAvatarProps {
  src: string;
}

export const Container = styled.div`
  background: #212121;
  border-radius: 20px;
  padding: 20px;

  .btn-action {
    background: #242526;
    border: 1px solid #333333;
    transition-duration: 0.3s;

    :hover {
      border: 1px solid #cbccce;
    }
  }

  .pyramid {
    .font-weight-semibold {
      font-weight: 600;
    }

    .font-weight-normal {
      color: #606060;
    }

    .main-crowdfunding {
      margin-top: 46px;
      background: #232425;
      border: 1px solid #232425;
      box-shadow: -2px 5px 16px rgba(0, 0, 0, 0.2);

      > div > div {
        border-color: #cbccce !important;
      }
    }

    .crowdfunding {
      margin-top: 46px;
      background: #202020;
      border: 1px solid #232425;
      box-shadow: -2px 5px 16px rgba(0, 0, 0, 0.2);
      height: 90px;
      transition-duration: 0.3s;
      transition-duration: 0.3s;

      :hover {
        border: 1px solid #4a4b4b;
      }
    }

    .crowdfunding.has-item {
      margin-top: 46px;
      background: #232425;
      border: 1px solid #232425;
      box-shadow: -2px 5px 16px rgba(0, 0, 0, 0.2);
      height: 90px;
      transition-duration: 0.3s;

      :hover {
        border: 1px solid #cbccce;
      }

      .avatar {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }

    .show-more {
      border: 1px solid #2f2f2f;
      background: #2f2f2f;
      transition-duration: 0.3s;

      small {
        color: #cbccce;
      }

      :hover {
        border: 1px solid #cbccce;
      }

      :disabled {
        cursor: default;
        pointer-events: none;
      }
    }

    .third-line {
      > div {
        > .crowdfunding {
          background: #202020;
          border: 1px solid #333333;
          height: 78px;
          transition-duration: 0.3s;

          :hover {
            border: 1px solid #4a4b4b;
          }
        }

        > .crowdfunding.has-item {
          background: #232425;
          border: 1px solid #4a4b4b;
          height: 78px;

          :hover {
            border: 1px solid #cbccce;
          }
        }
      }

      .fourth-line {
        img {
          max-width: 40px;
        }
        > div {
          > .crowdfunding {
            background: #202020;
            border: 1px solid #333333;
            height: 60px;
            transition-duration: 0.3s;

            :hover {
              border: 1px solid #4a4b4b;
            }
          }
          > .crowdfunding.has-item {
            background: #232425;
            border: 1px solid #4a4b4b;
            height: 60px;

            :hover {
              border: 1px solid #cbccce;
            }
          }
        }

        > div:nth-child(1) {
          padding-right: 0;
        }

        > div:nth-child(2) {
          padding: 0 7.5px;
        }

        > div:nth-child(3) {
          padding-left: 0;
        }
      }
    }
  }

  .border-rad {
    border-radius: 50rem;
  }

  @media screen and (min-width: 576px) {
    .border-sm-right {
      border-right: 1px solid #dee2e6 !important;
    }

    .third-line {
      > div:nth-child(1) {
        padding-right: 0;
      }

      > div:nth-child(2) {
        padding: 0 7.5px;
      }

      > div:nth-child(3) {
        padding-left: 0;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .border-rad {
      border-radius: 30px;
    }
  }

  @media screen and (max-width: 767px) {
    hr {
      border-top: 1px solid #dee2e6 !important;
    }
  }

  @media screen and (max-width: 575px) {
    .pyramid {
      .crowdfunding {
        margin-top: 20px;
      }

      .crowdfunding.has-item {
        margin-top: 20px;
      }
    }
  }
`;

export const Avatar = styled.div<IAvatarProps>`
  margin: 0 auto;
  width: 80px;
  height: 80px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 4px rgba(228, 228, 228, 0.5);
  box-sizing: border-box;

  @media screen and (max-width: 1199px) {
    width: 60px;
    height: 60px;
  }
`;
