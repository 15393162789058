import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { format, parseISO } from 'date-fns';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import {
  Container,
  Welcome,
  NameUserTable,
  OptionUser,
  Options,
  Modal,
} from './styles';
import Table from '~/components/Table';
import Select from '~/components/Select';

import { formatPrice } from '~/utils/format';

import options from '~/assets/icons/options.svg';
import Toast from '~/utils/toast';

interface ICommission {
  commissions_id: string;
  affiliate_name: string;
  affiliate_email: string;
  affiliate_avatar_url: string;
  sales_date: string;
  amount_paid: string;
  commission_earned: string;
  who_purchased: string;
  who_purchased_avatar_url: string;
  commission_percentage: string;
  wallets_histories_id: string;
  wallets_histories_status: string;
}

interface ICommissionResponse {
  data: ICommission[];
  pages: number;
  total: number;
}

const Commissions: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [commissions, setCommissions] = useState<ICommission[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [commissionSelected, setCommissionSelected] = useState(
    {} as ICommission
  );
  const [showUpdateCommission, setShowUpdateCommission] = useState(false);
  const [pageSelected, setPageSelected] = useState(1);
  const [orderByData, setOrderByData] = useState({
    column: 'sales_date',
    direction: 'DESC',
  });
  const [tableData, seTableData] = useState({
    total: 0,
    pages: 0,
  });

  const handleClose = useCallback(() => {
    setShowUpdateCommission(false);
  }, []);

  const handleLoadCommissions = useCallback(
    async (page, orderBy) => {
      const response = await api.get<ICommissionResponse>('commissions/admin', {
        params: {
          filter,
          page,
          orderBy,
        },
      });
      const data = response.data.data.map((commission) => ({
        ...commission,
        sales_date: format(parseISO(commission.sales_date), 'yyyy-MM-dd'),
        amount_paid: formatPrice(parseFloat(commission.amount_paid)),
        commission_earned: formatPrice(
          parseFloat(commission.commission_earned)
        ),
      }));
      setCommissions(data);
      seTableData({
        pages: response.data.pages,
        total: response.data.total,
      });
      setLoading(false);
    },
    [filter]
  );

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    handleLoadCommissions(1, {
      column: 'sales_date',
      direction: 'DESC',
    });
  }, [filter, handleLoadCommissions]);

  const handleSearch = useCallback((value) => {
    setFilter(value);
  }, []);

  const handleSelectedCommission = useCallback(
    (commission) => {
      if (commissionSelected.commissions_id === commission.commissions_id) {
        setCommissionSelected({} as ICommission);
      } else {
        setCommissionSelected(commission);
      }
    },
    [commissionSelected.commissions_id]
  );

  const handleClickEdit = useCallback(() => {
    setShowUpdateCommission(true);
  }, []);

  const handleClickDelete = useCallback(() => {
    Swal.fire({
      title: 'Delete commission?',
      text: `Would you like to delete this commission?`,
      icon: 'warning',
      confirmButtonText: 'Yes',
      confirmButtonColor: '#202020',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: '#606060',
      reverseButtons: true,
    }).then(async (e) => {
      if (e.isConfirmed) {
        await api.delete(
          `commissions/admin/${commissionSelected.commissions_id}`
        );
        handleLoadCommissions(pageSelected, orderByData);
        setCommissionSelected({} as ICommission);
        Toast.fire({
          icon: 'success',
          title: 'Commission successfully deleted!',
        });
      }
    });
  }, [
    commissionSelected.commissions_id,
    handleLoadCommissions,
    orderByData,
    pageSelected,
  ]);

  const columns = useMemo(
    () => [
      {
        name: 'Affiliate name',
        selector: 'affiliate_name',
        sortable: true,
        cell: (row: ICommission) => (
          <NameUserTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
          >
            <img
              src={row.affiliate_avatar_url}
              alt={row.affiliate_name}
              className="mr-2"
            />
            <div>
              <p className="mb-1">{row.affiliate_name}</p>
            </div>
          </NameUserTable>
        ),
      },
      {
        name: 'Sales date',
        selector: 'sales_date',
        sortable: true,
      },
      {
        name: 'Amount paid',
        selector: 'amount_paid',
        sortable: true,
      },
      {
        name: 'Commission earned',
        selector: 'commission_earned',
        sortable: true,
      },
      {
        name: 'Who purchased',
        selector: 'who_purchased',
        sortable: true,
        cell: (row: ICommission) => (
          <NameUserTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
          >
            <img
              src={row.who_purchased_avatar_url}
              alt={row.affiliate_name}
              className="mr-2"
            />
            <p className="mb-0">{row.who_purchased}</p>
          </NameUserTable>
        ),
      },
      {
        name: 'Commission percentage',
        selector: 'commission_percentage',
        sortable: true,
      },
      {
        name: 'Status',
        selector: 'wallets_histories_status',
        sortable: true,
      },
      {
        name: '',
        selector: 'commissions_id',
        sortable: true,
        cell: (row: ICommission) => (
          <OptionUser className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => handleSelectedCommission(row)}
              className="border-0 bg-transparent"
            >
              <img src={options} alt="options" className="mr-2" />
            </button>

            <Options
              show={commissionSelected.commissions_id === row.commissions_id}
            >
              {/* <button
                type="button"
                className="border-0 bg-transparent"
                onClick={handleClickMakePay}
              >
                Make a pay
              </button>
              <hr /> */}
              <button
                type="button"
                className="border-0 bg-transparent"
                onClick={handleClickEdit}
              >
                Edit Commission
              </button>
              <hr />
              <button
                type="button"
                className="border-0 bg-transparent"
                onClick={handleClickDelete}
              >
                Delete Commission
              </button>
            </Options>
          </OptionUser>
        ),
      },
    ],
    [
      commissionSelected.commissions_id,
      handleClickDelete,
      handleClickEdit,
      handleSelectedCommission,
    ]
  );

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({});

        await schema.validate(data, {
          abortEarly: false,
        });

        const { status } = data;

        const formData = {
          status,
        };

        await api.put(
          `wallets-history/${commissionSelected.wallets_histories_id}`,
          formData
        );

        const newCommission = commissions.slice();

        const commissionIndex = newCommission.findIndex(
          (commission) =>
            commission.commissions_id === commissionSelected.commissions_id
        );

        if (commissionIndex >= 0) {
          newCommission[commissionIndex].wallets_histories_status = data.status;
        }

        setCommissions(newCommission);
        setCommissionSelected(newCommission[commissionIndex]);

        handleLoadCommissions(pageSelected, orderByData);

        Toast.fire({
          icon: 'success',
          title: 'Payout successfully updated!',
        });

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          );
        }
      }
    },
    [
      commissionSelected.wallets_histories_id,
      commissionSelected.commissions_id,
      commissions,
      handleLoadCommissions,
      pageSelected,
      orderByData,
      handleClose,
    ]
  );

  const handleChangePage = useCallback(
    async (page) => {
      setLoading(true);
      await handleLoadCommissions(page, orderByData);
      setPageSelected(page);
    },
    [handleLoadCommissions, orderByData]
  );

  const handleSort = useCallback(
    async (column, direction) => {
      setLoading(true);
      await handleLoadCommissions(pageSelected, {
        column: column.selector,
        direction: direction.toUpperCase(),
      });

      setOrderByData({
        column: column.selector,
        direction: direction.toUpperCase(),
      });
    },
    [handleLoadCommissions, pageSelected]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h4 h2-lg mb-4">Commissions</h1>
                  </div>
                </div>
              </div>
            </Welcome>
            <Table
              data={commissions}
              columns={columns}
              pagination
              date
              searchable
              onSearch={handleSearch}
              exportable
              pages={tableData.pages}
              total={tableData.total}
              onChangePage={handleChangePage}
              pageSelected={pageSelected}
              onSort={handleSort}
            />
          </div>
        </div>
      </div>
      <Modal show={showUpdateCommission} onHide={handleClose}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="ml-auto">Edit Commission</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <label className="d-block w-100">
                <span className="d-block mb-2">Status</span>
              </label>
              <Select
                name="status"
                options={[
                  {
                    id: 'Pending',
                    value: 'Pending',
                    selected:
                      commissionSelected.wallets_histories_status === 'Pending',
                  },
                  {
                    id: 'Available',
                    value: 'Available',
                    selected:
                      commissionSelected.wallets_histories_status ===
                      'Available',
                  },
                  {
                    id: 'Paid',
                    value: 'Paid',
                    selected:
                      commissionSelected.wallets_histories_status === 'Paid',
                  },
                ]}
              />
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex flex-nowrap">
            <button
              type="button"
              className="btn-grey px-3 py-2 w-50"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button type="submit" className="btn-dark px-3 py-2 w-50">
              Save
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Commissions;
