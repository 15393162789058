import React, { useState, useEffect, useCallback } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import 'react-day-picker/lib/style.css';

import { Container, Welcome, Options, Search } from './styles';
import AddCategory from '~/components/AddCategory';
import Trainings from './Trainings';
import Courses from './Courses';

import plus from '~/assets/icons/plus-admin-icon2.svg';
import search from '~/assets/icons/search-icon.svg';

const Categories: React.FC = () => {
  const location = useLocation();
  const [optionSelected, setOptionSelected] = useState<'courses' | 'trainings'>(
    'courses'
  );
  const [categories, setCategories] = useState(0);
  const [show, setShow] = useState(false);
  const [filter, setFilter] = useState('');

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(() => setShow(true), []);

  useEffect(() => {
    window.scrollTo(0, 0);
    const option = location.pathname.slice(12) as 'courses' | 'trainings';
    setOptionSelected(option);
    setFilter('');
  }, [location.pathname]);

  const handleChange = useCallback((e) => {
    setFilter(e.target.value);
  }, []);

  const handleSubmit = useCallback(() => {
    handleClose();
    const oldOption = optionSelected;
    const newOption = '' as 'courses' | 'trainings';
    setOptionSelected(newOption);
    setOptionSelected(oldOption);
  }, [handleClose, optionSelected]);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-lg-8">
                    <div className="d-md-flex">
                      <h1 className="h2">Category Management</h1>
                      <button
                        type="button"
                        className="btn-new-course align-items-center px-5 px-sm-4 ml-lg-3 ml-xl-5 mx-auto ml-sm-auto mt-5 mt-md-0 rounded-pill text-center d-flex justify-content-center"
                        onClick={handleShow}
                      >
                        <img src={plus} alt="plus" className="mr-2" />
                        New&nbsp;Category
                      </button>
                    </div>
                  </div>
                  <div className="col-11 col-sm-9 col-lg-4">
                    <Search className="d-flex mt-5 mt-lg-0">
                      <input
                        className="w-100"
                        placeholder="Search"
                        onChange={handleChange}
                        value={filter}
                      />
                      <img src={search} alt="Search" className="mx-2" />
                    </Search>
                  </div>
                </div>
              </div>
            </Welcome>
            <Options>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xxl-10 order-2 order-xxl-1 col-12 ">
                    <div className="row mx-1 pt-5 pb-4 mb-4">
                      <div className="col-5 col-sm-3 px-0">
                        <NavLink
                          to={`${process.env.PUBLIC_URL}/categories/courses`}
                          activeClassName="active border-bottom pb-1"
                          exact
                        >
                          Courses
                        </NavLink>
                      </div>
                      <div className="col-5 col-sm-3 px-0">
                        <NavLink
                          to={`${process.env.PUBLIC_URL}/categories/trainings`}
                          activeClassName="active border-bottom pb-1"
                        >
                          Trainings
                        </NavLink>
                      </div>
                      <hr className="w-100 w-sm-75 mt-4" />
                    </div>
                    {optionSelected === 'courses' && (
                      <Courses
                        totalCategories={setCategories}
                        filter={filter}
                      />
                    )}
                    {optionSelected === 'trainings' && (
                      <Trainings
                        totalCategories={setCategories}
                        filter={filter}
                      />
                    )}
                  </div>
                  <div className="col-xxl-2 col-12 order-1 order-xxl-2 py-3 pt-5 overview">
                    <h2 className="h3 mb-5">Overview</h2>
                    <div className="row">
                      <div className="col-6 col-md-3 col-xxl-12">
                        <div className="over-card">
                          <div className="px-3 py-2">
                            <small className="title-card d-block h6 text-gray py-4">
                              Total categories
                            </small>
                            <span className="number-courses d-block h2 mb-0 text-white pb-3">
                              {categories}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Options>
          </div>
        </div>
      </div>
      <AddCategory
        type={optionSelected}
        show={show}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
    </Container>
  );
};

export default Categories;
