import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { IDataTableColumn } from 'react-data-table-component';
import { useParams, NavLink } from 'react-router-dom';
import { parseISO, format } from 'date-fns';
import { AxiosResponse } from 'axios';
import Swal from 'sweetalert2';

import api from '~/services/api';

import {
  Container,
  Welcome,
  NameUserTable,
  OptionUser,
  Search,
  Options,
  Modal,
} from './styles';
import Table from '~/components/Table';
import searchIcon from '~/assets/icons/search-icon.svg';

import { formatPrice } from '~/utils/format';

interface ITableData {
  purchaseDate: string;
  orderID: string;
  avatar: string;
  user: string;
  amountPaid: string;
  referrer: string;
  product: string;
  email: string;
  created_at: Date;
  payment_method: string;
  transaction_hash?: string;
  userId: string;
  status: string;
}

interface IOrder {
  id: string;
  amount_paid: number;
  status: string;
  transaction_hash?: string;
  created_at: string;
  user: {
    id: string;
    referral_code: string;
    avatar: {
      avatar_url: string;
    };
    name: string;
    email: string;
  };
  product: {
    name: string;
  };
  course: {
    title: string;
  };
  payment?: {
    payment_method: string;
  };
}

interface IParams {
  slug?: string;
}

interface IOrderResponse {
  status?: string;
}

const ReviewOrder: React.FC = () => {
  const params = useParams<IParams>();
  const [orders, setOrders] = useState<ITableData[]>([]);
  const [show, setShow] = useState(false);
  const [orderSelected, setOrderSelected] = useState<ITableData>(
    {} as ITableData
  );
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClickBox = useCallback((e) => {
    const box = e.target.closest('.options-box');
    if (box) {
      box.classList.toggle('active');
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);

    api
      .get('orders/admin', {
        params: {
          filter: 'AllOrders',
          payment_method: params.slug ? params.slug.replace('-', ' ') : '',
          search,
        },
      })
      .then((response) => {
        const data = response.data.map((order: IOrder) => {
          let product = '';
          if (order.product) {
            product = order.product.name;
          }

          if (order.course) {
            product = order.course.title;
          }
          return {
            created_at: order.created_at,
            purchaseDate: format(parseISO(order.created_at), 'yyyy-MMM-dd'),
            orderID: order.id,
            avatar: order.user.avatar.avatar_url,
            user: order.user.name,
            amountPaid: formatPrice(order.amount_paid),
            referrer: order.user.referral_code,
            product,
            email: order.user.email,
            payment_method: order.payment ? order.payment.payment_method : '',
            transaction_hash: order.transaction_hash,
            userId: order.user.id,
            status: order.status,
          };
        });
        setOrders(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.slug, search]);

  useEffect(() => {
    setSearch('');
  }, [params.slug]);

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleClick = useCallback(
    (data) => {
      setOrderSelected(data);
      handleShow();
    },
    [handleShow]
  );

  const columns = useMemo(
    () => {
      let data: IDataTableColumn[] = [];

      if (!params.slug) {
        data = [
          {
            name: 'Purchase date',
            selector: 'created_at',
            sortable: true,
            cell: (row: ITableData) => row.purchaseDate,
          },
          {
            name: 'Order ID',
            selector: 'orderID',
            sortable: true,
          },
          {
            name: 'User',
            selector: 'user',
            sortable: true,
            cell: (row: ITableData) => (
              <NameUserTable
                data-tag="allowRowEvents"
                className="d-flex align-items-center"
              >
                <img src={row.avatar} alt={row.user} className="mr-2" />
                <p className="mb-0">{row.user}</p>
              </NameUserTable>
            ),
          },
          {
            name: 'Email ',
            selector: 'email',
            sortable: true,
          },
          {
            name: 'Product',
            selector: 'product',
            sortable: true,
          },
          {
            name: 'Amount paid',
            selector: 'amountPaid',
            sortable: true,
          },
          {
            name: 'Payment method',
            selector: 'payment_method',
            sortable: true,
          },
          {
            name: 'Transaction hash',
            selector: 'transaction_hash',
            sortable: true,
            cell: (row: ITableData) => (
              <>
                {row.transaction_hash ? (
                  <OptionUser
                    data-tag="allowRowEvents"
                    className="d-flex align-items-center w-100"
                  >
                    <a
                      href={`https://etherscan.io/tx/${row.transaction_hash}`}
                      className="w-100 py-2 rounded-pill font-weight-300"
                    >
                      Check transaction hash
                    </a>
                  </OptionUser>
                ) : (
                  <>-</>
                )}
              </>
            ),
          },
          {
            name: '',
            selector: 'Options',
            sortable: true,
            cell: (row: ITableData) => (
              <OptionUser
                data-tag="allowRowEvents"
                className="d-flex align-items-center w-100"
              >
                <button
                  type="button"
                  onClick={() => handleClick(row)}
                  className="w-100 py-2 rounded-pill font-weight-300"
                  disabled={row.status === 'succeeded'}
                >
                  {row.status !== 'succeeded' ? 'Finish' : 'Finished'}
                </button>
              </OptionUser>
            ),
          },
        ];
      } else if (params.slug !== 'tether') {
        data = [
          {
            name: 'Purchase date',
            selector: 'created_at',
            sortable: true,
            cell: (row: ITableData) => row.purchaseDate,
          },
          {
            name: 'Order ID',
            selector: 'orderID',
            sortable: true,
          },
          {
            name: 'User',
            selector: 'user',
            sortable: true,
            cell: (row: ITableData) => (
              <NameUserTable
                data-tag="allowRowEvents"
                className="d-flex align-items-center"
              >
                <img src={row.avatar} alt={row.user} className="mr-2" />
                <p className="mb-0">{row.user}</p>
              </NameUserTable>
            ),
          },
          {
            name: 'Email ',
            selector: 'email',
            sortable: true,
          },
          {
            name: 'Product',
            selector: 'product',
            sortable: true,
          },
          {
            name: 'Amount paid',
            selector: 'amountPaid',
            sortable: true,
          },
          {
            name: '',
            selector: 'Options',
            sortable: true,
            cell: (row: ITableData) => (
              <OptionUser
                data-tag="allowRowEvents"
                className="d-flex align-items-center w-100"
              >
                <button
                  type="button"
                  onClick={() => handleClick(row)}
                  className="w-100 py-2 rounded-pill font-weight-300"
                  disabled={row.status === 'succeeded'}
                >
                  {row.status !== 'succeeded' ? 'Finish' : 'Finished'}
                </button>
              </OptionUser>
            ),
          },
        ];
      } else {
        data = [
          {
            name: 'Purchase date',
            selector: 'created_at',
            sortable: true,
            cell: (row: ITableData) => row.purchaseDate,
          },
          {
            name: 'Order ID',
            selector: 'orderID',
            sortable: true,
          },
          {
            name: 'User',
            selector: 'user',
            sortable: true,
            cell: (row: ITableData) => (
              <NameUserTable
                data-tag="allowRowEvents"
                className="d-flex align-items-center"
              >
                <img src={row.avatar} alt={row.user} className="mr-2" />
                <p className="mb-0">{row.user}</p>
              </NameUserTable>
            ),
          },
          {
            name: 'Email ',
            selector: 'email',
            sortable: true,
          },
          {
            name: 'Product',
            selector: 'product',
            sortable: true,
          },
          {
            name: 'Amount paid',
            selector: 'amountPaid',
            sortable: true,
          },
          {
            name: 'Transaction hash',
            selector: 'transaction_hash',
            sortable: true,
            cell: (row: ITableData) => (
              <>
                {row.transaction_hash ? (
                  <OptionUser
                    data-tag="allowRowEvents"
                    className="d-flex align-items-center w-100"
                  >
                    <a
                      href={`https://etherscan.io/tx/${row.transaction_hash}`}
                      className="w-100 py-2 rounded-pill font-weight-300"
                    >
                      Check transaction hash
                    </a>
                  </OptionUser>
                ) : (
                  <>-</>
                )}
              </>
            ),
          },
          {
            name: '',
            selector: 'Options',
            sortable: true,
            cell: (row: ITableData) => (
              <OptionUser
                data-tag="allowRowEvents"
                className="d-flex align-items-center w-100"
              >
                <button
                  type="button"
                  onClick={() => handleClick(row)}
                  className="w-100 py-2 rounded-pill font-weight-300"
                  disabled={row.status === 'succeeded'}
                >
                  {row.status !== 'succeeded' ? 'Finish' : 'Finished'}
                </button>
              </OptionUser>
            ),
          },
        ];
      }

      return data;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleClick, orders]
  );

  const handleFinished = useCallback(async () => {
    setLoading(true);
    try {
      let response: AxiosResponse<IOrderResponse> = {} as AxiosResponse<
        IOrderResponse
      >;
      if (
        orderSelected.payment_method === 'Tether' ||
        orderSelected.payment_method === 'Binance Pay'
      ) {
        const order_id = orderSelected.orderID.replace(/-/g, '');
        const data = { merchantTradeNo: order_id };
        const formData = {
          bizType: 'PAY',
          data: JSON.stringify(data),
          bizStatus: 'PAY_SUCCESS',
        };

        response = await api.post<IOrderResponse>(
          `orders/products/close`,
          formData
        );
      } else if (orderSelected.payment_method === 'Credit Card') {
        response = await api.patch<IOrderResponse>(
          `orders/close/${orderSelected.orderID}/${orderSelected.userId}`
        );
      }

      if (
        response.data &&
        response.data.status &&
        response.data.status === 'succeeded'
      ) {
        const newOrders = orders.slice();
        const orderIndex = newOrders.findIndex(
          (order) => order.orderID === orderSelected.orderID
        );

        newOrders[orderIndex].status = 'succeeded';

        setOrders(newOrders);
      }
      handleClose();
    } catch (error) {
      Swal.fire('Opss...', 'An error has occurred, please try again.', 'error');
    } finally {
      setLoading(false);
    }
  }, [
    handleClose,
    orderSelected.orderID,
    orderSelected.payment_method,
    orderSelected.userId,
    orders,
  ]);

  const handleChange = useCallback((e) => {
    setSearch(e.target.value);
  }, []);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h4 h2-lg mb-4">Order review</h1>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
        </div>
        <div className="row bg">
          <div className="col-12 flex-wrap d-flex mt-5 px-4 px-sm-5 px-xl-3 justify-content-end justify-content-xl-around align-items-center">
            <div className="col-xl-7 p-0 mt-4 mt-xl-0 buttons order-1 order-xl-0">
              <Options>
                <div className="col-12">
                  <div className="row justify-content-center text-center">
                    <div className="options-box col-12 px-0">
                      <button
                        type="button"
                        className="d-flex d-md-none justify-content-between align-items-center py-3 px-3 w-100 border-0 options-button"
                        onClick={handleClickBox}
                      >
                        <p className="w-100 mb-0 bg-btn rounded-pill m-1 my-lg-0 mx-lg-3 p-2 px-xxl-4 py-xxl-2 font-weight-600">
                          All
                        </p>
                        <FiChevronDown size={25} />
                      </button>
                      <div className="options d-md-flex justify-content-around  justify-content-md-start my-0 col-12 px-4 px-md-0">
                        <NavLink
                          to={`${process.env.PUBLIC_URL}/order-review`}
                          className="bg-btn m-1 pr-4 py-1 px-md-0 pt-md-0 pb-md-2 mr-md-4 mr-xxl-5 ml-md-0 my-md-0 font-weight-600"
                          activeClassName="active"
                          exact
                        >
                          <span className="d-block mr-1 mr-md-0 px-2 py-1 p-md-0">
                            All
                          </span>
                        </NavLink>

                        <NavLink
                          to={`${process.env.PUBLIC_URL}/order-review/binance-pay`}
                          className="bg-btn m-1 pr-4 py-1 px-md-0 pt-md-0 pb-md-2 mr-md-4 mr-xxl-5 ml-md-0 my-md-0 font-weight-600"
                          activeClassName="active"
                        >
                          <span className="d-block mr-1 mr-md-0 px-2 py-1 p-md-0">
                            Binance Pay
                          </span>
                        </NavLink>

                        <NavLink
                          to={`${process.env.PUBLIC_URL}/order-review/tether`}
                          className="bg-btn m-1 pr-4 py-1 px-md-0 pt-md-0 pb-md-2 mr-md-4 mr-xxl-5 ml-md-0 my-md-0 font-weight-600"
                          activeClassName="active"
                        >
                          <span className="d-block mr-1 mr-md-0 px-2 py-1 p-md-0">
                            Tether
                          </span>
                        </NavLink>

                        <NavLink
                          to={`${process.env.PUBLIC_URL}/order-review/credit-card`}
                          className="bg-btn m-1 pr-4 py-1 px-md-0 pt-md-0 pb-md-2 mr-md-4 mr-xxl-5 ml-md-0 my-md-0 font-weight-600"
                          activeClassName="active"
                        >
                          <span className="d-block mr-1 mr-md-0 px-2 py-1 p-md-0">
                            Credit Card
                          </span>
                        </NavLink>

                        <NavLink
                          to={`${process.env.PUBLIC_URL}/order-review/gift-card`}
                          className="bg-btn m-1 pr-4 py-1 px-md-0 pt-md-0 pb-md-2 mr-md-4 mr-xxl-5 ml-md-0 my-md-0 font-weight-600"
                          activeClassName="active"
                        >
                          <span className="d-block mr-1 mr-md-0 px-2 py-1 p-md-0">
                            Gift Card
                          </span>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </Options>
            </div>
            <Search className="col-md-6 col-xl-4 d-flex order-0 order-xl-1">
              <input
                className="w-100"
                placeholder="Search"
                onChange={handleChange}
                value={search}
              />
              <img src={searchIcon} alt="Search" className="mx-2" />
            </Search>
          </div>
          <div className="col-12 p-0">
            <Table data={orders} columns={columns} pagination />
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="ml-auto">Finish order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0 text-center">
            Are you sure you want to finish the order
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-around">
          <button
            type="button"
            onClick={handleClose}
            className="w-25 btn-grey px-3 py-2"
          >
            NO
          </button>
          <button
            type="button"
            onClick={handleFinished}
            className="w-25 btn-grey px-3 py-2"
          >
            YES
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ReviewOrder;
