import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FaRegUserCircle } from 'react-icons/fa';
import { IoSend } from 'react-icons/io5';
import * as yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';

import { BsChatDots } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import getValidationErros from '~/utils/getValidationsErrors';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';

import {
  Container,
  Welcome,
  Content,
  Notifications,
  ChatArea,
  ChatText,
} from './styles';
import Textarea from '~/components/Textarea';

interface IUserResponse {
  id: string;
  name: string;
  email: string;
  phone: string;
  avatar?: {
    avatar_url: string;
  };
}

interface IUser {
  id: string;
  name: string;
  phone: string;
  avatar: string;
}

interface IMessage {
  id: string;
  user_id: string;
  me: boolean;
  participants: boolean;
  avatar: string;
  name: string;
  text: string;
  hour: string;
}

const Chats: React.FC = () => {
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const [users, setUsers] = useState<IUser[]>([]);
  const [userSelected, setUserSelected] = useState({} as IUser);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [messages, setMessages] = useState<IMessage[]>([]);

  useEffect(() => {
    setLoading(true);
    api
      .get<IUserResponse[]>('users', {
        params: {
          isAdmin: true,
        },
      })
      .then((response) => {
        const reponseUsers = response.data.filter(
          (userData) => !!userData.phone
        );
        const data: IUser[] = reponseUsers.map((userData) => ({
          id: userData.id,
          name: userData.name,
          phone: userData.phone,
          avatar: userData.avatar?.avatar_url || '',
        }));
        setUsers(data);
      });
  }, []);

  const handleInfiniteLoad = useCallback(async () => {
    // console.log('AQUI');
  }, []);

  const handleKeyPress = useCallback((e) => {
    if (
      (e.keyCode === 13 || e.which === 13 || e.key === 'Enter') &&
      !e.shiftKey
    ) {
      e.preventDefault();
      formRef.current?.submitForm();
    }
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          message: yup.string().required('Message is required'),
        });

        await schema.validate(data, { abortEarly: false });

        const formData = {
          user_id: userSelected.id,
          message: data.message,
        };

        await api.post('sms', formData);

        setMessages((state) => [
          {
            id: new Date().toDateString(),
            user_id: user.id,
            me: true,
            participants: false,
            avatar: user.avatar_url || '',
            name: 'You',
            text: data.message,
            hour: '10pm',
          },
          ...state,
        ]);

        formRef.current?.reset();
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Looks like what you were trying to do didn’t work, please try again.',
            'error'
          );
        }
      }
    },
    [user.avatar_url, user.id, userSelected.id]
  );

  const handleClickSendToAll = useCallback(() => {
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleClick = useCallback((userData) => {
    setUserSelected(userData);
  }, []);

  const handleSubmitToAll = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          message: yup.string().required('Message is required'),
        });

        await schema.validate(data, { abortEarly: false });

        const formData = {
          message: data.message,
        };

        await api.post('sms/all', formData);

        setMessages((state) => [
          {
            id: new Date().toDateString(),
            user_id: user.id,
            me: true,
            participants: false,
            avatar: user.avatar_url || '',
            name: 'You',
            text: data.message,
            hour: '10pm',
          },
          ...state,
        ]);

        Swal.fire(
          'Good job!',
          'Sending the message to all users.',
          'success'
        ).then(() => {
          handleClose();
        });

        formRef.current?.reset();
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Looks like what you were trying to do didn’t work, please try again.',
            'error'
          );
        }
      }
    },
    [handleClose, user.avatar_url, user.id]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 d-flex align-items-center justify-content-between">
                    <h1 className="h4 h2-lg mb-4">SMS</h1>
                    <button
                      type="button"
                      onClick={handleClickSendToAll}
                      className="btn-new-ask align-items-center mx-auto m-sm-0 px-5 px-lg-4 ml-sm-5 mt-5 rounded-pill text-center d-flex justify-content-center"
                    >
                      Send to all
                    </button>
                  </div>
                </div>
              </div>
            </Welcome>

            <Content className="p-4">
              <div className="row justify-content-between">
                <div className="col-lg-4">
                  <div className="d-flex mb-4" />
                  <Notifications>
                    <h2 className="h3 font-weight-bold">
                      Send to an individual user
                    </h2>
                    <div className="height-notifications overflow-auto">
                      {users.map((data) => (
                        <button
                          type="button"
                          className="d-flex justify-content-between cursor-pointer notifications-hover p-3 rounded w-100 bg-transparent border-0"
                          onClick={() => handleClick(data)}
                        >
                          <div>
                            <h2 className="h4 font-weight-medium mb-2">
                              {data.name}
                            </h2>
                            <p className="mb-0">{data.phone}</p>
                          </div>
                        </button>
                      ))}
                    </div>
                  </Notifications>
                </div>
                <ChatArea className="col-lg-8">
                  {Object.keys(userSelected).length > 0 ? (
                    <Form ref={formRef} onSubmit={handleSubmit} className="p-4">
                      <div className="border-user d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <FaRegUserCircle size={24} color="#C1C1C1" />

                          <h2 className="ml-4 mb-0">{userSelected.name}</h2>
                        </div>
                      </div>
                      <ChatText
                        flipped
                        scrollLoadThreshold={100}
                        onInfiniteLoad={handleInfiniteLoad}
                        className="bodyChat p-4"
                      >
                        {messages.map((message) => (
                          <Fragment key={message.id}>
                            {message.user_id === user.id ? (
                              <div className="w-100 d-flex justify-content-end mb-2">
                                <div className="d-flex align-items-end">
                                  <div>
                                    <span
                                      className={`${
                                        message.me
                                          ? 'name-you'
                                          : 'name-participants'
                                      } d-block name-chat mb-2 text-right`}
                                    >
                                      {message.name}
                                    </span>
                                    <div className="bg-dark-gray pt-2 pb-2 px-2 mb-2">
                                      {message.text}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="w-100 d-flex mb-2">
                                <div className="d-flex align-items-end">
                                  <img
                                    src={message.avatar}
                                    alt="Avatar"
                                    className="avatar mr-2"
                                  />
                                  <div>
                                    <span
                                      className={`${
                                        message.me
                                          ? 'name-you'
                                          : 'name-participants'
                                      } d-block name-chat mb-2`}
                                    >
                                      {message.name}
                                    </span>
                                    <div className="bg-light-gray pt-2 pb-2 px-2 mb-2">
                                      {message.text}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Fragment>
                        ))}
                      </ChatText>
                      <div className="row mb-4">
                        <div className="col-12">
                          <div className="input-box p-3">
                            <div className="p-3">
                              <div className="row align-items-center">
                                <div className="col-lg-10">
                                  <Textarea
                                    id="message"
                                    name="message"
                                    className="w-100 border-0 textarea"
                                    placeholder="Mensagem"
                                    rows={2}
                                    onKeyPress={handleKeyPress}
                                  />
                                </div>
                                <div className="col-lg-2">
                                  <button
                                    type="submit"
                                    className="d-flex align-items-center ml-auto btn-grey px-3 py-1"
                                  >
                                    Enviar&nbsp;&nbsp;
                                    <IoSend size={19} color="#fff" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center no-chat">
                      <div className="icon">
                        <BsChatDots color="#9C9C9C" size={50} />
                      </div>
                      <p className="h2 text-center mb-0 ml-3">Select a user</p>
                    </div>
                  )}
                </ChatArea>
              </div>
            </Content>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Form ref={formRef} onSubmit={handleSubmitToAll}>
          <Modal.Header closeButton>
            <Modal.Title className="ml-auto">Send to all</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label htmlFor="message">Message</label>
            <Textarea id="message" name="message" rows={10} />
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn-grey px-3 py-2">
              Salvar
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default Chats;
