import styled from 'styled-components';

export const Container = styled.div``;

export const Welcome = styled.div`
  p {
    color: #969696;
  }
`;

export const Options = styled.div`
  button {
    border: none;
    background: none;
    color: #ababab;
    -webkit-text-stroke: 0.5px #00000000;

    :hover {
      color: #ffffff;
      -webkit-text-stroke: 0.5px #fff;
    }
  }

  button.active {
    color: #ffffff;
    -webkit-text-stroke: 0.5px #fff;
  }
`;

export const MyCourses = styled.div`
  a {
    height: 100%;

    p {
      color: #fff;
      background: #000000;
      border-radius: 0px 10px 10px 0px;
    }

    ::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 9px;
      position: absolute;
    }
  }

  a.no-before {
    cursor: default;
    ::before {
      display: none;
    }
  }
`;

export const Courses = styled.div`
  a {
    height: 100%;

    p {
      color: #fff;
      background: #000000;
      border-radius: 0px 8px 8px 0px;
    }

    ::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 9px;
      position: absolute;
    }
  }
`;

export const MyProfile = styled.div`
  .bg-gray {
    background: #202020;
    padding: 20px;
    border-radius: 20px;
  }

  img {
    width: 130px;
    height: 130px;
    padding: 3px;
    background-image: linear-gradient(0deg, #202020, #202020),
      radial-gradient(
        circle at top left,
        rgba(168, 107, 15, 0.609),
        rgba(242, 201, 76, 0.609)
      );
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 50%;
  }

  button,
  .button {
    cursor: pointer;
    border: none;
    padding: 2px;
    background-image: linear-gradient(0deg, #242526, #242526),
      linear-gradient(180deg, rgba(168, 107, 15), rgba(242, 201, 76, 0.609));
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 10px;

    span {
      padding: 5px 10px;
      display: block;
    }
  }
`;

export const SubscriptionsCards = styled.div`
  .bg-gray {
    background: #202020;
    border-radius: 20px;
  }

  button,
  .button {
    cursor: pointer;
    border: none;
    padding: 2px;
    background-image: linear-gradient(0deg, #242526, #242526),
      linear-gradient(180deg, rgba(168, 107, 15), rgba(242, 201, 76, 0.609));
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 10px;

    span {
      padding: 5px 10px;
      display: block;
    }
  }

  .graph {
    width: 11%;
    opacity: 0.1;
  }

  table {
    th,
    td {
      padding: 0 10px;
    }

    th {
      font-size: 0.9rem;
    }

    .cardId {
      width: 1%;
    }

    .cardNumber {
      width: 16%;
    }

    .cardExpirationDate {
      width: 15%;
    }

    .cardName {
      width: 15%;
    }

    .cardBillingAddress {
      width: 20%;
    }

    .cardPrimary {
      width: 13%;
    }

    .cardAction {
      width: 1%;
    }
  }
`;

export const BankWire = styled.div`
  .bg-gray {
    background: #202020;
    border-radius: 20px;
  }

  .ddp {
    width: 11%;
  }

  .graph {
    width: 11%;
    opacity: 0;
  }

  .alert-text {
    width: 81%;
    margin-left: auto;
    margin-bottom: 5px;
  }
`;

export const OtherPaymentOptions = styled.div`
  .bg-gray {
    background: #202020;
    border-radius: 20px;
  }

  .qr-code,
  .star-flag {
    width: 11%;
  }

  .infos {
    .empty {
      width: 97px;
    }
  }

  .alert-text {
    width: 81%;
    margin-left: auto;
    margin-bottom: 5px;
  }
`;
