import React, { useCallback, useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { Container, Options, Search, Welcome } from './styles';
import Downloads from './Downloads';
import Links from './Links';

import plus from '~/assets/icons/plus-admin-icon2.svg';
import search from '~/assets/icons/search-icon.svg';

const Resources: React.FC = () => {
  const location = useLocation();
  const [optionSelected, setOptionSelected] = useState<'links' | 'downloads'>(
    'downloads'
  );
  const [filter, setFilter] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const option = location.pathname.slice(11) as 'links' | 'downloads';
    setOptionSelected(option);
    setFilter('');
  }, [location.pathname]);

  const handleChange = useCallback((e) => {
    setFilter(e.target.value);
  }, []);

  return (
    <>
      <Container className="px-2 px-sm-0">
        <div className="container-fluid container-xxl">
          <div className="row">
            <div className="col-12 p-0">
              <Welcome>
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-lg-8 px-0 px-sm-3 mb-sm-5 mb-lg-0">
                      <div className="text-center text-sm-left d-sm-flex justify-content-sm-between justify-content-lg-start">
                        <h1 className="h2 mb-4 mb-sm-2 text-left">Resources</h1>
                        <Link
                          to={`${process.env.PUBLIC_URL}/resources/register`}
                          className="d-inline-flex btn-new-course align-items-center px-5 ml-sm-5 mb-5 mb-sm-0 rounded-pill text-center justify-content-center"
                        >
                          <img src={plus} alt="plus" className="mr-2" />
                          New Resource
                        </Link>
                      </div>
                    </div>
                    <div className="col-sm-8 col-lg-4 px-0 px-sm-3">
                      <Search className="d-flex">
                        <input
                          className="w-100"
                          placeholder="Search"
                          onChange={handleChange}
                          value={filter}
                        />
                        <img src={search} alt="Search" className="mx-2" />
                      </Search>
                    </div>
                  </div>
                </div>
              </Welcome>
              <Options>
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-xl-10">
                      <div className="row mx-sm-3 pt-5 pb-4 mb-4 justify-content-center">
                        <div className="col-6 col-md-4 col-lg-3 text-center px-0">
                          <NavLink
                            to={`${process.env.PUBLIC_URL}/resources/downloads`}
                            className="px-4 px-sm-5 py-2"
                            activeClassName="active border-bottom"
                            exact
                          >
                            Downloads
                          </NavLink>
                        </div>
                        <div className="col-6 col-md-4 col-lg-3 px-0">
                          <NavLink
                            to={`${process.env.PUBLIC_URL}/resources/links`}
                            className="px-5 px-sm-5 py-2"
                            activeClassName="active border-bottom"
                            exact
                          >
                            Links
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Options>
            </div>
            {optionSelected === 'downloads' && <Downloads search={filter} />}
            {optionSelected === 'links' && <Links search={filter} />}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Resources;
