import React from 'react';
import { GridDropZone, GridItem } from 'react-grid-dnd';

import { Box, Container, Item, Placeholder } from './styles';

import dragDrop from '~/assets/icons/drag-drop-icon.svg';

interface ILesson {
  id: string;
  title: string;
  thumbnail_url: string;
}

interface ILessonsDnd {
  data: ILesson[];
  error?: string;
}

const LessonsDnd: React.FC<ILessonsDnd> = ({ data, error }) => {
  return (
    <Container>
      <Box
        items={Math.ceil(data.length / 3)}
        className="p-3"
        hasError={!!error}
      >
        <GridDropZone
          id="lessons"
          className="dropzone user-select-none"
          boxesPerRow={3}
          rowHeight={200}
        >
          {data.map((lesson) => (
            <GridItem key={lesson.id} className="p-3 grab-item">
              <Item src={lesson.thumbnail_url}>
                <div className="p-3">{lesson.title}</div>
              </Item>
            </GridItem>
          ))}
          {data.length === 0 && (
            <Placeholder className="d-flex justify-content-center align-items-center">
              <div className="w-lg-50 text-center px-5">
                <img src={dragDrop} alt="drag-drop" />
                <p className="px-0 px-sm-4 my-2">
                  Drag courses from the list to the side to insert to the module{' '}
                  <span className="d-block">Drag the course</span>
                </p>
              </div>
            </Placeholder>
          )}
        </GridDropZone>
      </Box>
      {error && <span className="small text-danger error">{error}</span>}
    </Container>
  );
};

export default LessonsDnd;
