import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosResponse } from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import editIcon from '~/assets/icons/edit-icon-light.svg';
import Input from '~/components/Input';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import { Container } from './styles';

interface IFaq {
  id?: string;
  question: string;
  answer: string;
  edited: boolean;
  status: string;
}
interface IQuestionBoxProps {
  questionData: IFaq;
  onLoading(data: boolean): void;
  onClickQuestion?(data: any): void;
  onChangeQuestion?(data: IFaq, index?: number): void;
  indexQuestion?: number;
  edit?: boolean;
}

const QuestionBox: React.FC<IQuestionBoxProps> = ({
  questionData,
  onClickQuestion,
  onChangeQuestion,
  onLoading,
  indexQuestion,
  edit,
}) => {
  const formRef = useRef<FormHandles>(null);
  const boxRef = useRef<any>(null);
  const [inEdit, setInEdit] = useState(edit || false);

  const handleEditQuestion = useCallback(() => {
    if (boxRef.current) {
      const filterFlip = boxRef.current;

      if (filterFlip) {
        filterFlip.querySelector('.front').classList.add('flip');
      }
      setTimeout(() => {
        setInEdit(true);
        if (filterFlip) {
          try {
            filterFlip.querySelector('.back').classList.add('flip');
          } catch (error) {
            filterFlip.querySelector('.front').classList.remove('flip');
          }
        }
      }, 350);
    }
  }, []);

  const handleCloseQuestion = useCallback(() => {
    if (boxRef.current) {
      const filterFlip = boxRef.current;

      if (filterFlip) {
        filterFlip.querySelector('.back').classList.remove('flip');
      }
      setTimeout(() => {
        setInEdit(false);
        if (filterFlip) {
          try {
            filterFlip.querySelector('.front').classList.remove('flip');
          } catch (error) {
            filterFlip.querySelector('.back').classList.add('flip');
          }
        }
      }, 350);
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: IFaq) => {
      onLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          question: Yup.string().required('Question is a required'),
          answer: Yup.string().required('Answer is a required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { question, answer } = data;

        const formData = { question, answer };

        let response: AxiosResponse<any> = {} as AxiosResponse<any>;

        if (data.status === 'new') {
          response = await api.post('faq', formData);
        } else {
          response = await api.put(`faq/${data.id}`, formData);
        }

        if (onChangeQuestion) {
          const dataQuestion: IFaq = {
            id: response.data.id,
            question,
            answer,
            edited: false,
            status: 'old',
          };
          onChangeQuestion(dataQuestion, indexQuestion);
        }

        if (response.data) {
          Swal.fire('Good job!', 'Question save successfully.', 'success').then(
            () => {
              handleCloseQuestion();
            }
          );
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          );
        }
      } finally {
        onLoading(false);
      }
    },
    [handleCloseQuestion, indexQuestion, onChangeQuestion, onLoading]
  );

  return (
    <Container className="col-md-6 col-xl-4 mt-2 mt-lg-4">
      <div className="p-2" ref={boxRef}>
        {inEdit ? (
          <div
            className={`${
              questionData.status === 'new' ? 'flip' : ''
            } question-box active back`}
          >
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={questionData}
            >
              <div className="d-flex justify-content-between align-items-center py-2 px-3 w-100 border-0 add-question">
                <p className="mb-0">Register Question</p>
                <button className="btn-grey px-4 mr-3 py-2" type="submit">
                  Save
                </button>
              </div>
              <div className="answer d-flex align-items-center">
                <div className="w-100 d-flex justify-content-center">
                  <div className="pb-3 w-75">
                    <Input name="id" type="hidden" className="d-none" />
                    <Input
                      name="status"
                      type="hidden"
                      className="d-none"
                      value={questionData.status}
                    />
                    <label htmlFor="add_question">Question</label>
                    <Input name="question" id="add_question" />
                    <label htmlFor="add_answer" className="mt-4">
                      Answer
                    </label>
                    <Input name="answer" id="add_answer" />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        ) : (
          <>
            <div
              className={`${
                questionData.status === 'new' ? 'flip active' : ''
              } question-box front`}
            >
              <div className="d-flex justify-content-between align-items-center py-2 px-3 question w-100 border-0">
                <button
                  type="button"
                  className="d-flex text-left justify-content-between align-items-center py-1 px-3 question w-100 border-0"
                  onClick={onClickQuestion}
                >
                  {questionData.question} <FiChevronDown />
                </button>
              </div>
              <div className="answer px-4 p-relative">
                <p className="small px-2">{questionData.answer}</p>
                <button
                  type="button"
                  onClick={handleEditQuestion}
                  className="btn-edit rounded-pill px-2 text-left mt-auto py-1 p-absolute"
                >
                  <span>
                    Edit
                    <img src={editIcon} alt="edit" className="ml-3" />
                  </span>
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default QuestionBox;
