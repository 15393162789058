import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useParams, useHistory } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import { Container, Welcome, InputsGroup, Thumbnail } from './styles';
import Input from '~/components/Input';
import Player from '~/components/Player';
import Textarea from '~/components/Textarea';
import InputMask from '~/components/InputMask';
import InputCourses, { IValue } from '~/components/InputCourses';

import videoThumb from '~/assets/icons/video_thumb.svg';
import imgThumb from '~/assets/icons/img_thumb.svg';

interface productFormData {
  id?: string;
  name: string;
  description: string;
  price: string;
  lines: number;
}

interface ICourses {
  id: string;
  title: string;
}

interface IProduct {
  id: string;
  name: string;
}

interface IParams {
  slug: string;
}

interface ICourseProduct {
  id: string;
  course: {
    id: string;
    title: string;
  };
}

interface IProducts {
  id: string;
  thumbnail: {
    id: string;
    thumbnail_url: string;
  };
  video: {
    id: string;
    video_url: string;
  };
  name: string;
  description: string;
  courseProduct: ICourseProduct[];
  price: string;
  slug: string;
  lines: number;
  mandatory_purchase: string;
  mandatory_gift: boolean;
}
const ProductsUpdate: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailId, setThumbnailId] = useState('');
  const [thumbnailSelected, setThumbnailSelected] = useState<File | null>(null);
  const [courses, setCourses] = useState<IValue[]>([]);
  const [coursesSelected, setCoursesSelected] = useState('');
  const [coursesRemoved, setCoursesRemoved] = useState<string[]>([]);
  const [dataCoursesSelected, setDataCoursesSelected] = useState<IValue[]>([]);
  const [products, setProducts] = useState<IValue[]>([]);
  const [productsSelected, setProductsSelected] = useState('');
  const [dataProductsSelected, setDataProductsSelected] = useState<IValue[]>(
    []
  );
  const [video, setVideo] = useState('');
  const [videoSelected, setVideoSelected] = useState<File | null>(null);
  const [videoId, setVideoId] = useState('');
  const [isGift, setIsGift] = useState(false);
  const [dataCourses, setDataCourses] = useState<ICourseProduct[]>([]);
  const [product, setProduct] = useState<productFormData>(
    {} as productFormData
  );
  const [loading, setLoading] = useState(false);

  const handleSeach = useCallback(async (dataSearch: string) => {
    const response = await api.get('courses', {
      params: {
        title: dataSearch,
        include_not_released: true,
      },
    });
    const data: IValue[] = response.data.map((course: ICourses) => ({
      id: course.id,
      value: course.title,
    }));
    setCourses(data);
  }, []);

  const handleSeachProduct = useCallback(async (dataSearch: string) => {
    const response = await api.get('products', {
      params: {
        name: dataSearch,
      },
    });
    const data: IValue[] = response.data.map((productData: IProduct) => ({
      id: productData.id,
      value: productData.name,
    }));
    setProducts(data);
  }, []);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    handleSeach('');
    handleSeachProduct('');

    api.get<IProducts>(`products/${params.slug}`).then((response) => {
      const data: productFormData = {
        id: response.data.id,
        name: response.data.name,
        price: response.data.price,
        description: response.data.description,
        lines: response.data.lines,
      };
      const dataCoursesTitle: IValue[] = response.data.courseProduct.map(
        (courseProduct) => ({
          id: courseProduct.course.id,
          value: courseProduct.course.title,
        })
      );
      const dataCoursesResponse = response.data.courseProduct.map(
        (courseProduct) => courseProduct
      );
      const coursesSelectedId = response.data.courseProduct.map(
        (courseProduct) => courseProduct.course.id
      );
      coursesSelectedId.forEach((courseSelectedId) => {
        setCoursesSelected((state) =>
          state.length > 0 ? `${state}|${courseSelectedId}` : courseSelectedId
        );
      });
      setProductsSelected(response.data.mandatory_purchase || '');
      setIsGift(response.data.mandatory_gift);
      setDataCourses(dataCoursesResponse);
      setDataCoursesSelected(dataCoursesTitle);
      setThumbnail(response.data.thumbnail.thumbnail_url);
      setThumbnailId(response.data.thumbnail.id);
      setVideoId(response.data.video ? response.data.video.id : '');
      setVideo(response.data.video ? response.data.video.video_url : '');
      setProduct(data);
      setLoading(false);
    });
  }, [handleSeach, handleSeachProduct, params.slug]);

  useEffect(() => {
    if (productsSelected) {
      api
        .get<IProducts>(`products/admin/${productsSelected}`)
        .then((response) => {
          const data: IValue[] = [
            {
              id: response.data.id,
              value: response.data.name,
            },
          ];
          setDataProductsSelected(data);
        });
    }
  }, [productsSelected]);

  const handleRemoveVideo = useCallback(() => {
    setVideo('');
    setVideoSelected(null);
  }, []);

  const handleSelectThumbnail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        if (file) {
          setThumbnail(URL.createObjectURL(file));
          setThumbnailSelected(file);
        } else {
          setThumbnail('');
          setThumbnailSelected(null);
        }
      }
    },
    []
  );

  const handleSelectVideo = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        setVideo(URL.createObjectURL(file));
        setVideoSelected(file);
      } else {
        setVideo('');
        setVideoSelected(null);
      }
    }
  }, []);

  const handleSelectedCourse = useCallback((data) => {
    setCoursesSelected((state) =>
      state.length > 0 ? `${state}|${data.id}` : data.id
    );
  }, []);

  const handleRemoveCourse = useCallback(
    (data) => {
      const courseRemoved = dataCourses.find(
        (course) => course.course.title === data.value
      );

      if (courseRemoved) {
        const coursesToRemove = coursesSelected.split('|');
        const newCoursesSelected = coursesToRemove
          .filter(
            (courseToRemove) => courseToRemove !== courseRemoved.course.id
          )
          .join('|');
        setCoursesSelected(newCoursesSelected);
        setCoursesRemoved((state) => [...state, courseRemoved.id]);
      }
    },
    [coursesSelected, dataCourses]
  );

  const handleSelectedProduct = useCallback((data) => {
    setProductsSelected((state) =>
      state.length > 0 ? `${state}|${data.id}` : data.id
    );
  }, []);

  const handleRemoveProduct = useCallback(() => {
    setProductsSelected('');
  }, []);

  const handleChange = useCallback((e) => {
    setIsGift(e.target.checked);
  }, []);

  const handleSubmit = useCallback(
    async (data: productFormData) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Name is a required'),
          description: Yup.string().required('Description is a required'),
          courses: Yup.string().when('$filledCourses', {
            is: (filledCourses: boolean) => filledCourses,
            then: Yup.string(),
            otherwise: Yup.string().required('Courses is a required'),
          }),
          price: Yup.string().required('Price is a required'),
          lines: Yup.number().required('Lines is a required'),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: { filledCourses: !!coursesSelected },
        });

        coursesRemoved.forEach(async (courseRemoved) => {
          await api.delete(`courses-products/${courseRemoved}`);
        });

        let responseThumbnail;
        if (thumbnailSelected) {
          const thumbnailData = new FormData();
          thumbnailData.append('thumbnail', thumbnailSelected as File);
          responseThumbnail = await api.post('thumbnails', thumbnailData);
        } else {
          responseThumbnail = {
            data: {
              id: thumbnailId,
            },
          };
        }

        if (responseThumbnail.data) {
          // setStatus('Uploading video...');

          let responseVideo;
          if (videoSelected) {
            const videoData = new FormData();
            videoData.append('video', videoSelected as File);
            responseVideo = await api.post('videos', videoData);
          } else {
            responseVideo = {
              data: {
                id: videoId,
              },
            };
          }

          if (responseVideo.data) {
            const { name, price, lines, description } = data;

            const slug = name
              .replace(/[àáâãäå]/g, 'a')
              .replace(/æ/g, 'ae')
              .replace(/ç/g, 'c')
              .replace(/[èéêë]/g, 'e')
              .replace(/[ìíîï]/g, 'i')
              .replace(/ñ/g, 'n')
              .replace(/[òóôõö]/g, 'o')
              .replace(/œ/g, 'oe')
              .replace(/[ùúûü]/g, 'u')
              .replace(/[ýÿ]/g, 'y')
              .replace(/[^a-zA-Z0-9 -]/g, '')
              .replace(/ /g, '-')
              .toLowerCase();

            const formData = {
              thumbnail_id: responseThumbnail.data.id,
              video_id: responseVideo.data.id,
              name,
              description,
              courses: coursesSelected,
              price: parseFloat(price.replace('$', '').replace(',', '')),
              slug,
              lines,
              mandatory_purchase: productsSelected,
              mandatory_gift: isGift,
            };

            const response = await api.put(`products/${product.id}`, formData);

            if (response.data) {
              Swal.fire(
                'Good job!',
                'Product updated successfully.',
                'success'
              ).then(() => {
                setLoading(false);
                history.push(`${process.env.PUBLIC_URL}/products`);
              });
            }
          }
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          ).then(() => setLoading(false));
        }
      }
    },
    [
      coursesRemoved,
      coursesSelected,
      history,
      isGift,
      product.id,
      productsSelected,
      thumbnailId,
      thumbnailSelected,
      videoId,
      videoSelected,
    ]
  );

  const handleDelete = useCallback(async () => {
    await api.delete(`products/${product.id}`);
    history.push(`${process.env.PUBLIC_URL}/products`);
  }, [history, product.id]);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row justify-content-center">
          <div className="col-12 p-0 mb-5">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h2">Franchises Management</h1>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          <div className="col-12">
            <Form
              ref={formRef}
              initialData={product}
              onSubmit={handleSubmit}
              className="row"
            >
              <div className="col-lg-6 order-1 order-lg-0">
                <InputsGroup className="p-4 h-100">
                  <h2 className="h4">Franchise Information</h2>
                  <div className="py-3">
                    <label htmlFor="name">Name</label>
                    <Input name="name" id="name" />
                  </div>
                  <div className="py-2">
                    <label htmlFor="price">Description</label>
                    <Textarea id="description" name="description" />
                  </div>
                  <div className="py-3">
                    <label htmlFor="link">Courses:</label>
                    <InputCourses
                      name="courses"
                      id="courses"
                      onSearch={handleSeach}
                      foundData={courses}
                      className="pt-2"
                      onSelect={handleSelectedCourse}
                      data={dataCoursesSelected}
                      onRemove={handleRemoveCourse}
                    />
                  </div>
                  <div className="py-3">
                    <label htmlFor="price">Price</label>
                    <InputMask
                      kind="money"
                      options={{
                        unit: '$',
                        delimiter: ',',
                        separator: '.',
                      }}
                      id="price"
                      name="price"
                      value={product.price}
                    />
                  </div>
                  <div className="py-2">
                    <label htmlFor="link">
                      Mandatory purchase for sale release:
                    </label>
                    <InputCourses
                      name="product"
                      id="product"
                      onSearch={handleSeachProduct}
                      foundData={products}
                      onSelect={handleSelectedProduct}
                      onRemove={handleRemoveProduct}
                      limit={1}
                      data={dataProductsSelected}
                      className="pt-2"
                    />
                    <label htmlFor="gift" className="mt-1">
                      <input
                        type="checkbox"
                        id="gift"
                        name="gift"
                        className="mr-1"
                        onChange={handleChange}
                        checked={isGift}
                      />
                      Mandatory franchise goes as a free gift.
                    </label>
                  </div>
                  <div className="py-2 d-flex align-items-center">
                    <label htmlFor="lines" className="w-75 mb-0">
                      Total lines to be released in crowdfunding:
                    </label>
                    <div className="w-50">
                      <Input
                        type="number"
                        name="lines"
                        id="lines"
                        min={0}
                        max={8}
                      />
                    </div>
                  </div>
                  <div className="d-flex py-3 mt-5">
                    <button
                      type="button"
                      className="btn-dark py-2 w-100 mr-1"
                      onClick={handleDelete}
                    >
                      <span className="d-block py-1">Delete</span>
                    </button>
                    <button type="submit" className="btn-grey py-2 w-100 ml-1">
                      <span className="d-block py-1">Save</span>
                    </button>
                  </div>
                </InputsGroup>
              </div>
              <div className="col-lg-6 order-0 oreder-lg-1 mb-5 mb-lg-0">
                <div className="w-100 h-50 pb-4">
                  {video ? (
                    <>
                      <Player src={video} />
                      <button
                        type="button"
                        onClick={handleRemoveVideo}
                        className="btn-remove-video mt-2 p-absolute"
                      >
                        <span className="d-block py-1 px-2">X</span>
                      </button>
                    </>
                  ) : (
                    <label
                      htmlFor="video"
                      className="btn bg-gray text-center w-100 h-100 p-0 mb-4 d-flex flex-column justify-content-center"
                    >
                      <div className="py-2 h-100 row align-items-center">
                        <div className="col-12">
                          <h3 className="h4 text-left pl-5 text-white">
                            Video
                          </h3>
                        </div>
                        <div className="col-12">
                          <img
                            src={videoThumb}
                            alt="AddThumbnail"
                            className="my-5"
                          />
                        </div>
                        <div className="col-12">
                          <p className="h5">Upload your file here or</p>
                          <p className="h5 text-white text-decoration-underline">
                            Browse file
                          </p>
                        </div>
                      </div>
                    </label>
                  )}
                  <Input
                    type="file"
                    id="video"
                    name="video"
                    className="d-none"
                    onChange={handleSelectVideo}
                  />
                </div>
                <div className="w-100 h-50">
                  <Thumbnail
                    src={thumbnail}
                    htmlFor="thumbnail"
                    className="btn bg-gray text-center w-100 h-100 p-0"
                  >
                    <div className="py-2 h-100 row align-items-center">
                      <div className="col-12">
                        <h3 className="h4 text-left pl-5 text-white">
                          Thumbnail
                        </h3>
                      </div>
                      <div className={`col-12 ${thumbnail ? 'opacity-0' : ''}`}>
                        <img
                          src={imgThumb}
                          alt="AddThumbnail"
                          className="my-5"
                        />
                      </div>
                      <div className={`col-12 ${thumbnail ? 'opacity-0' : ''}`}>
                        <p className="h5">Upload your file here or</p>
                        <p className="h5 text-white text-decoration-underline">
                          Browse file
                        </p>
                      </div>
                    </div>
                  </Thumbnail>
                  <Input
                    type="file"
                    id="thumbnail"
                    name="thumbnail"
                    className="d-none"
                    onChange={handleSelectThumbnail}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default ProductsUpdate;
