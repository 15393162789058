import styled from 'styled-components';

interface IPercentBar {
  percent: number;
}

export const Container = styled.div`
  .browse-color,
  h1,
  h3 {
    color: #cbccce !important;
  }

  h4 {
    color: #828282;
    font-size: 14px;
  }

  .opacity-100 {
    opacity: 1;
  }

  .opacity-25 {
    opacity: 0.25;
  }

  .pe-none {
    pointer-events: none !important;
  }

  .cursor-not-allowed {
    cursor: not-allowed !important;
  }

  .btns button {
    color: #8c8c8c !important;
    border: none;
    border-radius: 15px;
    background: #1f1f1f;
    padding: 11px;
    width: 48%;
  }

  .btns .active {
    background: #6f6e6e !important;
    color: #f5f5f5 !important;
  }

  .min-height {
    height: 262px;
  }

  .bg-gray {
    background: #202020;
    border-radius: 20px;
    overflow: hidden;
    border: solid 2px #333;

    p {
      font-family: 'Hind';
      color: #606060;
      line-height: inherit;
      span:nth-child(1) {
        color: #c6c2c2;
      }
      span:nth-child(2) {
        color: #8c8c8c;
        text-decoration-line: underline;
      }
    }
  }
  .h-40 {
    height: 40%;
  }
  .h-60 {
    height: 60%;
  }
  .btn-remove-video {
    top: 0;
    right: 30px;
    z-index: 99;
    background-color: #606060;
    border: none;
    border-radius: 50%;
  }

  @media screen and (min-width: 992px) {
    .h-lg-40 {
      height: 40%;
    }
    .h-lg-33 {
      height: 33%;
    }
  }
`;

export const Welcome = styled.div`
  p {
    color: #969696;
  }

  a {
    color: #e4e4e4;
  }

  .search {
    padding: 1px;
    border-radius: 100px;
    border: 1px solid #4a4b4b;
    background-color: #202020;

    input {
      margin: 5px 0;
      padding: 0 10px;
      border: none;
      border-radius: 100px;
      background-color: #202020;
    }
  }
`;

export const InputsGroup = styled.div`
  background: #202020;
  border-radius: 15px;

  h2 {
    color: #cbccce;
  }

  h4,
  label {
    color: #828282;
    font-size: 14px;
  }

  .input {
    > div {
      height: 56px;
      border-radius: 15px;
    }
  }
  .text-area {
    > div {
      border-radius: 15px;
    }
  }
  .description {
    textarea {
      min-height: 250px;
    }
  }

  .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_nw,
  .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_sw {
    border-radius: 20px 20px 0 0;
  }
  .ck.ck-button,
  a.ck.ck-button {
    :hover {
      color: #414141 !important;
      background-color: #cbccca !important;
    }
  }

  .ck-on {
    color: #414141 !important;
  }

  .ck.ck-list {
    background-color: #414141;
  }

  .ck.ck-button .ck-tooltip,
  a.ck.ck-button .ck-tooltip {
    display: none !important;
  }

  .ck-content {
    height: 250px !important;
  }

  .ck-toolbar {
    background: rgba(65, 65, 65, 1) !important;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-color: rgba(51, 51, 51, 1) !important;
    padding: 1rem !important;
  }

  .ck-content {
    background: #202020 !important;
    border: 1px solid #3e3e3e !important;
    box-shadow: none !important;
    //min-height: 100vh;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }

  .ck-focused {
    border: 1px solid #6e6e6e !important;
  }
`;

export const PercentBar = styled.div<IPercentBar>`
  width: 200px;
  height: 15px;
  border-radius: 16px;
  border: 1px solid #fff;
  background-color: #ffffff55;
  position: relative;
  overflow: hidden;

  :after {
    content: '';
    background-color: #ffffff;
    width: ${(props) => props.percent}%;
    height: 100%;
    transition-duration: 0.3s;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
