import styled from 'styled-components';

export const Container = styled.div`
  .graph h2 {
    color: #cbccce;
  }
  .graph .numbers {
    color: #e4e4e4;
    font-size: 32px;
  }

  .percent {
    color: #37c694;
  }

  .circles div {
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }

  .circles p {
    margin-bottom: 0;
  }

  .bg-direct {
    background-color: #d9b760;
  }

  .bg-social {
    background-color: #6960d9;
  }

  .bg-purple {
    background-color: #5e0fac;
  }
  .bg {
    background: #202020;
    border-radius: 20px;
  }

  .funnel-graph {
    width: 100%;
    height: 430px;
  }

  .label__value,
  .label__title,
  .svg-funnel-js__subLabels {
    display: none !important;
  }

  .svg-funnel-js:not(.svg-funnel-js--vertical) {
    padding-top: 0px;
    padding-bottom: 0px;
    margin: 20px 0;
  }

  .svg-funnel-js:not(.svg-funnel-js--vertical)
    .svg-funnel-js__label:not(:first-child) {
    border-left: 1px solid #fff;
  }

  .svg-funnel-js
    .svg-funnel-js__labels
    .svg-funnel-js__label:hover
    .label__segment-percentages {
    opacity: 0 !important;
  }
`;
