import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Container, Lessons, Lesson } from './styles';
import CoursesActionTitle from '~/components/CoursesActionTitle';
import lessonsIcon from '~/assets/icons/lessons-icon.svg';
import edit from '~/assets/icons/edit-icon-light.svg';
import api from '~/services/api';

interface IParams {
  slug: string;
}

interface ILessonsResponse {
  id: string;
  title: string;
  slug: string;
  thumbnail: {
    thumbnail_url: string;
  };
}

interface ILessonsParams {
  id: string;
  title: string;
  slug: string;
  thumbnail: string;
}

const LessonsList: React.FC = () => {
  const params = useParams<IParams>();
  const [courseId, setCourseId] = useState('');
  const [lessons, setLessons] = useState<ILessonsParams[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    api.get(`courses/${params.slug}`).then((response) => {
      setCourseId(response.data.id);
      setLoading(false);
    });
  }, [params.slug]);

  useEffect(() => {
    if (courseId.length > 0) {
      api.get(`lessons/${courseId}`).then((response) => {
        const data = response.data.map((lesson: ILessonsResponse) => ({
          id: lesson.id,
          title: lesson.title,
          slug: lesson.slug,
          thumbnail: lesson.thumbnail ? lesson.thumbnail.thumbnail_url : '',
        }));
        setLessons(data);
      });
    }
  }, [courseId]);

  return (
    <Container className="h-100">
      <div className="container-fluid container-xxl bg-gray h-100">
        <div className="row justify-content-center">
          <div className="col-12 text-center p-0 mt-5 pt-4">
            <CoursesActionTitle
              boldTitle="Lessons"
              description="Surprisingly, there is a very vocal faction of the design
              community that wants to see filler text banished the original
              sources his will just slow down the design process."
            />
          </div>
          <div className="col-12">
            <div className="row justify-content-center mb-3 my-5">
              <div className="col-11 col-lg-9 col-xl-8 col-xxl-7 overflow-auto pb-3 pb-md-0">
                <div className="d-flex ml-auto align-items-center min-width">
                  <Link
                    to={`${process.env.PUBLIC_URL}/courses/update/${params.slug}`}
                    className="w-100 text-center btn-grey step-lesson rounded-pill"
                  >
                    <span className="d-block px-3 py-3">Update Course</span>
                  </Link>
                  <hr className="w-25 w-md-50 border-gray mx-2" />
                  <div className="w-100 text-center btn-light-grey step-course rounded-pill">
                    <span className="d-block px-3 py-3">Lessons</span>
                  </div>
                  <hr className="w-25 w-md-50 border-gray mx-2" />
                  <Link
                    to={`${process.env.PUBLIC_URL}/courses/${params.slug}/modules`}
                    className="w-100 text-center btn-grey step-lesson rounded-pill"
                  >
                    <span className="d-block px-3 py-3">Modules</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-10">
            <h3 className="h5 pl-2 pl-md-4">Course Lessons</h3>
            <Lessons className="row">
              <Link
                to={`${process.env.PUBLIC_URL}/courses/${params.slug}/lessons/register`}
                className="col-md-6 col-xl-4 my-2 mh-220 px-1 px-md-3 mb-4"
              >
                <div className="d-flex flex-column justify-content-center align-items-center bg-gray py-3 text-center h-100">
                  <img src={lessonsIcon} alt="plus" className="pt-4" />
                  <p className="h4 mt-3">Add Lesson</p>
                </div>
              </Link>
              {lessons.map((lesson) => (
                <Lesson
                  key={lesson.id}
                  src={lesson.thumbnail}
                  className="col-md-6 col-xl-4 my-2 mh-220 px-1 px-md-3 mb-4"
                >
                  <div className="course-link py-4 px-3 d-flex flex-column justify-content-between h-100">
                    <p className="h6 h5-xxl mb-0">{lesson.title}</p>
                    <Link
                      to={`${process.env.PUBLIC_URL}/courses/${params.slug}/lessons/update/${lesson.slug}`}
                      className="py-1 d-block text-center w-50 w-sm-25 w-md-50 rounded-pill btn-edit small align-self-end"
                    >
                      Edit <img src={edit} alt="Edit" />
                    </Link>
                  </div>
                </Lesson>
              ))}
            </Lessons>
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default LessonsList;
