import React, { useEffect, useState } from 'react';
import FunnelGraph from 'funnel-graph-js';
import 'funnel-graph-js/dist/css/main.min.css';
import 'funnel-graph-js/dist/css/theme.min.css';
import { Container } from './styles';

interface IFunnel {
  labels: string[];
  values: number[][];
}
interface IGraphSalesFunnelProps {
  data: IFunnel;
  id?: string;
  className?: string;
}

const GraphSalesFunnel: React.FC<IGraphSalesFunnelProps> = ({ data, id }) => {
  const [total, setTotal] = useState<number[]>([]);

  useEffect(() => {
    const graph = new FunnelGraph({
      container: `.${id || 'funnel'}`,
      data: {
        labels: data.labels,
        subLabels: ['Direct', 'Social Media', 'Ads'],
        colors: [['#D9B760'], '#6960D9', ['#5E0FAC']],
        values: data.values,
      },
      displayPercent: false,
      subLabelValue: 'raw',
      direction: 'horizontal',
    });
    graph.draw();
  }, [data, id]);

  useEffect(() => {
    const totalData = data.values.map((valuesData) => {
      const totalQuantity = valuesData.reduce((previous, current) => {
        return previous + current;
      }, 0);

      return totalQuantity;
    });

    setTotal(totalData);
  }, [data]);

  return (
    <Container>
      <div className="container bg p-5">
        <div className="row graph">
          {data.labels.map((label, index) => (
            <div key={label} className="col-3">
              <h2 className="h6 font-weight-300">{label}</h2>
              <p className="numbers">{total[index]}</p>
            </div>
          ))}
          <div className="col-12">
            <div className={`${id || 'funnel'} funnel-graph`} />
          </div>
        </div>
        <div className="row">
          <div className="col-12 circles d-flex align-items-center justify-content-center">
            <div className="bg-direct mr-2" />
            <p className="mr-5">Direct</p>
            <div className="bg-social mr-2" />
            <p className="mr-5">Social Media</p>
            <div className="bg-purple mr-2" />
            <p className="mr-5">Ads</p>
          </div>
        </div>
        <div />
      </div>
    </Container>
  );
};

export default GraphSalesFunnel;
