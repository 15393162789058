import React, { useCallback, useEffect, useState } from 'react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { isUUID } from 'validator';

import { AddButton, Card } from '../styles';

import plus from '~/assets/icons/plus-admin-icon2.svg';
import api from '~/services/api';

interface IEmails {
  isPostEase: boolean;
}

interface ISwipeFile {
  id: string;
  subject: string;
  body: string;
}

interface IEmail {
  id: string;
  subject: string;
  body: string;
  edited: boolean;
}

const Emails: React.FC<IEmails> = ({ isPostEase }) => {
  const [emails, setEmails] = useState<IEmail[]>([]);

  useEffect(() => {
    api
      .get<ISwipeFile[]>('swipe-files', {
        params: {
          type: 'email',
          category: isPostEase ? 'postease' : 'autoaffiliate',
        },
      })
      .then((response) => {
        const data = response.data.map<IEmail>((swipeFile) => ({
          id: swipeFile.id,
          subject: swipeFile.subject,
          body: swipeFile.body,
          edited: false,
        }));

        setEmails(data);
      });
  }, [isPostEase]);

  const handleChangeSubjectEmail = useCallback(
    (event, index) => {
      const newEmails = [...emails];
      if (event.target) {
        const { value } = event.target;
        newEmails[index].subject = value;
        newEmails[index].edited = true;
        setEmails(newEmails);
      }
    },
    [emails]
  );

  const handleChangeBodyEmail = useCallback(
    (event, index) => {
      const newEmails = [...emails];
      if (event.target) {
        const { value } = event.target;
        newEmails[index].body = value;
        newEmails[index].edited = true;
        setEmails(newEmails);
      }
    },
    [emails]
  );

  const handleAddLink = useCallback(() => {
    const nextId =
      emails.length > 0
        ? (parseInt(emails[emails.length - 1].id, 10) + 1).toString()
        : '1';
    setEmails((state) => [
      ...state,
      {
        id: nextId,
        subject: '',
        body: '',
        edited: false,
      },
    ]);
  }, [emails]);

  const handleClickSave = useCallback(
    async (index) => {
      const newEmails = [...emails];

      const formData = {
        type: 'email',
        category: isPostEase ? 'postease' : 'autoaffiliate',
        subject: newEmails[index].subject,
        body: newEmails[index].body,
      };

      let emailId = newEmails[index].id;

      if (isUUID(emailId, 4)) {
        await api.put(`swipe-files/${emailId}`, formData);
      } else {
        const response = await api.post('swipe-files', formData);

        emailId = response.data.id;
      }

      newEmails[index].id = emailId;
      newEmails[index].edited = false;
      setEmails(newEmails);
    },
    [emails, isPostEase]
  );

  const handleClickDelete = useCallback(
    async (selectedId) => {
      await api.delete(`swipe-files/${selectedId}`);

      const updatedItems = emails.filter((item) => item.id !== selectedId);

      setEmails(updatedItems);
    },
    [emails]
  );

  return (
    <>
      <div className="col-lg-12 my-3">
        <div className="d-flex w-100 my-5 my-md-4 my-lg-0 justify-content-center justify-content-sm-start justify-content-lg-end">
          <AddButton
            type="button"
            className="h6 mb-0 py-2 px-4 small normal-sm"
            onClick={handleAddLink}
          >
            <img src={plus} alt="plus" className="my-1 mr-3" />
            Add New Email
          </AddButton>
        </div>
      </div>
      {emails.map((email, index) => (
        <div key={index} className="col-md-6 col-xl-4 my-3">
          <Card className="p-3 p-sm-4 h-100">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div />
              {email.edited ? (
                <button
                  type="button"
                  className="rounded-pill btn-top py-2"
                  onClick={() => handleClickSave(index)}
                >
                  <span className="mb-0 small m-1">Save</span>
                </button>
              ) : (
                <button
                  type="button"
                  className="d-flex align-items-center justify-content-center rounded-pill btn-top py-2 text-danger"
                  onClick={() => handleClickDelete(email.id)}
                >
                  <RiDeleteBin6Line size={15} />
                  <span className="mb-0 small ml-2">Delete</span>
                </button>
              )}
            </div>
            <h3 className="mb-0">Subject:</h3>
            <div className="subject link d-flex justify-content-between align-items-end py-1 p-relative">
              <textarea
                className={`py-2 w-100 bg-transparent border-0 `}
                value={email.subject}
                onChange={(e) => handleChangeSubjectEmail(e, index)}
              />
            </div>

            <h3 className="mb-0">Body:</h3>
            <div className="body link d-flex justify-content-between align-items-end py-1 p-relative">
              <textarea
                className={`py-2 w-100 bg-transparent border-0 `}
                value={email.body}
                onChange={(e) => handleChangeBodyEmail(e, index)}
              />
            </div>
          </Card>
        </div>
      ))}
    </>
  );
};

export default Emails;
