import styled from 'styled-components';
import { lighten } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IAvatar {
  src: string;
}

export const Container = styled.div`
  label {
    color: #8c8c8c;
  }
  .border-gray {
    border-color: #333333;
    padding: 14.5px 10px !important;
    border-radius: 15px !important;
  }
`;

export const Welcome = styled.div`
  p {
    color: #969696;
  }
`;

export const Avatar = styled.label<IAvatar>`
  margin: 0 auto;
  width: 114px;
  height: 114px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 4px rgba(228, 228, 228, 0.5);
  box-sizing: border-box;
  position: relative;
  cursor: pointer;

  > div {
    position: absolute;
    background-color: #606060;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 0;
    right: 0;
  }
`;

export const ContactInfo = styled.div`
  background: #1f1f1f;
  border-radius: 20px;
  padding: 50px 25px;

  h3,
  label.commission {
    color: #cbccce !important;
    font-weight: 400 !important;
  }

  hr {
    border-top: solid 1.5px #333;
  }

  .percentage {
    top: 1px;
    right: 1px;
    border-left: 1.5px solid #3e3e3e;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: transparent;
    height: calc(100% - 2px);
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8c8c8c;
    background-color: #1f1f1f;
    border-top-right-radius: 15px !important;
    border-bottom-right-radius: 15px !important;
  }

  @media screen and (max-width: 1199px) {
    padding: 30px 25px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    .bd-right {
      border-right: solid 2px #242526;
    }
  }
`;

export const PersonalInformation = styled.div`
  background: #1f1f1f;
  border-radius: 20px;
  padding: 30px 25px;

  .bg-gray {
    background: #242526;
    border-radius: 15px;
    overflow: hidden;

    p {
      color: #565656;
    }
  }

  .h-107px {
    min-height: 107px;
  }

  .h-143px {
    textarea {
      min-height: 143px;
    }
  }

  .bio-photo {
    width: 100%;
    border-radius: 15px;
  }

  .language-input {
    textarea {
      padding: 0 10px;
      height: 75px;
      resize: none;
    }
  }

  .my-bio {
    background-color: transparent;
    border: none;
    border-radius: 15px;
    color: #e4e4e4 !important;
    font-weight: bold;
    text-align: center;

    :hover {
      text-decoration: none;
    }
  }

  .submit-button {
    background-color: #606060;
    border: none;
    border-radius: 15px;
    color: #e4e4e4 !important;
    font-weight: bold;
    text-align: center;

    :hover {
      text-decoration: none;
      background-color: ${lighten(0.1, '#606060')};
      transition: 0.5s;
    }
  }

  @media screen and (max-width: 768px) {
    .my-bio {
      background-color: #333333;

      :hover {
        background-color: ${lighten(0.1, '#333')};
        transition: 0.5s;
      }
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border-radius: 20px !important;
  }
`;
