import styled from 'styled-components';

interface IAvatar {
  src: string;
}

export const Container = styled.div`
  background: #202020;
  border-radius: 20px;

  .box-title {
    color: #8c8c8c;
  }

  .box-number {
    color: #d9d9d9;
  }

  .border-gray {
    border: solid 2px #333;
    border-radius: 20px;
  }

  .btn-month {
    background: #242526;
    border: 1px solid rgba(225, 225, 225, 0.2);

    span {
      color: #8c8c8c;
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  width: 90px;
  height: 90px;
  padding: 3px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 4px rgba(228, 228, 228, 0.5);
  box-sizing: border-box;

  @media screen and (max-width: 1199px) {
    width: 70px;
    height: 70px;
  }
`;
