import React, { useEffect, useState } from 'react';
import { RiWhatsappFill } from 'react-icons/ri';
import { MdEmail } from 'react-icons/md';
import { AiFillSkype } from 'react-icons/ai';
import { BsFillBookmarkDashFill } from 'react-icons/bs';
import { FaCalendarCheck } from 'react-icons/fa';
import { useParams } from 'react-router-dom';

import api from '~/services/api';

import { Container, BioPhoto } from './styles';
import Maps from '~/components/Maps';

const profilePhoto = 'http://cdn.wiserr.io/avatars/default-avatar.png';

interface IParams {
  slug: string;
}

interface ICoach {
  id: string;
  display_name: string;
  biography: string;
  display_phone: string;
  display_email: string;
  skype: string;
  bio_photo: string;
  favorite_quote: string;
}

const Bio: React.FC = () => {
  const params = useParams<IParams>();
  const [coach, setCoach] = useState<ICoach>({} as ICoach);
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get(`coaches/${params.slug}`)
      .then((response) => {
        const data: ICoach = {
          id: response.data.id,
          display_name: response.data.display_name,
          display_phone: response.data.display_phone,
          display_email: response.data.display_email,
          skype: response.data.skype,
          bio_photo: response.data.file[0]
            ? response.data.file[0].file_url
            : '',
          biography: response.data.biography,
          favorite_quote: response.data.favorite_quote,
        };
        if (response.data.address) {
          const addressData = `${response.data.address.street}, ${response.data.address.number}, ${response.data.address.neighborhood}, ${response.data.address.city}, ${response.data.address.state} - ${response.data.address.zipCode} - ${response.data.address.country}`;
          setAddress(addressData);
        }
        setCoach(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.slug]);

  return (
    <Container className="py-5">
      <div className="container-fluid container-lg">
        <div className="row justify-content-center align-items-center p-relative">
          <div className="col-lg-10 d-flex flex-column bg-gray p-relative px-sm-5 px-md-3 py-4">
            <BioPhoto
              src={coach.bio_photo || profilePhoto}
              className="p-md-absolute"
            />
            <div className="row justify-content-end order-1 order-lg-0">
              <div className="col-lg-7 col-xl-8 pt-xxl-4 mt-4 mt-lg-5 px-md-5 px-lg-3">
                <h1 className="font-weight-bold">{coach.display_name}</h1>
                <p className="h5 py-4 font-weight-300 mh-description">
                  {coach.biography}
                </p>
              </div>
            </div>
            <div className="row justify-content-md-end justify-content-lg-start justify-content-xl-end order-0 order-lg-1">
              <div className="col-md-6 col-lg-12 col-xl-8 mt-5 mt-lg-0">
                <div className="row">
                  <div className="col-lg-6 order-1 order-xl-0 mt-5 mt-lg-0">
                    <div className="row">
                      <div className="col-2 book-mark text-right text-md-center pl-2 pl-md-3 mt-n2 pr-0">
                        <BsFillBookmarkDashFill color="#606060" />
                      </div>
                      <div className="col-10">
                        <h2 className="h5 font-weight-400">Favorite Quote</h2>
                        <p className="w-100 font-weight-300 mh-quote">
                          {coach.favorite_quote}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 order-0 order-xl-1">
                    <ul>
                      <li className="mb-1 d-flex">
                        <RiWhatsappFill size={20} color="#969696" />{' '}
                        <b className="mx-2">Phone:</b>{' '}
                        <span>{coach.display_phone}</span>
                      </li>
                      <li className="my-3 d-flex">
                        <MdEmail size={20} color="#969696" />{' '}
                        <b className="mx-2">Email:</b>{' '}
                        <span>{coach.display_email}</span>
                      </li>
                      <li className="mt-1 d-flex">
                        <AiFillSkype size={20} color="#969696" />{' '}
                        <b className="mx-2">Skype:</b>{' '}
                        <span>{coach.skype}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-xl-end order-2 order-lg-2">
              <div className="col-lg-12 col-xl-8">
                <div className="row justify-content-md-end justify-content-lg-start">
                  <div className="col-lg-6 order-1 order-md-0 d-flex d-md-block">
                    <button
                      type="button"
                      className="btn mt-4 mt-md-0 mt-lg-5"
                      disabled
                    >
                      <FaCalendarCheck size={19} color="#18191A" />
                      <small className="font-weight-600">
                        Book A Call With Me Here
                      </small>
                    </button>
                  </div>
                  <div className="col-md-8 col-lg-6 order-0 order-md-1">
                    <Maps address={address} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Bio;
