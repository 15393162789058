import styled from 'styled-components';

export const Container = styled.div`
  .rdt_TableHeadRow,
  .rdt_TableRow {
    > div:first-child {
      max-width: 150px;
    }
  }

  .users-radius {
    border-radius: 20px;
  }

  @media screen and (max-width: 991px) {
    .h-date input {
      height: 41px;
    }
  }

  @media screen and (max-width: 767px) {
  }

  @media screen and (max-width: 575px) {
    .size-users {
      font-size: 12px;
    }
    .users-size {
      display: block;

      margin: auto;
    }
  }

  @media (min-width: 992px) and (max-width: 1250px) {
    .vw80 {
      width: 80vw !important;
    }
  }
`;

export const Welcome = styled.div`
  p {
    color: #969696;
  }

  button {
    border-radius: 33px;
    border: 1px solid #606060;
    background: #202020;
    color: #fff;
    font-family: 'Inter';
    font-size: 12px;
    font-weight: 600;
    width: 164px;
  }
`;

export const NameUserTable = styled.div`
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;

export const OptionUser = styled.div`
  cursor: pointer;
`;
