import React, { useCallback, useEffect, useState } from 'react';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { isUUID } from 'validator';

import { Card, AddButton } from '../styles';

import plus from '~/assets/icons/plus-admin-icon2.svg';
import facebook from '~/assets/icons/facebook-icon.svg';
import twitter from '~/assets/icons/twitter-icon.svg';
import whatsapp from '~/assets/icons/whatsapp-circle-icon.svg';
import imgThumb from '~/assets/icons/img_thumb.svg';
import api from '~/services/api';

interface IImagesProps {
  isPostEase: boolean;
}

interface ISwipeFile {
  id: string;
  file: {
    id: string;
    file_url: string;
  };
}

interface IImages {
  id: string;
  url: string;
  file_id?: string;
  file?: File;
  edited: boolean;
}

const Images: React.FC<IImagesProps> = ({ isPostEase }) => {
  const [images, setImages] = useState<IImages[]>([]);

  useEffect(() => {
    api
      .get<ISwipeFile[]>('swipe-files', {
        params: {
          type: 'image',
          category: isPostEase ? 'postease' : 'autoaffiliate',
        },
      })
      .then((response) => {
        const data = response.data.map<IImages>((swipeFile) => ({
          id: swipeFile.id,
          url: swipeFile.file.file_url,
          file_id: swipeFile.file.id,
          edited: false,
        }));

        setImages(data);
      });
  }, [isPostEase]);

  const handleClickSave = useCallback(
    async (index) => {
      const newImages = [...images];

      const formData = {
        type: 'image',
        category: isPostEase ? 'postease' : 'autoaffiliate',
      };
      const imageFormData = new FormData();
      imageFormData.append('file', newImages[index].file as File);

      let imageId = newImages[index].id;

      if (isUUID(imageId, 4)) {
        imageFormData.append('foreign_id', imageId);
        await api.put(`swipe-files/${imageId}`, formData);
        await api.put(`files/${newImages[index].file_id}`, imageFormData);
      } else {
        const response = await api.post('swipe-files', formData);
        imageId = response.data.id;
        imageFormData.append('foreign_id', imageId);
        await api.post(`files`, imageFormData);
      }

      newImages[index].edited = false;
      newImages[index].id = imageId;
      setImages(newImages);
    },
    [images, isPostEase]
  );

  const handleClickDelete = useCallback(
    async (selectedId) => {
      await api.delete(`swipe-files/${selectedId}`);

      const updatedItems = images.filter((item) => item.id !== selectedId);

      setImages(updatedItems);
    },
    [images]
  );

  const handleAddLink = useCallback(() => {
    const nextId =
      images.length > 0
        ? (parseInt(images[images.length - 1].id, 10) + 1).toString()
        : '1';
    setImages((state) => [
      ...state,
      {
        id: nextId,
        url: '',
        edited: false,
      },
    ]);
  }, [images]);

  const handleSelectFile = useCallback(
    (e, index) => {
      const newLinks = images.slice();
      if (e.target.files) {
        const file = e.target.files[0];
        if (file) {
          newLinks[index].url = URL.createObjectURL(file);
          newLinks[index].file = file;
        } else {
          newLinks[index].url = '';
          newLinks[index].file = undefined;
        }
      }
      newLinks[index].edited = true;
      setImages(newLinks);
    },
    [images]
  );

  return (
    <>
      <div className="col-lg-12 my-3">
        <div className="d-flex w-100 my-5 my-md-4 my-lg-0 justify-content-center justify-content-sm-start justify-content-lg-end">
          <AddButton
            type="button"
            className="h6 mb-0 py-2 px-4 small normal-sm"
            onClick={handleAddLink}
          >
            <img src={plus} alt="plus" className="my-1 mr-3" />
            Add New Image
          </AddButton>
        </div>
      </div>
      {images.map((image, index) => (
        <div key={index} className="col-md-6 col-xl-4 px-0 px-sm-3 my-3">
          <Card className="p-3 h-100">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div />
              {image.edited ? (
                <button
                  type="button"
                  className="rounded-pill btn-top py-2"
                  onClick={() => handleClickSave(index)}
                >
                  <span className="mb-0 small m-1">Save</span>
                </button>
              ) : (
                <button
                  type="button"
                  className="d-flex align-items-center justify-content-center rounded-pill btn-top py-2 text-danger"
                  onClick={() => handleClickDelete(image.id)}
                >
                  <RiDeleteBin6Line size={15} />
                  <span className="mb-0 small ml-2">Delete</span>
                </button>
              )}
            </div>

            <label
              htmlFor={`image-${index}`}
              className="d-flex flex-column justify-content-center align-items-center "
            >
              {image.url ? (
                <div className="w-100">
                  <img src={image.url} alt="Banner" className="w-100" />
                </div>
              ) : (
                <div className="label-image w-100">
                  <div className="h-100 d-flex flex-column align-items-center justify-content-center">
                    <img src={imgThumb} alt="plus" className="w-25 mb-2" />
                    <p className="h4 mt-2 tinytext">New Image</p>
                  </div>
                </div>
              )}
            </label>
            <input
              type="file"
              id={`image-${index}`}
              className="d-none"
              onChange={(e) => handleSelectFile(e, index)}
            />

            <div className="d-flex justify-content-center flex-wrap mt-4 pb-xxl-3">
              <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                <button
                  type="button"
                  className="btn-share shadow rounded-pill w-100"
                >
                  <img src={facebook} alt="Facebook" className="my-2" />
                </button>
              </div>
              <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                <button
                  type="button"
                  className="btn-share shadow rounded-pill w-100"
                >
                  <img src={twitter} alt="Twitter" className="my-2" />
                </button>
              </div>
              <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                <button
                  type="button"
                  className="btn-share shadow rounded-pill w-100"
                >
                  <img src={whatsapp} alt="Whatsapp" className="my-2" />
                </button>
              </div>
            </div>
          </Card>
        </div>
      ))}
    </>
  );
};

export default Images;
