import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory, useParams } from 'react-router-dom';

import { FormHandles } from '@unform/core';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import { Container, Welcome, InputsGroup, Thumbnail } from './styles';
import Input from '~/components/Input';

import attachment from '~/assets/icons/resource-files.svg';
import imgThumb from '~/assets/icons/img_thumb.svg';

interface resourceFormData {
  id?: string;
  name: string;
  link?: string;
}

interface IParams {
  slug: string;
}

const ResourcesUpdate: React.FC = () => {
  const history = useHistory();
  const params = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailId, setThumbnailId] = useState('');
  const [thumbnailSelected, setThumbnailSelected] = useState<File | null>(null);
  const [fileSelected, setFileSelected] = useState<File | null>(null);
  const [isFile, setIsFile] = useState(false);
  const [resource, setResource] = useState<resourceFormData>(
    {} as resourceFormData
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);

    api.get(`resources/${params.slug}`).then((response) => {
      setIsFile(!!response.data.resource_url);
      setThumbnailId(response.data.thumbnail.id);
      setThumbnail(response.data.thumbnail.thumbnail_url);
      setResource(response.data);
      setLoading(false);
    });
  }, [params.slug]);

  const handleSelectFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        setFileSelected(file);
      } else {
        setFileSelected(null);
      }
    }
  }, []);

  const handleSelectThumbnail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        if (file) {
          setThumbnail(URL.createObjectURL(file));
          setThumbnailSelected(file);
        } else {
          setThumbnail('');
          setThumbnailSelected(null);
        }
      }
    },
    []
  );

  const handleSubmit = useCallback(
    async (data: resourceFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Name is a required'),
          link: Yup.string().when('$filledFile', {
            is: (filledFile: boolean) => filledFile,
            then: Yup.string(),
            otherwise: Yup.string().required(
              'Link is required if you do not select a file'
            ),
          }),
          file: Yup.string().when('$filledLink', {
            is: (filledLink: boolean) => filledLink,
            then: Yup.string(),
            otherwise: Yup.string().required(
              'The file is required if you do not specify a link'
            ),
          }),
          thumbnail: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            filledFile: !!fileSelected || isFile,
            filledLink: !!data.link || isFile,
          },
        });

        let responseThumbnail;
        if (thumbnailSelected) {
          const thumbnailData = new FormData();
          thumbnailData.append('thumbnail', thumbnailSelected as File);
          responseThumbnail = await api.post('thumbnails', thumbnailData);
        } else {
          responseThumbnail = {
            data: {
              id: thumbnailId,
            },
          };
        }

        if (responseThumbnail.data) {
          const { name, link } = data;

          const slug = name
            .replace(/[àáâãäå]/g, 'a')
            .replace(/æ/g, 'ae')
            .replace(/ç/g, 'c')
            .replace(/[èéêë]/g, 'e')
            .replace(/[ìíîï]/g, 'i')
            .replace(/ñ/g, 'n')
            .replace(/[òóôõö]/g, 'o')
            .replace(/œ/g, 'oe')
            .replace(/[ùúûü]/g, 'u')
            .replace(/[ýÿ]/g, 'y')
            .replace(/[^a-zA-Z0-9 -]/g, '')
            .replace(/ /g, '-')
            .toLowerCase();

          const formData = new FormData();
          formData.append('thumbnail_id', responseThumbnail.data.id);
          formData.append('name', name);
          if (link) {
            formData.append('link', link);
          }
          if (fileSelected) {
            formData.append('resource', fileSelected);
          }
          formData.append('slug', slug);

          const response = await api.put(`resources/${resource.id}`, formData);

          if (response.data) {
            Swal.fire(
              'Good job!',
              'Resource updated successfully.',
              'success'
            ).then(() => {
              setLoading(false);
              history.push(`${process.env.PUBLIC_URL}/resources`);
            });
          }
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          ).then(() => setLoading(false));
        }
      }
    },
    [fileSelected, history, isFile, resource.id, thumbnailId, thumbnailSelected]
  );

  const handleDelete = useCallback(async () => {
    await api.delete(`resources/${resource.id}`);
    history.push(`${process.env.PUBLIC_URL}/resources`);
  }, [history, resource.id]);

  return (
    <Container>
      <div className="container-fluid container-xxl px-0">
        <div className="row justify-content-center">
          <div className="col-12 p-0 mb-5">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-4">
                    <h1 className="h2">Resources</h1>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          <div className="col-12">
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              initialData={resource}
              className="row"
            >
              <div className="col-lg-6 order-1 order-lg-0">
                <InputsGroup className="p-4 h-100">
                  <h2 className="h5">Resources Information</h2>
                  <div className="py-3">
                    <label htmlFor="name">Name</label>
                    <Input name="name" className="py-3" id="name" />
                  </div>
                  <div className="py-3">
                    <label htmlFor="link">Link:</label>
                    <Input name="link" className="py-3" id="link" />
                  </div>
                  <div className="py-3">
                    <label htmlFor="file">Upload File</label>
                    <label htmlFor="file" className="upload-file d-flex">
                      <span className="mx-2 text-gray w-100">
                        {fileSelected ? (
                          fileSelected.name
                        ) : (
                          <div className="text-center py-5">
                            <img
                              src={attachment}
                              alt="attachment"
                              className="w-75 w-sm-50"
                            />
                            <p className="mb-0 h6 mt-5">
                              Upload your file here or
                            </p>
                            <p className="font-weight-bold underline">
                              Browse file
                            </p>
                          </div>
                        )}
                      </span>
                    </label>
                    <Input
                      type="file"
                      name="file"
                      id="file"
                      className="d-none"
                      onChange={handleSelectFile}
                    />
                  </div>
                  <div className="d-flex py-3">
                    <button
                      type="button"
                      className="btn-dark py-2 w-100 mr-1"
                      onClick={handleDelete}
                    >
                      <span className="d-block py-1">Delete</span>
                    </button>
                    <button type="submit" className="btn-grey py-2 w-100 ml-1">
                      <span className="d-block py-1">Save</span>
                    </button>
                  </div>
                </InputsGroup>
              </div>
              <div className="col-lg-6 height-lg order-0 order-lg-1 mb-5 mb-lg-0">
                <div className="w-100 h-100 mb-2">
                  <Thumbnail
                    src={thumbnail}
                    htmlFor="thumbnail"
                    className="btn bg-gray text-center w-100 h-100 p-0 p-relative"
                  >
                    <div className="p-absolute p-4 w-100 h-100 text-left">
                      <p className="h5 text-white">Thumbnail</p>
                    </div>
                    {!thumbnail && (
                      <div className="h-100 py-2 d-flex flex-column justify-content-center align-items-center">
                        <img
                          src={imgThumb}
                          alt="AddThumbnail"
                          className="w-25 mb-2"
                        />
                        <p className="mb-0">Upload your file here or</p>
                        <p className="underline">Browse file</p>
                      </div>
                    )}
                  </Thumbnail>
                  <Input
                    type="file"
                    id="thumbnail"
                    name="thumbnail"
                    className="d-none"
                    onChange={handleSelectThumbnail}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default ResourcesUpdate;
