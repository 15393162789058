import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GoFileSubmodule, GoPlus } from 'react-icons/go';

import api from '~/services/api';

import { Container, Modules } from './styles';
import CoursesActionTitle from '~/components/CoursesActionTitle';

interface IParams {
  slug: string;
}

interface IModulesResponse {
  id: string;
  title: string;
  slug: string;
  thumbnail: {
    thumbnail_url: string;
  };
}

interface IModulesParams {
  id: string;
  title: string;
  slug: string;
  thumbnail: string;
}

const ModulesList: React.FC = () => {
  const params = useParams<IParams>();
  const [courseId, setCourseId] = useState('');
  const [modules, setModules] = useState<IModulesParams[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    api.get(`courses/${params.slug}`).then((response) => {
      setCourseId(response.data.id);
      setLoading(false);
    });
  }, [params.slug]);

  useEffect(() => {
    if (courseId.length > 0) {
      api.get(`courses/modules/${courseId}`).then((response) => {
        const data = response.data.map((module: IModulesResponse) => ({
          id: module.id,
          title: module.title,
          slug: module.slug,
          thumbnail: module.thumbnail ? module.thumbnail.thumbnail_url : '',
        }));
        setModules(data);
      });
    }
  }, [courseId]);

  return (
    <Container className="h-100">
      <div className="container-fluid container-xxl bg-gray h-100">
        <div className="row justify-content-center">
          <div className="col-12 text-center p-0 mt-5 pt-4">
            <CoursesActionTitle
              boldTitle="Modules"
              description="Surprisingly, there is a very vocal faction of the design
              community that wants to see filler text banished the original
              sources his will just slow down the design process."
            />
          </div>
          <div className="col-12">
            <div className="row justify-content-center mb-3 my-5">
              <div className="col-11 col-lg-9 col-xl-8 col-xxl-7 overflow-auto pb-3 pb-md-0">
                <div className="d-flex ml-auto align-items-center min-width">
                  <Link
                    to={`${process.env.PUBLIC_URL}/courses/update/${params.slug}`}
                    className="w-100 text-center btn-grey step-module rounded-pill"
                  >
                    <span className="d-block px-3 py-3">Update Course</span>
                  </Link>
                  <hr className="w-25 w-md-50 border-gray mx-2" />
                  <Link
                    to={`${process.env.PUBLIC_URL}/courses/${params.slug}/lessons`}
                    className="w-100 text-center btn-grey step-module rounded-pill"
                  >
                    <span className="d-block px-3 py-3">Lessons</span>
                  </Link>
                  <hr className="w-25 w-md-50 border-gray mx-2" />
                  <div className="w-100 text-center btn-light-grey step-course rounded-pill">
                    <span className="d-block px-3 py-3">Modules</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-10">
            <Modules className="row">
              <Link
                to={`${process.env.PUBLIC_URL}/courses/${params.slug}/modules/register`}
                className="col-md-6 col-xl-4 my-2 mb-4 px-0 px-md-3"
              >
                <div className="bg-gray min-height h-md-100 d-flex justify-content-center align-items-center py-5 text-center">
                  <div className="d-flex justify-content-center align-items-center">
                    <GoPlus size={24} color="#6E6E6E" className="mr-2" />
                    <p className="h4 mb-0">Add Module</p>
                  </div>
                </div>
              </Link>
              {modules.map((module) => (
                <div
                  key={module.id}
                  className="col-md-6 col-xl-4 my-2 mb-4 px-0 px-md-3"
                >
                  <Link
                    to={`${process.env.PUBLIC_URL}/courses/${params.slug}/modules/update/${module.slug}`}
                    className="module-link d-block p-relative"
                  >
                    <div className="bg-gray py-4 text-center d-flex flex-column justify-content-center align-items-center">
                      <GoFileSubmodule size={63} color="#6E6E6E" />
                      <span className="small d-block mt-2 mb-4 px-3">
                        {module.title}
                      </span>
                      <p className="small mx-auto mb-0 py-2 pl-1 w-75">Go to</p>
                    </div>
                  </Link>
                </div>
              ))}
            </Modules>
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default ModulesList;
