import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { Modal } from 'react-bootstrap';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { Form } from '@unform/web';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Welcome, NameUserTable, OptionUser } from './styles';
import Table from '~/components/Table';
import Input from '~/components/Input';

import avatar from '~/assets/defaults/avatar-profile.png';

interface ITableData {
  positionID: string;
  userID: string;
  avatar: string;
  name: string;
  position: string;
}

interface ICourseUser {
  course: {
    title: string;
  };
}

interface IUser {
  id: string;
  created_at: string;
  referral_code: string;
  avatar: {
    avatar_url: string;
  };
  name: string;
  courseUser: ICourseUser[];
  email: string;
  phone: string;
}

interface IWaitlist {
  id: string;
  position: string;
  user: IUser;
}

const Waitlist: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [waitlist, setWaitlist] = useState<ITableData[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [waitListSelected, setWaitlistSelected] = useState({} as ITableData);
  const [show, setShow] = useState(false);

  const handleLoadWaitlist = useCallback(async () => {
    const response = await api.get('waitlist', {
      params: {
        filter,
      },
    });
    const data = response.data.map((waitlistItem: IWaitlist) => ({
      positionID: waitlistItem.id,
      userID: waitlistItem.user.id,
      avatar: waitlistItem.user.avatar?.avatar_url || avatar,
      name: waitlistItem.user.name || waitlistItem.user.email,
      position: waitlistItem.position,
    }));
    setWaitlist(data);
    setLoading(false);
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    handleLoadWaitlist();
  }, [filter, handleLoadWaitlist]);

  const handleClickEditPosition = useCallback((e) => {
    setWaitlistSelected(e);
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setWaitlistSelected({} as ITableData);
    setShow(false);
  }, []);

  const handleSearch = useCallback((value) => {
    setFilter(value);
  }, []);

  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        cell: (row: ITableData) => (
          <NameUserTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
          >
            <img src={row.avatar} alt={row.name} className="mr-2" />
            <p className="mb-0">{row.name}</p>
          </NameUserTable>
        ),
      },
      {
        name: 'Position',
        selector: 'position',
        sortable: true,
      },
      {
        name: '',
        selector: 'Options',
        sortable: true,
        cell: (row: ITableData) => (
          <OptionUser
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
            onClick={() => handleClickEditPosition(row)}
          >
            Edit position
          </OptionUser>
        ),
      },
    ],
    [handleClickEditPosition]
  );

  const handleSubmit = useCallback(
    async (formData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          position: Yup.string().required('New category is a required'),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        await api.put(`waitlist/${waitListSelected.positionID}`, {
          user_id: waitListSelected.userID,
          position: formData.position,
        });

        const newWaitlist = waitlist.slice();
        const positionIndex = newWaitlist.findIndex(
          (waitlistItem) =>
            waitlistItem.positionID === waitListSelected.positionID
        );

        if (positionIndex >= 0) {
          newWaitlist[positionIndex].position = formData.position;
        }

        setWaitlist(newWaitlist);
        handleLoadWaitlist();
        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          );
        }
      }
    },
    [
      handleClose,
      handleLoadWaitlist,
      waitListSelected.positionID,
      waitListSelected.userID,
      waitlist,
    ]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h4 h2-lg mb-4">Waitlist</h1>
                  </div>
                </div>
              </div>
            </Welcome>
            <Table
              data={waitlist}
              columns={columns}
              pagination
              date
              searchable
              onSearch={handleSearch}
              exportable
            />
          </div>
        </div>
      </div>
      <Modal className="modal-crowdfunding" show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Assign New Placement Sponsor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row justify-content-center">
              <div className="col-12">
                <label htmlFor="position">New Position</label>
                <Input id="position" name="position" />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="submit" className="btn-grey px-3 py-1">
              <span className="d-block py-1 px-2">Done</span>
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Waitlist;
