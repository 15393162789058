import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { format, parseISO } from 'date-fns';
import { Form } from '@unform/web';

import { IoClose } from 'react-icons/io5';
import api from '~/services/api';

import {
  Container,
  Welcome,
  NameUserTable,
  Modal,
  ToggleButton,
} from './styles';
import Table from '~/components/Table';
import Select from '~/components/Select';

interface ILeadResponse {
  id: string;
  avatar: {
    avatar_url: string;
  };
  name: string;
  created_at: string;
  has_referrer: boolean;
  referrer: {
    affiliate: {
      user: {
        name: string;
        foundersLeadsFounders: {
          id: string;
        }[];
      };
    };
  };
  foundersLeadsLead?: {
    id: string;
    founder_id: string;
  };
  orders: {
    product?: {
      name: string;
    };
  }[];
}

interface IFounderLeadResponse {
  lead_id: string;
  lead: ILeadResponse;
}

interface ILead {
  id: string;
  avatar: string;
  name: string;
}

interface IOption {
  id: string;
  avatar: string;
  value: string;
  selected: boolean;
  assigned_founder: boolean;
}

interface IFoundersLeads {
  id: string;
  lead_id: string;
}

interface IFounderResponse {
  id: string;
  name: string;
  affiliates_id: '1d294004-443e-4e71-a982-cff87f609050';
  created_at: string;
  path: '85b92974d1a44cac1d72-img0633.jpeg';
  leadsCount: string;
  position: string;
  avatar_url: string;
  has_qualified: boolean;
}

interface IResponseData {
  data: IFounderResponse[];
  total: number;
  pages: number;
}

interface IFounder {
  id: string;
  affiliate_id: string;
  avatar: string;
  name: string;
  queue_position: string;
  leads_received: string;
  leads: IFoundersLeads[];
  founder_date: string;
  has_qualified: boolean;
}

interface IOrderBy {
  column: string;
  direction: string;
}

const FoundersClubMembers: React.FC = () => {
  const [allLeads, setAllLeads] = useState<IOption[]>([]);
  const [leads, setLeads] = useState<IOption[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [leadsSelected, setLeadsSelected] = useState<ILead[]>([]);
  const [selectedFounder, setSelectedFounder] = useState({} as IFounder);
  const [showAssignLead, setShowAssignLead] = useState(false);
  const [founders, setFounders] = useState<IFounder[]>([]);
  const [page, setPage] = useState(1);
  const [orderByData, setOrderByData] = useState<IOrderBy | undefined>(
    undefined
  );
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const handleLoadLeads = useCallback(async () => {
    const response = await api.get<ILeadResponse[]>('users/leads', {
      params: {
        allLeads: true,
      },
    });

    const data = response.data.map<IOption>((lead) => ({
      id: lead.id,
      value: lead.name || '-',
      avatar: lead.avatar.avatar_url,
      selected: false,
      assigned_founder: !!lead.foundersLeadsLead,
    }));

    setAllLeads(data);
    setLoading(false);
  }, []);

  const handleLoadFounders = useCallback(
    async (pageData, orderBy, searchData) => {
      try {
        const response = await api.get<IResponseData>('users/founders', {
          params: {
            showAll: true,
            page: pageData,
            orderBy,
            search: searchData,
          },
        });

        const data = response.data.data.map<IFounder>((founder) => ({
          id: founder.id,
          affiliate_id: founder.affiliates_id,
          name: founder.name,
          avatar: founder.avatar_url,
          queue_position: founder.position.padStart(3, '0'),
          leads_received: founder.leadsCount.padStart(2, '0'),
          leads: [],
          founder_date: format(parseISO(founder.created_at), 'yyyy-MM-dd'),
          has_qualified: founder.has_qualified,
        }));
        setFounders(data);
        setTotal(response.data.total);
        setTotalPages(response.data.pages);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    handleLoadLeads();
    handleLoadFounders(1, undefined, '');
  }, [handleLoadFounders, handleLoadLeads]);

  const handleClose = useCallback(() => {
    setSelectedFounder({} as IFounder);
    setShowAssignLead(false);
  }, []);

  const handleClickAssignLead = useCallback(
    async (founder: IFounder) => {
      const response = await api.get<IFounderLeadResponse[]>(
        `founders-leads/leads/${founder.id}`
      );

      const leadsData = allLeads
        .filter((lead) => {
          return response.data.find(
            (founderLead) => founderLead.lead_id === lead.id
          );
        })
        .map<ILead>((lead) => ({
          id: lead.id,
          name: lead.value,
          avatar: lead.avatar,
        }));

      const newLeads = allLeads.filter((lead) => !lead.assigned_founder);

      setLeads(newLeads);
      setLeadsSelected(leadsData);
      setSelectedFounder(founder);
      setShowAssignLead(true);
    },
    [allLeads]
  );

  const handleClickQualified = useCallback(
    async (founder: IFounder) => {
      const newFounders = founders.slice();
      const founderIndex = newFounders.findIndex(
        (founderData) => founderData.id === founder.id
      );

      await api.put(`affiliates/${founder.affiliate_id}`, {
        has_qualified: !founder.has_qualified,
      });

      if (founderIndex >= 0) {
        newFounders[founderIndex].has_qualified = !founder.has_qualified;
      }

      setFounders(newFounders);
    },
    [founders]
  );

  const columns = useMemo(
    () => [
      {
        name: 'Founder Date',
        selector: 'founder_date',
        sortable: true,
      },
      {
        name: 'Founder Name',
        selector: 'member',
        sortable: true,
        cell: (row: IFounder) => (
          <NameUserTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
          >
            <img src={row.avatar} alt={row.name} className="mr-2" />
            <div>
              <p className="mb-0">{row.name}</p>
            </div>
          </NameUserTable>
        ),
      },
      {
        name: 'Queue Position',
        selector: 'queue_position',
        sortable: true,
      },
      {
        name: 'Total Leads Received',
        selector: 'leads_received',
        sortable: true,
      },
      {
        name: 'Qualified',
        selector: 'has_qualified',
        sortable: true,
        cell: (row: IFounder) => (
          <ToggleButton
            type="button"
            onClick={() => handleClickQualified(row)}
            className="btn-qualified rounded-pill"
            active={row.has_qualified}
          />
        ),
      },
      {
        name: '',
        selector: 'id',
        sortable: true,
        cell: (row: IFounder) => (
          <button
            type="button"
            onClick={() => handleClickAssignLead(row)}
            className="btn-assign rounded-pill bg-transparent"
          >
            Assign Lead
          </button>
        ),
      },
    ],
    [handleClickAssignLead, handleClickQualified]
  );

  const handleChangeLead = useCallback(
    (e) => {
      const lead = leads.find((leadData) => e.id === leadData.id);
      if (lead) {
        setLeadsSelected((state) => [
          ...state,
          {
            id: lead.id,
            name: lead.value,
            avatar: lead.avatar,
          },
        ]);
        const newLeads = leads.filter((leadData) => leadData !== lead);
        setLeads(newLeads);
      }
    },
    [leads]
  );

  const handleDone = useCallback(async () => {
    setLoading(true);
    try {
      const newAllLeads = allLeads.slice();

      const removedLeads = selectedFounder.leads.filter(
        (founderLead) =>
          !leadsSelected.find((lead) => lead.id === founderLead.lead_id)
      );

      if (removedLeads.length > 0) {
        await new Promise<void>((resolve) => {
          removedLeads.forEach(async (founderLead, index) => {
            await api.delete(`users/leads/${founderLead.id}`);

            const leadIndex = newAllLeads.findIndex(
              (leadData) => leadData.id === founderLead.lead_id
            );

            if (leadIndex >= 0) {
              newAllLeads[leadIndex].assigned_founder = false;
            }

            if (removedLeads.length === index + 1) {
              resolve();
            }
          });
        });
      }

      const newLeadsSelected = leadsSelected.filter(
        (lead) =>
          !selectedFounder.leads.find(
            (founderLead) => lead.id === founderLead.lead_id
          )
      );

      if (newLeadsSelected.length > 0) {
        await new Promise<void>((resolve) => {
          newLeadsSelected.forEach(async (lead, index) => {
            await api.post(`users/leads`, {
              lead_id: lead.id,
              founder_id: selectedFounder.id,
            });

            const leadIndex = newAllLeads.findIndex(
              (leadData) => leadData.id === lead.id
            );

            if (leadIndex >= 0) {
              newAllLeads[leadIndex].assigned_founder = true;
            }

            if (newLeadsSelected.length === index + 1) {
              resolve();
            }
          });
        });
      }

      const newFounders = founders.slice();
      const founderIndex = newFounders.findIndex(
        (founder) => founder.id === selectedFounder.id
      );
      if (founderIndex >= 0) {
        newFounders[
          founderIndex
        ].leads_received = leadsSelected.length.toString().padStart(2, '0');
      }

      setFounders(newFounders);
      setAllLeads(newAllLeads);
      handleClose();
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }, [
    allLeads,
    founders,
    handleClose,
    leadsSelected,
    selectedFounder.id,
    selectedFounder.leads,
  ]);

  const handleClickRemoveLead = useCallback(
    (lead_id) => {
      const lead = leadsSelected.find((leadData) => leadData.id === lead_id);
      const newleadsSelected = leadsSelected.filter(
        (leadData) => leadData.id !== lead_id
      );
      if (lead) {
        setLeads((state) => [
          ...state,
          {
            id: lead.name,
            value: lead.name,
            avatar: lead.avatar,
            selected: false,
            assigned_founder: false,
          },
        ]);
      }
      setLeadsSelected(newleadsSelected);
    },
    [leadsSelected]
  );

  const handleSearch = useCallback(
    async (value) => {
      setLoading(true);
      await handleLoadFounders(1, orderByData, value);
      setFilter(value);
    },
    [handleLoadFounders, orderByData]
  );

  const handleChangePage = useCallback(
    async (pageSelected) => {
      setLoading(true);
      await handleLoadFounders(pageSelected, orderByData, filter);
      setPage(pageSelected);
    },
    [filter, handleLoadFounders, orderByData]
  );

  const handleSort = useCallback(
    async (column, direction) => {
      setLoading(true);

      let orderBy: IOrderBy | undefined;

      switch (column.selector) {
        case 'founder_date':
          orderBy = {
            column: 'affiliatesOffers.created_at',
            direction: direction.toUpperCase(),
          };

          break;
        case 'member':
          orderBy = {
            column: 'users.name',
            direction: direction.toUpperCase(),
          };

          break;
        case 'queue_position':
          orderBy = {
            column: '"position"',
            direction: direction.toUpperCase(),
          };

          break;
        case 'leads_received':
          orderBy = {
            column: '"leadsCountSubquery"."leadsCount"',
            direction: direction.toUpperCase(),
          };

          break;
        case 'has_qualified':
          orderBy = {
            column: 'affiliates.has_qualified',
            direction: direction.toUpperCase(),
          };

          break;

        default:
          orderBy = undefined;
          break;
      }

      console.log(orderBy);

      await handleLoadFounders(page, orderBy, filter);

      setOrderByData(orderBy);
    },
    [filter, handleLoadFounders, page]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 mb-4 d-flex align-items-center justify-content-between">
                    <h1 className="h4 h2-lg">Founders Club Members</h1>
                  </div>
                </div>
              </div>
            </Welcome>

            <Table
              data={founders}
              columns={columns}
              pagination
              date
              searchable
              onSearch={handleSearch}
              exportable
              onChangePage={handleChangePage}
              onSort={handleSort}
              total={total}
              pageSelected={page}
              pages={totalPages}
            />
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        className="modal-crowdfunding"
        show={showAssignLead}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign Lead</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-12">
              <Form
                onSubmit={() => {
                  console.log('submit');
                }}
              >
                <label htmlFor="member">Lead</label>
                <Select
                  id="lead"
                  name="lead"
                  options={leads}
                  onChange={handleChangeLead}
                />
              </Form>
            </div>
            {leadsSelected.length > 0 ? (
              <>
                {leadsSelected.map((lead) => (
                  <div key={lead.id} className="col-lg-3 mt-5">
                    <div className="box h-100 p-3 d-flex flex-column align-items-center">
                      <button
                        type="button"
                        className="btn-delete border-0 bg-transparent"
                        onClick={() => handleClickRemoveLead(lead.id)}
                      >
                        <IoClose size={24} color="#8c8c8c" />
                      </button>
                      <img
                        src={lead.avatar}
                        alt={lead.name}
                        className="w-75 rounded-circle mb-3"
                      />{' '}
                      <p className="text-center">{lead.name || '-'}</p>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              ''
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={handleDone}
            className="btn-grey px-3 py-1"
          >
            <span className="d-block py-1 px-2">Done</span>
          </button>
        </Modal.Footer>
      </Modal>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default FoundersClubMembers;
