import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormHandles } from '@unform/core';

import { FiArrowLeft } from 'react-icons/fi';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Welcome, InputsGroup, PercentBar } from './styles';
import Input from '~/components/Input';
import InputCategory, { IValue } from '~/components/InputCategory';
import Player from '~/components/Player';

import videoThumb from '~/assets/icons/video_thumb.svg';
import imgThumb from '~/assets/icons/img_thumb.svg';
import uploadVideo from '~/utils/uploadVideo';

interface ITraining {
  title: string;
  description: string;
}

interface ICategory {
  id: string;
  name: string;
}

interface ITrainingCategory {
  category: ICategory;
  deleted_at: string;
}

interface IParams {
  slug: string;
}

const TrainingUpdate: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [training, setTraining] = useState<ITraining>({} as ITraining);
  const [thumbnail, setThumbnail] = useState('');
  const [uiThumbnail, setUiThumbnail] = useState('');
  const [banner, setBanner] = useState('');
  const [thumbnailSelected, setThumbnailSelected] = useState<File | null>(null);
  const [uiThumbnailSelected, setUiThumbnailSelected] = useState<File | null>(
    null
  );
  const [bannerSelected, setBannerSelected] = useState<File | null>(null);
  const [video, setVideo] = useState('');
  const [videoSelected, setVideoSelected] = useState<File | null>(null);
  const [status, setStatus] = useState('');
  const [trainingId, setTrainingId] = useState('');
  const [thumbnailId, setThumbnailId] = useState('');
  const [uiThumbnailId, setUiThumbnailId] = useState('');
  const [bannerId, setBannerId] = useState('');
  const [videoId, setVideoId] = useState('');
  const [body, setBody] = useState('');
  const [loading, setLoading] = useState(false);
  const [categoriesData, setCategoriesData] = useState<IValue[]>([]);
  const [categoriesDeleted, setCategoriesDeleted] = useState<IValue[]>([]);
  const [percent, setPercent] = useState(0);
  const [showPercent, setShowPercent] = useState(false);
  const [btnYesNo, setBtnYesNo] = useState(false);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);

    api.get(`trainings/${params.slug}`).then((response) => {
      const data = {
        title: response.data.title,
        description: response.data.description,
      };

      if (
        response.data.trainingCategory &&
        response.data.trainingCategory.length > 0
      ) {
        const coursesCategories = response.data.trainingCategory.filter(
          (trainingCategory: ITrainingCategory) => !trainingCategory.deleted_at
        );
        const dataCategories: IValue[] = coursesCategories.map(
          (trainingCategory: ITrainingCategory) => ({
            id: trainingCategory.category.id,
            value: trainingCategory.category.name,
          })
        );
        setCategoriesData(dataCategories);
      }

      setTrainingId(response.data.id);
      setThumbnailId(response.data.thumbnail.id);
      setThumbnail(response.data.thumbnail.thumbnail_url);
      if (response.data.uiThumbnail) {
        setUiThumbnailId(response.data.uiThumbnail.id);
        setUiThumbnail(response.data.uiThumbnail.thumbnail_url);
      }
      if (response.data.banner) {
        setBannerId(response.data.banner.id);
        setBanner(response.data.banner.thumbnail_url);
        setBtnYesNo(Object.keys(response.data.banner).length > 0);
      }
      setVideoId(response.data.video.id);
      setBody(response.data.description);
      setVideo(response.data.video.video_url);
      setTraining(data);
      setLoading(false);
    });
  }, [params.slug]);

  const handleSelectCategories = useCallback((data) => {
    setCategoriesData(data);
  }, []);

  const handleRemoveCategory = useCallback((data) => {
    if (data.id) {
      setCategoriesDeleted((state) => [...state, data]);
    }
  }, []);

  const handleChangeBody = useCallback((_, editor) => {
    setBody(editor.getData());
  }, []);

  const handleSelectThumbnail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        if (file) {
          setThumbnail(URL.createObjectURL(file));
          setThumbnailSelected(file);
        } else {
          setThumbnail('');
          setThumbnailSelected(null);
        }
      }
    },
    []
  );

  const handleSelectUiThumbnail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        if (file) {
          setUiThumbnail(URL.createObjectURL(file));
          setUiThumbnailSelected(file);
        } else {
          setUiThumbnail('');
          setUiThumbnailSelected(null);
        }
      }
    },
    []
  );

  const handleSelectBanner = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        setBanner(URL.createObjectURL(file));
        setBannerSelected(file);
      } else {
        setBanner('');
        setBannerSelected(null);
      }
    }
  }, []);

  const handleSelectVideo = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        setVideo(URL.createObjectURL(file));
        setVideoSelected(file);
      } else {
        setVideo('');
        setVideoSelected(null);
      }
    }
  }, []);

  const handleRemoveVideo = useCallback(() => {
    setVideo('');
    setVideoSelected(null);
  }, []);

  const handleSubmit = useCallback(
    async (data: ITraining) => {
      try {
        setStatus('validating data!');
        setLoading(true);
        const schema = Yup.object().shape({
          title: Yup.string().required('Title is a required'),
          description: Yup.string().when('$descriptionCheck', {
            is: (descriptionCheck: boolean) => descriptionCheck,
            then: Yup.string(),
            otherwise: Yup.string().required(
              'Training Description is a required'
            ),
          }),
          categories: Yup.string().when('$categoryFilled', {
            is: (categoryFilled: boolean) => categoryFilled,
            then: Yup.string(),
            otherwise: Yup.string().required('Categories is a required'),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            descriptionCheck: !!body,
            categoryFilled: categoriesData.length > 0,
          },
        });

        if (categoriesDeleted.length > 0) {
          categoriesDeleted.forEach(async (category) => {
            const trainingsCategoriesResponse = await api.get(
              `trainings-categories/${trainingId}/${category.id}`
            );

            if (trainingsCategoriesResponse.data) {
              await api.delete(
                `trainings-categories/${trainingsCategoriesResponse.data.id}`
              );
            }
          });
        }

        let responseThumbnails;
        if (thumbnailSelected) {
          setStatus('uploading thumbnail!');
          const thumbnailData = new FormData();
          thumbnailData.append('thumbnail', thumbnailSelected as File);
          responseThumbnails = await api.post('thumbnails', thumbnailData);
        } else {
          responseThumbnails = {
            data: {
              id: thumbnailId,
            },
          };
        }

        let responseUiThumbnail;
        if (uiThumbnailSelected) {
          const uiThumbnailData = new FormData();
          uiThumbnailData.append('thumbnail', uiThumbnailSelected);
          responseUiThumbnail = await api.post('thumbnails', uiThumbnailData);
        } else if (uiThumbnailId) {
          responseUiThumbnail = {
            data: {
              id: uiThumbnailId,
            },
          };
        }

        let responseBanner;
        if (bannerSelected) {
          const bannerData = new FormData();
          bannerData.append('thumbnail', bannerSelected as File);
          responseBanner = await api.post('thumbnails', bannerData);
        } else if (bannerId) {
          responseBanner = {
            data: {
              id: bannerId,
            },
          };
        }

        if (responseThumbnails.data) {
          let responseVideo;
          if (videoSelected) {
            setStatus('uploading video!');
            setPercent(0);
            setShowPercent(true);
            responseVideo = await uploadVideo({
              course_title: data.title,
              file: videoSelected,
              duration: '00:00:00',
              setPercent,
              setStatus,
            });
          } else {
            responseVideo = {
              data: {
                id: videoId,
              },
            };
          }

          if (responseVideo.data) {
            setStatus('updating training!');
            const { title } = data;

            const slug = title
              .replace(/[àáâãäå]/g, 'a')
              .replace(/æ/g, 'ae')
              .replace(/ç/g, 'c')
              .replace(/[èéêë]/g, 'e')
              .replace(/[ìíîï]/g, 'i')
              .replace(/ñ/g, 'n')
              .replace(/[òóôõö]/g, 'o')
              .replace(/œ/g, 'oe')
              .replace(/[ùúûü]/g, 'u')
              .replace(/[ýÿ]/g, 'y')
              .replace(/[^a-zA-Z0-9 -]/g, '')
              .replace(/ /g, '-')
              .toLowerCase();

            const formData = {
              thumbnail_id: responseThumbnails.data.id,
              ui_thumbnail_id: responseUiThumbnail?.data.id,
              banner_id: responseBanner?.data.id,
              video_id: responseVideo.data.id,
              title,
              description: body,
              slug,
            };

            const response = await api.put(`trainings/${trainingId}`, formData);

            if (response.data) {
              const categoriesPromise = new Promise<void>((resolve) => {
                if (categoriesData.length > 0) {
                  const lastIndex = categoriesData.length - 1;
                  categoriesData.forEach((category, index) => {
                    api
                      .get(
                        `trainings-categories/${response.data.id}/${category.id}`
                      )
                      .catch(async () => {
                        const trainingsCategoriesFormData = {
                          training_id: response.data.id,
                          category_id: category.id,
                        };

                        await api.post(
                          'trainings-categories',
                          trainingsCategoriesFormData
                        );

                        if (lastIndex === index) {
                          resolve();
                        }
                      });

                    if (lastIndex === index) {
                      resolve();
                    }
                  });
                } else {
                  resolve();
                }
              });

              await categoriesPromise;

              Swal.fire(
                'Good job!',
                'Training updated successfully.',
                'success'
              ).then(() => {
                setLoading(false);
                history.push(`${process.env.PUBLIC_URL}/trainings`);
              });
            }
          }
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          ).then(() => setLoading(false));
        }
      }
    },
    [
      bannerId,
      bannerSelected,
      body,
      categoriesData,
      categoriesDeleted,
      history,
      thumbnailId,
      thumbnailSelected,
      trainingId,
      uiThumbnailId,
      uiThumbnailSelected,
      videoId,
      videoSelected,
    ]
  );

  const handleChangeBannerDisplay = useCallback((state) => {
    if (!state) {
      setBannerId('');
      setBanner('');
    }
    setBtnYesNo(state);
  }, []);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row justify-content-center">
          <div className="col-12 p-0 mb-4">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="d-flex pl-3 align-items-center">
                    <Link
                      to={`${process.env.PUBLIC_URL}/trainings`}
                      className="mr-4"
                    >
                      <FiArrowLeft size={33} />
                    </Link>
                    <h1 className="h2 mb-0">Training</h1>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          <div className="col-12">
            <Form
              ref={formRef}
              initialData={training}
              onSubmit={handleSubmit}
              className="row"
            >
              <div className="col-lg px-lg-0">
                <div className="w-100 pb-4 pb-lg-3">
                  {video ? (
                    <div className="min-height">
                      <Player src={video} />
                      <button
                        type="button"
                        onClick={handleRemoveVideo}
                        className="btn-remove-video mt-2 p-absolute"
                      >
                        <span className="d-block py-1 px-2">X</span>
                      </button>
                    </div>
                  ) : (
                    <label
                      htmlFor="video"
                      className="btn bg-gray text-center w-100 h-100 p-0 mb-0 d-flex flex-column justify-content-center"
                    >
                      <div className="py-4 h-100 row align-items-between">
                        <div className="col-12">
                          <h3 className="h5 text-center text-sm-left pl-sm-4">
                            Video
                          </h3>
                        </div>
                        <div className="col-12 my-4 my-lg-0">
                          <img
                            src={videoThumb}
                            alt="AddThumbnail"
                            className="my-4"
                          />
                        </div>
                        <div className="col-12">
                          <p className="h6 px-4">
                            Upload your <span>Video</span> file here or{' '}
                            <span>Browse file</span>
                          </p>
                        </div>
                      </div>
                    </label>
                  )}
                  <Input
                    type="file"
                    id="video"
                    name="video"
                    className="d-none"
                    onChange={handleSelectVideo}
                  />
                </div>
                <div className="w-100 pb-4 pb-lg-3">
                  <label
                    htmlFor="thumbnail"
                    className="btn bg-gray text-center w-100 h-lg-100 p-0 mb-0"
                  >
                    {thumbnail ? (
                      <img
                        src={thumbnail}
                        alt="Thumbnail"
                        className="min-height w-100"
                      />
                    ) : (
                      <div className="py-4 h-100 row align-content-between">
                        <div className="col-12">
                          <h3 className="h5 text-center text-sm-left pl-sm-4">
                            Video Thumbnail
                          </h3>
                        </div>
                        <div className="col-12">
                          <img
                            src={imgThumb}
                            alt="AddThumbnail"
                            className="my-4"
                          />
                        </div>
                        <div className="col-12">
                          <p className="h6 px-4">
                            Upload your <span>1020w x 575h</span> file here or{' '}
                            <span>Browse file</span>
                          </p>
                        </div>
                      </div>
                    )}
                  </label>
                  <Input
                    type="file"
                    id="thumbnail"
                    name="thumbnail"
                    className="d-none"
                    onChange={handleSelectThumbnail}
                  />
                </div>
                <div className="w-100 pb-4 pb-lg-0">
                  <label
                    htmlFor="ui_thumbnail"
                    className="btn bg-gray text-center w-100 h-lg-100 p-0 mb-0"
                  >
                    {uiThumbnail ? (
                      <img
                        src={uiThumbnail}
                        alt="Thumbnail"
                        className="w-100 min-height"
                      />
                    ) : (
                      <div className="py-4 h-100 row align-content-between">
                        <div className="col-12">
                          <h3 className="h5 text-center text-sm-left pl-sm-4">
                            UI Thumbnail
                          </h3>
                        </div>
                        <div className="col-12">
                          <img
                            src={imgThumb}
                            alt="AddThumbnail"
                            className="my-4"
                          />
                        </div>
                        <div className="col-12">
                          <p className="h6 px-4">
                            Upload your <span>360w x 340h</span> file here or{' '}
                            <span>Browse file</span>
                          </p>
                        </div>
                      </div>
                    )}
                  </label>
                  <Input
                    type="file"
                    id="ui_thumbnail"
                    name="ui_thumbnail"
                    className="d-none"
                    onChange={handleSelectUiThumbnail}
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <InputsGroup className="px-4 px-sm-5 py-4 h-100">
                  <h2 className="h5">Training Information</h2>
                  <div className="pb-3 pt-5 input">
                    <label htmlFor="title" className="font-weight-400">
                      Title
                    </label>
                    <Input name="title" id="title" />
                  </div>
                  <div className="py-3 input">
                    <label htmlFor="categories" className="font-weight-400">
                      Category
                    </label>
                    <InputCategory
                      type="trainings"
                      onSelect={handleSelectCategories}
                      onRemove={handleRemoveCategory}
                      className="py-1"
                      valuesSelected={categoriesData}
                    />
                  </div>
                  <div className="pb-3 mt-3 text-area">
                    <label htmlFor="description" className="font-weight-400">
                      Description
                    </label>

                    <CKEditor
                      name="description"
                      id="description"
                      editor={ClassicEditor}
                      data={body}
                      onChange={handleChangeBody}
                    />
                  </div>
                  <div className="d-flex py-3 py-lg-0 mt-4 mb-0">
                    <button
                      type="submit"
                      className="font-weight-bold btn-grey py-3 w-100"
                    >
                      Save
                    </button>
                  </div>
                </InputsGroup>
              </div>
              <div className="col-lg px-lg-0 mt-4 mt-lg-0">
                <h4>Banner Display</h4>
                <div className="btns mb-5 d-flex justify-content-between">
                  <button
                    type="button"
                    onClick={() => handleChangeBannerDisplay(true)}
                    className={`${btnYesNo && 'active'}`}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    onClick={() => handleChangeBannerDisplay(false)}
                    className={`${!btnYesNo && 'active'}`}
                  >
                    No
                  </button>
                </div>
                <div
                  className={`${
                    !btnYesNo && 'cursor-not-allowed'
                  } w-100 pb-4 pb-lg-0`}
                >
                  <label
                    htmlFor="banner"
                    className={`${
                      btnYesNo ? 'opacity-100' : 'opacity-25 pe-none'
                    } btn bg-gray text-center w-100 h-lg-100 p-0`}
                  >
                    {banner ? (
                      <img
                        src={banner}
                        alt="Thumbnail"
                        className="min-height w-100"
                      />
                    ) : (
                      <div className="py-4 h-100 row align-content-between">
                        <div className="col-12">
                          <h3 className="h4 text-center text-sm-left pl-sm-4">
                            Banner
                          </h3>
                        </div>
                        <div className="col-12">
                          <img
                            src={imgThumb}
                            alt="AddThumbnail"
                            className="my-4"
                          />
                        </div>
                        <div className="col-12">
                          <p className="h6 px-4">
                            Upload your <span>1125w x 380h</span> file here or{' '}
                            <span>Browse file</span>
                          </p>
                        </div>
                      </div>
                    )}
                  </label>
                  <Input
                    type="file"
                    id="banner"
                    name="banner"
                    className="d-none"
                    onChange={handleSelectBanner}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p className="font-weight-bold text-white mt-3">{status}</p>
            {showPercent && <PercentBar percent={percent} />}
          </div>
        </>
      )}
    </Container>
  );
};

export default TrainingUpdate;
