import styled from 'styled-components';
import { shade, lighten } from 'polished';

interface IBgImageProps {
  src: string;
}

export const Container = styled.div`
  .thumbnail-course {
    width: 100%;
    height: 200px;
    border-radius: 20px;
  }

  .infos-course {
    transition-duration: 0.3s;
    z-index: 1;
    width: 100%;
    height: 50px;
    top: calc(100% - 49px);

    .bg-image {
      transition-duration: 0.2s;
      opacity: 0;
      border-radius: 0px 0px 20px 20px;
      mask-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(0, 0, 0, 1)),
        to(rgba(0, 0, 0, 0))
      );
    }

    .infos {
      border-radius: 0px 0px 20px 20px;
      background: linear-gradient(
        188.52deg,
        rgba(1, 1, 1, 0.192) -0.55%,
        rgba(7, 7, 7, 0.8) 95.76%
      );
      padding: 15px 10px;
      overflow: hidden;
    }

    h2 {
      color: #e4e4e4;
    }

    p {
      max-height: 65px;
      overflow-x: auto;
    }
  }

  .infos-course:hover {
    height: 300px;
    z-index: 500;

    .bg-image {
      opacity: 1;
      border-radius: 20px;
    }

    .infos {
      border-radius: 20px;
      backdrop-filter: blur(35px);
    }
  }

  .btn-edit {
    background: #242526;
    border: 1px solid #4a4b4b;
    color: #8c8c8c;

    :hover {
      text-decoration: none;
    }
  }
`;

export const BoxOthers = styled.div<IBgImageProps>`
  border-radius: 20px;
  text-decoration: none;
  height: 14.5vw;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .see-more-link {
    a {
      color: rgba(203, 204, 206, 1);
      text-decoration: none;
      border-radius: 20px;
      :hover {
        color: ${lighten(0.1, 'rgba(203, 204, 206, 1)')};
        img {
          filter: brightness(2.5);
        }
      }
    }
  }
  .short-title {
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 20px 20px 20px 20px;
    height: 100%;

    p {
      color: #e4e4e4 !important;
    }
  }

  @media screen and (min-width: 1800px) {
    height: 238px;

    .align-center-1800 {
      align-items: center;
    }

    .h4-1800 {
      font-size: 1.5rem !important;
    }
  }

  @media screen and (min-width: 2200px) {
    height: 300px;
  }

  @media screen and (max-width: 991px) {
    height: 27vw;
  }

  @media screen and (max-width: 768px) {
    height: 40vw;
  }

  @media screen and (max-width: 575px) {
    height: 50vw;
  }
`;

export const Calendar = styled.aside`
  width: 380px;

  .DayPicker {
    border-radius: 10px;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
    border-radius: 10px;
    border: 1px solid #dadada;
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-NavButton {
    color: #999591 !important;
  }

  .DayPicker-NavButton--prev {
    right: auto;
    left: 1.5em;
    margin-right: 0;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px 0 0 0;
    padding: 16px;
    border-radius: 0 0 10px 10px;
  }

  .DayPicker-Caption {
    margin-bottom: -10px;
    padding: 0 1em 1em;
    color: #dadada;
    border-bottom: 1px solid #dadada;

    > div {
      text-align: center;
    }
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: transparent;
    border-radius: 50%;
    color: #fff;
    border: 1px solid #dadada;
  }

  .DayPicker-Day--outside {
    background: transparent;
    border-radius: 50%;
    color: #565656;
    border: 1px solid #565656;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: ${shade(0.2, '#3e3b47')};
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: #666360 !important;
    background: transparent !important;
  }

  .DayPicker-Day--selected {
    border-color: #dadada !important;
    background: #dadada !important;
    color: #000000 !important;
    font-weight: bold;
  }
`;
