/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import GraphSalesFunnel from '~/components/GraphSalesFunnel';
import { Container } from './styles';

import members from '~/assets/icons/members-icon.svg';
import adspend from '~/assets/icons/adspend-icon.svg';
import colombia from '~/assets/icons/flag-colombia.svg';
import usa from '~/assets/icons/flag-usa.svg';
import brazil from '~/assets/icons/flag-brazil.svg';
import argentina from '~/assets/icons/flag-argentina.svg';
import generic from '~/assets/icons/generic-flag.svg';
import api from '~/services/api';
import mask from '~/utils/mask';

interface IFunnelTrackAllData {
  quantity: number;
  country: string;
}

interface IFunnelTrack {
  page: string;
  quantity: string;
  origin: string;
  action: string;
}

interface IFunnel {
  labels: string[];
  values: number[][];
}

interface ICountry {
  name: string;
  flag: string;
  quantity: number;
}

interface IOrder {
  id: string;
  amount_paid: string;
}

const Traffic: React.FC = () => {
  const [funnelUpsell, setFunnelUpsell] = useState<IFunnel>({
    labels: [
      'First Contact',
      'Upsell',
      'Confirm Order',
      'Elite Affiliate Status',
    ],
    values: [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
    ],
  });
  const [funnelDownsell, setFunnelDownsell] = useState<IFunnel>({
    labels: [
      'First Contact',
      'Downsell',
      'Confirm Order',
      'Elite Affiliate Status',
    ],
    values: [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
    ],
  });
  const [conversions, setConversions] = useState(0);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [totalSales, setTotalSales] = useState('0');

  useEffect(() => {
    api
      .get<IFunnelTrack[]>('funnels-tracks', {
        params: {
          page: 'sales-funnel/view-offer-2',
        },
      })
      .then(async (response) => {
        const funnelDataUpsell: IFunnel = {
          labels: [
            'First Contact',
            'Upsell',
            'Confirm Order',
            'Elite Affiliate Status',
          ],
          values: [
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0],
          ],
        };
        const funnelDataDownsell: IFunnel = {
          labels: [
            'First Contact',
            'Downsell',
            'Confirm Order',
            'Elite Affiliate Status',
          ],
          values: [
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0],
          ],
        };

        const upgradeOrder = response.data.filter(
          (funnelTrack) => funnelTrack.action === 'upgrade-order'
        );
        const closeUpgradeOrder = response.data.filter(
          (funnelTrack) => funnelTrack.action === 'close-upgrade-order'
        );
        const upgradeOrderPaid = response.data.filter(
          (funnelTrack) => funnelTrack.action === 'upgrade-order-paid'
        );

        upgradeOrder.forEach((funnelTrack) => {
          if (funnelTrack.origin === 'direct') {
            const closeUpgradeOrderDirect = closeUpgradeOrder.find(
              (upgradeOrderData) => upgradeOrderData.origin === 'direct'
            );
            const upgradeOrderPaidDirect = upgradeOrderPaid.find(
              (upgradeOrderData) => upgradeOrderData.origin === 'direct'
            );

            const closeUpgradeOrderQuantity = closeUpgradeOrderDirect
              ? parseInt(closeUpgradeOrderDirect.quantity, 10)
              : 0;
            const upgradeOrderPaidQuantity = upgradeOrderPaidDirect
              ? parseInt(upgradeOrderPaidDirect.quantity, 10)
              : 0;

            if (closeUpgradeOrderQuantity > 0) {
              funnelDataUpsell.values[1][0] =
                parseInt(funnelTrack.quantity, 10) -
                closeUpgradeOrderQuantity +
                upgradeOrderPaidQuantity;
            } else {
              funnelDataUpsell.values[1][0] = parseInt(
                funnelTrack.quantity,
                10
              );
            }
          } else if (funnelTrack.origin === 'social media') {
            const closeUpgradeOrderSocialMedia = closeUpgradeOrder.find(
              (upgradeOrderData) => upgradeOrderData.origin === 'social media'
            );
            const upgradeOrderPaidSocialMedia = upgradeOrderPaid.find(
              (upgradeOrderData) => upgradeOrderData.origin === 'social media'
            );

            const closeUpgradeOrderQuantity = closeUpgradeOrderSocialMedia
              ? parseInt(closeUpgradeOrderSocialMedia.quantity, 10)
              : 0;
            const upgradeOrderPaidQuantity = upgradeOrderPaidSocialMedia
              ? parseInt(upgradeOrderPaidSocialMedia.quantity, 10)
              : 0;
            if (closeUpgradeOrderQuantity > 0) {
              funnelDataUpsell.values[1][1] =
                parseInt(funnelTrack.quantity, 10) -
                closeUpgradeOrderQuantity +
                upgradeOrderPaidQuantity;
            } else {
              funnelDataUpsell.values[1][1] = parseInt(
                funnelTrack.quantity,
                10
              );
            }
          } else if (funnelTrack.origin === 'ads') {
            const closeUpgradeOrderAds = closeUpgradeOrder.find(
              (upgradeOrderData) => upgradeOrderData.origin === 'ads'
            );
            const upgradeOrderPaidAds = upgradeOrderPaid.find(
              (upgradeOrderData) => upgradeOrderData.origin === 'ads'
            );

            const closeUpgradeOrderQuantity = closeUpgradeOrderAds
              ? parseInt(closeUpgradeOrderAds.quantity, 10)
              : 0;
            const upgradeOrderPaidQuantity = upgradeOrderPaidAds
              ? parseInt(upgradeOrderPaidAds.quantity, 10)
              : 0;
            if (closeUpgradeOrderQuantity > 0) {
              funnelDataUpsell.values[1][2] =
                parseInt(funnelTrack.quantity, 10) -
                closeUpgradeOrderQuantity +
                upgradeOrderPaidQuantity;
            } else {
              funnelDataUpsell.values[1][2] = parseInt(
                funnelTrack.quantity,
                10
              );
            }
          }
        });

        const downsellOrder = response.data.filter(
          (funnelTrack) => funnelTrack.action === 'downsell-order'
        );
        const downsellOrderRefused = response.data.filter(
          (funnelTrack) => funnelTrack.action === 'downsell-order-refused'
        );
        const downsellOrderPaid = response.data.filter(
          (funnelTrack) => funnelTrack.action === 'downsell-order-payment-paid'
        );

        downsellOrder.forEach((funnelTrack) => {
          if (funnelTrack.origin === 'direct') {
            const downsellOrderRefusedDirect = downsellOrderRefused.find(
              (downsellOrderData) => downsellOrderData.origin === 'direct'
            );
            const downsellOrderPaidDirect = downsellOrderPaid.find(
              (downsellOrderData) => downsellOrderData.origin === 'direct'
            );

            const downsellOrderRefusedQuantity = downsellOrderRefusedDirect
              ? parseInt(downsellOrderRefusedDirect.quantity, 10)
              : 0;
            const downsellOrderPaidQuantity = downsellOrderPaidDirect
              ? parseInt(downsellOrderPaidDirect.quantity, 10)
              : 0;

            if (downsellOrderRefusedQuantity > 0) {
              funnelDataDownsell.values[1][0] =
                parseInt(funnelTrack.quantity, 10) -
                downsellOrderRefusedQuantity +
                downsellOrderPaidQuantity;
            } else {
              funnelDataDownsell.values[1][0] = parseInt(
                funnelTrack.quantity,
                10
              );
            }
          } else if (funnelTrack.origin === 'social media') {
            const downsellOrderRefusedSocialMedia = downsellOrderRefused.find(
              (downsellOrderData) => downsellOrderData.origin === 'social media'
            );
            const downsellOrderPaidSocialMedia = downsellOrderPaid.find(
              (downsellOrderData) => downsellOrderData.origin === 'social media'
            );

            const downsellOrderRefusedQuantity = downsellOrderRefusedSocialMedia
              ? parseInt(downsellOrderRefusedSocialMedia.quantity, 10)
              : 0;
            const downsellOrderPaidQuantity = downsellOrderPaidSocialMedia
              ? parseInt(downsellOrderPaidSocialMedia.quantity, 10)
              : 0;
            if (downsellOrderRefusedQuantity > 0) {
              funnelDataDownsell.values[1][1] =
                parseInt(funnelTrack.quantity, 10) -
                downsellOrderRefusedQuantity +
                downsellOrderPaidQuantity;
            } else {
              funnelDataDownsell.values[1][1] = parseInt(
                funnelTrack.quantity,
                10
              );
            }
          } else if (funnelTrack.origin === 'ads') {
            const downsellOrderRefusedAds = downsellOrderRefused.find(
              (downsellOrderData) => downsellOrderData.origin === 'ads'
            );
            const downsellOrderPaidAds = downsellOrderPaid.find(
              (downsellOrderData) => downsellOrderData.origin === 'ads'
            );

            const downsellOrderRefusedQuantity = downsellOrderRefusedAds
              ? parseInt(downsellOrderRefusedAds.quantity, 10)
              : 0;
            const downsellOrderPaidQuantity = downsellOrderPaidAds
              ? parseInt(downsellOrderPaidAds.quantity, 10)
              : 0;
            if (downsellOrderRefusedQuantity > 0) {
              funnelDataDownsell.values[1][2] =
                parseInt(funnelTrack.quantity, 10) -
                downsellOrderRefusedQuantity +
                downsellOrderPaidQuantity;
            } else {
              funnelDataDownsell.values[1][2] = parseInt(
                funnelTrack.quantity,
                10
              );
            }
          }
        });

        response.data.forEach((funnelTrack) => {
          if (funnelTrack.action === 'first-contact') {
            if (funnelTrack.origin === 'direct') {
              funnelDataUpsell.values[0][0] = parseInt(
                funnelTrack.quantity,
                10
              );
              funnelDataDownsell.values[0][0] = parseInt(
                funnelTrack.quantity,
                10
              );
            } else if (funnelTrack.origin === 'social media') {
              funnelDataUpsell.values[0][1] = parseInt(
                funnelTrack.quantity,
                10
              );
              funnelDataDownsell.values[0][1] = parseInt(
                funnelTrack.quantity,
                10
              );
            } else if (funnelTrack.origin === 'ads') {
              funnelDataUpsell.values[0][2] = parseInt(
                funnelTrack.quantity,
                10
              );
              funnelDataDownsell.values[0][2] = parseInt(
                funnelTrack.quantity,
                10
              );
            }
          } else if (funnelTrack.action === 'upgrade-order-paid') {
            if (funnelTrack.origin === 'direct') {
              funnelDataUpsell.values[2][0] = parseInt(
                funnelTrack.quantity,
                10
              );
            } else if (funnelTrack.origin === 'social media') {
              funnelDataUpsell.values[2][1] = parseInt(
                funnelTrack.quantity,
                10
              );
            } else if (funnelTrack.origin === 'ads') {
              funnelDataUpsell.values[2][2] = parseInt(
                funnelTrack.quantity,
                10
              );
            }
          } else if (funnelTrack.action === 'downsell-order-payment-paid') {
            if (funnelTrack.origin === 'direct') {
              funnelDataDownsell.values[2][0] = parseInt(
                funnelTrack.quantity,
                10
              );
            } else if (funnelTrack.origin === 'social media') {
              funnelDataDownsell.values[2][1] = parseInt(
                funnelTrack.quantity,
                10
              );
            } else if (funnelTrack.origin === 'ads') {
              funnelDataDownsell.values[2][2] = parseInt(
                funnelTrack.quantity,
                10
              );
            }
          }
        });

        const responseTrafficFunnel = await api.get<IFunnelTrack[]>(
          'funnels-tracks',
          {
            params: {
              page: 'sales-funnel/view-offer-3',
              action: 'first-contact',
            },
          }
        );

        responseTrafficFunnel.data.forEach((funnelTrack) => {
          if (funnelTrack.origin === 'direct') {
            funnelDataUpsell.values[3][0] =
              parseInt(funnelTrack.quantity, 10) -
              funnelDataDownsell.values[2][0];
            funnelDataDownsell.values[3][0] =
              parseInt(funnelTrack.quantity, 10) -
              funnelDataUpsell.values[2][0];

            if (funnelDataUpsell.values[3][0] > funnelDataUpsell.values[2][0]) {
              funnelDataUpsell.values[3][0] = funnelDataUpsell.values[2][0];
            }

            if (
              funnelDataDownsell.values[3][0] > funnelDataDownsell.values[2][0]
            ) {
              funnelDataDownsell.values[3][0] = funnelDataDownsell.values[2][0];
            }
          } else if (funnelTrack.origin === 'social media') {
            funnelDataUpsell.values[3][1] =
              parseInt(funnelTrack.quantity, 10) -
              funnelDataDownsell.values[2][1];
            funnelDataDownsell.values[3][1] =
              parseInt(funnelTrack.quantity, 10) -
              funnelDataUpsell.values[2][1];

            if (funnelDataUpsell.values[3][1] > funnelDataUpsell.values[2][1]) {
              funnelDataUpsell.values[3][1] = funnelDataUpsell.values[2][1];
            }

            if (
              funnelDataDownsell.values[3][2] > funnelDataDownsell.values[2][2]
            ) {
              funnelDataDownsell.values[3][2] = funnelDataDownsell.values[2][2];
            }
          } else if (funnelTrack.origin === 'ads') {
            funnelDataUpsell.values[3][2] =
              parseInt(funnelTrack.quantity, 10) -
              funnelDataDownsell.values[2][2];
            funnelDataDownsell.values[3][2] =
              parseInt(funnelTrack.quantity, 10) -
              funnelDataUpsell.values[2][2];

            if (funnelDataUpsell.values[3][1] > funnelDataUpsell.values[2][1]) {
              funnelDataUpsell.values[3][1] = funnelDataUpsell.values[2][1];
            }

            if (
              funnelDataDownsell.values[3][2] > funnelDataDownsell.values[2][2]
            ) {
              funnelDataDownsell.values[3][2] = funnelDataDownsell.values[2][2];
            }
          }
        });

        const responseOrders = await api.get<IOrder[]>(
          'orders/admins/offers/a838aa3b-cbf9-4813-a1c9-d4a060ff8690'
        );

        const totalSalesData = responseOrders.data.reduce(
          (previous, current) => previous + parseFloat(current.amount_paid),
          0
        );

        const conversionsUpsellData = funnelDataUpsell.values[2].reduce(
          (previous, current) => previous + current,
          0
        );

        const conversionsDownsellData = funnelDataDownsell.values[2].reduce(
          (previous, current) => previous + current,
          0
        );

        const conversionsData = conversionsUpsellData + conversionsDownsellData;

        const responseFunnelUpsell = await api.get<IFunnelTrackAllData[]>(
          'funnels-tracks',
          {
            params: {
              page: 'sales-funnel/view-offer-2',
              action: 'upgrade-order-paid',
              allData: true,
            },
          }
        );
        const responseFunnelDownsell = await api.get<IFunnelTrackAllData[]>(
          'funnels-tracks',
          {
            params: {
              page: 'sales-funnel/view-offer-2',
              action: 'downsell-order-payment-paid',
              allData: true,
            },
          }
        );

        const responseFunnel = [
          ...responseFunnelUpsell.data,
          ...responseFunnelDownsell.data,
        ];
        const orderedFunnelData = responseFunnel.sort(
          (a, b) => b.quantity - a.quantity
        );
        const countriesData = orderedFunnelData.map((funnelOrderedData) => {
          let countryData: ICountry;
          switch (funnelOrderedData.country) {
            case 'Br':
              countryData = {
                flag: brazil,
                name: 'Brazil',
                quantity: funnelOrderedData.quantity,
              };
              break;
            case 'Ar':
              countryData = {
                flag: argentina,
                name: 'Argentina',
                quantity: funnelOrderedData.quantity,
              };
              break;
            case 'Us':
              countryData = {
                flag: usa,
                name: 'USA',
                quantity: funnelOrderedData.quantity,
              };
              break;
            case 'Co':
              countryData = {
                flag: colombia,
                name: 'Colombia',
                quantity: funnelOrderedData.quantity,
              };
              break;

            default:
              countryData = {
                flag: generic,
                name: 'Outros',
                quantity: funnelOrderedData.quantity,
              };
              break;
          }

          return countryData;
        });
        const othersCountriesData = countriesData.filter(
          (countryData) => countryData.name === 'Outros'
        );
        const newCountriesData = countriesData.filter(
          (countryData) => countryData.name !== 'Outros'
        );
        const otherCountriesQuantity = othersCountriesData.reduce(
          (previous, current) => previous + current.quantity,
          0
        );
        newCountriesData.push({
          name: 'Outros',
          flag: generic,
          quantity: otherCountriesQuantity,
        });

        setTotalSales(
          mask({
            kind: 'money',
            value: totalSalesData.toFixed(2),
            options: {
              unit: '',
              separator: '.',
              delimiter: ',',
            },
          })
        );
        setCountries(newCountriesData);
        setConversions(conversionsData);
        setFunnelUpsell(funnelDataUpsell);
        setFunnelDownsell(funnelDataDownsell);
      });
  }, []);

  return (
    <Container>
      <div className="mb-4">
        <h2 className="font-weight-bold pl-3 mb-3">Traffic Upsell</h2>
        <GraphSalesFunnel data={funnelUpsell} id="funnelUpsell" />
      </div>
      <div>
        <h2 className="font-weight-bold pl-3 mb-3">Traffic Downsell</h2>
        <GraphSalesFunnel data={funnelDownsell} id="funnelDownsell" />
      </div>
      <div className="container px-0">
        <div className="row mt-5">
          <div className="col-4">
            <div className="bg d-flex flex-column p-4 h-box">
              <h2 className="font-weight-600 mb-auto">Total Sales</h2>
              {/* <p className="color-green mb-1">+1.4%</p> */}
              <div className="d-flex justify-content-between align-items-center">
                <span className="color-green">{totalSales}</span>
                <img src={members} alt="icon members" />
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="bg d-flex flex-column p-4 h-box">
              <h2 className="font-weight-600 mb-auto">Conversion</h2>
              {/* <p className="color-green mb-1">+1.4%</p> */}
              <div className="d-flex justify-content-between align-items-center">
                <span>{conversions.toString().padStart(3, '0')}</span>
                <img src={adspend} alt="icon ads" />
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="bg d-flex flex-column p-4 h-box">
              <h2 className="font-weight-600 mb-5">Visits by country</h2>
              <div className="h-box-flags overflow-auto">
                {countries.map((country) => (
                  <div
                    key={country.name}
                    className="d-flex justify-content-between mb-4"
                  >
                    <div className="d-flex">
                      <img
                        src={country.flag}
                        alt="Colombia Flag"
                        className="mr-4"
                      />
                      {country.name}
                    </div>
                    <p className="h5 mb-0 font-weight-normal">
                      {country.quantity.toString().padStart(2, '0')}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Traffic;
