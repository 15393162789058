import React, { useEffect } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

import { Container } from './styles';

import StyleMap from './style.json';
import markerIcon from '~/assets/icons/marker-icon.svg';

const loader = new Loader({
  apiKey: 'AIzaSyAFIKsXFxZbluvp0tOK8NXJl5-PEXJdsSc',
  version: 'weekly',
  libraries: ['places'],
});

interface IMapProps {
  address: string;
  className?: string;
}

const MapContainer: React.FC<IMapProps> = ({ address, className }) => {
  useEffect(() => {
    loader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK' && results) {
          let lat = 0;
          let lng = 0;
          if (results[0].geometry) {
            if (results[0].geometry.location) {
              lat = results[0].geometry.location.lat();
              lng = results[0].geometry.location.lng();
            }
          }
          const position = { lat, lng };
          const map = new google.maps.Map(
            document.getElementById('map') as HTMLElement,
            {
              center: position,
              zoom: 18,
              styles: StyleMap,
              disableDefaultUI: true,
            }
          );
          new google.maps.Marker({
            position,
            map,
            icon: markerIcon,
          });
        }
      });
    });
  }, [address]);

  return (
    <Container className={className}>
      <div id="map" style={{ height: 170 }} />
    </Container>
  );
};

export default MapContainer;
