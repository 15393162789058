import React, { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import api from '~/services/api';

import { Modal } from './styles';

interface IEditCategoryProps {
  name: string;
  category_id: string;
  show: boolean;
  onDelete?(category_id: string): void;
  onClose?(): void;
}

const EditCategory: React.FC<IEditCategoryProps> = ({
  category_id,
  name,
  show,
  onDelete,
  onClose,
}) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const handleClose = useCallback(() => {
    setShowModal(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleDelete = useCallback(async () => {
    try {
      await api.delete(`categories/${category_id}`);

      if (onDelete) {
        onDelete(category_id);
      }

      handleClose();
    } catch (error) {
      Swal.fire('Opss...', 'An error has occurred, please try again.', 'error');
    }
  }, [category_id, handleClose, onDelete]);

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="ml-auto">Delete category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="mb-0 text-center">
          Are you sure you want to delete the category: {name}
        </p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-around">
        <button
          type="button"
          onClick={handleClose}
          className="w-25 btn-grey px-3 py-2"
        >
          NO
        </button>
        <button
          type="button"
          onClick={handleDelete}
          className="w-25 btn-grey px-3 py-2"
        >
          YES
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditCategory;
