import { lighten } from 'polished';
import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IToogleButton {
  active: boolean;
}

export const Container = styled.div`
  .rdt_TableHeadRow,
  .rdt_TableRow {
    > div:nth-child(2) {
      max-width: 500px;
    }

    .input {
      border: none;

      input {
        color: #8c8c8c !important;
        font-size: 13px;
      }
    }

    .crawl-back {
      input {
        color: #e40041 !important;
      }
    }
  }

  .users-radius {
    border-radius: 20px;
  }

  .btn-assign {
    border: 1px solid #8c8c8c;
    width: 120px;
    height: 40px;
    font-size: 12px;
    color: #fff;
  }

  @media screen and (max-width: 991px) {
    .h-date input {
      height: 41px;
    }
  }

  @media screen and (max-width: 767px) {
  }

  @media screen and (max-width: 575px) {
    .size-users {
      font-size: 12px;
    }
    .users-size {
      display: block;

      margin: auto;
    }
  }

  @media (min-width: 992px) and (max-width: 1250px) {
    .vw80 {
      width: 80vw !important;
    }
  }
`;

export const Welcome = styled.div`
  p {
    color: #969696;
  }

  .btn-pay {
    border-radius: 50px;
    background-color: #202020;
    border: solid 1px #606060;
    color: #e4e4e4 !important;

    :hover {
      color: ${lighten(0.1, '#e4e4e4')}!important;
      border: solid 1px ${lighten(0.1, '#606060')};
    }
  }
`;

export const NameUserTable = styled.div`
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;

export const Modal = styled(ModalComponent)`
  .box {
    border: 1px solid #8c8c8c;
    border-radius: 16px;
    position: relative;

    .btn-delete {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
`;

export const ToggleButton = styled.button<IToogleButton>`
  position: relative;
  width: 50px;
  height: 22px;
  border-radius: 25px;
  border: 1px solid #8c8c8c;
  background-color: ${(props) => (props.active ? '#8c8c8c' : 'transparent')};
  transition-duration: 0.3s;

  ::after {
    content: '';
    position: absolute;
    width: 12.5px;
    height: 12.5px;
    background-color: #8c8c8c;
    top: 50%;
    transform: translateY(-50%);
    left: ${(props) => (props.active ? 32 : 5)}px;
    background-color: ${(props) => (props.active ? '#202020' : '#8c8c8c')};
    transition-duration: 0.3s;
    border-radius: 50%;
  }
`;
