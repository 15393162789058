import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { format, parseISO } from 'date-fns';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import {
  Container,
  Welcome,
  NameUserTable,
  OptionUser,
  Options,
  Modal,
} from './styles';
import Table from '~/components/Table';

import { formatPrice } from '~/utils/format';
import InputMask from '~/components/InputMask';
import Select from '~/components/Select';

import options from '~/assets/icons/options.svg';
import Toast from '~/utils/toast';

interface ICommission {
  id: string;
  earning: string;
  created_at: string;
}

interface IPayoutResponse {
  id: string;
  total_available: string;
  total_pending: string;
  total_crawl_back: string;
  net_payout: string;
  status: string;
  created_at: string;
  wallet: {
    affiliate: {
      type: string;
      affiliate_commission: string;
      user: {
        name: string;
        avatar: {
          avatar_url: string;
        };
      };
      commissions: ICommission[];
    };
  };
}

interface IPayout {
  id: string;
  request_date: string;
  avatar: string;
  member: string;
  old_total_available: string;
  total_available: string;
  total_pending: string;
  total_crawl_back: string;
  net_payout: string;
  commission_rate: string;
  type: string;
  status: string;
}

const PayoutRequest: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [payouts, setPayouts] = useState<IPayout[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [payoutSelected, setPayoutSelected] = useState({} as IPayout);
  const [showUpdatePayout, setShowUpdatePayout] = useState(false);
  const [showUpdateStatusPayout, setShowUpdateStatusPayout] = useState(false);

  const handleLoadCommissions = useCallback(async () => {
    const response = await api.get<IPayoutResponse[]>('payouts', {
      params: {
        search: filter,
      },
    });

    const data = response.data.map((payout) => {
      return {
        id: payout.id,
        request_date: format(parseISO(payout.created_at), 'yyyy-MM-dd'),
        avatar: payout.wallet.affiliate?.user?.avatar?.avatar_url || '-',
        member: payout.wallet.affiliate?.user?.name || '-',
        old_total_available: formatPrice(parseFloat(payout.total_available)),
        total_available: formatPrice(parseFloat(payout.total_available)),
        total_pending: formatPrice(parseFloat(payout.total_pending)),
        total_crawl_back: formatPrice(parseFloat(payout.total_crawl_back)),
        net_payout: formatPrice(parseFloat(payout.net_payout)),
        commission_rate: payout.wallet.affiliate
          ? `${payout.wallet.affiliate.affiliate_commission}%`
          : '-',
        type: payout.wallet.affiliate?.type || '-',
        status: payout.status,
      };
    });

    setPayouts(data);
    setLoading(false);
  }, [filter]);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    handleLoadCommissions();
  }, [filter, handleLoadCommissions]);

  const handleClose = useCallback(() => {
    setShowUpdatePayout(false);
    setShowUpdateStatusPayout(false);
  }, []);

  const handleSearch = useCallback((value) => {
    setFilter(value);
  }, []);

  const handleSelectedPayout = useCallback(
    (payout) => {
      if (payoutSelected.id === payout.id) {
        setPayoutSelected({} as IPayout);
      } else {
        setPayoutSelected(payout);
      }
    },
    [payoutSelected.id]
  );

  const handleClickMakePay = useCallback(() => {
    Swal.fire({
      title: 'Making commission payments?',
      text: `Would you like to pay the ${payoutSelected.member} now?`,
      icon: 'warning',
      confirmButtonText: 'Yes',
      confirmButtonColor: '#202020',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: '#606060',
      reverseButtons: true,
    }).then(async (e) => {
      if (e.isConfirmed) {
        // await api.post('payouts/make');
      }
    });
  }, [payoutSelected.member]);

  const handleClickEdit = useCallback(() => {
    setShowUpdatePayout(true);
  }, []);

  const handleClickEditStatus = useCallback(() => {
    setShowUpdateStatusPayout(true);
  }, []);

  const handleClickDelete = useCallback(() => {
    Swal.fire({
      title: 'Delete payout?',
      text: `Would you like to delete this payout?`,
      icon: 'warning',
      confirmButtonText: 'Yes',
      confirmButtonColor: '#202020',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: '#606060',
      reverseButtons: true,
    }).then(async (e) => {
      if (e.isConfirmed) {
        await api.delete(`payouts/${payoutSelected.id}`);
        const newPayouts = payouts.filter(
          (payout) => payout.id !== payoutSelected.id
        );
        setPayouts(newPayouts);
        setPayoutSelected({} as IPayout);
        Toast.fire({
          icon: 'success',
          title: 'Payout successfully deleted!',
        });
      }
    });
  }, [payoutSelected.id, payouts]);

  const columns = useMemo(
    () => [
      {
        name: 'Request Date',
        selector: 'request_date',
        sortable: true,
      },
      {
        name: 'Member',
        selector: 'member',
        sortable: true,
        cell: (row: IPayout) => (
          <NameUserTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
          >
            <img src={row.avatar} alt={row.member} className="mr-2" />
            <div>
              <p className="mb-0">{row.member}</p>
            </div>
          </NameUserTable>
        ),
      },
      {
        name: 'Total Available',
        selector: 'total_available',
        sortable: true,
      },
      {
        name: 'Total Peding',
        selector: 'total_pending',
        sortable: true,
      },
      {
        name: 'Total Crawl Back',
        selector: 'total_crawl_back',
        sortable: true,
      },
      {
        name: 'Net Payout',
        selector: 'net_payout',
        sortable: true,
      },
      {
        name: 'Commission Rate',
        selector: 'commission_rate',
        sortable: true,
      },
      {
        name: 'Member Type',
        selector: 'type',
        sortable: true,
      },
      {
        name: 'Status',
        selector: 'status',
        sortable: true,
      },
      {
        name: '',
        selector: 'id',
        sortable: true,
        cell: (row: IPayout) => (
          <OptionUser className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => handleSelectedPayout(row)}
              className="border-0 bg-transparent"
            >
              <img src={options} alt="options" className="mr-2" />
            </button>

            <Options show={payoutSelected.id === row.id}>
              {/* <button
                type="button"
                className="border-0 bg-transparent"
                onClick={handleClickMakePay}
              >
                Make a pay
              </button>
              <hr /> */}
              <button
                type="button"
                className="border-0 bg-transparent"
                onClick={handleClickEdit}
              >
                Edit Payout
              </button>
              <hr />
              <button
                type="button"
                className="border-0 bg-transparent"
                onClick={handleClickEditStatus}
              >
                Edit Status Payout
              </button>
              <hr />
              <button
                type="button"
                className="border-0 bg-transparent"
                onClick={handleClickDelete}
              >
                Delete Payout
              </button>
            </Options>
          </OptionUser>
        ),
      },
    ],
    [
      handleClickDelete,
      handleClickEdit,
      handleClickEditStatus,
      handleSelectedPayout,
      payoutSelected.id,
    ]
  );

  const handleClickPayAll = useCallback(async () => {
    Swal.fire({
      title: 'Pay all?',
      text: 'Would you like to pay everyone now?',
      icon: 'warning',
      confirmButtonText: 'Yes',
      confirmButtonColor: '#202020',
      showCancelButton: true,
      cancelButtonText: 'No',
      cancelButtonColor: '#606060',
      reverseButtons: true,
    }).then(async (e) => {
      if (e.isConfirmed) {
        await api.post('payouts/make');
      }
    });
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({});

        await schema.validate(data, {
          abortEarly: false,
        });

        const { total_available, total_crawl_back } = data;

        const totalAvailable = parseFloat(
          total_available.replace('$', '').replaceAll(',', '')
        );

        const totalCrawlBack = parseFloat(
          total_crawl_back.replace('$', '').replaceAll(',', '')
        );

        const formData = {
          total_available: totalAvailable,
          total_crawl_back: totalCrawlBack,
          net_payout: totalAvailable - totalAvailable * 0.03,
          status: 'Manually Corrected',
        };

        await api.put(`payouts/${payoutSelected.id}`, formData);

        const newPayouts = payouts.slice();

        const payoutIndex = newPayouts.findIndex(
          (payout) => payout.id === payoutSelected.id
        );

        if (payoutIndex >= 0) {
          newPayouts[payoutIndex].total_crawl_back = formatPrice(
            totalCrawlBack
          );
          newPayouts[payoutIndex].old_total_available = formatPrice(
            totalAvailable
          );
          newPayouts[payoutIndex].total_available = formatPrice(totalAvailable);
          newPayouts[payoutIndex].net_payout = formatPrice(formData.net_payout);
          newPayouts[payoutIndex].status = 'Manually Corrected';
        }
        setPayouts(newPayouts);
        setPayoutSelected(newPayouts[payoutIndex]);

        handleLoadCommissions();

        Toast.fire({
          icon: 'success',
          title: 'Payout successfully updated!',
        });

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          );
        }
      }
    },
    [handleClose, handleLoadCommissions, payoutSelected.id, payouts]
  );

  const handleSubmitStatus = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({});

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          status: data.status,
        };

        await api.put(`payouts/${payoutSelected.id}`, formData);

        const newPayouts = payouts.slice();

        const payoutIndex = newPayouts.findIndex(
          (payout) => payout.id === payoutSelected.id
        );

        if (payoutIndex >= 0) {
          newPayouts[payoutIndex].status = data.status;
        }
        setPayouts(newPayouts);
        setPayoutSelected(newPayouts[payoutIndex]);

        handleLoadCommissions();

        Toast.fire({
          icon: 'success',
          title: 'Status Payout successfully updated!',
        });

        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          );
        }
      }
    },
    [handleClose, handleLoadCommissions, payoutSelected.id, payouts]
  );

  const handleChangeTotalAvailable = useCallback(async (e) => {
    const totalAvailable = parseFloat(
      e.target.value.replace('$', '').replaceAll(',', '')
    );

    setPayoutSelected((state) => ({
      ...state,
      net_payout: formatPrice(totalAvailable - totalAvailable * 0.03),
    }));
  }, []);

  const handleChangeTotalCrawlBack = useCallback(
    async (e) => {
      let totalAvailable = parseFloat(
        payoutSelected.old_total_available.replace('$', '').replaceAll(',', '')
      );
      const totalCrawlBack = parseFloat(
        e.target.value.replace('$', '').replaceAll(',', '')
      );
      const oldTotalCrawlBack = parseFloat(
        payoutSelected.total_crawl_back.replace('$', '').replaceAll(',', '')
      );

      if (totalCrawlBack < oldTotalCrawlBack) {
        totalAvailable += oldTotalCrawlBack;
        totalAvailable -= totalCrawlBack;
      } else {
        totalAvailable -= totalCrawlBack;
      }

      setPayoutSelected((state) => ({
        ...state,
        total_available: formatPrice(totalAvailable),
        net_payout: formatPrice(totalAvailable - totalAvailable * 0.03),
      }));
    },
    [payoutSelected]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 mb-4 d-flex align-items-center justify-content-between">
                    <h1 className="h4 h2-lg">Payout Request</h1>
                    <button
                      type="button"
                      className="btn-pay px-5 py-2"
                      onClick={handleClickPayAll}
                    >
                      Pay all
                    </button>
                  </div>
                </div>
              </div>
            </Welcome>

            <Table
              data={payouts}
              columns={columns}
              pagination
              date
              searchable
              onSearch={handleSearch}
              exportable
            />
          </div>
        </div>
      </div>
      <Modal show={showUpdatePayout} onHide={handleClose}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="ml-auto">Edit Payout</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label className="d-block w-100 mb-3">
              <span className="d-block mb-2">Total Available</span>
              <InputMask
                kind="money"
                name="total_available"
                value={payoutSelected.total_available}
                className="input"
                options={{
                  unit: '$',
                  delimiter: ',',
                  separator: '.',
                }}
                onChange={handleChangeTotalAvailable}
              />
            </label>
            <label className="d-block w-100 mb-3">
              <span className="d-block mb-2">Total Crawl Back</span>
              <InputMask
                kind="money"
                name="total_crawl_back"
                value={payoutSelected.total_crawl_back}
                className="input"
                options={{
                  unit: '$',
                  delimiter: ',',
                  separator: '.',
                }}
                onChange={handleChangeTotalCrawlBack}
              />
            </label>
            <label className="d-flex w-100 justify-content-between">
              <span className="d-block">Net Payout:</span>
              <span className="d-block">{payoutSelected.net_payout}</span>
            </label>
          </Modal.Body>
          <Modal.Footer className="d-flex flex-nowrap">
            <button
              type="button"
              className="btn-grey px-3 py-2 w-50"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button type="submit" className="btn-dark px-3 py-2 w-50">
              Save
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={showUpdateStatusPayout} onHide={handleClose}>
        <Form ref={formRef} onSubmit={handleSubmitStatus}>
          <Modal.Header closeButton>
            <Modal.Title className="ml-auto">Edit Status Payout</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label className="d-block w-100 mb-3">
              <span className="d-block mb-2">Status</span>
              <Select
                name="status"
                options={[
                  {
                    id: 'Ready to Pay',
                    value: 'Ready to Pay',
                    selected: payoutSelected.status === 'Ready to Pay',
                  },
                  {
                    id: 'Paid',
                    value: 'Paid',
                    selected: payoutSelected.status === 'Paid',
                  },
                  {
                    id: 'Manually Corrected',
                    value: 'Manually Corrected',
                    selected: payoutSelected.status === 'Manually Corrected',
                  },
                ]}
              />
            </label>
          </Modal.Body>
          <Modal.Footer className="d-flex flex-nowrap">
            <button
              type="button"
              className="btn-grey px-3 py-2 w-50"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button type="submit" className="btn-dark px-3 py-2 w-50">
              Save
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default PayoutRequest;
