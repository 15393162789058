import styled from 'styled-components';

export const Container = styled.div`
  h2.text-gray {
    color: #ababab;
  }

  .menu-table {
    justify-content: end !important;
  }

  .bd-radius-20 {
    border-radius: 20px;
  }
`;

export const Welcome = styled.div`
  button {
    z-index: 100;
    top: 93px;
    left: 30px;
    border: none;
    background-color: transparent;
    border-bottom: solid 1px #2f2f2f;
    span {
      color: #6f6e6e;
      font-size: 18px;
      font-weight: 700;
    }
  }

  .active {
    border-bottom: solid 1px #fff;
    span {
      color: #fff;
    }
  }
  p {
    color: #606060;
  }

  h1 {
    color: #e4e4e4;
  }

  hr {
    background-color: #606060;
    width: 25%;
  }

  span {
    color: #cbccce;
    font-size: 24px;
    font-weight: 600;
  }

  .bg-gray {
    background: #242526;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
  }

  .border-golden {
    :hover {
      color: #e4e4e4;
      text-decoration: none;
    }
  }

  @media screen and (min-width: 576px) {
    .borders-gray {
      border-left: 1px solid #606060;
      border-right: 1px solid #606060;
    }
  }

  @media screen and (max-width: 430px) {
    button span {
      font-size: 13px;
    }
  }
`;

export const Card = styled.div`
  border-radius: 20px;
  overflow: hidden;

  img {
    border-radius: 20px;
  }

  div {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    p {
      width: 55%;
    }

    a.btn-edit {
      width: 40%;
      background: #242526;
      border: 1px solid #4a4b4b;
      color: #8c8c8c;
      text-align: center;

      :hover {
        text-decoration: none;
      }
    }
  }
`;

export const LinkCopy = styled.div`
  background: #343434;

  small {
    color: #e6e7e7;
  }
`;
