import React from 'react';

import { Container } from './styles';

interface ICoursesActionTitleProps {
  lightTitle?: string;
  boldTitle: string;
  description: string;
}

const CoursesActionTitle: React.FC<ICoursesActionTitleProps> = ({
  lightTitle,
  boldTitle,
  description,
}) => {
  return (
    <Container>
      <h1 className="h2 h1-sm pb-3 px-3">
        {lightTitle || ''} <span>{boldTitle}</span>
      </h1>
      <p className="w-md-75 mx-auto px-4 px-sm-5">{description}</p>
    </Container>
  );
};

export default CoursesActionTitle;
