import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  background: #242526;
  min-height: 100vh;

  .margin-top {
    margin-top: 60px;
  }
  @media screen and (max-width: 991px) {
    display: block;
  }
`;

export const HeaderSpacer = styled.div`
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Separator = styled.div`
  background-color: #18191a;
  width: calc(100% - 100px);
  padding: 20px;

  @media screen and (max-width: 991px) {
    width: 100%;
  }

  @media screen and (max-width: 575px) {
    padding: 15px;
  }
`;

export const FooterSpacer = styled.div`
  width: 35%;
  background: #202020;
`;
