import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { parseISO, format } from 'date-fns';
import axios, { CancelTokenSource } from 'axios';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Form } from '@unform/web';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Welcome, NameUserTable, OptionUser } from './styles';
import Table from '~/components/Table';
import options from '~/assets/icons/options.svg';
import Input from '~/components/Input';
import InputAddress from '~/components/InputAddress';
import InputMask from '~/components/InputMask';
import Toast from '~/utils/toast';

interface IAddress {
  city: string;
  complement: string;
  country: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  zipCode: string;
}

interface ITableData {
  dataJoined: string;
  userID: string;
  avatar: string;
  name: string;
  member_type: string;
  referrer: string;
  referrerName: string;
  produtc_owned: string;
  email: string;
  phone: string;
  created_at: string;
}

interface ICourseUser {
  course: {
    title: string;
  };
}

interface IUser {
  id: string;
  created_at: string;
  referral_code: string;
  origin: string;
  avatar: {
    avatar_url: string;
  };
  name: string;
  courseUser: ICourseUser[];
  email: string;
  phone: string;
  affiliate: {
    type: string;
    affiliatesOffers: {
      offer_id: string;
    }[];
  };
  referrer: {
    affiliate: {
      user: {
        name: string;
      };
    };
  };
}

let cancelToken: CancelTokenSource | undefined;

interface IFormData {
  name: string;
  email: string;
  phone: string;
  zipCode: string;
  country: string;
  street: string;
  number: string;
  neighborhood: string;
  state: string;
  city: string;
  complement: string;
  price: string;
}

const Users: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const hisotry = useHistory();
  const [users, setUsers] = useState<ITableData[]>([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [zipCodeData, setZipCode] = useState('');
  const [countryData, setCountry] = useState('');
  const [streetData, setStreet] = useState('');
  const [numberData, setNumber] = useState('');
  const [neighborhoodData, setNeighborhood] = useState('');
  const [stateData, setState] = useState('');
  const [cityData, setCity] = useState('');
  const [complementData, setComplement] = useState('');
  const [price, setPrice] = useState('0.00');

  const handleLoadUsers = useCallback(async (filterData = '') => {
    cancelToken = axios.CancelToken.source();
    const response = await api.get('users', {
      cancelToken: cancelToken.token,
      params: {
        filter:
          filterData || sessionStorage.getItem('@AutoAffiliate:searchUser'),
        isAdmin: true,
      },
    });
    const data = response.data.map((user: IUser) => {
      const originParts = user.origin.split(', ');
      return {
        created_at: user.created_at,
        dataJoined: format(parseISO(user.created_at), 'yyyy-MMM-dd'),
        userID: user.id,
        avatar: user.avatar?.avatar_url,
        name: user.name,
        member_type: user.affiliate?.type || 'New Member',
        referrerName: user.referrer?.affiliate?.user?.name || '-',
        referrer: user.referral_code,
        origin: originParts[0],
        produtc_owned:
          user.courseUser.length > 0
            ? user.courseUser[0].course.title
            : 'Without course',
        email: user.email,
      };
    });
    setUsers(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);

    handleLoadUsers();
  }, [handleLoadUsers]);

  const handleSelectedUser = useCallback(
    (e) => {
      hisotry.push(`/users/update/${e.userID}`);
    },
    [hisotry]
  );

  const handleSearch = useCallback(
    (value) => {
      if (cancelToken) {
        cancelToken.cancel('canceled');
      }
      sessionStorage.setItem('@AutoAffiliate:searchUser', value);
      handleLoadUsers(value);
    },
    [handleLoadUsers]
  );

  const columns = useMemo(
    () => [
      {
        name: 'Date Joined',
        selector: 'created_at',
        sortable: true,
        cell: (row: ITableData) => row.dataJoined,
      },
      // {
      //   name: 'User ID',
      //   selector: 'userID',
      //   sortable: true,
      // },
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        cell: (row: ITableData) => (
          <NameUserTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
          >
            <img src={row.avatar} alt={row.name} className="mr-2" />
            <p className="mb-0">{row.name}</p>
          </NameUserTable>
        ),
      },
      {
        name: 'Origin',
        selector: 'origin',
        sortable: true,
      },
      {
        name: 'Member Type',
        selector: 'member_type',
        sortable: true,
      },
      {
        name: 'Referrer ',
        selector: 'referrer',
        sortable: true,
      },
      {
        name: 'Affiliate',
        selector: 'referrerName',
        sortable: true,
      },
      {
        name: 'Product Owned ',
        selector: 'produtc_owned',
        sortable: true,
      },
      {
        name: 'Email ',
        selector: 'email',
        sortable: true,
      },
      {
        name: '',
        selector: 'Options',
        sortable: true,
        cell: (row: ITableData) => (
          <OptionUser
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
            onClick={() => handleSelectedUser(row)}
          >
            <img src={options} alt="options" className="mr-2" />
          </OptionUser>
        ),
      },
    ],
    [handleSelectedUser]
  );

  const handleChangeZipCode = useCallback((address: IAddress) => {
    const {
      zipCode,
      country,
      street,
      number,
      neighborhood,
      state,
      city,
      complement,
    } = address;
    setZipCode(zipCode);
    setCountry(country);
    setStreet(street);
    setNumber(number);
    setNeighborhood(neighborhood);
    setState(state);
    setCity(city);
    setComplement(complement);
  }, []);

  const handleChangeCountry = useCallback((e) => {
    setCountry(e.target.data);
  }, []);

  const handleChangeStreet = useCallback((e) => {
    setStreet(e.target.data);
  }, []);

  const handleChangeNumber = useCallback((e) => {
    setNumber(e.target.data);
  }, []);

  const handleChangeNeighborhood = useCallback((e) => {
    setNeighborhood(e.target.data);
  }, []);

  const handleChangeState = useCallback((e) => {
    setState(e.target.data);
  }, []);

  const handleChangeCity = useCallback((e) => {
    setCity(e.target.data);
  }, []);

  const handleChangePrice = useCallback((e) => {
    setPrice(e.target.value);
  }, []);

  const handleChangeComplement = useCallback((e) => {
    setComplement(e.target.data);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Full name is a required'),
          email: Yup.string().email().required('Email address is a required'),
          address: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const {
          name,
          email,
          phone,
          zipCode,
          country,
          street,
          number,
          neighborhood,
          city,
          complement,
          state,
        } = data;

        const formData = {
          name,
          email,
          reference: '419f0104',
          phone: phone || undefined,
        };

        const response = await api.post(`users`, formData);

        const formDataAffiliates = {
          user_id: response.data.id,
          type: 'New Member',
          active_affiliate: false,
          affiliate_commission: 10,
          franchise_owner: false,
          crowdfunding_participation: false,
          crowdfunding_share: 5,
          franchise_commission: 40,
        };

        await api.post('affiliates', formDataAffiliates);

        if (zipCode) {
          const formAddressData = {
            user_id: response.data.id,
            zip_code: zipCode,
            country,
            street,
            number,
            neighborhood,
            city,
            complement,
            state,
          };

          await api.post('adresses', formAddressData);
        }

        console.log(price);

        const formDataPaymentLink = {
          user_id: response.data.id,
          product_id: '43e9692d-005b-4c46-997f-6ad4575c023f',
          price: parseFloat(price.replace('$', '')),
        };

        const responsePaymentLink = await api.post(
          'payments-links',
          formDataPaymentLink
        );

        console.log(responsePaymentLink.data);

        Swal.fire({
          icon: 'success',
          title: 'Good Job!',
          html: `<button type="button" onClick="copiarLink('https://postease.ai/payment?pli=${responsePaymentLink.data.id}')" class="border-0 bg-transparent text-primary">Clique aqui</button> e copie o link ou copie o link abaixo https://postease.ai/payment?pli=${responsePaymentLink.data.id}`,
          showConfirmButton: false,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          console.log(errors);
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          ).then(() => setLoading(false));
        }
      } finally {
        setLoading(false);
      }
    },
    [price]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome className="mb-4">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 d-flex">
                    <h1 className="h4 h2-lg mb-0">Users Management</h1>
                    <button
                      type="button"
                      className="ml-5"
                      onClick={() => setShow(true)}
                    >
                      New Member
                    </button>
                  </div>
                </div>
              </div>
            </Welcome>
            <Table
              data={users}
              columns={columns}
              pagination
              date
              searchable
              onSearch={handleSearch}
              exportable
              searchValue={
                sessionStorage.getItem('@AutoAffiliate:searchUser') || undefined
              }
            />
          </div>
        </div>
      </div>
      <Modal
        className="bg-modal-gray"
        centered
        show={show}
        onHide={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h3 className="pt-4 h4">Create new member</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-3">
              <div className="col-12 mb-2">
                <label htmlFor="name">Full Name</label>
                <Input name="name" id="name" />
              </div>
              <div className="col-md-6 my-2">
                <label htmlFor="email">Email Address</label>
                <Input type="email" name="email" id="email" />
              </div>
              <div className="col-md-6 my-2">
                <label htmlFor="phone">Phone Number</label>
                <Input name="phone" id="phone" />
              </div>
              <div className="col-md-6 col-lg-4 my-2">
                <label htmlFor="email">Zip Code</label>
                <InputAddress
                  name="zipCode"
                  value={zipCodeData}
                  onChange={handleChangeZipCode}
                />
              </div>
              <div className="col-md-6 col-lg-4 my-2">
                <label htmlFor="email">Country</label>
                <Input
                  name="country"
                  value={countryData}
                  onChange={handleChangeCountry}
                />
              </div>
              <div className="col-md-6 col-lg-4 my-2">
                <label htmlFor="email">Street</label>
                <Input
                  name="street"
                  value={streetData}
                  onChange={handleChangeStreet}
                />
              </div>
              <div className="col-md-6 col-lg-4 col-xl-3 my-2">
                <label htmlFor="email">Number</label>
                <Input
                  name="number"
                  value={numberData}
                  onChange={handleChangeNumber}
                />
              </div>
              <div className="col-md-6 col-lg-4 col-xl-3 my-2">
                <label htmlFor="email">Neighborhood</label>
                <Input
                  name="neighborhood"
                  value={neighborhoodData}
                  onChange={handleChangeNeighborhood}
                />
              </div>
              <div className="col-md-6 col-lg-4 col-xl-6 my-2">
                <label htmlFor="email">State</label>
                <Input
                  name="state"
                  value={stateData}
                  onChange={handleChangeState}
                />
              </div>
              <div className="col-md-6 my-2">
                <label htmlFor="email">City</label>
                <Input
                  name="city"
                  value={cityData}
                  onChange={handleChangeCity}
                />
              </div>
              <div className="col-md-6 my-2">
                <label htmlFor="email">Complement</label>
                <Input
                  name="complement"
                  value={complementData}
                  onChange={handleChangeComplement}
                />
              </div>
              <div className="col-12 my-2">
                <label htmlFor="email">Price</label>
                <InputMask
                  name="price"
                  value={price}
                  onChange={handleChangePrice}
                  kind="money"
                  options={{ separator: '.', delimiter: ',', unit: '$' }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="row justify-content-center w-100 mb-3 mt-2">
              <div className="col-lg-10">
                <button type="submit" className="btn-grey w-100">
                  <span className="d-block py-3">
                    SAVE MEMBER AND GENERATE LINK
                  </span>
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Users;
