import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaRegUserCircle } from 'react-icons/fa';
import { IoSend } from 'react-icons/io5';
import * as yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';
import { io } from 'socket.io-client';

import { BsChatDots, BsCheck, BsFillReplyFill, BsX } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
import getValidationErros from '~/utils/getValidationsErrors';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';

import {
  Container,
  Welcome,
  Content,
  Notifications,
  ChatArea,
  ChatText,
  Modal,
} from './styles';
import Textarea from '~/components/Textarea';
import InputMask from '~/components/InputMask';

interface IChatResponse {
  id: string;
  type: string;
  referrer_code: string;
}

interface IChat {
  id: string;
  name: string;
  message: string;
}

interface IMessageResponse {
  id: string;
  user_id?: string;
  chat_id: string;
  avatar_id: string;
  temp_user_id: string;
  name: string;
  content: string;
  time: number;
  approved: boolean;
  avatar: {
    avatar_url: string;
  };
}

interface IMessage {
  id: string;
  user_id: string;
  chat_id: string;
  avatar_id: string;
  me: boolean;
  participants: boolean;
  avatar: string;
  name: string;
  text: string;
  time: number;
  approved: boolean;
}

interface IParams {
  reference?: string;
}

interface ITempUserData {
  id?: string;
  avatar_id?: string;
  name: string;
}

const Chats: React.FC = () => {
  const { user } = useAuth();
  const params = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const [chats, setChats] = useState<IChat[]>([]);
  const [chatSelected, setChatSelected] = useState({} as IChat);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [tempUserData, setTempUserData] = useState({} as ITempUserData);
  const [loading, setLoading] = useState(false);
  const [messageSelected, setMessageSelected] = useState({} as IMessage);
  const [showApprove, setShowApprove] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [reply, setReply] = useState(false);
  const [timeValue, setTimeValue] = useState('');

  useEffect(() => {
    if (chatSelected) {
      const socket = io(process.env.REACT_APP_API_URL as string, {
        reconnection: true,
      });

      socket.on('connect', () => {
        // console.log('socket connected');
        socket.emit('join-room', { roomID: chatSelected.id });
      });

      socket.on('user-disconnected', () => {
        // console.log('user disconnected-- closing peers', userData);
      });

      socket.on('disconnect', () => {
        // console.log('socket disconnected --');
      });

      socket.on('error', () => {
        // console.log('socket error --', err);
      });

      socket.on('new-message', (data: IMessageResponse) => {
        setMessages((state) => {
          const checkMessage = state.find((message) => message.id === data.id);
          if (
            !checkMessage &&
            user.id !== data.temp_user_id &&
            user.id !== data.user_id
          ) {
            const newMessage: IMessage = {
              id: data.id,
              chat_id: data.chat_id,
              avatar_id: data.avatar_id,
              user_id: data.temp_user_id,
              me: user.id === data.temp_user_id,
              participants: true,
              avatar: data.avatar.avatar_url,
              name: user.id === data.temp_user_id ? 'You' : data.name,
              text: data.content,
              time: data.time,
              approved: data.approved,
            };
            return [newMessage, ...state];
          }
          return state;
        });
      });
    }
  }, [chatSelected, user.id]);

  useEffect(() => {
    const tempUserDataString = localStorage.getItem(
      '@AUTOAFFILIATE:tempUserData'
    );
    if (!tempUserDataString) {
      const tempUserDataAux = {
        id: user.id,
        name: user.name,
      };
      localStorage.setItem(
        '@AUTOAFFILIATE:tempUserData',
        JSON.stringify(tempUserDataAux)
      );
      setTempUserData(tempUserDataAux);
    } else {
      setTempUserData(JSON.parse(tempUserDataString));
    }
  }, [user.id, user.name]);

  useEffect(() => {
    setLoading(true);
    api
      .get<IChatResponse[]>('chats', {
        params: {
          type: 'webinar',
        },
      })
      .then((response) => {
        const data = response.data.map((chat) => {
          return {
            id: chat.referrer_code,
            name: `Chat ${chat.referrer_code || 'Not Defined'}`,
            message: '',
          };
        });
        setChats(data);
        setLoading(false);
      });
  }, [user.id]);

  useEffect(() => {
    if (params.reference) {
      api
        .get<IChatResponse>(`chats/${params.reference}`)
        .then(async (response) => {
          const responseMessages = await api.get<IMessageResponse[]>(
            `chats-messages/${response.data.id}`,
            {
              params: {
                all: true,
              },
            }
          );
          const data = responseMessages.data.map((message) => ({
            id: message.id,
            chat_id: message.chat_id,
            avatar_id: message.avatar_id,
            user_id: message.temp_user_id,
            me: user.id === message.temp_user_id,
            participants: true,
            avatar: message.avatar.avatar_url,
            name: user.id === message.temp_user_id ? 'You' : message.name,
            text: message.content,
            time: message.time,
            approved: message.approved,
          }));
          setMessages(data.reverse());
          setChatSelected({
            id: response.data.id,
            name: response.data.referrer_code.replace(/-/g, ' '),
            message: '',
          });
        });
    }
  }, [params.reference, user.id]);

  const handleInfiniteLoad = useCallback(async () => {
    // console.log('AQUI');
  }, []);

  const handleKeyPress = useCallback((e) => {
    if (
      (e.keyCode === 13 || e.which === 13 || e.key === 'Enter') &&
      !e.shiftKey
    ) {
      e.preventDefault();
      formRef.current?.submitForm();
    }
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});

        const schema = yup.object().shape({
          message: yup.string().required('Message is required'),
          timeVideo: yup.string().required('Time is required'),
        });

        await schema.validate(data, { abortEarly: false });

        const timeAux = data.timeVideo.split(':');

        const hoursSeconds = parseInt(timeAux[0], 10) * 3600;
        const minutesSeconds = parseInt(timeAux[1], 10) * 60;
        const seconds = parseInt(timeAux[2], 10);
        const time = hoursSeconds + minutesSeconds + seconds;

        const formData = {
          chat_id: chatSelected.id,
          avatar_id: tempUserData.avatar_id,
          temp_user_id: user.id,
          name: user.name,
          content: data.message,
          time,
          approved: true,
        };

        const response = await api.post('chats-messages', formData);

        if (!tempUserData.avatar_id) {
          const newTempUserData = {
            id: response.data.user_id,
            avatar_id: response.data.avatar_id,
            name: user.name,
          };
          setTempUserData(newTempUserData);
          localStorage.setItem(
            '@AUTOAFFILIATE:tempUserData',
            JSON.stringify(newTempUserData)
          );
        }

        setMessages((state) => [
          {
            id: response.data.id,
            chat_id: response.data.chat_id,
            avatar_id: response.data.avatar_id,
            user_id: response.data.temp_user_id,
            me: true,
            participants: false,
            avatar:
              response.data.temp_user_id ===
              'fbef9550-54aa-4899-aa76-618568edb0fd'
                ? 'https://cdn.autoaffiliate.ai/avatars/7df85a7dc82a069bbea3-david-blue.png'
                : response.data.avatar.avatar_url,
            name: 'You',
            text: response.data.content,
            time: response.data.time,
            approved: response.data.approved,
          },
          ...state,
        ]);

        setReply(false);
        setTimeValue('');
        setMessageSelected({} as IMessage);
        formRef.current?.reset();
      } catch (error) {
        if (error instanceof yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'Looks like what you were trying to do didn’t work, please try again.',
            'error'
          );
        }
      }
    },
    [chatSelected.id, tempUserData.avatar_id, user.id, user.name]
  );

  const handleClickApprove = useCallback((message) => {
    setMessageSelected(message);
    setShowApprove(true);
  }, []);

  const handleClickDelete = useCallback((message) => {
    setMessageSelected(message);
    setShowDelete(true);
  }, []);

  const handleClickReply = useCallback(
    (message) => {
      if (messageSelected.id === message.id) {
        setMessageSelected({} as IMessage);
        setReply(false);
        setTimeValue('');
      } else {
        setMessageSelected(message);
        setReply(true);
        const time = message.time + 30;
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        setTimeValue(
          `${hours.toString().padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
        );
      }
    },
    [messageSelected.id]
  );

  const handleClose = useCallback(() => {
    setShowApprove(false);
    setShowDelete(false);
  }, []);

  const handleApprove = useCallback(async () => {
    setLoading(true);
    try {
      await api.put(`chats-messages/${messageSelected.id}`, {
        chat_id: messageSelected.chat_id,
        avatar_id: messageSelected.avatar_id,
        user_id: messageSelected.user_id,
        temp_user_id: messageSelected.user_id,
        name: messageSelected.name,
        content: messageSelected.text,
        time: messageSelected.time,
        approved: true,
      });

      const newMessages = messages.slice();

      const messageIndex = newMessages.findIndex(
        (message) => message.id === messageSelected.id
      );

      if (messageIndex >= 0) {
        newMessages[messageIndex].approved = true;

        setMessages(newMessages);
        setMessageSelected({} as IMessage);
      }

      Swal.fire('Good job!', 'Message approved successfully.', 'success').then(
        () => {
          handleClose();
          setLoading(false);
        }
      );
    } catch (error) {
      Swal.fire(
        'Opss...',
        'An error has occurred, please try again.',
        'error'
      ).then(() => setLoading(false));
    }
  }, [handleClose, messageSelected, messages]);

  const handleDelete = useCallback(async () => {
    setLoading(true);
    try {
      await api.delete(`chats-messages/${messageSelected.id}`);

      const newMessages = messages.filter(
        (message) => message.id !== messageSelected.id
      );

      setMessages(newMessages);
      setMessageSelected({} as IMessage);

      Swal.fire('Good job!', 'Message deleted successfully.', 'success').then(
        () => {
          handleClose();
          setLoading(false);
        }
      );
    } catch (error) {
      Swal.fire(
        'Opss...',
        'An error has occurred, please try again.',
        'error'
      ).then(() => setLoading(false));
    }
  }, [handleClose, messageSelected.id, messages]);

  const messagesData = useMemo(() => {
    const newMessages = messages.map((message) => {
      if (message.user_id === 'fbef9550-54aa-4899-aa76-618568edb0fd') {
        return {
          ...message,
          avatar:
            'https://cdn.autoaffiliate.ai/avatars/7df85a7dc82a069bbea3-david-blue.png',
        };
      }
      return message;
    });

    newMessages.sort((a, b) => {
      return a.time - b.time;
    });

    return newMessages;
  }, [messages]);

  const handleCloseReply = useCallback(() => {
    setReply(false);
    setMessageSelected({} as IMessage);
    setTimeValue('');
  }, []);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h4 h2-lg mb-4">Chats</h1>
                  </div>
                </div>
              </div>
            </Welcome>

            <Content className="p-4">
              <div className="row justify-content-between">
                <div className="col-lg-3">
                  <div className="d-flex mb-4" />
                  <Notifications>
                    <div className="height-notifications overflow-auto">
                      {chats.map((data) => (
                        <Link
                          to={`${process.env.PUBLIC_URL}/chats/${data.id}`}
                          className="d-flex justify-content-between cursor-pointer notifications-hover p-3 rounded w-100"
                        >
                          <div>
                            <h2 className="h3 font-weight-medium mb-2">
                              {data.name}
                            </h2>
                            <p className="mb-0">{data.message.slice(0, 90)}…</p>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </Notifications>
                </div>
                <ChatArea className="col-lg-9">
                  {Object.keys(chatSelected).length > 0 ? (
                    <Form ref={formRef} onSubmit={handleSubmit} className="p-4">
                      <div className="border-user d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <FaRegUserCircle size={24} color="#C1C1C1" />

                          <h2 className="ml-4 mb-0">{chatSelected.name}</h2>
                        </div>
                      </div>
                      <ChatText
                        flipped
                        scrollLoadThreshold={100}
                        onInfiniteLoad={handleInfiniteLoad}
                        className="bodyChat p-4"
                      >
                        {messagesData.map((message) => (
                          <Fragment key={message.id}>
                            {message.user_id === user.id ? (
                              <div className="w-100 d-flex justify-content-end align-items-end mb-2">
                                <div className="d-flex mb-2 ml-2">
                                  <button
                                    type="button"
                                    className="btn btn-delete mr-2"
                                    onClick={() => handleClickDelete(message)}
                                  >
                                    <BsX size={24} color="#fff" />
                                  </button>
                                </div>
                                <div className="d-flex align-items-end">
                                  <div>
                                    <span
                                      className={`${
                                        message.me
                                          ? 'name-you'
                                          : 'name-participants'
                                      } d-block name-chat mb-2 text-right`}
                                    >
                                      {message.name}
                                    </span>
                                    <div className="bg-dark-gray pt-2 pb-2 px-2 mb-2">
                                      {message.text}
                                    </div>
                                  </div>
                                  <img
                                    src={message.avatar}
                                    alt="Avatar"
                                    className="avatar ml-2"
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="w-100 d-flex mb-2">
                                <div className="d-flex align-items-end">
                                  <img
                                    src={message.avatar}
                                    alt="Avatar"
                                    className="avatar mr-2"
                                  />
                                  <div>
                                    <span
                                      className={`${
                                        message.me
                                          ? 'name-you'
                                          : 'name-participants'
                                      } d-block name-chat mb-2`}
                                    >
                                      {message.name}
                                    </span>
                                    <div className="d-flex align-items-center">
                                      <div className="bg-light-gray pt-2 pb-2 px-2 mb-2">
                                        {message.text}
                                      </div>
                                      <div className="d-flex mb-2 ml-2">
                                        {!message.approved && (
                                          <button
                                            type="button"
                                            className="btn btn-approve"
                                            onClick={() =>
                                              handleClickApprove(message)
                                            }
                                          >
                                            <BsCheck size={24} color="#fff" />
                                          </button>
                                        )}
                                        <button
                                          type="button"
                                          className="btn btn-delete mx-1"
                                          onClick={() =>
                                            handleClickDelete(message)
                                          }
                                        >
                                          <BsX size={24} color="#fff" />
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-reply"
                                          onClick={() =>
                                            handleClickReply(message)
                                          }
                                        >
                                          <BsFillReplyFill
                                            size={20}
                                            color="#fff"
                                          />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Fragment>
                        ))}
                      </ChatText>
                      <div className="row mb-4">
                        <div className="col-12">
                          <div className="input-box p-3">
                            <div className="p-3">
                              <div className="row align-items-center">
                                <div className="col-12">
                                  <div
                                    className={`reply ${
                                      reply ? 'active mb-2' : 'mb-0'
                                    }`}
                                  >
                                    <div className="detail" />
                                    {reply && (
                                      <div>
                                        <p className="font-weight-bold small mb-1">
                                          {messageSelected.name}
                                        </p>
                                        <p className="mb-0 small">
                                          {messageSelected.text}
                                        </p>
                                      </div>
                                    )}
                                    {reply && (
                                      <button
                                        type="button"
                                        className="btn btn-close-reply"
                                        onClick={handleCloseReply}
                                      >
                                        <AiOutlineClose
                                          size={18}
                                          color="#fff"
                                        />
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-10">
                                  <Textarea
                                    id="message"
                                    name="message"
                                    className="w-100 border-0 textarea"
                                    placeholder="Message"
                                    rows={2}
                                    onKeyPress={handleKeyPress}
                                  />
                                </div>
                                <div className="col-lg-2">
                                  <InputMask
                                    kind="datetime"
                                    options={{
                                      format: 'HH:mm:ss',
                                    }}
                                    id="timeVideo"
                                    name="timeVideo"
                                    placeholder="hh:mm:ss"
                                    className="py-2 mb-2"
                                    value={timeValue}
                                  />
                                  <button
                                    type="submit"
                                    className="d-flex justify-content-center align-items-center w-100 btn-grey px-3 py-1"
                                  >
                                    Send&nbsp;&nbsp;
                                    <IoSend size={19} color="#fff" />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  ) : (
                    <div className="d-flex justify-content-center align-items-center no-chat">
                      <div className="icon">
                        <BsChatDots color="#9C9C9C" size={50} />
                      </div>
                      <p className="h2 text-center mb-0 ml-3">
                        Selecione uma conversa
                      </p>
                    </div>
                  )}
                </ChatArea>
              </div>
            </Content>
          </div>
        </div>
      </div>
      <Modal show={showApprove} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="ml-auto">Approve message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0 text-center">
            Are you sure you want to approve this message?
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-around">
          <button
            type="button"
            onClick={handleClose}
            className="w-25 btn-grey px-3 py-2"
          >
            NO
          </button>
          <button
            type="button"
            onClick={handleApprove}
            className="w-25 btn-grey px-3 py-2"
          >
            YES
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="ml-auto">Delete message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0 text-center">
            Are you sure you want to delete this message?
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-around">
          <button
            type="button"
            onClick={handleClose}
            className="w-25 btn-grey px-3 py-2"
          >
            NO
          </button>
          <button
            type="button"
            onClick={handleDelete}
            className="w-25 btn-grey px-3 py-2"
          >
            YES
          </button>
        </Modal.Footer>
      </Modal>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default Chats;
