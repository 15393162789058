import React from 'react';

import { Link, useParams } from 'react-router-dom';
import { Container, Welcome } from './styles';

import filter from '~/assets/icons/filter-white.svg';
import articlesButton from '~/assets/icons/b-white.svg';
import bannersIcon from '~/assets/icons/banners-icon.svg';
import swipeIcon from '~/assets/icons/swipe.svg';
import SalesFunnels from './SalesFunnels';
import Articles from './Articles';
import Banners from './Banners';
import Swipe from './Swipe';

interface IParams {
  type: string;
}

const PromotionalMaterial: React.FC = () => {
  const params = useParams<IParams>();

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row justify-content-end">
                  <div className="col-12">
                    <div className="d-xl-flex mb-3">
                      <h1 className="h3 h2-lg mb-3 mt-4 mt-sm-0">
                        Promotional material
                      </h1>
                      <div className="d-md-flex align-items-center ml-auto">
                        <Link
                          to={`${process.env.PUBLIC_URL}/promotional-material/sales-funnels`}
                          className="btn-funnels-articles mx-auto ml-md-0 mr-md-4 mt-4 mt-lg-0"
                        >
                          <img src={filter} alt="Logo" />
                          <span>Sales Funnels</span>
                        </Link>
                        <Link
                          to={`${process.env.PUBLIC_URL}/promotional-material/blog-articles`}
                          className="btn-funnels-articles mx-auto ml-md-0 mr-md-4 mt-4 mt-lg-0"
                        >
                          <img src={articlesButton} alt="Logo" />
                          <span>Blog Articles</span>
                        </Link>
                        <Link
                          to={`${process.env.PUBLIC_URL}/promotional-material/banners`}
                          className="btn-funnels-articles mx-auto ml-md-0 mr-md-4 mt-4 mt-lg-0"
                        >
                          <img src={bannersIcon} alt="Logo" />
                          <span>Banners</span>
                        </Link>
                        <Link
                          to={`${process.env.PUBLIC_URL}/promotional-material/swipe-files`}
                          className="btn-funnels-articles mx-auto ml-md-0 mr-md-0 mt-4 mt-lg-0"
                        >
                          <img src={swipeIcon} alt="Logo" />
                          <span>Swipe Files</span>
                        </Link>
                      </div>
                    </div>
                    <p className="my-5 mb-lg-0 mb-xxl-4 font-weight-300">
                      Below you will find several links to promotional content,
                      funnels, blogs, etc. Each link carries your personal
                      referal code.
                      <br />
                      These high converting pages are created to help you grow
                      your referral list within our platorm. Enjoy!
                    </p>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          {params.type === 'sales-funnels' && <SalesFunnels />}
          {params.type === 'blog-articles' && <Articles />}
          {params.type === 'banners' && <Banners />}
          {params.type === 'swipe-files' && <Swipe />}
        </div>
      </div>
    </Container>
  );
};

export default PromotionalMaterial;
