import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import api from '~/services/api';

import { Container, Card } from './styles';

import edit from '~/assets/icons/edit-icon.svg';

interface IDownloads {
  id: string;
  name: string;
  link: string;
  resource_url: string;
  thumbnail: {
    thumbnail_url: string;
  };
  slug: string;
}

interface IDownloadsProps {
  search?: string;
}

const Downloads: React.FC<IDownloadsProps> = ({ search }) => {
  const [downloads, setDownloads] = useState<IDownloads[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    api
      .get('resources', {
        params: {
          type: 'Downloads',
          search,
        },
      })
      .then((response) => {
        setDownloads(response.data);
        setLoading(false);
      });
  }, [search]);

  return (
    <Container className="col-12">
      <div className="row">
        {downloads.map((download) => (
          <div key={download.id} className="col-md-6 col-lg-4 col-xl-3 my-2">
            <Card
              className="d-block p-relative w-100 thumb"
              src={download.thumbnail.thumbnail_url}
            >
              <div className="short-title p-absolute w-100 p-2 p-xxl-3">
                <div className="row h-100">
                  <div className="col-12">
                    <p className="h6 h5-xxl h4-1800 font-weight-600 mb-0 py-2 pl-3 mt-2 w-100">
                      {download.name}
                    </p>
                  </div>
                  <div className="col-12">
                    <div className="row h-100 align-items-end">
                      <div className="col-12">
                        <div className="see-more-link mb-2">
                          <Link
                            to={`${process.env.PUBLIC_URL}/resources/update/${download.slug}`}
                            className="btn h6 px-4 py-1 py-sm-2 ml-3 mt-sm-3 mt-xl-0 mb-0"
                          >
                            Edit <img src={edit} className="ml-2" alt="Edit" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>

      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Downloads;
