const compare = (a: any, b: any) => {
  if (a.created_at < b.created_at) {
    return -1;
  }
  if (a.created_at > b.created_at) {
    return 1;
  }
  return 0;
};

function orderBy(objs: any): any {
  return objs.sort(compare);
}

export default orderBy;
