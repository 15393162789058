import React, { useState, useEffect, useCallback, useRef } from 'react';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { io } from 'socket.io-client';

import { Link } from 'react-router-dom';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import {
  Container,
  AddButton,
  ArticleSkeleton,
  Article,
  Modal,
} from './styles';
import Input from '~/components/Input';
import Select from '~/components/Select';
import InputTags from '~/components/InputTags';

import plus from '~/assets/icons/plus-admin-icon2.svg';
import edit from '~/assets/icons/edit-icon.svg';

interface IArticles {
  id: string;
  articleID: string;
  title?: string;
  slug?: string;
  generating: boolean;
  articleFiles?: {
    id: string;
    file_url: string;
  }[];
}

const socket = io(process.env.REACT_APP_API_URL as string, {
  reconnection: true,
});

const Articles: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [articles, setArticles] = useState<IArticles[]>([]);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showGenerateWithIa, setShowGenerateWithIa] = useState(false);
  const [subheadings, setSubheadings] = useState<string[]>([]);

  useEffect(() => {
    socket.on(`articleGenerated`, (data: IArticles) => {
      setArticles((state) => {
        const newArticles = state.slice();
        const articleIndex = newArticles.findIndex(
          (article) => article.id === data.id
        );
        if (articleIndex >= 0) {
          newArticles[articleIndex] = data;
        }

        return newArticles;
      });
    });
  }, []);

  const handleLoadArticles = useCallback(async () => {
    const response = await api.get('articles', {
      params: {
        isAdmin: true,
      },
    });
    setArticles(response.data);
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    handleLoadArticles();
  }, [handleLoadArticles]);

  const handleClickAddArticle = useCallback(() => {
    setShow(true);
  }, []);

  const handleClickGenerateWithAi = useCallback(() => {
    setShowGenerateWithIa(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleCloseGenerateWithAi = useCallback(() => {
    setShowGenerateWithIa(false);
  }, []);

  const handleChangeSubheadings = useCallback((valuesData) => {
    const values = valuesData.map((value: { value: string }) => value.value);
    setSubheadings(values);
  }, []);

  const handleRemoveSubheadings = useCallback((valuesData) => {
    const values = valuesData.map((value: { value: string }) => value.value);
    setSubheadings(values);
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('Title is a required'),
          tone: Yup.string().required('Tone is a required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          title: data.title,
          subheadings: subheadings.join(';'),
          tone: data.tone,
          length: data.length,
        };

        const response = await api.post('articles', formData);

        setArticles((state) => [response.data, ...state]);

        handleCloseGenerateWithAi();
        handleClose();
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          );
        }
      }
    },
    [handleClose, handleCloseGenerateWithAi, subheadings]
  );

  return (
    <Container>
      <div className="col-lg-12 my-3">
        <div className="d-flex w-100 my-5 my-md-4 my-lg-0 justify-content-center justify-content-sm-start justify-content-lg-end">
          <AddButton
            type="button"
            className="h6 mb-0 py-2 px-4 small normal-sm"
            onClick={handleClickAddArticle}
          >
            <img src={plus} alt="plus" className="my-1 mr-3" />
            New Article
          </AddButton>
        </div>
      </div>
      {articles.map((article) => (
        <div key={article.id} className="col-lg-4 mb-4">
          {article.generating ? (
            <ArticleSkeleton className="d-block p-relative w-100 thumb">
              <p>Generating...</p>
            </ArticleSkeleton>
          ) : (
            <Article
              src={
                article.articleFiles && article.articleFiles.length > 0
                  ? article.articleFiles[0].file_url
                  : ''
              }
              to={`${process.env.PUBLIC_URL}/promotional-material/blog-articles/${article.slug}`}
              className="d-block p-relative w-100 thumb"
            >
              <div className="short-title p-absolute w-100 p-2 p-xxl-3">
                <div className="row h-100">
                  <div className="col-12">
                    <p className="h6 h5-xxl h4-1800 font-weight-600 mb-0 py-2 pl-3 mt-2 w-100">
                      {article.title}
                    </p>
                  </div>
                  <div className="col-12">
                    <div className="row h-100 align-items-end">
                      <div className="col-12">
                        <div className="see-more-link mb-2">
                          <p className="btn h6 px-0 py-1 py-sm-2 ml-3 mt-sm-3 mt-xl-0 mb-0">
                            Edit <img src={edit} className="ml-2" alt="Edit" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Article>
          )}
        </div>
      ))}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>What do you want to do?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-6">
              <button
                type="button"
                onClick={handleClickGenerateWithAi}
                className="w-100 btn text-white btn-grey px-3 py-2"
              >
                Generate with AI
              </button>
            </div>
            <div className="col-lg-6">
              <Link
                to={`${process.env.PUBLIC_URL}/promotional-material/blog-articles/register`}
                className="w-100 btn text-white btn-grey px-3 py-2"
              >
                Create article manually
              </Link>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-end" />
      </Modal>
      <Modal show={showGenerateWithIa} onHide={handleCloseGenerateWithAi}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>New Article</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label className="w-100 d-flex flex-column mb-3 d-block">
              <span className="mb-1">Title*</span>
              <Input name="title" />
            </label>
            <label className="w-100 d-flex flex-column">
              <span className="mb-1">Subheadings</span>
              <InputTags
                name="subheadings"
                onChange={handleChangeSubheadings}
                onRemove={handleRemoveSubheadings}
              />
            </label>
            <small className="mb-3 d-block">Add 4 to 10 subheadings</small>
            <label className="w-100 d-flex flex-column mb-3 d-block">
              <span className="mb-1">Tone*</span>
              <Select
                name="tone"
                options={[
                  {
                    id: 'neutral',
                    value: 'Neutral',
                    selected: true,
                  },
                  {
                    id: 'engaging',
                    value: 'Engaging',
                    selected: false,
                  },
                  {
                    id: 'professional',
                    value: 'Professional',
                    selected: false,
                  },
                  {
                    id: 'playful',
                    value: 'Playful',
                    selected: false,
                  },
                ]}
              />
            </label>
            <label className="w-100 d-flex flex-column">
              <span className="mb-1">Wordcount length</span>
              <Input type="number" name="length" />
            </label>
            <small>Default 1500 Wordcount</small>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-end">
            <button type="submit" className="w-25 btn-grey px-3 py-2">
              Generate
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Articles;
