import styled from 'styled-components';

interface IBox {
  items: number;
  hasError?: boolean;
}

interface IItem {
  src: string;
}

export const Container = styled.div`
  .text-danger {
    color: #dc3545 !important;
  }
`;

export const Box = styled.div<IBox>`
  background: #242526;
  border: 1.5px solid ${(props) => (props.hasError ? '#c53030' : '#2c2c2c')};
  border-radius: 15px;
  min-height: 500px;
  height: ${(props) => props.items * 200 + 30}px;

  .dropzone {
    width: 100%;
    min-height: 500px;
    height: ${(props) => props.items * 200}px;
  }

  p {
    color: #606060;

    span {
      font-weight: bold;
      text-decoration: underline;
      color: #cbccce;
    }
  }

  .grab-item {
    opacity: 1 !important;
    cursor: grab;

    :active {
      cursor: grabbing;
    }
  }
`;

export const Item = styled.div<IItem>`
  > div {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url('${(props) => props.src}');
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    width: 100%;
    height: 150px;

    p {
      color: #e4e4e4;
      font-weight: 600;
    }
  }
`;

export const Placeholder = styled.div`
  height: 500px;
`;
