import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import api from '~/services/api';

import { Container } from './styles';

interface IAchievements {
  userId: string;
}

const Achievements: React.FC<IAchievements> = ({ userId }) => {
  const [position, setPosition] = useState(0);
  const [origin, setOrigin] = useState('');
  const [referrals, setReferrals] = useState(0);
  const [referrerUserId, setReferrerUserId] = useState('');
  const [referencedBy, setReferencedBy] = useState('');

  useEffect(() => {
    api.get(`users/admin/${userId}`).then((response) => {
      setOrigin(response.data.origin);
      setPosition(response.data.waitlist.position);
      setReferrals(
        response.data.affiliate ? response.data.affiliate.referrers.length : 0
      );
      if (response.data.referrer) {
        setReferrerUserId(response.data.referrer.affiliate.user.id);
        setReferencedBy(response.data.referrer.affiliate.user.name);
      }
    });
  }, [userId]);

  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-12 col-xl-6 px-2">
            <div className="border-gray-achievements mx-auto my-2">
              <div className="p-3 d-flex d-md-block d-lg-flex d-xl-block justify-content-between align-items-center">
                <h3 className="h6 small-md normal-lg mb-0 mb-md-3 mb-lg-0 mb-xl-3">
                  Waitlist
                  <br />
                  Position
                </h3>
                <p className="h1 font-weight-bold mb-0">
                  {position > 0 ? position.toString().padStart(2, '0') : '00'}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-12 col-xl-6 px-2">
            <div className="border-gray-achievements mx-auto my-2">
              <div className="p-3 d-flex d-md-block d-lg-flex d-xl-block justify-content-between align-items-center">
                <h3 className="h6 small-md normal-lg mb-0 mb-md-3 mb-lg-0 mb-xl-3">
                  Your
                  <br />
                  Referrals
                </h3>
                <p className="h1 font-weight-bold mb-0">
                  {referrals.toString().padStart(2, '0')}
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 px-2">
            <div className="border-gray-achievements mx-auto my-2">
              <div className="p-3 d-flex d-md-block d-lg-flex d-xl-block justify-content-between align-items-center">
                <h3 className="h6 small-md normal-lg mb-0 mb-md-3 mb-lg-0 mb-xl-3">
                  Referred by
                </h3>
                <Link
                  to={`${process.env.PUBLIC_URL}/users/update/${referrerUserId}`}
                  className="h3 font-weight-bold mb-0"
                >
                  {referencedBy}
                </Link>
              </div>
            </div>
            <div className="border-gray-achievements mx-auto mt-3 mb-2">
              <div className="p-3 d-flex d-md-block d-lg-flex d-xl-block justify-content-between align-items-center">
                <h3 className="h6 small-md normal-lg mb-0 mb-md-3 mb-lg-0 mb-xl-3">
                  Origin
                </h3>
                <p className="h5 font-weight-bold mb-0">{origin}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Achievements;
