import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Modal } from './styles';
import Input from '~/components/Input';

interface ICategory {
  id: string;
  name: string;
  slug: string;
}

interface IEditCategoryProps {
  type: 'courses' | 'trainings';
  category_id: string;
  value: string;
  show: boolean;
  onSubmit?(data: ICategory): void;
  onClose?(): void;
}

const EditCategory: React.FC<IEditCategoryProps> = ({
  category_id,
  type,
  show,
  value,
  onSubmit,
  onClose,
}) => {
  const formRef = useRef<FormHandles>(null);

  const [showModal, setShowModal] = useState(false);
  const [newCategory, setNewCategory] = useState('');

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const handleClose = useCallback(() => {
    setShowModal(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleNewCategoryChange = useCallback((e) => {
    setNewCategory(e.target.value);
  }, []);

  const handleClick = useCallback(() => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  }, []);

  const handleSubmit = useCallback(
    async (dataForm) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          category_name: Yup.string().required('New category is a required'),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        const { category_name } = dataForm;

        const slug = category_name
          .replace(/[àáâãäå]/g, 'a')
          .replace(/æ/g, 'ae')
          .replace(/ç/g, 'c')
          .replace(/[èéêë]/g, 'e')
          .replace(/[ìíîï]/g, 'i')
          .replace(/ñ/g, 'n')
          .replace(/[òóôõö]/g, 'o')
          .replace(/œ/g, 'oe')
          .replace(/[ùúûü]/g, 'u')
          .replace(/[ýÿ]/g, 'y')
          .replace(/[^a-zA-Z0-9 -]/g, '')
          .replace(/ /g, '-')
          .toLowerCase();

        const formData = {
          name: category_name,
          slug,
          type,
        };

        const response = await api.put(`categories/${category_id}`, formData);

        if (response.data) {
          if (onSubmit) {
            onSubmit(response.data);
          }

          handleClose();
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          );
        }
      }
    },
    [category_id, handleClose, onSubmit, type]
  );

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title className="ml-auto">Edit category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="category_name">category</label>
          <Input
            id="category_name"
            name="category_name"
            value={newCategory || value}
            onChange={handleNewCategoryChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={handleClick}
            className="btn-grey px-3 py-2"
          >
            Salvar
          </button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditCategory;
