import styled from 'styled-components';

interface IBioPhoto {
  src: string;
}

export const Container = styled.div`
  h1 {
    color: #e4e4e4;
  }

  .book-mark svg {
    width: 50px;
    height: 50px;
  }

  .p-relative {
    z-index: 1;
  }

  .bg-gray {
    background-color: #202020;
    border-radius: 20px;
    z-index: -1;
  }

  ul {
    list-style: none;

    b {
      color: #969696;
      font-weight: normal;
    }

    span {
      color: #cbccce;
      font-weight: 700;
    }
  }

  .mh-description {
    min-height: 150px;
  }

  .mh-500px {
    min-height: 500px;
  }

  p {
    color: #6e6e6e;
  }

  button {
    padding: 13px 40px;

    opacity: 1 !important;
    color: #18191a !important;
    background: #969696;
    border-radius: 15px;
    cursor: default;

    small {
      margin-left: 10px;
    }
  }
  @media screen and (max-width: 1199px) {
    .mh-description {
      min-height: 345px;
    }
  }
  @media screen and (max-width: 991px) {
    .mh-quote {
      min-height: 168px;
    }
    .mh-description {
      min-height: 150px;
    }

    .skeleton {
      border-radius: 45px;
    }

    .book-mark svg {
      width: 31px;
      height: 31px;
    }
  }

  @media screen and (max-width: 767px) {
    .mh-quote {
      min-height: 50px;
    }

    button {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media screen and (max-width: 575px) {
    button {
      width: 100%;
    }
  }

  @media screen and (max-width: 350px) {
    button {
      padding: 13px 7px;
    }
  }
`;

export const BioPhoto = styled.div<IBioPhoto>`
  width: 400px;
  height: 500px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 65px;
  margin-top: -30px;

  top: 100px;
  left: -70px;

  @media screen and (max-width: 1399px) {
    width: 363px;
    height: 408px;
  }

  @media screen and (max-width: 1199px) {
    top: 65px;
  }

  @media screen and (max-width: 991px) {
    width: 345px;
    height: 438px;
    border-radius: 45px;
    top: 47px;
    left: 19px;
  }

  @media screen and (max-width: 768px) {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: -100px;
    position: relative;
    left: 0px;
    margin-bottom: 3rem;
  }
  @media screen and (max-width: 575px) {
    width: 260px;
    height: 290px;
  }
`;
