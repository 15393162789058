import styled from 'styled-components';
import { lighten } from 'polished';
import ReactChatView from 'react-chatview';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div``;

export const Welcome = styled.div`
  p {
    color: #969696;
  }

  .btn-new-ask {
    color: #dadada !important;
    border: solid 1px rgba(96, 96, 96, 1);
    background-color: rgba(32, 32, 32, 1);
    padding: 0.8rem 0;
    :hover {
      color: ${lighten(0.2, '#dadada')}!important;
      border: solid 1px ${lighten(0.1, 'rgba(96, 96, 96, 1)')};
      img {
        filter: brightness(2.5);
      }
      text-decoration: none;
    }
  }
`;

export const Content = styled.div`
  border-radius: 20px;
  background-color: #242526;

  .no-chat {
    border-radius: 20px;
    background-color: #202020;
    height: calc(100vh - 180px);
  }
`;

export const Notifications = styled.div`
  h2 {
    color: #e4e4e4;
  }
  p {
    color: #fff;
  }

  hr {
    border-top: 1px solid #606060;
  }

  .height-notifications {
    height: calc(100vh - 180px);
  }

  .notifications-hover {
    transition-duration: 0.3s;

    :hover {
      background-color: ${lighten(0.03, '#707070')};
      text-decoration: none;
    }
  }
`;

export const ChatArea = styled.div`
  form {
    border-radius: 20px;
    background-color: #18191a;

    .bodyChat,
    .input-box {
      border-radius: 20px;
      background-color: #202020;
    }

    .bodyChat {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .input-box {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;

      .textarea {
        textarea {
          resize: none;
        }
      }

      > div {
        border-radius: 20px;
        background-color: #18191a;
      }
    }
  }
`;

export const ChatText = styled(ReactChatView)`
  margin-top: 1rem;
  overflow-y: auto;
  height: calc(100vh - 400px);

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .bg-dark-gray {
    color: #fff;
    font-weight: 500;
    border-radius: 10px 10px 0 10px;
    background-color: #8a8a8a;
  }

  .bg-light-gray {
    color: #3d3f41;
    font-weight: 500;
    border-radius: 10px 10px 10px 0;
    background-color: #e4e4e4;
  }

  .date-message {
    font-weight: 500;
    color: #b0b6c3;
    font-size: 10px;
  }

  .tail-dark-gray {
    position: relative;
    top: 8px;
    z-index: 100;
    display: block;
    width: 16px;
    height: 13px;
    left: 28px;
  }

  .tail-light-gray {
    position: relative;
    top: 9px;
    z-index: 100;
    display: block;
    width: 16px;
    height: 13px;
    left: -30px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: transparent !important;
    border-radius: 4px;
  }
`;

export const Modal = styled(ModalComponent)``;
