import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  div.rdt_TableHeadRow,
  div.rdt_TableRow {
    > div:first-child {
      max-width: 300px;
    }

    > div:nth-child(3) {
      max-width: 150px;
    }

    > div:last-child {
      max-width: 250px;
    }
  }
  div.rdt_TableHeadRow > div div {
    width: 100%;
    text-align: center;
  }
  .btn-new-course {
    color: #dadada;
    border: solid 1px rgba(96, 96, 96, 1);
    background-color: rgba(32, 32, 32, 1);
    :hover {
      color: ${lighten(0.1, '#dadada')};
      border: solid 1px ${lighten(0.1, 'rgba(96, 96, 96, 1)')};
      text-decoration: none;
      img {
        filter: brightness(2.5);
      }
    }
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #e4e4e4;
  }
  p {
    color: #969696;
  }

  .search {
    padding: 1px;
    border-radius: 100px;
    border: 1px solid #4a4b4b;
    background-color: #202020;

    input {
      margin: 5px 0;
      padding: 0 10px;
      border: none;
      border-radius: 100px;
      background-color: #202020;
    }
  }
`;

export const Product = styled.div`
  p,
  a {
    color: #cbccce;
  }

  a:hover {
    color: ${lighten(0.1, '#cbccce')};
    img {
      filter: brightness(2.5);
    }
  }

  .color-gray {
    color: #606060;
  }
  .box {
    background-color: #202020;
    border-radius: 20px;
    overflow: hidden;

    > img {
      height: 180px;
    }
  }

  .price {
    right: 20px;
  }

  .text-decoration-none {
    text-decoration: none !important;
  }

  .product-img {
    width: 150px;
    border-radius: 25px;
  }

  .bg-products {
    background-color: rgba(32, 32, 32, 1);
    border-radius: 25px;
  }

  .courses {
    height: 100px;
    overflow: auto;
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: transparent;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(51, 51, 51, 1);
    }
  }

  button.btn-edit,
  a.btn-edit {
    width: 40%;
    background: #242526;
    border: 1px solid #4a4b4b;
    color: #8c8c8c;
    text-align: center;

    :hover {
      text-decoration: none;
    }
  }

  @media screen and (max-width: 1199px) {
    .courses {
      height: 130px;
    }
  }
  @media screen and (max-width: 969px) {
    .price {
      bottom: 20px;
    }
  }
`;

export const Course = styled.div`
  span {
    background-color: rgba(41, 41, 41, 1);
    color: #cbccce;
  }
`;

export const ButtonGroup = styled.div`
  button.btn-edit,
  a.btn-edit {
    width: 40%;
    background: #242526;
    border: 1px solid #4a4b4b;
    color: #8c8c8c;
    text-align: center;

    :hover {
      text-decoration: none;
    }
  }
`;
