import styled from 'styled-components';

import mapMask from '~/assets/defaults/map-mask.svg';

export const Container = styled.div`
  mask: url(${mapMask});
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: contain;
`;
