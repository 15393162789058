import styled, { css } from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface ContainerProps {
  hasValidateError?: boolean;
}

interface InputRadio {
  checked?: boolean;
}

export const Container = styled.div<ContainerProps>`
  .button-quiz {
    background: transparent;
    border-radius: 10px;
    border: 1.5px solid
      ${(props) => (props.hasValidateError ? '#c53030' : '#3e3e3e')};
    padding: 5px 10px;
    color: #828282;
  }
`;

export const Question = styled.div`
  border-radius: 10px;
  padding: 10px;

  .checkbox {
    width: auto;
    padding: 0;
    background: none;
    border: 0;

    input {
      width: auto;
    }
  }

  + div {
    margin-top: 10px;
  }

  .answer {
    + .answer {
      margin-top: 10px;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  label {
    color: #e4e4e4 !important;
  }

  label.correct {
    font-weight: 400;
    color: #37c694 !important;
  }

  .input-answers {
    width: calc(100% - 26px);
  }

  span {
    font-weight: 600;
  }

  button {
    border: none;
    background-color: transparent;
  }
`;

export const InputRadio = styled.label<InputRadio>`
  .checkbox {
    position: relative;
    width: 26px;
    height: 26px;
    border: 2px solid ${(props) => (props.checked ? '#37c694' : '#CBCCCE')};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    ${(props) =>
      props.checked &&
      css`
        :after {
          content: '';
          background: #37c694;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          display: block;
        }
      `}

    > div {
      display: none;
    }
  }
`;
