import React, { FC, ComponentType, useMemo } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouterProps,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../hooks/Auth';

import Auth from '~/pages/_Layouts/Auth';
import Default from '~/pages/_Layouts/Default';

interface RouteProps extends ReactDOMRouterProps {
  isPrivate?: boolean;
  component: ComponentType;
}

const Route: FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  const Layout = useMemo(() => {
    return isPrivate ? Default : Auth;
  }, [isPrivate]);

  const pathname = useMemo(() => {
    if (isPrivate && !!user && user.type !== 'master') {
      return '/courses';
    }

    if (isPrivate) {
      return '/';
    }

    return '/dashboard';
  }, [isPrivate, user]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (!!user && user.type !== 'master') {
          return isPrivate === !!user && location.pathname.match(pathname) ? (
            <Layout>
              <Component />
            </Layout>
          ) : (
            <Redirect
              to={{
                pathname,
                state: location,
              }}
            />
          );
        }

        return isPrivate === !!user ? (
          <Layout>
            <Component />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname,
              state: location,
            }}
          />
        );
      }}
    />
  );
};

export default Route;
