import styled from 'styled-components';

export const Container = styled.div`
  .bg-gray {
    background: #242526;
    border-radius: 20px;
    overflow: hidden;

    p {
      color: #565656;
    }
  }
`;

export const Welcome = styled.div`
  p {
    color: #969696;
  }

  .search {
    padding: 1px;
    border-radius: 100px;
    border: 1px solid #4a4b4b;
    background-color: #202020;

    input {
      margin: 5px 0;
      padding: 0 10px;
      border: none;
      border-radius: 100px;
      background-color: #202020;
    }
  }
`;

export const InputsGroup = styled.div`
  background: #202020;
  border-radius: 15px;
`;
