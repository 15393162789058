import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { parseISO, format } from 'date-fns';

import api from '~/services/api';
import { formatPrice } from '~/utils/format';

import { Container, Welcome, NameUserTable } from './styles';
import Table from '~/components/Table';

interface ITableData {
  dataCreated: string;
  withdrawalId: string;
  avatar: string;
  name: string;
  amount_total: string;
  rate: string;
  amount_paid: string;
  cripto_address: string;
  paid?: boolean;
  created_at: string;
}

interface IScheduledWithdrawals {
  id: string;
  created_at: string;
  cripto_address: string;
  amount: number;
  paid?: boolean;
  wallet: {
    affiliate: {
      user: {
        name: string;
        avatar: {
          avatar_url: string;
        };
      };
    };
    amount: number;
  };
}

const ScheduledWithdrawals: React.FC = () => {
  const [scheduledWithdrawals, setScheduledWithdrawals] = useState<
    ITableData[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [rate] = useState(4.5);

  const discountRate = useCallback(
    (amount) => {
      const partAmount = amount - (amount / 100) * rate;
      const part = formatPrice(partAmount);
      return part;
    },
    [rate]
  );

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);

    api
      .get<IScheduledWithdrawals[]>('scheduled-withdrawals')
      .then((response) => {
        const data: ITableData[] = response.data.map((scheduledWithdrawal) => ({
          created_at: scheduledWithdrawal.created_at,
          dataCreated: format(
            parseISO(scheduledWithdrawal.created_at),
            'yyyy-MMM-dd'
          ),
          withdrawalId: scheduledWithdrawal.id,
          avatar: scheduledWithdrawal.wallet.affiliate.user.avatar.avatar_url,
          name: scheduledWithdrawal.wallet.affiliate.user.name,
          cripto_address: scheduledWithdrawal.cripto_address,
          amount_total: formatPrice(scheduledWithdrawal.amount),
          rate: `${rate}%`,
          amount_paid: discountRate(scheduledWithdrawal.amount),
          paid: scheduledWithdrawal.paid,
        }));
        setScheduledWithdrawals(data);
      })
      .finally(() => setLoading(false));
  }, [discountRate, rate]);

  const handleChangePaid = useCallback(
    async (row) => {
      if (row.paid) {
        await api.patch(`scheduled-withdrawals/unset/${row.withdrawalId}`);
      } else {
        await api.patch(`scheduled-withdrawals/set/${row.withdrawalId}`);
      }

      const newScheduledWithdrawals = scheduledWithdrawals.slice();
      const scheduledWithdrawalIndex = newScheduledWithdrawals.findIndex(
        (scheduledWithdrawal) =>
          scheduledWithdrawal.withdrawalId === row.withdrawalId
      );
      if (scheduledWithdrawalIndex > -1) {
        newScheduledWithdrawals[scheduledWithdrawalIndex].paid = !row.paid;
        setScheduledWithdrawals(newScheduledWithdrawals);
      }
    },
    [scheduledWithdrawals]
  );

  const columns = useMemo(
    () => [
      {
        name: 'Date created',
        selector: 'created_at',
        sortable: true,
        cell: (row: ITableData) => row.dataCreated,
      },
      {
        name: 'Withdrawal ID',
        selector: 'withdrawalId',
        sortable: true,
      },
      {
        name: 'User',
        selector: 'name',
        sortable: true,
        cell: (row: ITableData) => (
          <NameUserTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
          >
            <img src={row.avatar} alt={row.name} className="mr-2" />
            <p className="mb-0">{row.name}</p>
          </NameUserTable>
        ),
      },
      {
        name: 'Amount Total',
        selector: 'amount_total',
        sortable: true,
      },
      {
        name: 'Rate',
        selector: 'rate',
        sortable: true,
      },
      {
        name: 'Amount to pay',
        selector: 'amount_paid',
        sortable: true,
      },
      {
        name: 'Cripto Address ',
        selector: 'cripto_address',
        sortable: true,
      },
      {
        name: 'Paid',
        selector: 'paid',
        sortable: true,
        cell: (row: ITableData) => (
          <input
            type="checkbox"
            name="set-paid"
            onChange={() => handleChangePaid(row)}
            checked={row.paid}
          />
        ),
      },
    ],
    [handleChangePaid]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h4 h2-lg mb-4">
                      Scheduled Withdrawals Management
                    </h1>
                  </div>
                </div>
              </div>
            </Welcome>
            <Table
              title={`Scheduled\u00A0Withdrawals`}
              data={scheduledWithdrawals}
              columns={columns}
              pagination
              exportable
            />
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default ScheduledWithdrawals;
