import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IOptions {
  show: boolean;
}

export const Container = styled.div`
  .tableData {
    .rdt_TableHeadRow,
    .rdt_TableRow {
      > div:nth-child(1) {
        max-width: 350px;
      }
      > div:nth-child(2) {
        max-width: 110px;
      }
      > div:nth-child(3) {
        max-width: 130px;
      }
      > div:nth-child(4) {
        max-width: 170px;
      }
      > div:nth-child(5) {
        max-width: 250px;
      }
      > div:nth-child(6) {
        max-width: 190px;
      }
    }

    div:nth-child(2) {
      overflow: unset !important;
    }
  }

  .users-radius {
    border-radius: 20px;
  }

  @media screen and (max-width: 991px) {
    .h-date input {
      height: 41px;
    }
  }

  @media screen and (max-width: 767px) {
  }

  @media screen and (max-width: 575px) {
    .size-users {
      font-size: 12px;
    }
    .users-size {
      display: block;

      margin: auto;
    }
  }

  @media (min-width: 992px) and (max-width: 1250px) {
    .vw80 {
      width: 80vw !important;
    }
  }
`;

export const Welcome = styled.div`
  p {
    color: #969696;
  }
`;

export const NameUserTable = styled.div`
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;

export const OptionUser = styled.div`
  cursor: pointer;
  position: relative;
`;

export const Options = styled.div<IOptions>`
  position: absolute;
  width: 200px;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition-duration: 0.3s;
  display: flex;
  flex-direction: column;
  background-color: #202020 !important;
  border: 1px solid #606060;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 16px;
  padding: 10px;
  z-index: 1;

  button {
    padding: 10px 0;
  }

  hr {
    margin: 0;
    border: none;
    opacity: 1;
    background: #606060;
    height: 1px;
  }

  :after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    border-top: 1px solid #606060;
    border-right: 1px solid #606060;
    background-color: #202020;
    right: -8px;
    top: 50%;
    transform: translateY(-50%) rotateZ(45deg);
  }
`;

export const Modal = styled(ModalComponent)``;
