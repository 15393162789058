import styled from 'styled-components';

export const Container = styled.div`
  .thumbnail-course {
    width: 100%;
    height: 200px;
    border-radius: 20px;
  }

  .infos-course {
    transition-duration: 0.3s;
    z-index: 1;
    width: 100%;
    height: 50px;
    top: calc(100% - 49px);

    .bg-image {
      transition-duration: 0.2s;
      opacity: 0;
      border-radius: 0px 0px 20px 20px;
      mask-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(0, 0, 0, 1)),
        to(rgba(0, 0, 0, 0))
      );
    }

    .infos {
      border-radius: 0px 0px 20px 20px;
      background: linear-gradient(
        188.52deg,
        rgba(1, 1, 1, 0.192) -0.55%,
        rgba(7, 7, 7, 0.8) 95.76%
      );
      padding: 15px 10px;
      overflow: hidden;
    }

    h2 {
      color: #e4e4e4;
    }

    p {
      max-height: 65px;
      overflow-x: auto;
    }
  }

  .infos-course:hover {
    height: 300px;
    z-index: 500;

    .bg-image {
      opacity: 1;
      border-radius: 20px;
    }

    .infos {
      border-radius: 20px;
      backdrop-filter: blur(35px);
    }
  }

  .btn-edit {
    background: #242526;
    border: 1px solid #4a4b4b;
    color: #8c8c8c;

    :hover {
      text-decoration: none;
    }
  }
`;
