import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { GridContextProvider, swap, move } from 'react-grid-dnd';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';
import orderBy from '~/utils/orderBy';

import { Container, UnlistedLessons, ModuleInfo } from './styles';
import Input from '~/components/Input';
import Quizzes, { IQuestion } from '~/components/Quizzes';
import UnlistedLessonsDnd from '~/components/UnlistedLessonsDnd';
import LessonsDnd from '~/components/LessonsDnd';
import CoursesActionTitle from '~/components/CoursesActionTitle';

interface IParams {
  slug: string;
}
interface ILessonsResponse {
  id: string;
  title: string;
  thumbnail: {
    thumbnail_url: string;
  };
  created_at: Date;
}

interface ILesson {
  id: string;
  title: string;
  thumbnail_url: string;
  created_at: Date;
}

interface IFormData {
  title: string;
}

const ModulesRegister: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const params = useParams<IParams>();
  const history = useHistory();
  const [questions, setQuestions] = useState<IQuestion[]>([]);
  const [lessonsError, setLessonsError] = useState('');
  const [unlistedLessons, setUnlistedLessons] = useState<ILesson[]>([]);
  const [lessons, setLessons] = useState<ILesson[]>([]);
  const [courseId, setCourseId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    api.get(`courses/${params.slug}`).then(async (response) => {
      const responseLessons = await api.get<ILessonsResponse[]>(
        `lessons/${response.data.id}/unlisted`
      );
      const data = responseLessons.data.map((lesson) => ({
        id: lesson.id,
        title: lesson.title,
        thumbnail_url: lesson.thumbnail ? lesson.thumbnail.thumbnail_url : '',
        created_at: lesson.created_at,
      }));
      setCourseId(response.data.id);
      setUnlistedLessons(data);
    });
  }, [params.slug]);

  const handleChangeQuizzes = useCallback((questionsData) => {
    if (questionsData[0]) {
      if (questionsData[0].question.length > 0) {
        setQuestions(questionsData);
      } else {
        setQuestions([]);
      }
    } else {
      setQuestions([]);
    }
  }, []);
  const handleSubmit = useCallback(
    async (data: IFormData) => {
      setLessonsError('');
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('Title is a required'),
          lessons: Yup.string().when('$lessons', {
            is: (exist: boolean) => exist,
            then: Yup.string().required('Need at least one lesson'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            lessons: lessons.length === 0,
          },
        });

        const { title } = data;

        const slug = title
          .replace(/[àáâãäå]/g, 'a')
          .replace(/æ/g, 'ae')
          .replace(/ç/g, 'c')
          .replace(/[èéêë]/g, 'e')
          .replace(/[ìíîï]/g, 'i')
          .replace(/ñ/g, 'n')
          .replace(/[òóôõö]/g, 'o')
          .replace(/œ/g, 'oe')
          .replace(/[ùúûü]/g, 'u')
          .replace(/[ýÿ]/g, 'y')
          .replace(/[^a-zA-Z0-9 -]/g, '')
          .replace(/ /g, '-')
          .toLowerCase();

        const formData = {
          course_id: courseId,
          title,
          slug,
        };

        const response = await api.post('modules', formData);

        if (response.data) {
          const lessonsPromise = new Promise<void>((resolve) => {
            if (lessons.length > 0) {
              const lastIndex = lessons.length - 1;
              lessons.forEach(async (lesson, index) => {
                const lessonData = {
                  module_id: response.data.id,
                  order: index,
                };

                await api.patch(`lessons/${lesson.id}/set-module`, lessonData);

                if (lastIndex === index) {
                  resolve();
                }
              });
            } else {
              resolve();
            }
          });

          await lessonsPromise;

          if (questions.length) {
            const examFormData = {
              module_id: response.data.id,
            };

            const responseExam = await api.post('exams', examFormData);
            const exam_id = responseExam.data.id;

            const quizzesPromise = new Promise<void>((resolve) => {
              const lastIndex = questions.length - 1;
              questions.forEach(async (question, index) => {
                if (question.status && question.question.length > 0) {
                  const quiz_id = question.id;
                  switch (question.status) {
                    case 'UPDATED':
                      question.options.forEach(async (option) => {
                        const option_id = option.id;
                        const optionFormData = {
                          quiz_id,
                          answer: option.answer,
                          correct_answer: option.correct_answer,
                        };
                        if (option.status && option.answer.length > 0) {
                          switch (option.status) {
                            case 'UPDATED':
                              await api.put(
                                `options/${option_id}`,
                                optionFormData
                              );
                              break;
                            case 'DELETED':
                              if (option_id) {
                                await api.delete(`options/${option_id}`);
                              }
                              break;
                            default:
                              await api.post('options', optionFormData);
                              break;
                          }
                        }
                      });
                      break;
                    case 'DELETED':
                      if (quiz_id) {
                        await api.delete(`quizzes/${quiz_id}`);
                      }
                      break;
                    default:
                      const questionFormData = {
                        exam_id,
                        question: question.question,
                      };
                      const responseQuestion = await api.post(
                        'quizzes',
                        questionFormData
                      );
                      if (responseQuestion.data) {
                        question.options.forEach(async (option) => {
                          const optionFormData = {
                            quiz_id: responseQuestion.data.id,
                            answer: option.answer,
                            correct_answer: option.correct_answer,
                          };
                          await api.post('options', optionFormData);
                        });
                      }
                      break;
                  }
                }

                if (lastIndex === index) {
                  resolve();
                }
              });
            });

            await quizzesPromise;
          }

          Swal.fire(
            'Good job!',
            'Module successfully registered.',
            'success'
          ).then(() => {
            setLoading(false);
            history.push(
              `${process.env.PUBLIC_URL}/courses/${params.slug}/modules`
            );
          });
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          if (errors.lessons) {
            setLessonsError(errors.lessons);
          }
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          ).then(() => setLoading(false));
        }
      }
    },
    [courseId, history, lessons, params.slug, questions]
  );

  const handleDragEnd = useCallback(
    (sourceId, sourceIndex, targetIndex, targetId) => {
      if (targetId) {
        if (targetId === 'lessons') {
          const result = move(
            unlistedLessons,
            lessons,
            sourceIndex,
            targetIndex
          );
          setUnlistedLessons(result[0]);
          setLessons(result[1]);
        } else {
          const result = move(
            lessons,
            unlistedLessons,
            sourceIndex,
            targetIndex
          );
          setLessons(result[0]);
          const newUnlistedLessons = orderBy(result[1]);
          setUnlistedLessons(newUnlistedLessons);
        }
      } else if (sourceId === 'lessons') {
        const result = swap(lessons, sourceIndex, targetIndex);
        setLessons(result);
      }
    },
    [lessons, unlistedLessons]
  );

  return (
    <GridContextProvider onChange={handleDragEnd}>
      <Container>
        <div className="container-fluid container-xxl bg-gray">
          <Form ref={formRef} onSubmit={handleSubmit} className="row">
            <div className="col-12 p-0 pt-5 text-center">
              <CoursesActionTitle
                lightTitle="Create"
                boldTitle="Module"
                description="Surprisingly, there is a very vocal faction of the design
              community that wants to see filler text banished the original
              sources his will just slow down the design process."
              />
            </div>
            <div className="col-12">
              <div className="row justify-content-center my-5">
                <div className="col-11 col-lg-10 col-xl-8 col-xxl-7 overflow-auto pb-3 pb-md-0">
                  <div className="d-flex ml-auto align-items-center min-width">
                    <Link
                      to={`${process.env.PUBLIC_URL}/courses/update/${params.slug}`}
                      className="w-100 text-center btn-grey step-module rounded-pill"
                    >
                      <span className="d-block px-3 py-3">Update Course</span>
                    </Link>
                    <hr className="w-25 w-md-50 border-gray mx-2" />
                    <Link
                      to={`${process.env.PUBLIC_URL}/courses/${params.slug}/modules`}
                      className="w-100 text-center btn-grey step-module rounded-pill"
                    >
                      <span className="d-block px-3 py-3">Modules</span>
                    </Link>
                    <hr className="w-25 w-md-50 border-gray mx-2" />
                    <div className="w-100 text-center btn-light-grey rounded-pill">
                      <span className="d-block px-3 py-3">Create Module</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 pr-xl-0">
              <UnlistedLessons
                id="videoInfo"
                className="d-flex flex-column h-100"
              >
                <p>Unlisted lessons</p>
                <UnlistedLessonsDnd data={unlistedLessons} />
              </UnlistedLessons>
            </div>
            <div className="col-xl-9">
              <ModuleInfo>
                <h3 className="h5">New Module</h3>
                <div className="row justify-content-end">
                  <div className="col-md-6">
                    <div className="mt-3">
                      <label htmlFor="title" className="small">
                        Title
                      </label>
                      <Input id="title" name="title" className="py-3" />
                    </div>
                  </div>
                  <div className="col-md-6 px-0">
                    <div className="mt-3">
                      <Quizzes onChange={handleChangeQuizzes} />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mt-3">
                      <label htmlFor="description" className="small">
                        Lessons
                      </label>
                      <LessonsDnd data={lessons} error={lessonsError} />
                    </div>
                  </div>
                  <div className="col-lg-4 mt-3">
                    <button type="submit" className="btn-grey py-3 w-100">
                      <span className="d-block font-weight-bold">Save</span>
                    </button>
                  </div>
                </div>
              </ModuleInfo>
            </div>
          </Form>
        </div>
        {loading && (
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </Container>
    </GridContextProvider>
  );
};

export default ModulesRegister;
