import React from 'react';
import { GridDropZone, GridItem } from 'react-grid-dnd';

import { Container, Item } from './styles';

interface ILesson {
  id: string;
  title: string;
  thumbnail_url: string;
}

interface IUnlistedLessonsDnd {
  data: ILesson[];
}

const UnlistedLessonsDnd: React.FC<IUnlistedLessonsDnd> = ({ data }) => {
  return (
    <Container items={data.length} className="p-3 height-sm">
      <GridDropZone
        id="unlisted"
        className="dropzone user-select-none"
        boxesPerRow={1}
        rowHeight={150}
      >
        {data.map((lesson) => (
          <GridItem key={lesson.id} className="grab-item">
            <Item src={lesson.thumbnail_url}>
              <div className="p-3">{lesson.title}</div>
            </Item>
          </GridItem>
        ))}
      </GridDropZone>
    </Container>
  );
};

export default UnlistedLessonsDnd;
