import React, { useEffect } from 'react';

import { Form } from '@unform/web';

import { Container, Welcome, InputsGroup } from './styles';
import Input from '~/components/Input';

const LinksRegister: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 p-0 mb-5">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-4">
                    <h1 className="h2 font-weight-bold">Training</h1>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          <div className="col-12">
            <Form
              onSubmit={(e) => {
                console.log(e);
              }}
              className="row"
            >
              <div className="col-lg-6">
                <InputsGroup className="p-4 h-100">
                  <h2 className="h4">Link Information</h2>
                  <div className="py-3">
                    <label htmlFor="name_course">Name Of Course</label>
                    <Input name="name_course" id="name_course" />
                  </div>
                  <div className="py-3">
                    <label htmlFor="select_course">Select Course</label>
                    <Input name="select_course" id="select_course" />
                  </div>
                  <div className="py-3">
                    <label htmlFor="link">Link</label>
                    <Input name="link" id="link" />
                  </div>
                  <div className="d-flex py-3">
                    <button type="button" className="border-golden w-100">
                      <span className="d-block py-1">Save</span>
                    </button>
                  </div>
                </InputsGroup>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LinksRegister;
