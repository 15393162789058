import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import DayPicker, { DayModifiers } from 'react-day-picker';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';

import api from '~/services/api';

import { Container, BoxOthers, Calendar } from './styles';

import edit from '~/assets/icons/edit-icon.svg';
import Input from '~/components/Input';

interface IDraftsProps {
  active: boolean;
  totalCourses(total: number): void;
  filter?: string;
}

interface ICourseUser {
  id: string;
}

interface ILesson {
  id: string;
}

interface coursesResponseProps {
  id: string;
  title: string;
  description: string;
  slug: string;
  courseUser: ICourseUser[];
  lessons: ILesson[];
  thumbnail: {
    thumbnail_url: string;
    title: string;
  };
}

interface ICourses {
  id: string;
  title: string;
  description: string;
  slug: string;
  membersEnrolled: number;
  videos: number;
  thumbnail: string;
  thumbnail_title: string;
}

const Drafts: React.FC<IDraftsProps> = ({ active, totalCourses, filter }) => {
  const [courseId, setCourseId] = useState('');
  const [drafts, setDrafts] = useState<ICourses[]>([]);
  const [show, setShow] = useState(false);
  const [releaseNow, setReleaseNow] = useState('no');
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    api
      .get('courses/drafts/admin', {
        params: {
          title: filter,
        },
      })
      .then((response) => {
        const data: ICourses[] = response.data.map(
          (course: coursesResponseProps) => ({
            id: course.id,
            title: course.title,
            description: course.description,
            slug: course.slug,
            membersEnrolled: course.courseUser.length,
            videos: course.lessons.length,
            thumbnail: course.thumbnail ? course.thumbnail.thumbnail_url : '',
            thumbnail_title: course.thumbnail ? course.thumbnail.title : '',
          })
        );
        totalCourses(data.length);
        setDrafts(data);
      });
  }, [filter, totalCourses]);

  const handleClose = useCallback(() => setShow(false), []);

  const handleShow = useCallback(async (id, slug) => {
    const response = await api.get(`courses/${slug}`);
    setCourseId(id);
    setSelectedDate(
      response.data.release_date
        ? new Date(response.data.release_date)
        : new Date()
    );
    setShow(true);
  }, []);

  const changeReleaseNow = useCallback((e) => {
    setReleaseNow(e.target.value);
  }, []);

  const handleDateChange = useCallback((day: Date, modifiers: DayModifiers) => {
    if (modifiers.available && !modifiers.disabled) {
      setSelectedDate(day);
    }
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const formData = {
        released: releaseNow === 'yes',
        released_date: selectedDate,
      };
      const response = await api.patch(`courses/release/${courseId}`, formData);

      if (response.data) {
        if (releaseNow === 'yes') {
          Swal.fire('Good job!', 'Course released.', 'success').then(() => {
            const course = drafts.find((draft) => draft.id === courseId);
            const newDrafts = drafts.filter((draft) => draft.id !== courseId);
            if (course) {
              setDrafts(newDrafts);
            }
          });
        } else {
          Swal.fire('Good job!', 'Scheduled release.', 'success');
        }
        setSelectedDate(new Date());
        setCourseId('');
        setShow(false);
        setReleaseNow('no');
      }
    } catch {
      Swal.fire('Opss...', 'An error has occurred, please try again.', 'error');
    }
  }, [courseId, drafts, releaseNow, selectedDate]);

  return (
    <Container className={active ? 'd-block' : 'd-none'}>
      <div className="container-fluid px-0">
        <div className="row py-3">
          {drafts.map((draft) => (
            <div key={draft.id} className="col-sm-6 col-lg-4 my-2">
              <BoxOthers
                src={draft.thumbnail}
                className="d-block p-relative w-100 thumb"
              >
                <div className="short-title p-absolute w-100 p-2 p-xxl-3">
                  <div className="row h-100">
                    <div className="col-12">
                      <p className="h6 h5-xxl h4-1800 font-weight-600 mb-0 py-2 pl-3 mt-2 w-100">
                        {draft.title}
                      </p>
                    </div>
                    <div className="col-12">
                      <div className="row h-100 align-items-end">
                        <div className="col-12">
                          <div className="d-flex justify-content-between">
                            <div className="see-more-link mb-2">
                              <Link
                                to={`${process.env.PUBLIC_URL}/courses/update/${draft.slug}`}
                                className="btn h6 px-0 py-1 py-sm-2 ml-3 mt-sm-3 mt-xl-0 mb-0"
                              >
                                Edit{' '}
                                <img src={edit} className="ml-2" alt="Edit" />
                              </Link>
                            </div>
                            <div className="see-more-link mb-2">
                              <button
                                type="button"
                                onClick={() => handleShow(draft.id, draft.slug)}
                                className="h6 btn-grey px-4 py-2 mt-sm-3 mt-xl-0 mb-0"
                              >
                                Release
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BoxOthers>
            </div>
          ))}
        </div>
      </div>
      <Modal
        className="bg-modal-gray"
        centered
        show={show}
        onHide={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h3 className="py-4 h4 pl-3">Course Settings</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-3 mt-n4">
              <div className="col-12">
                <p className="small">Launch Course Now</p>
              </div>
              <div className="col-lg-6">
                <label
                  className={`border-1px btn w-100 mx-1 btn-radio py-2 ${
                    releaseNow === 'yes' ? 'btn-grey' : 'btn-dark'
                  }`}
                  htmlFor="watch_full_video_yes"
                >
                  <span className="d-block py-2">YES</span>
                  <Input
                    type="radio"
                    onChange={changeReleaseNow}
                    name="watch_full"
                    id="watch_full_video_yes"
                    className="d-none"
                    value="yes"
                    checked={releaseNow === 'yes'}
                  />
                </label>
              </div>
              <div className="col-lg-6">
                <label
                  className={`border-1px btn w-100 mx-1 btn-radio py-2 ${
                    releaseNow === 'no' ? 'btn-grey' : 'btn-dark'
                  }`}
                  htmlFor="watch_full_video_no"
                >
                  <span className="d-block py-2">NO</span>
                  <Input
                    type="radio"
                    onChange={changeReleaseNow}
                    name="watch_full"
                    id="watch_full_video_no"
                    className="d-none"
                    value="no"
                    checked={releaseNow === 'no'}
                  />
                </label>
              </div>
            </div>
            {releaseNow === 'no' && (
              <div className="row">
                <div className="col-12">
                  <p className="small my-5">Schedule When Course Goes Live</p>
                </div>
                <div className="col-12 text-center">
                  <Calendar className="mx-auto">
                    <DayPicker
                      showOutsideDays
                      fromMonth={new Date()}
                      initialMonth={selectedDate}
                      selectedDays={selectedDate}
                      modifiers={{
                        available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] },
                      }}
                      onDayClick={handleDateChange}
                    />
                  </Calendar>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="row justify-content-center w-100 mb-3 mt-2">
              <div className="col-lg-10">
                <button type="submit" className="btn-grey w-100">
                  <span className="d-block py-3">SAVE LAUNCH COURSE DATE</span>
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default Drafts;
