import React, { useEffect, useState } from 'react';

import { Container, BoxOthers } from './styles';

import thumbAffiliateOffers from '~/assets/defaults/thumbAffiliateOffers.png';

interface IAllAffiliateOffersProps {
  active: boolean;
  filter?: string;
  totalAffiliateOffers?(total: number): void;
}

interface IAffiliateOffers {
  id: string;
  title: string;
  by: string;
  link: string;
  comission: string;
  thumbnail: string;
  thumbnail_name: string;
}

const AllAffiliateOffers: React.FC<IAllAffiliateOffersProps> = ({
  active,
  totalAffiliateOffers,
  filter,
}) => {
  const [loading, setLoading] = useState(false);
  const [affiliateOffers, setAffiliateOffers] = useState<IAffiliateOffers[]>(
    []
  );

  useEffect(() => {
    setLoading(true);

    const data: IAffiliateOffers[] = [
      {
        id: '1',
        title: 'Funnel Building Software',
        by: 'Clickfunnels',
        link: 'https://affiliates.clickfunnels.com/affiliate-access/383c3',
        comission: '40%',
        thumbnail: thumbAffiliateOffers,
        thumbnail_name: 'Click Funnels',
      },
      {
        id: '2',
        title: 'Funnel Building Software',
        by: 'Clickfunnels',
        link: 'https://affiliates.clickfunnels.com/affiliate-access/383c3',
        comission: '40%',
        thumbnail: thumbAffiliateOffers,
        thumbnail_name: 'Click Funnels',
      },
      {
        id: '3',
        title: 'Funnel Building Software',
        by: 'Clickfunnels',
        link: 'https://affiliates.clickfunnels.com/affiliate-access/383c3',
        comission: '40%',
        thumbnail: thumbAffiliateOffers,
        thumbnail_name: 'Click Funnels',
      },
    ];

    setAffiliateOffers(data);

    if (totalAffiliateOffers) {
      totalAffiliateOffers(3);
    }

    setLoading(false);
  }, [totalAffiliateOffers, active, filter]);

  return (
    <Container className={active ? 'd-block' : 'd-none'}>
      <div className="container-fluid px-0">
        <div className="row">
          <div className="col-12 box p-5">
            <div className="row">
              {affiliateOffers.map((affiliateOffer) => (
                <div key={affiliateOffer.id} className="col-12 my-3">
                  <div className="d-flex align-items-center">
                    <BoxOthers
                      src={affiliateOffer.thumbnail}
                      className="d-block p-relative thumb"
                    >
                      <div className="short-title p-absolute w-100 p-2 p-xxl-3">
                        <div className="row h-100">
                          <div className="col-12">
                            <p className="h6 h6-xxl h4-1800 font-weight-600 mb-0 py-2 pl-3 mt-2 w-100">
                              {affiliateOffer.thumbnail_name}
                            </p>
                          </div>
                        </div>
                      </div>
                    </BoxOthers>
                    <div className="px-5 infos">
                      <div className="row">
                        <div className="col-lg-6">
                          <h6 className="mb-2">{affiliateOffer.title}</h6>
                          <p className="mb-0">by {affiliateOffer.by}</p>
                        </div>
                        <div className="col-lg-6">
                          <h6 className="mb-2">Affiliate Commission</h6>
                          <p className="mb-0">{affiliateOffer.comission}</p>
                        </div>
                        <div className="col-lg-8 mt-5">
                          <h6 className="mb-2">Affiliate Link</h6>
                          <p className="mb-0">{affiliateOffer.link}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default AllAffiliateOffers;
