import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { differenceInHours, format, parseISO } from 'date-fns';
import { Form } from '@unform/web';

import api from '~/services/api';

import { Container, Welcome, NameUserTable, Modal } from './styles';
import Table from '~/components/Table';
import Select from '~/components/Select';

interface ILeadResponse {
  id: string;
  avatar: {
    avatar_url: string;
  };
  name: string;
  created_at: string;
  has_referrer: boolean;
  referrer: {
    affiliate: {
      user: {
        name: string;
        foundersLeadsFounders: {
          id: string;
        }[];
      };
    };
  };
  foundersLeadsLead?: {
    id: string;
    founder_id: string;
  };
  orders: {
    product?: {
      name: string;
    };
  }[];
}

interface ILead {
  id: string;
  founderLead_id?: string;
  founder_id?: string;
  avatar: string;
  lead_date: string;
  member: string;
  time_hold: string;
  assigned_founder: string;
  product_purchased: string;
  founder_assigned: string;
  leads_received: string;
}

interface IOption {
  id: string;
  avatar: string;
  value: string;
  selected: boolean;
}

interface IFounderResponse {
  id: string;
  name: string;
  avatar: {
    avatar_url: string;
  };
}

interface IFounder {
  id: string;
  avatar: string;
  name: string;
}

const FoundersClubLeads: React.FC = () => {
  const [leads, setLeads] = useState<ILead[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');
  const [selectedLead, setSelectedLead] = useState({} as ILead);
  const [showAssignLead, setShowAssignLead] = useState(false);
  const [founders, setFounders] = useState<IOption[]>([]);
  const [founderSelected, setFounderSelected] = useState({} as IFounder);

  const handleLoadLeads = useCallback(async () => {
    const response = await api.get<ILeadResponse[]>('users/leads', {
      params: {
        allLeads: true,
        search: filter,
      },
    });

    const data = response.data.map((user) => {
      return {
        id: user.id,
        founderLead_id: user.foundersLeadsLead
          ? user.foundersLeadsLead.id
          : undefined,
        founder_id: user.foundersLeadsLead
          ? user.foundersLeadsLead.founder_id
          : undefined,
        avatar: user.avatar.avatar_url,
        lead_date: format(parseISO(user.created_at), 'yyyy-MM-dd'),
        member: user.name || '-',
        time_hold: `${
          differenceInHours(new Date(), parseISO(user.created_at)) > 0
            ? differenceInHours(new Date(), parseISO(user.created_at))
            : 0
        } Hours`,
        assigned_founder: user.foundersLeadsLead ? 'Yes' : 'No',
        product_purchased:
          user.orders.length > 0 && user.orders[0].product
            ? user.orders[0].product.name
            : 'Not Yet',
        founder_assigned: user.foundersLeadsLead
          ? user.referrer.affiliate.user.name
          : 'Not Yet',
        leads_received: user.foundersLeadsLead
          ? user.referrer.affiliate.user.foundersLeadsFounders.length
              .toString()
              .padStart(2, '0')
          : '-',
      };
    });

    setLeads(data);
    setLoading(false);
  }, [filter]);

  const handleLoadFounders = useCallback(async () => {
    const response = await api.get<IFounderResponse[]>('users/founders');

    const data = response.data.map<IOption>((founder) => ({
      id: founder.id,
      value: founder.name,
      avatar: founder.avatar.avatar_url,
      selected: false,
    }));
    setFounders(data);
  }, []);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    handleLoadLeads();
    handleLoadFounders();
  }, [filter, handleLoadFounders, handleLoadLeads]);

  const handleClose = useCallback(() => {
    setSelectedLead({} as ILead);
    setShowAssignLead(false);
  }, []);

  const handleSearch = useCallback((value) => {
    setFilter(value);
  }, []);

  const handleClickAssignLead = useCallback(
    (lead: ILead) => {
      const newFounders = founders.map((founder) => ({
        ...founder,
        selected: founder.id === lead.founder_id,
      }));
      const founder = newFounders.find((founderData) => founderData.selected);
      if (founder) {
        setFounderSelected({
          id: founder.id,
          name: founder.value,
          avatar: founder.avatar,
        });
      }
      setFounders(newFounders);
      setSelectedLead(lead);
      setShowAssignLead(true);
    },
    [founders]
  );

  const columns = useMemo(
    () => [
      {
        name: 'Lead Date',
        selector: 'lead_date',
        sortable: true,
      },
      {
        name: 'Member Name',
        selector: 'member',
        sortable: true,
        cell: (row: ILead) => (
          <NameUserTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
          >
            <img src={row.avatar} alt={row.member} className="mr-2" />
            <div>
              <p className="mb-0">{row.member}</p>
            </div>
          </NameUserTable>
        ),
      },
      {
        name: 'Time on Hold',
        selector: 'time_hold',
        sortable: true,
      },
      {
        name: 'Assigned to Founder',
        selector: 'assigned_founder',
        sortable: true,
      },
      {
        name: 'Product Purchased',
        selector: 'product_purchased',
        sortable: true,
      },
      {
        name: 'Founder Assigned',
        selector: 'founder_assigned',
        sortable: true,
      },
      {
        name: 'Total Leads Received',
        selector: 'leads_received',
        sortable: true,
      },
      {
        name: '',
        selector: 'id',
        sortable: true,
        cell: (row: ILead) => (
          <button
            type="button"
            onClick={() => handleClickAssignLead(row)}
            className="btn-assign rounded-pill bg-transparent"
          >
            {row.assigned_founder === 'Yes'
              ? 'Change Assign Founder'
              : 'Assign Lead'}
          </button>
        ),
      },
    ],
    [handleClickAssignLead]
  );

  const handleChangeFounder = useCallback(
    (e) => {
      const founder = founders.find((founderData) => e.id === founderData.id);
      if (founder) {
        setFounderSelected({
          id: founder.id,
          name: founder.value,
          avatar: founder.avatar,
        });
      }
    },
    [founders]
  );

  const handleDone = useCallback(async () => {
    setLoading(true);
    try {
      const formData = {
        lead_id: selectedLead.id,
        founder_id: founderSelected.id,
      };

      let founderLeadId = selectedLead.founderLead_id;
      if (selectedLead.founderLead_id) {
        await api.put(`users/leads/${selectedLead.founderLead_id}`, formData);
      } else {
        const response = await api.post(`users/leads`, formData);
        founderLeadId = response.data.id;
      }

      const newLeads = leads.slice();
      const leadIndex = newLeads.findIndex(
        (lead) => lead.id === selectedLead.id
      );

      if (leadIndex >= 0) {
        newLeads[leadIndex].assigned_founder = 'Yes';
        newLeads[leadIndex].founderLead_id = founderLeadId;
        newLeads[leadIndex].founder_assigned = founderSelected.name;
      }

      setLeads(newLeads);
      handleClose();
    } catch (error) {
      // console.log(error);
    } finally {
      setLoading(false);
    }
  }, [
    founderSelected.id,
    founderSelected.name,
    handleClose,
    leads,
    selectedLead.founderLead_id,
    selectedLead.id,
  ]);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 mb-4 d-flex align-items-center justify-content-between">
                    <h1 className="h4 h2-lg">Founders Club Leads</h1>
                  </div>
                </div>
              </div>
            </Welcome>

            <Table
              data={leads}
              columns={columns}
              pagination
              date
              searchable
              onSearch={handleSearch}
              exportable
            />
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        className="modal-crowdfunding"
        show={showAssignLead}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign Lead</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-12">
              <Form
                onSubmit={() => {
                  // console.log('submit');
                }}
              >
                <label htmlFor="member">Founder</label>
                <Select
                  id="founder"
                  name="founder"
                  options={founders}
                  onChange={handleChangeFounder}
                />
              </Form>
            </div>
            {Object.keys(founderSelected).length > 0 ? (
              <div className="col-lg-3 mt-5">
                <div className="h-100 p-3">
                  <img
                    src={founderSelected.avatar}
                    alt={founderSelected.name}
                    className="w-75 rounded-circle mb-3"
                  />{' '}
                  <p>{founderSelected.name}</p>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={handleDone}
            className="btn-grey px-3 py-1"
          >
            <span className="d-block py-1 px-2">Done</span>
          </button>
        </Modal.Footer>
      </Modal>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default FoundersClubLeads;
