import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  .rdt_TableHeadRow,
  .rdt_TableRow {
    > div {
      max-width: 200px !important;
      min-width: 200px !important;
      box-sizing: border-box;
    }

    > div:first-child {
      max-width: 150px !important;
      min-width: 150px !important;
    }

    > div:nth-child(2) {
      max-width: 350px !important;
      min-width: 350px !important;
    }
  }

  .bg {
    background-color: #202020;
    border-radius: 20px;
  }

  .users-radius {
    border-radius: 20px;
  }

  @media screen and (max-width: 767px) {
  }

  @media screen and (max-width: 575px) {
    .size-users {
      font-size: 12px;
    }

    .users-size {
      display: block;
      width: 12px;
      margin: auto;
    }
  }

  @media (min-width: 992px) and (max-width: 1250px) {
    .vw80 {
      width: 80vw !important;
    }
  }
`;

export const Welcome = styled.div`
  p {
    color: #969696;
  }
`;

export const NameUserTable = styled.div`
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;

export const OptionUser = styled.div`
  button {
    background-color: #242526;
    border: 1px solid #4a4b4b;
    font-size: 14px;
    :hover {
      background-color: #4a4b4b;
      transition: 0.3s;
    }

    :disabled {
      cursor: default;
      opacity: 0.8 !important;
      background-color: #242526 !important;
    }
  }
`;

export const Search = styled.div`
  padding: 1px;
  border-radius: 100px;
  border: 1px solid #4a4b4b;
  background-color: #202020;

  input {
    margin: 5px 0;
    padding: 0 10px;
    border: none;
    border-radius: 100px;
    background-color: #202020;
  }
`;

export const Options = styled.div`
  button {
    p {
      color: #dadada !important;
    }
  }

  .bg-btn {
    background-color: transparent;
    color: #606060;
    text-decoration: none;
  }

  @media screen and (min-width: 768px) {
    .bg-btn {
      border-bottom: 2px solid #202020;

      :hover {
        color: #dadada;
        border-bottom: 2px solid #606060;
      }
    }

    .active {
      color: #dadada;
      border-bottom: 2px solid #606060;
    }
  }

  @media screen and (max-width: 767px) {
    .bg-btn {
      border: solid 1px #606060;
      background-color: #242526;
      font-size: 14px;
    }
    .options-button {
      transition-duration: 0.3s;
      background: #18191a;
      color: #fff !important;
      border-radius: 20px;

      svg {
        transition-duration: 0.3s;
        color: rgba(203, 204, 206, 1);
      }
    }

    .options {
      transition-duration: 0.3s;
      background: #1f1f1f;
      color: #8c8c8c;
      border-radius: 0 0 20px 20px;
      height: 0;
      overflow: hidden;
      padding-top: 0;
      padding-bottom: 0;

      .bg-btn {
        background-color: transparent !important;
        border: none;
        border-radius: 0 !important;
        width: 100%;
        color: #606060;
      }
    }

    .options-box.active {
      .options-button {
        border-radius: 20px 20px 0px 0px;

        svg {
          transform: rotate(180deg);
        }
      }

      .options {
        height: 300px;
        padding: 0;
        overflow: none;

        ::-webkit-scrollbar {
          background-color: #202020;
          width: 5px;
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background: #292929;
        }
      }
    }
  }
`;

export const Modal = styled(ModalComponent)``;
