import styled from 'styled-components';

export const Container = styled.div`
  .rdt_TableHeadRow,
  .rdt_TableRow {
    > div:nth-child(1) {
      max-width: 350px;
    }
    > div:nth-child(2) {
      max-width: 110px;
    }
    > div:nth-child(3) {
      max-width: 130px;
    }
    > div:nth-child(4) {
      max-width: 170px;
    }
    > div:nth-child(5) {
      max-width: 250px;
    }
    > div:nth-child(6) {
      max-width: 190px;
    }
  }

  .users-radius {
    border-radius: 20px;
  }

  @media screen and (max-width: 991px) {
    .h-date input {
      height: 41px;
    }
  }

  @media screen and (max-width: 767px) {
  }

  @media screen and (max-width: 575px) {
    .size-users {
      font-size: 12px;
    }
    .users-size {
      display: block;

      margin: auto;
    }
  }

  @media (min-width: 992px) and (max-width: 1250px) {
    .vw80 {
      width: 80vw !important;
    }
  }
`;

export const Welcome = styled.div`
  p {
    color: #969696;
  }
`;

export const NameUserTable = styled.div`
  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;
export const OptionUser = styled.div`
  cursor: pointer;
`;
