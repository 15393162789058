import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import api from '~/services/api';

import { Container, Welcome, Product, Course } from './styles';

import edit from '~/assets/icons/edit-icon-light.svg';
import plus from '~/assets/icons/plus-admin-icon2.svg';

interface ITableData {
  id: string;
  thumbail: string;
  name: string;
  courses: string[];
  price: string;
  slug: string;
}

interface ICourseProduct {
  id: string;
  course: {
    id: string;
    title: string;
  };
}

interface IProducts {
  id: string;
  thumbnail: {
    thumbnail_url: string;
  };
  name: string;
  courseProduct: ICourseProduct[];
  price: string;
  slug: string;
}

const ProductsList: React.FC = () => {
  const [products, setProducts] = useState<ITableData[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);

    api.get('products').then((response) => {
      const data = response.data.map((product: IProducts) => {
        const courses = product.courseProduct.map(
          (courseProduct) => courseProduct.course.title
        );

        return {
          id: product.id,
          thumbail: product.thumbnail.thumbnail_url,
          name: product.name,
          courses,
          price: `$${product.price}`,
          slug: product.slug,
        };
      });
      setProducts(data);
      setLoading(false);
    });
  }, []);

  return (
    <Container>
      <div className="container-fluid mb-5">
        <Welcome>
          <div className="container-fluid container-xxl">
            <div className="row">
              <div className="col-xl-8 px-0">
                <div className="d-md-flex text-center text-md-left">
                  <h1 className="h2 pl-3 text-left">Franchises Management</h1>
                  <Link
                    to={`${process.env.PUBLIC_URL}/products/register`}
                    className="btn-new-course align-items-center py-2 px-4 ml-md-5 mt-5 mt-md-0 rounded-pill text-center d-inline-flex justify-content-center"
                  >
                    <img src={plus} alt="plus" className="mr-2" />
                    Add New Franchise
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Welcome>
      </div>
      <Product className="my-4">
        <div>
          <div className="container-fluid container-xxl">
            <div className="row mt-5">
              {products.length > 0 ? (
                <>
                  {products.map((product) => (
                    <div key={product.id} className="col-lg-6 my-2">
                      <div className="row p-relative bg-products py-5 px-4 h-lg-100 w-lg-100">
                        <div className="col-sm-5 col-lg-6 col-xl-5 col-xxl-4 text-center px-0">
                          <div className="d-flex">
                            <img
                              src={product.thumbail}
                              alt={product.name}
                              className="product-img w-100"
                            />
                          </div>
                        </div>
                        <div className="col-sm-7 col-lg-6 col-xl-7 col-xxl-8 pl-2 pl-sm-3 pl-xl-4 pr-0">
                          <div className="col-12 text-right px-0">
                            <p className="h5 mt-4 mt-sm-0 mb-4">
                              {product.price}
                            </p>
                          </div>
                          <h2 className="h4 mb-5">{product.name}</h2>
                          <p className="mb-0 color-gray">Courses:</p>
                          <div className="d-flex flex-wrap align-content-start align-items-center my-2 courses">
                            {product.courses.map((course: string) => (
                              <Course
                                key={course}
                                className="col-sm-6 col-md-4 col-lg-12 col-xxl-6 pl-0 pr-1 my-1"
                              >
                                <span className="small d-block w-100 px-3 py-3 font-weight-300 text-center rounded-pill">
                                  {course}
                                </span>
                              </Course>
                            ))}
                          </div>
                        </div>

                        <div className="col-12 mt-4 mt-lg-0">
                          <div className="d-flex h-100 align-items-center">
                            <Link
                              to={`${process.env.PUBLIC_URL}/products/update/${product.slug}`}
                              className="text-decoration-none d-flex font-weight-bold"
                            >
                              Edit
                              <img src={edit} className="ml-3" alt="edit" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="col-12">
                  <div className="row justify-content-center">
                    <div className="col-lg-8">
                      <p className="h5 text-center">
                        No products found for this filter.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Product>

      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default ProductsList;
