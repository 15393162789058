import styled from 'styled-components';

export const Container = styled.div`
  h1 {
    color: #e4e4e4;
    font-weight: 300 !important;

    span {
      font-weight: 500 !important;
    }
  }

  p {
    color: #606060;
  }
`;
