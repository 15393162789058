import React, { useCallback, useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

import api from '~/services/api';

import { Container, SelectTag } from './styles';
import AddCategory from '~/components/AddCategory';
import Textarea from '~/components/Textarea';

export interface IValue {
  id?: string;
  value: string;
}

interface ICategory {
  id: string;
  name: string;
}

interface IInputTagsProps {
  type: 'courses' | 'trainings';
  className?: string;
  onSelect?(data: IValue[]): void;
  onRemove?(data: IValue): void;
  limit?: number;
  valuesSelected?: IValue[];
}

const InputTags: React.FC<IInputTagsProps> = ({
  type,
  className,
  onSelect,
  onRemove,
  limit,
  valuesSelected,
}) => {
  const [values, setValues] = useState<IValue[]>([]);
  const [tag, setTag] = useState<IValue>({} as IValue);
  const [isFocused, setIsFocused] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [foundData, setFoundData] = useState<IValue[]>([]);
  const [filterFoundData, setFilterFoundData] = useState<IValue[]>([]);
  const [show, setShow] = useState(false);
  const [enteredValue, setEnteredValue] = useState('');

  const handleClose = useCallback(() => setShow(false), []);
  const handleShow = useCallback(() => setShow(true), []);

  const loadCategories = useCallback(async () => {
    const response = await api.get('categories', {
      params: {
        type,
      },
    });
    const data: IValue[] = response.data.map((category: ICategory) => ({
      id: category.id,
      value: category.name,
    }));
    setFoundData(data);
    setFilterFoundData(data);
  }, [type]);

  useEffect(() => {
    if (valuesSelected) {
      setValues(valuesSelected);
    }
  }, [valuesSelected]);

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  const handleKeyPress = useCallback(
    (e) => {
      const keyPressed = e.keyCode || e.which || e.charCode;
      if (keyPressed === 8) {
        if (e.target.value.length === 0) {
          e.preventDefault();
          const newValuesList = values.slice();
          setTag(newValuesList[newValuesList.length - 1]);
          newValuesList.splice(-1, 1);
          setValues(newValuesList);
        }
      }
    },
    [values]
  );

  const handleRemoveValue = useCallback(
    (indexSelected) => {
      const newValuesList = values.filter(
        (_, index) => index !== indexSelected
      );
      if (onRemove) {
        onRemove(values[indexSelected]);
      }
      setValues(newValuesList);
    },
    [onRemove, values]
  );

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setTimeout(() => {
      setIsFocused(false);
    }, 200);
  }, []);

  const handleChange = useCallback(
    (e) => {
      const { value } = e.target;
      setTag({ value });
      setEnteredValue(value);
      if (foundData) {
        const newFilterFoundData = foundData.filter((found) =>
          found.value.toLowerCase().match(value.toLowerCase())
        );
        setFilterFoundData(newFilterFoundData);
      }
    },
    [foundData]
  );

  const handleSelect = useCallback(
    (dataSelected: IValue) => {
      setValues((state) => [...state, dataSelected]);
      setTag({} as IValue);
      setFilterFoundData(foundData);
      if (onSelect) {
        onSelect([...values, dataSelected]);
      }
    },
    [foundData, onSelect, values]
  );

  const handleSubmit = useCallback(
    async (dataSelected) => {
      setValues((state) => [...state, dataSelected]);
      setTag({} as IValue);

      if (onSelect) {
        onSelect([...values, dataSelected]);
      }
      await loadCategories();
      handleClose();
    },
    [handleClose, loadCategories, onSelect, values]
  );

  return (
    <div className="p-relative w-100">
      <Container
        className={`p-relative d-flex align-items-start flex-wrap ${
          className || ''
        }`}
        noBorderRadiusBottom={
          !!(foundData && foundData.length > 0 && isFocused)
        }
        isErrored={hasError}
      >
        {values.map((value, index) => (
          <div
            key={index}
            className="ml-2 my-1 px-3 py-2 language rounded-pill d-flex align-items-center"
          >
            <span>{value.value}</span>
            <button
              type="button"
              className="border-0 bg-transparent ml-1"
              onClick={() => handleRemoveValue(index)}
            >
              <FaTimes />
            </button>
          </div>
        ))}
        <Textarea
          id="categories"
          name="categories"
          className="border-0 col px-1 min-width-10px"
          onKeyDown={handleKeyPress}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={tag && tag.value ? tag.value : ''}
          autoComplete="off"
          hasError={setHasError}
          disabled={!!(limit && values.length >= limit)}
        />
      </Container>
      {isFocused && (
        <SelectTag className="w-100 p-absolute">
          {filterFoundData && filterFoundData.length > 0 ? (
            <>
              {filterFoundData.map((found: IValue) => (
                <button
                  key={found.id}
                  type="button"
                  onClick={() => handleSelect(found)}
                  className="w-100 py-2 border-0"
                >
                  {found.value}
                </button>
              ))}
            </>
          ) : (
            <div>
              <p className="text-center my-2">Category not found</p>
              <button
                type="button"
                onClick={handleShow}
                className="w-100 py-2 border-0 small"
              >
                Add new category
              </button>
            </div>
          )}
        </SelectTag>
      )}
      <AddCategory
        type={type}
        show={show}
        value={enteredValue}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

export default InputTags;
