import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface IProgressBarProps {
  percentage: number;
  color: string;
}

const ProgressBar: React.FC<IProgressBarProps> = ({ percentage, color }) => {
  const options = useMemo(() => {
    const data: ApexOptions = {
      colors: [`${percentage >= 0 ? color : '#f9000f'}`],
      plotOptions: {
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          hollow: {
            size: '50%',
          },
          track: {
            show: true,
            background: '#1A1B1D',
            strokeWidth: '97%',
            opacity: 1,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: true,
              offsetY: 5,
              color: 'white',
              fontSize: '25px',
            },
          },
        },
      },
      stroke: {
        lineCap: 'round',
      },
      labels: [`${percentage >= 0 ? 'Lucros' : 'Perdas'}`],
      tooltip: {
        enabled: true,
        custom: ({ series, seriesIndex, w }) => {
          return `<div class="arrow_box">
              <span>${w.globals.labels[0]}: ${series[seriesIndex]}%</span>
            </div>`;
        },
      },
    };

    return data;
  }, [color, percentage]);

  const series = useMemo(
    () => [percentage >= 0 ? percentage : percentage * -1],
    [percentage]
  );

  const [width, setWidth] = useState(0);

  const updateSize = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [updateSize]);

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="radialBar"
      height={width > 1399 ? '250px' : '100%'}
    />
  );
};

export default ProgressBar;
