import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  .question {
    transition-duration: 0.3s;
    background: #292929;
    color: #cbccce;
    border-radius: 20px;

    svg {
      transition-duration: 0.3s;
      min-width: 25px;
      height: 25px;
    }
  }
  .front {
    transform: rotateY(0deg);
    transition: 0.3s;
  }
  .front.flip {
    transform: rotateY(90deg);
  }
  .back {
    transform: rotateY(90deg);
    transition: 0.3s;
  }
  .back.flip {
    transform: rotateY(180deg);
    form {
      transform: rotateY(180deg);
    }
  }

  .add-question {
    background: #202020;
    border-radius: 20px 20px 0 0;
  }

  .answer {
    transition-duration: 0.3s;
    background: #202020;
    color: #8c8c8c;
    border-radius: 0 0 20px 20px;
    height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
  }

  .question-box.active {
    .question {
      border-radius: 20px 20px 0px 0px;

      svg {
        transform: rotate(180deg);
      }
    }

    .answer {
      height: 250px !important;
      p {
        height: 150px !important;
        margin-top: 2.5rem;
        overflow: auto;

        ::-webkit-scrollbar {
          background-color: #18191a;
          width: 5px;
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background: #292929;
        }
      }
    }
  }

  .btn-edit {
    width: 30%;
    bottom: 5%;
    background: transparent;
    color: #8c8c8c;
    border: none;
    text-align: center;

    :hover {
      color: ${lighten(0.1, '#e4e4e4')}!important;
      img {
        filter: brightness(2.5);
      }
      text-decoration: none;
    }
  }
`;
