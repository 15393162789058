import styled from 'styled-components';

export const Container = styled.div`
  .bg-gray {
    background-color: rgba(32, 32, 32, 1);
    border-radius: 20px;
  }
  .border-gray {
    border-color: #8c8c8c;
  }

  .step-course,
  .step-module {
    span {
      color: #8c8c8c;

      :hover {
        color: rgba(32, 32, 32, 1) !important;
        text-decoration: none;
      }
    }
    text-decoration: none;
  }

  .min-width {
    min-width: 600px;
  }

  .btn-light-grey {
    background-color: rgba(203, 204, 206, 1);
    border: none;
    color: rgba(110, 110, 110, 1);
    border-radius: 15px;
  }
`;

export const UnlistedLessons = styled.div`
  background: #202020;
  border-radius: 20px;
  padding: 20px 0 20px 20px;

  p {
    color: #828282;
  }

  .bg-gray {
    background: #242526;
    border-radius: 20px;
    overflow: hidden;

    p {
      color: #565656;
    }
  }

  .remove-video {
    top: 0;
    right: 5px;
    z-index: 999;
    background-color: transparent;
    border: none;
  }

  .short-tilte {
    border: none;
    border-bottom: 1px solid rgba(51, 51, 51, 1);
    border-radius: 0;
  }

  .border-gray {
    border-color: #8c8c8c;
  }

  label {
    color: #828282;
  }

  .btn-radio {
    color: #cbccce;

    :hover {
      color: #cbccce;
    }
  }

  @media screen and (max-width: 1199px) {
    padding: 20px;
    .height-sm {
      min-height: 250px;
    }
  }
`;

export const ModuleInfo = styled.div`
  background: #202020;
  border-radius: 20px;
  padding: 20px;

  .button-quiz {
    background: transparent;
    border: 1.5px solid #3e3e3e;
  }

  .bg-gray {
    background: #242526;
    border-radius: 20px;
    overflow: hidden;

    p {
      color: #565656;
    }
  }

  label {
    color: #828282;
  }

  .bio-photo {
    width: 100%;
    border-radius: 20px;
  }

  .link-preview {
    span.error {
      position: absolute;
      bottom: -24px;
    }

    span.before-slug {
      background: #242526;
      border-radius: 10px;
      border: 1.5px solid #6e6e6e;
      padding: 5px 20px;
      color: #cbccce;
      margin-right: -15px;
      z-index: 1;
    }

    input {
      padding-left: 15px;
    }
  }
`;
