import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { RiDeleteBin6Fill } from 'react-icons/ri';

import api from '~/services/api';

import { Container } from './styles';
import Table from '~/components/Table';
import EditCategory from '~/components/EditCategory';
import DeleteCategory from '~/components/DeleteCategory';

interface ITrainingsProps {
  totalCategories(total: number): void;
  filter?: string;
}

interface ICategoryResponseProps {
  id: string;
  name: string;
  type: string;
  slug: string;
  created_at: string;
}

interface ICategory {
  id: string;
  index: number;
  name: string;
  slug: string;
  created_at: Date;
  type: 'Trainings';
}

const Trainings: React.FC<ITrainingsProps> = ({ totalCategories, filter }) => {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [categoryId, setCategoryId] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [value, setValue] = useState('');
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    api
      .get('categories', {
        params: {
          type: 'trainings',
          name: filter,
        },
      })
      .then((response) => {
        const data: ICategory[] = response.data.map(
          (course: ICategoryResponseProps, index: number) => ({
            id: course.id,
            index: index + 1,
            name: course.name,
            slug: course.slug,
            type: 'Trainings',
            created_at: course.created_at.split('T')[0],
          })
        );
        totalCategories(data.length);
        setCategories(data);
      });
  }, [filter, totalCategories]);

  const handleClose = useCallback(() => {
    setShowEdit(false);
    setShowDelete(false);
  }, []);

  const handleShowEdit = useCallback(() => setShowEdit(true), []);
  const handleShowDelete = useCallback(() => setShowDelete(true), []);

  const handleClickEdit = useCallback(
    async (slug: string) => {
      const response = await api.get(`categories/${slug}`, {
        params: {
          type: 'trainings',
        },
      });

      if (response.data) {
        setCategoryId(response.data.id);
        setValue(response.data.name);
        handleShowEdit();
      }
    },
    [handleShowEdit]
  );

  const handleClickDelete = useCallback(
    async (slug: string) => {
      const response = await api.get(`categories/${slug}`, {
        params: {
          type: 'trainings',
        },
      });

      if (response.data) {
        setCategoryId(response.data.id);
        setCategoryName(response.data.name);
        handleShowDelete();
      }
    },
    [handleShowDelete]
  );

  const handleSubmit = useCallback(
    (data) => {
      const newCategories = categories.slice();
      const categorySelected = categories.find(
        (category) => category.id === data.id
      );
      const categoriesIndex = categories.findIndex(
        (category) => category.id === data.id
      );

      if (categorySelected) {
        categorySelected.name = data.name;
        categorySelected.slug = data.slug;
        newCategories[categoriesIndex] = categorySelected;
      }

      setCategoryId('');
      setValue('');
      setCategories(newCategories);
      handleClose();
    },
    [categories, handleClose]
  );

  const handleDelete = useCallback(
    (delete_category_id) => {
      const newCategories = categories.filter(
        (category) => category.id !== delete_category_id
      );

      setCategoryId('');
      setCategoryName('');
      setCategories(newCategories);
      handleClose();
    },
    [categories, handleClose]
  );

  const columns = useMemo(
    () => [
      {
        name: '#',
        selector: 'index',
        sortable: true,
      },
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
      },
      {
        name: 'Date of create',
        selector: 'created_at',
        sortable: true,
      },
      {
        name: 'Type',
        selector: 'type',
        sortable: true,
      },
      {
        name: '',
        cell: (row: ICategory) => (
          <div className="d-flex">
            <button
              type="button"
              className="mx-1"
              onClick={() => handleClickEdit(row.slug)}
            >
              <FiEdit size={20} color="#CBCCCE" />
            </button>
            <button
              type="button"
              className="mx-1"
              onClick={() => handleClickDelete(row.slug)}
            >
              <RiDeleteBin6Fill size={20} color="#FF1A50" />
            </button>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleClickDelete, handleClickEdit, categories]
  );

  return (
    <Container>
      <div className="container-fluid">
        <div className="row py-3">
          <div className="col-12 px-0">
            <Table columns={columns} data={categories} pagination />
          </div>
        </div>
      </div>
      <EditCategory
        type="trainings"
        category_id={categoryId}
        value={value}
        show={showEdit}
        onSubmit={handleSubmit}
        onClose={handleClose}
      />
      <DeleteCategory
        category_id={categoryId}
        name={categoryName}
        show={showDelete}
        onDelete={handleDelete}
        onClose={handleClose}
      />
    </Container>
  );
};

export default Trainings;
