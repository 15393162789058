import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import api from '~/services/api';
import Categories from '~/components/Categories';
import { Container, Welcome, Search, Card } from './styles';

import search from '~/assets/icons/search-icon.svg';
import video from '~/assets/icons/video.svg';
import edit from '~/assets/icons/edit.svg';

interface ITrainingResponse {
  id: string;
  title: string;
  description: string;
  thumbnail: {
    thumbnail_url: string;
  };
  slug: string;
}

interface ITrainingCategoryResponse {
  id: string;
  training: ITrainingResponse;
}

interface ITraining {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  slug: string;
}

interface IParamsProps {
  slug?: string;
}

const TrainingList: React.FC = () => {
  const params = useParams<IParamsProps>();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [category, setCategory] = useState('All');
  const [trainings, setTrainings] = useState<ITraining[]>([]);
  const [loading, setLoading] = useState(false);
  const [carousel, setCarousel] = useState<ITraining[]>([]);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    if (params.slug) {
      setSearchValue('');
      api
        .get(`categories/${params.slug}`, {
          params: {
            type: 'trainings',
          },
        })
        .then(async (response) => {
          const data = response.data.trainingCategory.map(
            (trainingCategory: ITrainingCategoryResponse) => ({
              id: trainingCategory.training.id,
              title: trainingCategory.training.title,
              description: trainingCategory.training.description,
              thumbnail: trainingCategory.training.thumbnail.thumbnail_url,
              slug: trainingCategory.training.slug,
            })
          );
          setTrainings(data);
          setCategory(response.data.name);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      api
        .get('trainings')
        .then(async (response) => {
          const data = response.data.map((training: ITrainingResponse) => ({
            id: training.id,
            title: training.title,
            description: training.description,
            thumbnail: training.thumbnail.thumbnail_url,
            slug: training.slug,
          }));
          setTrainings(data);
          setCarousel(data);
          setCategory('All');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [params.slug]);

  const handleSearch = useCallback(
    async (e) => {
      history.push(`${process.env.PUBLIC_URL}/trainings`);
      setSearchValue(e.target.value);
      const response = await api.get('trainings', {
        params: {
          title: e.target.value,
        },
      });

      if (response.data) {
        const data = response.data.map((training: ITrainingResponse) => ({
          id: training.id,
          title: training.title,
          description: training.description,
          thumbnail: training.thumbnail.thumbnail_url,
          slug: training.slug,
        }));
        setTrainings(data);
      }
    },
    [history]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row align-items-lg-center">
                  <div className="col-sm-6 col-lg-8 px-0">
                    <div className="d-lg-flex text-center text-sm-left">
                      <h1 className="h2 mb-0 text-left">Training</h1>
                      <Link
                        to={`${process.env.PUBLIC_URL}/trainings/register`}
                        className="d-none d-sm-inline-block rounded-pill btn d-lg-flex px-4 py-3 py-lg-1 ml-lg-5 mt-4 mt-sm-5 mt-lg-0 align-items-lg-center add-training"
                      >
                        <img
                          src={video}
                          alt="Add New Training"
                          className="mr-2"
                        />
                        Add New Training
                      </Link>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-4 mt-4 mt-sm-0 text-center text-sm-left">
                    <Search className="d-flex">
                      <input
                        className="w-100"
                        placeholder="Search"
                        onChange={handleSearch}
                        value={searchValue}
                      />
                      <img src={search} alt="Search" className="mx-2" />
                    </Search>
                    <Link
                      to={`${process.env.PUBLIC_URL}/trainings/register`}
                      className="d-sm-none rounded-pill btn px-4 py-3 mt-5 add-training"
                    >
                      <img
                        src={video}
                        alt="Add New Training"
                        className="mr-2"
                      />
                      Add New Training
                    </Link>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          <div className="col-xl-9 mt-4">
            <Slider
              autoplaySpeed={5000}
              autoplay
              dots
              arrows={false}
              infinite
              speed={2000}
              slidesToShow={1}
            >
              {carousel.map((trainingCarrousel, index) => (
                <Card className="p-relative px-2">
                  <>
                    <img
                      src={trainingCarrousel.thumbnail}
                      alt="Marketing to
                      wooComerce"
                      className="w-100 training-img"
                    />
                    <div className="p-absolute h-100">
                      <div className="w-100 h-100 row ">
                        <div className="col-sm-7 col-md-8 px-0">
                          <p className="mb-0 pl-4 pr-0 pr-sm-4 pt-4 h4 h2-sm">
                            {trainingCarrousel.title}
                          </p>
                        </div>
                        <div className="col-sm-5 col-md-4 pb-4 pb-sm-0">
                          <div className="row align-content-sm-between align-items-end h-100 px-md-4">
                            <div className="order-1 order-sm-0 col-4 col-sm-12 text-right mt-sm-4">
                              <Link
                                to={`${process.env.PUBLIC_URL}/trainings/update/${trainingCarrousel.slug}`}
                                className="d-flex justify-content-end rounded-pill px-sm-3 py-3 edit font-weight-bold"
                              >
                                Edit
                                <img src={edit} className="ml-3" alt="" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                </Card>
              ))}
            </Slider>
          </div>
          <div className="mt-4 col-md-4 col-xl-3">
            <Categories type="trainings" />
          </div>
          <div className="col-md-8 col-xl-12 mt-0 mt-md-5 mt-lg-4">
            <div className="row justify-content-lg-between justify-content-xl-start">
              <div className="col-12">
                <h2 className="h5">
                  {searchValue ? `Filter: ${searchValue}` : category}
                </h2>
              </div>

              {trainings.length > 0 ? (
                <>
                  {trainings.map((training, index) => (
                    <Fragment key={training.id}>
                      <div className="col-sm-6 col-xl-3 my-3">
                        <Card className="p-relative">
                          <img
                            src={training.thumbnail}
                            alt={training.title}
                            className="w-100 mkt-img"
                          />
                          <div className="p-absolute w-100 h-100">
                            <div className="w-100 h-100 row mx-0">
                              <div className="col-12">
                                <p className="mb-0 px-3 pt-4 h4">
                                  {training.title}
                                </p>
                              </div>
                              <div className="col-12">
                                <div className="d-flex align-items-end justify-content-end h-100 px-3 pb-4">
                                  <Link
                                    to={`${process.env.PUBLIC_URL}/trainings/update/${training.slug}`}
                                    className="d-flex justify-content-end rounded-pill px-sm-3 py-3 edit font-weight-bold"
                                  >
                                    <span>Edit</span>
                                    <img src={edit} className="ml-3" alt="" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </Fragment>
                  ))}
                </>
              ) : (
                <div className="col-12 my-5">
                  <p className="h5 text-center">
                    No trainings found for this filter.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default TrainingList;
