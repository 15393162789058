import styled from 'styled-components';
import { darken } from 'polished';

interface ILesson {
  src: string;
}

export const Container = styled.div`
  .bg-gray {
    background-color: rgba(32, 32, 32, 1);
    border-radius: 20px;
  }

  .border-gray {
    border-color: #8c8c8c;
  }

  .btn-light-grey {
    background-color: rgba(203, 204, 206, 1);
    border: none;
    color: rgba(110, 110, 110, 1);
    border-radius: 15px;
  }

  .step-lesson {
    span {
      color: #8c8c8c;

      :hover {
        color: rgba(32, 32, 32, 1) !important;
        text-decoration: none;
      }
    }
    text-decoration: none;
  }

  .min-width {
    min-width: 600px;
  }
`;

export const Lessons = styled.div`
  background: #202020;
  border-radius: 20px;
  padding: 20px;

  a {
    :hover {
      text-decoration: none;
    }
  }

  .bg-gray {
    background: #242526;
    border-radius: 20px;
    overflow: hidden;

    p {
      color: #565656;
    }
  }

  .mh-220 {
    min-height: 220px;
  }
`;

export const Lesson = styled.div<ILesson>`
  .course-link {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url(${(props) => props.src});
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    height: 100%;

    p {
      color: #e4e4e4;
      font-weight: 600;
    }

    .btn-edit {
      color: #cbccce;
      font-weight: 700;

      :hover {
        color: ${darken(0.1, '#CBCCCE')};
        text-decoration: none;
        img {
          filter: brightness(2.5);
        }
      }
    }
  }
`;
