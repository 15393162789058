import React from 'react';
import PlayerCopy from '~/components/Player';

// import { Container } from './styles';

const TestVideo: React.FC = () => {
  return (
    <div>
      <PlayerCopy src="https://cdn.autoaffiliate.ai/courses-and-lessons/1715781126245-superbook-portugues-o-caminho-para-damasco-temporada-1-episodio-12-versao-oficial-em-hd/1715781126245-superbook-portugues-o-caminho-para-damasco-temporada-1-episodio-12-versao-oficial-em-hd.m3u8" />
    </div>
  );
};

export default TestVideo;
