import React, { useState } from 'react';
import { Container } from './styles';
import Emails from './Emails';
import Social from './Social';
import Images from './Images';

const Swipe: React.FC = () => {
  const [choiseShare, setChoiseShare] = useState('emails');
  const [isPostEase, setIsPostEase] = useState(true);

  return (
    <>
      <Container className="btn-choise mx-auto overflow-auto my-3">
        <div className="min-width d-flex justify-content-center">
          <button
            type="button"
            className={`${choiseShare === 'emails' && 'active'}`}
            onClick={() => setChoiseShare('emails')}
          >
            Emails
          </button>
          <button
            type="button"
            className={`${choiseShare === 'social' && 'active'}`}
            onClick={() => setChoiseShare('social')}
          >
            Social Posts
          </button>
          <button
            type="button"
            className={`${choiseShare === 'images' && 'active'}`}
            onClick={() => setChoiseShare('images')}
          >
            Images
          </button>
          <button
            type="button"
            className={`${choiseShare === 'headlines' && 'active'}`}
            onClick={() => setChoiseShare('headlines')}
          >
            Headlines
          </button>
          <button
            type="button"
            className={`${choiseShare === 'videos' && 'active'}`}
            onClick={() => setChoiseShare('videos')}
          >
            Videos
          </button>
          <button
            type="button"
            className={`${choiseShare === 'flyers' && 'active'}`}
            onClick={() => setChoiseShare('flyers')}
          >
            Flyers
          </button>
          <button
            type="button"
            className={`${choiseShare === 'business' && 'active'}`}
            onClick={() => setChoiseShare('business')}
          >
            Business Cards
          </button>
        </div>
        <div className="text-center mt-4 btn-product">
          <button
            type="button"
            className={`${isPostEase ? 'active' : ''}`}
            onClick={() => setIsPostEase(true)}
          >
            PostEASE
          </button>
          <button
            type="button"
            className={`${!isPostEase ? 'active' : ''}`}
            onClick={() => setIsPostEase(false)}
          >
            AutoAffiliate
          </button>
        </div>
      </Container>

      {choiseShare === 'emails' && <Emails isPostEase={isPostEase} />}
      {choiseShare === 'social' && <Social isPostEase={isPostEase} />}
      {choiseShare === 'images' && <Images isPostEase={isPostEase} />}
    </>
  );
};

export default Swipe;
