import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import { Form } from '@unform/web';
import Select from '~/components/Select';

import { Container, NameTable, Input } from './styles';
import Table from '~/components/Table';
import api from '~/services/api';

interface ITableData {
  id: string;
  affiliate_id: string;
  referral_id: string;
  affiliate_owner_id: string;
  username: string;
  name: string;
  avatar: string;
  affiliate_owner: string;
}
interface IOption {
  id: string;
  value: string;
  affiliate_id: string;
  selected: boolean;
}

interface IListFormatProps {
  active: boolean;
  changeValues: boolean;
  setChangeValues?(value: boolean): void;
}

interface IAffilaiteResponse {
  id: string;
  user: {
    id: string;
    name: string;
    email: string;
    referral_code: string;
    avatar: {
      avatar_url: string;
    };
    referrer?: {
      id: string;
      affiliate: {
        id: string;
        user: {
          name: string;
          email: string;
        };
      };
    };
  };
}

const ListFormat: React.FC<IListFormatProps> = ({
  active,
  changeValues,
  setChangeValues,
}) => {
  const [show, setShow] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [allAffiliates, setAllAffiliates] = useState<ITableData[]>([]);
  const [affiliates, setAffiliates] = useState<ITableData[]>([]);
  const [selectedMemberData, setSelectedMemberData] = useState<ITableData>(
    {} as ITableData
  );
  const [affiliateSelected, setAffiliateSelected] = useState<ITableData>(
    {} as ITableData
  );
  const [affiliateAvailables, setAffilaiteAvailables] = useState<IOption[]>([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);

  const handleLoadAffiliates = useCallback(async (filterData) => {
    const response = await api.get<IAffilaiteResponse[]>('affiliates', {
      params: {
        filter: filterData,
      },
    });
    const data: ITableData[] = response.data.map((affliate) => {
      return {
        id: affliate.user.id,
        affiliate_id: affliate.id,
        affiliate_owner_id: affliate.user.referrer
          ? affliate.user.referrer.affiliate.id
          : '',
        referral_id: affliate.user.referrer ? affliate.user.referrer.id : '',
        username: affliate.user.referral_code,
        avatar: affliate.user.avatar?.avatar_url,
        name: affliate.user.name || affliate.user.email,
        affiliate_owner: affliate.user.referrer
          ? affliate.user.referrer.affiliate.user?.name ||
            affliate.user.referrer.affiliate.user?.email ||
            ''
          : '',
      };
    });

    return data;
  }, []);

  useEffect(() => {
    setLoading(true);
    handleLoadAffiliates(filter)
      .then((data) => {
        setAllAffiliates((state) => (state.length === 0 ? data : state));
        setAffiliates(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filter, handleLoadAffiliates]);

  const handleClickPlaceMember = useCallback(
    async (selectedAffiliate: ITableData) => {
      setLoading(true);
      const affiliatesData = await handleLoadAffiliates('');
      const data = affiliatesData.map<IOption>((affiliate) => ({
        id: affiliate.id,
        affiliate_id: affiliate.id,
        value: affiliate.name,
        selected: false,
      }));
      setAffiliateSelected(selectedAffiliate);
      setAffilaiteAvailables(data);
      setShow(true);
      setLoading(false);
    },
    [handleLoadAffiliates]
  );

  const handleClose = useCallback(() => {
    setShow(false);
    setShowRemoveModal(false);
  }, []);

  const handleDone = useCallback(async () => {
    setLoading(true);
    try {
      const formData = {
        affiliate_id: selectedMemberData.affiliate_id,
        user_id: affiliateSelected.id,
      };

      const response = await api.post(`referrers`, formData);

      const newAffiliateList = affiliates.slice();
      const crowdfundingIndex = newAffiliateList.findIndex(
        (newAffiliate) => newAffiliate === affiliateSelected
      );
      newAffiliateList[crowdfundingIndex].affiliate_owner_id =
        selectedMemberData.affiliate_id;
      newAffiliateList[crowdfundingIndex].affiliate_owner =
        selectedMemberData.name;
      newAffiliateList[crowdfundingIndex].referral_id = response.data.id;
      setAffiliates(newAffiliateList);
      if (setChangeValues) {
        setChangeValues(!changeValues);
      }
      setSelectedMemberData({} as ITableData);
      handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [
    changeValues,
    affiliates,
    affiliateSelected,
    handleClose,
    selectedMemberData.affiliate_id,
    selectedMemberData.name,
    setChangeValues,
  ]);

  const handleRemovePlaceMember = useCallback(
    (selectedAffilaite: ITableData) => {
      setAffiliateSelected(selectedAffilaite);
      setShowRemoveModal(true);
    },
    []
  );

  const handleConfirmRemove = useCallback(async () => {
    setLoading(true);
    try {
      await api.delete(`referrers/${affiliateSelected.referral_id}`);

      const newAffiliateList = affiliates.slice();
      const crowdfundingIndex = newAffiliateList.findIndex(
        (newAffiliate) => newAffiliate === affiliateSelected
      );
      newAffiliateList[crowdfundingIndex].affiliate_owner_id = '';
      newAffiliateList[crowdfundingIndex].affiliate_owner = '';
      newAffiliateList[crowdfundingIndex].referral_id = '';
      setAffiliates(newAffiliateList);
      if (setChangeValues) {
        setChangeValues(!changeValues);
      }

      handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [
    changeValues,
    affiliates,
    affiliateSelected,
    handleClose,
    setChangeValues,
  ]);

  const columns = useMemo(
    () => [
      {
        name: 'Username',
        selector: 'username',
        sortable: true,
      },
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        cell: (row: ITableData) => (
          <NameTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center text-center"
          >
            <img src={row.avatar} alt={row.name} className="mr-2" />
            <p className="mb-0">{row.name}</p>
          </NameTable>
        ),
      },
      {
        name: 'Assign New Placement Sponsor',
        cell: (row: ITableData) => (
          <Input
            name="input"
            className="rounded-pill form-control"
            value={row.affiliate_owner}
            disabled
          />
        ),
      },
      {
        name: 'Action',
        cell: (row: ITableData) => (
          <button
            type="button"
            onClick={() =>
              row.affiliate_owner_id
                ? handleRemovePlaceMember(row)
                : handleClickPlaceMember(row)
            }
            className="btn-dark rounded-pill w-100 font-weight-light py-1 table-btn"
          >
            <span className="d-block py-1 px-2">
              {row.affiliate_owner_id ? 'Remove Member' : 'Place Member'}
            </span>
          </button>
        ),
      },
    ],
    [handleClickPlaceMember, handleRemovePlaceMember]
  );

  const handleChange = useCallback(
    (e) => {
      const member = allAffiliates.find(
        (crowdfundingSearch) => e.id === crowdfundingSearch.id
      );
      if (member) {
        setSelectedMemberData(member);
      }
    },
    [allAffiliates]
  );

  const handleSearch = useCallback((value) => {
    setFilter(value);
  }, []);

  return (
    <Container className={`col-12 ${active ? 'd-block' : 'd-none'}`}>
      <div className="row">
        <div className="col-12 first-table px-0 px-sm-3 my-3">
          <Table
            data={affiliates}
            columns={columns}
            searchable
            onSearch={handleSearch}
            pagination
          />
        </div>
        {false && (
          <div className="col-12 my-3 table pb-3">
            <div className="d-flex justify-content-between align-items-center header p-3">
              <div className="small" />
              <div className="small">Username</div>
              <div className="small">Name</div>
              <div className="small">Product</div>
              <div className="small">Referrer</div>
              <div className="small">Assigned Placement Sponsor</div>
              <div className="small">Level</div>
            </div>
            {affiliates.map((affiliate) => (
              <div className="row-table-group">
                <div className="d-flex justify-content-between align-items-center row-table p-3">
                  <div>
                    <BsChevronRight size={20} color="#fff" />
                  </div>
                  <div>{affiliate.username}</div>
                  <div>
                    <NameTable
                      data-tag="allowRowEvents"
                      className="d-flex align-items-center"
                    >
                      <img
                        src={affiliate.avatar}
                        alt={affiliate.name}
                        className="mr-2"
                      />
                      <p className="mb-0">{affiliate.name}</p>
                    </NameTable>
                  </div>
                  <div>{affiliate.affiliate_owner}</div>
                  <div>1</div>
                </div>
                <div className="row-table-group pl-2">
                  <div className="d-flex justify-content-between align-items-center row-table p-3">
                    <div>
                      <BsChevronRight size={20} color="#fff" />
                    </div>
                    <div>{affiliate.username}</div>
                    <div>
                      <NameTable
                        data-tag="allowRowEvents"
                        className="d-flex align-items-center"
                      >
                        <img
                          src={affiliate.avatar}
                          alt={affiliate.name}
                          className="mr-2"
                        />
                        <p className="mb-0">{affiliate.name}</p>
                      </NameTable>
                    </div>
                    <div>teste</div>
                    <div>2</div>
                  </div>
                  <div className="row-table-group pl-2">
                    <div className="d-flex justify-content-between align-items-center row-table p-3">
                      <div>
                        <BsChevronRight size={20} color="#fff" />
                      </div>
                      <div>{affiliate.username}</div>
                      <div>
                        <NameTable
                          data-tag="allowRowEvents"
                          className="d-flex align-items-center"
                        >
                          <img
                            src={affiliate.avatar}
                            alt={affiliate.name}
                            className="mr-2"
                          />
                          <p className="mb-0">{affiliate.name}</p>
                        </NameTable>
                      </div>
                      <div>teste</div>
                      <div>3</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Modal
        size="lg"
        className="modal-crowdfunding"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign New Placement Sponsor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-12">
              <Form
                onSubmit={() => {
                  console.log('submit');
                }}
              >
                <label htmlFor="member">Assign New Placement Sponsor</label>
                <Select
                  id="member"
                  name="member"
                  options={affiliateAvailables}
                  onChange={handleChange}
                />
              </Form>
            </div>
            {Object.keys(selectedMemberData).length > 0 ? (
              <div className="col-lg-3 mt-5">
                <div className="h-100 p-3">
                  <img
                    src={selectedMemberData.avatar}
                    alt={selectedMemberData.name}
                    className="w-75 rounded-circle mb-3"
                  />{' '}
                  <p>{selectedMemberData.name}</p>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={handleDone}
            className="btn-grey px-3 py-1"
          >
            <span className="d-block py-1 px-2">Done</span>
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        className="modal-crowdfunding"
        show={showRemoveModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Do you want to remove the selected member?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center pb-3">
            <div className="col-lg-5">
              <button
                type="button"
                onClick={handleConfirmRemove}
                className="btn-grey px-3 py-1 w-100"
              >
                <span className="d-block py-1 px-2">Yes</span>
              </button>
            </div>
            <div className="col-lg-5">
              <button
                type="button"
                onClick={handleClose}
                className="btn-dark px-3 py-1 w-100"
              >
                <span className="d-block py-1 px-2">No</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default ListFormat;
