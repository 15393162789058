import styled, { css } from 'styled-components';

interface IThumbnail {
  src?: string;
}

export const Container = styled.div`
  .bg-gray {
    background: #242526;
    border-radius: 20px;
    overflow: hidden;
    border: solid 2px #333;

    p {
      color: #565656;
    }
  }

  .img-thumbnail {
    background-color: rgb(0 0 0 / 50%);
    border: none;
  }

  .underline {
    text-decoration: underline;
    color: rgba(228, 228, 228, 1) !important;
  }

  @media screen and (max-width: 991px) {
    .height-lg {
      height: 65vw;
    }
  }
`;

export const Welcome = styled.div`
  p {
    color: #969696;
  }

  .search {
    padding: 1px;
    border-radius: 100px;
    border: 1px solid #4a4b4b;
    background-color: #202020;

    input {
      margin: 5px 0;
      padding: 0 10px;
      border: none;
      border-radius: 100px;
      background-color: #202020;
    }
  }
`;

export const InputsGroup = styled.div`
  background: #202020;
  border-radius: 15px;

  .upload-file {
    background: #242526;
    border-radius: 10px;
    border: 1.5px solid #6e6e6e;
    padding: 5px 10px;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #cbccce;
  }

  p,
  label {
    color: rgba(130, 130, 130, 1);
  }
`;

export const Thumbnail = styled.label<IThumbnail>`
  ${(props) =>
    props.src &&
    props.src.length > 0 &&
    css`
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url(${props.src}), #242526 !important;
      background-position: center !important;
      background-size: cover !important;
      border: none !important;
    `}
`;
