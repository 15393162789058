import styled from 'styled-components';

export const Container = styled.div`
  .rdt_TableHeadRow,
  .rdt_TableRow {
    > div:nth-child(5) {
      max-width: 100%;
      width: 100%;
    }
  }

  .first-table {
    background: #202020;
    border-radius: 20px;
    width: 870px;
  }

  .table {
    background: #202020;
    border-radius: 20px;
    color: #8c8c8c;

    .header {
      color: #cbccce;
    }

    .row-table-group {
      + .row-table-group {
        margin-top: 1rem;
      }
    }

    .row-table {
      background-color: #242526;
      border-radius: 15px;

      + div {
        margin-top: 1rem;
      }
    }

    .d-flex {
      > div {
        max-width: 16%;
        width: 16%;
      }

      > div:nth-child(1) {
        max-width: 3%;
        width: 3%;
        min-width: 0;
        padding-right: 0;
      }

      > div:nth-child(3) {
        max-width: 20%;
        width: 20%;
      }

      > div:nth-child(5) {
        max-width: 16%;
        width: 16%;
      }

      > div:nth-child(6) {
        max-width: 25%;
        width: 25%;
      }
    }
  }

  .tableData div:nth-child(2) > div {
    min-width: 200px;
  }

  @media screen and (max-width: 767px) {
    .tableData {
      margin-top: 6rem;
    }

    .menu-table {
      margin-top: 5rem;
    }
  }
`;

export const NameTable = styled.div`
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
`;

export const Input = styled.input`
  background: #202020;
  border: 1px solid #606060;
  color: #8c8c8c;

  :disabled {
    background: #202020;
    border: 1px solid #606060;
  }
`;
