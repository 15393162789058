import { shade, lighten } from 'polished';
import styled from 'styled-components';

interface IBgImageProps {
  src: string;
}

export const Container = styled.div``;

export const Welcome = styled.div`
  p {
    color: #969696;
  }
  .btn-new-course {
    color: #dadada !important;
    border: solid 1px rgba(96, 96, 96, 1);
    background-color: rgba(32, 32, 32, 1);
    padding: 0.8rem 0;
    :hover {
      color: ${lighten(0.1, '#dadada')}!important;
      border: solid 1px ${lighten(0.1, 'rgba(96, 96, 96, 1)')};
      text-decoration: none;
      img {
        filter: brightness(2.5);
      }
    }
  }
  @media screen and (max-width: 768px) {
  }
`;
export const Search = styled.div`
  padding: 1px;
  border-radius: 100px;
  border: 1px solid #242526;
  background-color: transparent;

  input {
    margin: 5px 0;
    padding: 0 10px;
    border: none;
    border-radius: 100px;
    background-color: transparent;
    color: #cbccce !important;

    ::placeholder {
      color: #cbccce !important;
    }
  }
`;

export const Options = styled.div`
  a,
  button {
    border: none;
    background: none;
    color: #ababab;

    :hover {
      color: #ffffff;

      text-decoration: none;
    }
  }

  hr {
    border-bottom: solid 1px #3a3b3d;
  }

  .bd-radius-20 {
    border-radius: 20px;
  }

  .overview {
    h2 {
      color: rgba(109, 109, 109, 1);
    }
    hr {
      background-color: rgba(51, 51, 51, 1);
    }
    .over-card {
      background-color: rgba(32, 32, 32, 1);
      border-radius: 20px;
    }
  }

  a.active,
  button.active {
    color: #ffffff;
    -webkit-text-stroke: 0.5px #fff;
  }

  .border-silver {
    border-radius: 20px;
  }

  .title-card {
    color: #8c8c8c;
  }

  .number-courses {
    font-weight: 600;
    color: #cbccce;
  }
`;

export const Drafts = styled.div`
  .thumbnail-course {
    width: 100%;
    height: 200px;
    border-radius: 20px;
  }

  .infos-course {
    transition-duration: 0.3s;
    z-index: 1;
    width: 100%;
    height: 50px;
    top: calc(100% - 49px);

    .bg-image {
      transition-duration: 0.2s;
      opacity: 0;
      border-radius: 0px 0px 20px 20px;
      mask-image: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(0, 0, 0, 1)),
        to(rgba(0, 0, 0, 0))
      );
    }

    .infos {
      border-radius: 0px 0px 20px 20px;
      background: linear-gradient(
        188.52deg,
        rgba(1, 1, 1, 0.192) -0.55%,
        rgba(7, 7, 7, 0.8) 95.76%
      );
      padding: 15px 10px;
      overflow: hidden;
    }

    h2 {
      color: #e4e4e4;
    }
  }

  .infos-course.active {
    height: 266px;
    z-index: 500;

    .bg-image {
      opacity: 1;
      border-radius: 20px;
    }

    .infos {
      border-radius: 20px;
      backdrop-filter: blur(35px);
    }
  }

  .btn-edit {
    background: #242526;
    border: 1px solid #4a4b4b;
    color: #8c8c8c;

    :hover {
      text-decoration: none;
    }
  }
`;

export const BgImage = styled.div<IBgImageProps>`
  z-index: -1;
  width: 100%;
  height: 100%;
  bottom: 0;
  background-image: url('${(props) => props.src}');
  background-position: center;
  background-size: 550px;
  background-repeat: no-repeat;
`;

export const Calendar = styled.aside`
  width: 380px;

  .DayPicker {
    border-radius: 10px;
  }

  .DayPicker-wrapper {
    padding-bottom: 0;
    border-radius: 10px;
    border: 1px solid #dadada;
  }

  .DayPicker,
  .DayPicker-Month {
    width: 100%;
  }

  .DayPicker-NavButton {
    color: #999591 !important;
  }

  .DayPicker-NavButton--prev {
    right: auto;
    left: 1.5em;
    margin-right: 0;
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 8px;
    margin: 16px 0 0 0;
    padding: 16px;
    border-radius: 0 0 10px 10px;
  }

  .DayPicker-Caption {
    margin-bottom: -10px;
    padding: 0 1em 1em;
    color: #dadada;
    border-bottom: 1px solid #dadada;

    > div {
      text-align: center;
    }
  }

  .DayPicker-Day--available:not(.DayPicker-Day--outside) {
    background: transparent;
    border-radius: 50%;
    color: #fff;
    border: 1px solid #dadada;
  }

  .DayPicker-Day--outside {
    background: transparent;
    border-radius: 50%;
    color: #565656;
    border: 1px solid #565656;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background: ${shade(0.2, '#3e3b47')};
  }

  .DayPicker-Day--today {
    font-weight: normal;
  }

  .DayPicker-Day--disabled {
    color: #666360 !important;
    background: transparent !important;
  }

  .DayPicker-Day--selected {
    border-color: #dadada !important;
    background: #dadada !important;
    color: #000000 !important;
    font-weight: bold;
  }
`;
